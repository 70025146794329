import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const SalaryGenerate = () => {

    return (
        <div>
        <DashboardHeader payroll salaryGenerate/>
        <div className="card p-3  styled-card">
            <h6 className="heading-text">Generate Salary</h6>
            <hr/>
            <Form autoComplete="off">
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Salary Month<span
                        className="text-danger asterics">*</span></Form.Label>
                    <Col sm={8}>
                        <Form.Control type="month"/>
                    </Col>
                </Form.Group>

                <div className="text-center">
                    <button className="btn pharmacy-btn px-4 mt-3">Generate</button>
                </div>
            </Form>
        </div>
        </div>
    )
};
export default SalaryGenerate;
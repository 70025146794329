import React from "react";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import {usePurchaseOrderDetails} from "./CustomHooks/usePurchaseOrderDetails";
import {ReusablePurchaseOrderDetails} from "./ReusablePurchaseOrderDetails";


const PurchaseOrderDetails = ({match}) => {
const {sub_total, downloadPdf, productList, details,
    arr, instructions, pdfRef} = usePurchaseOrderDetails(match)
    return (
        <div>
            <DashboardHeader purchase purchaseOrderDetails/>
            <div className="purchase-order-root">
                <button onClick={downloadPdf} className="btn pharmacy-info-btn px-3 mb-2 offset-8">Download PDF</button>
                <ReusablePurchaseOrderDetails details={details} instructions={instructions} arr={arr}
                pdfRef={pdfRef} productList={productList} sub_total={sub_total}/>
            </div>
        </div>
    )
};
export default PurchaseOrderDetails;
import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions"
import Workbook from "react-excel-workbook";
import DeleteDialog from "../../Utils/DeleteDialog";
import {titleCase} from "../../Utils/titleCaseFunction";
import {CSVLink} from "react-csv";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {useManageMedicine} from "./CustomHooks/useManageMedicine";
import {CustomPagination, EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";

const  ManageMedicine  = ({actions, snackbars}) => {
        const {openDialog,  product_count, activePage, products, loading, searchValue,
             handleChange, handleOpenDialog, handleCloseDialog, downloadPDF,
           handleCloseBar,  handlePageChange,handleDelete} = useManageMedicine(actions)
        const {variant, message, open} = snackbars;
        const user = JSON.parse(sessionStorage.getItem('user'));
        const singleUser = !user ? {} : user;
        const permissions = !singleUser.permission ? {} : singleUser.permission;
        const manage_medicine = !permissions.manage_medicine ? {} : permissions.manage_medicine;
    const headData = [
        {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Medicine Name'},
        {id: 'generic', numeric: false, disablePadding: false, label: 'Generic Name'},
        {id: 'type', numeric: false, disablePadding: false, label: 'Medicine Type'},
        {id: 'unit', numeric: false, disablePadding: false, label: 'Unit'},
        {id: 'shelf', numeric: false, disablePadding: false, label: 'Shelf'},
        {id: 'strength', numeric: false, disablePadding: false, label: 'Strength'},
        {id: 'reorderlevel', numeric: false, disablePadding: false, label: 'Reorder Level'},
        {id: 'sellprice', numeric: false, disablePadding: false, label: 'Selling Price'},
        (manage_medicine.create || manage_medicine.update || manage_medicine.delete)
            ? {id: 'action', numeric: false, disablePadding: false, label: 'Action'}:
            {id: 'nonaction', numeric: false, disablePadding: false, label: ''},
    ];
        return (
            <div className='journals'>
                <ReusableDashboardHeader component='Manage Medicine' heading="Medicine"
                                         subHeading='Manage Medicine' link='/managemedicine'/>
                <div className="general-ledger-header">
                    <div className="row">
                        <div className="col-md-6">
                            <h6 className="ml-3 mt-2">Manage Medicine</h6>
                        </div>
                        <div className="col-md-6">
                            <div className="text-right">
                                <Link to="/addmedicine" style={{textDecoration: "none", color: "inherit"}}>
                                    <button type="button" className="btn mr-2 pharmacy-btn">
                                        <FontAwesomeIcon icon={faPlus}/> Add Medicine
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <DeleteDialog openDialog={openDialog} handleDelete={handleDelete}
                              handleClose={handleCloseDialog} message="Product"/>

                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={handleCloseBar}/>
                <div className="mui-tables">
                    <MuiPaper>
                        <EnhancedTableToolbar title='Medicines'>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                                    <CSVLink
                                        data={products}
                                        filename="Products.csv"
                                        target="_blank"
                                    >
                            <button className="btn btn-sm pharmacy-btn mr-1">CSV</button>
                        </CSVLink>

                        <Workbook filename="Products.xlsx" element={
                            <span><button className="btn pharmacy-btn btn-sm mr-1">Excel</button></span>
                        }>
                            <Workbook.Sheet data={products} name="Products">
                                <Workbook.Column label="Medicine Name" value="product_name"/>
                                <Workbook.Column label="Generic Name" value="generic_name"/>
                                <Workbook.Column label="Medicine Type" value="product_model"/>
                                <Workbook.Column label="Shelf" value="product_location"/>
                                <Workbook.Column label="Strength" value="strength"/>
                            </Workbook.Sheet>

                        </Workbook>

                        <span><button className="btn pharmacy-btn mr-1 btn-sm"
                                      onClick={downloadPDF}>Pdf</button></span>
                            </span>
                            <Form.Control type="text" value={searchValue ? titleCase(searchValue) : ''}
                                          onChange={handleChange}
                                          className='form__control'
                                          placeholder="Search" style={{float: 'right'}}/>
                        </EnhancedTableToolbar>
                            <Table
                                aria-labelledby="tableTitle"
                                size='medium'
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead headCells={headData}/>
                                {loading ? <TableBody><TableRow>
                                    <TableCell colSpan="10" align="center"><img src="/images/listPreloader.gif"
                                                                               alt="loader"
                                                                               style={{width: '5%'}}/></TableCell>
                                </TableRow></TableBody> : products.length > 0 ?
                                    <TableBody>
                                        {products.slice(0, 10)
                                            .map((info, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                        className='mui-table-row'>
                                                        <TableCell component="th" scope="row" padding="default"
                                                                   className='mui-table-cell'>
                                                            <span>{info.sl}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                    <span><Link to={{
                                                        pathname: `/medicinedetails/${info.product_id}`
                                                    }} style={{color: "#09905c"}}>{titleCase(info.product_name)}</Link></span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{titleCase(info.generic_name)}</span>
                                                        </TableCell>
                                                        <TableCell>{info.product_model}</TableCell>
                                                        <TableCell>{!info.unit ? "" : info.unit}</TableCell>
                                                        <TableCell>{info.product_location}</TableCell>
                                                        <TableCell>{info.strength}</TableCell>
                                                        <TableCell>{info.reorder_level}</TableCell>
                                                        <TableCell>{info.price}</TableCell>
                                                        {(manage_medicine.update || manage_medicine.delete) ?
                                                            <TableCell id="mainbuttons">
                                                                {manage_medicine.update ?
                                                                    <Tooltip title="Update" placement="left-start">
                                                                        <Link to={{
                                                                            pathname: `/editmedicine/${info.product_id}`
                                                                        }}>
                                                                            <button
                                                                                className="btn btn-sm pharmacy-info-btn mr-3">
                                                                                <FontAwesomeIcon
                                                                                    icon={faPencilAlt}/></button>
                                                                        </Link>
                                                                    </Tooltip> : null}
                                                                {manage_medicine.delete ?
                                                                    <Tooltip title="Delete" placement="left-start">
                                                                        <button
                                                                            className="btn btn-sm pharmacy-btn-dismiss"
                                                                            onClick={() => handleOpenDialog(info.product_id)}>
                                                                            <FontAwesomeIcon icon={faTrash}/></button>
                                                                    </Tooltip> : null}
                                                            </TableCell> : <TableCell/>}
                                                    </TableRow>
                                                )
                                            })}
                                    </TableBody>

                                    : <TableBody><TableRow>
                                        <TableCell colSpan="10" align="center">No records found</TableCell>
                                    </TableRow></TableBody>}
                            </Table>
                        <CustomPagination data={products} records={10} activePage={activePage} total_count={product_count}
                                          handleChangeNextPage={handlePageChange}/>
                    </MuiPaper>
                </div>
            </div>
        )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMedicine);
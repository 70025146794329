import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import config from "../../Helpers/config";
import axios from "axios";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {history} from "../../Helpers/history";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Card from "@material-ui/core/Card";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import {dateStringConvert} from "../../Utils/titleCaseFunction";
import Textarea from "../../Utils/FormInputs/Textarea";
import InvoicePrintDialog from "../Invoice/InvoicePrintDialog";


const ManufacturerReturnInvoice  = ({actions, snackbars, match:{params}}) => {
    const {purchase_id} = params;
    const [rows, setRows] = useState([{product_name:'',product_id:'',batch_id:'',available_quantity:'',
        product_quantity:'',rate:'', discount:'', total:0, rtn:false,purchase_quantity:0,
        expire_date:'', cartoon:0,purchase_detail_id:''}]);
    const [data, setData] = useState({});
    const [totals, setTotals] = useState({total_discount: 0, grand_total_price: 0});
    const [state, setState] = useState({manufacturer_name: '', date: '', manufacturer_id: '', radio:0});
    const [details, setDetails] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [receipt_no, setReceiptNo] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        axios.get(`${config.epharmUrl}/Creturn/manufacturer_return_form`, {params:{purchase_id}}).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            setData(dt)
        }).catch((err) => {
            errorMessages(err, null, actions)
        })
    }, [actions, purchase_id])

    useEffect(() => {
        const {purchase_all_data, manufacturer_name,manufacturer_id,date,} = data;
        const invoiceArr = purchase_all_data ?? []
        const arr = invoiceArr.map(item => ({
            ...item, product_quantity: '', total: 0,available_quantity:item.avaialable_quantity,
            rtn: false, discount: '', rate:item.manufacturer_price, purchase_quantity:item.quantity,
            expire_date:item.expeire_date
        }))
        setState({
            manufacturer_name: manufacturer_name ?? '', date:date ?? '', manufacturer_id: manufacturer_id ?? '',  radio:0
        })
        setRows(arr)
    }, [data])

    const handleChangeState = (event) =>{
        setState({...state, radio: event.target.value})
    }

    const handleChangeDetails = event => {
        setDetails(event.target.value)
    };

    const calculateTotal = (item) => {
        return +(item.product_quantity) * +item.rate;
    };

    const calculateDiscount = (item) => {
        let discount_value = !item.discount ? 0 : item.discount;
        let val = +(discount_value) / 100;
        return calculateTotal(item) * val;
    };

    const calculateTotalAmount = (arr) => {
        let total_discount = arr.reduce((acc, row) => {
            const discount = !row.discount ? 0 : row.discount;
            const product_quantity = !row.product_quantity ? 0 : row.product_quantity;
            const total = product_quantity * row.rate;
            const val = total * discount / 100;
            return acc + val
        }, 0);
        let grand_total_price = arr.reduce((acc, row) => {
            const dt = row.total ? row.total : 0
            return acc + dt;
        }, 0);
        setTotals({total_discount, grand_total_price});
    };

    const calculateFinalTotal = (item) => {
        return calculateTotal(item) - calculateDiscount(item);
    };


    const changeCheckValue = (e, purchase_detail_id) => {
        const {checked} = e.target;
        const arr = rows.map(item => {
            if (item.purchase_detail_id === purchase_detail_id) {
                return {...item, rtn: checked, total: calculateFinalTotal(item)}
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };


    const handleChangeQuantity = (event, purchase_detail_id) => {
        const {value} = event.target;
        const arr = rows.map(item => {
            if (item.purchase_detail_id === purchase_detail_id) {
                return {
                    ...item,
                    product_quantity: value,
                    total: calculateFinalTotal({
                        rate: item.rate, product_quantity: value, discount: item.discount
                    })
                }
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };

    const handleChangeDiscount = (event, purchase_detail_id) => {
        const {value} = event.target;
        const arr = rows.map(item => {
            if (item.purchase_detail_id === purchase_detail_id) {
                return {
                    ...item,
                    discount: value,
                    total: calculateFinalTotal({
                        rate: item.rate,
                        product_quantity: item.product_quantity,
                        discount: value
                    })
                }
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleCancel = () =>{
        const {radio} = state;
        const obj = {'0':'/manufreturnlist/supplier', '1':'/wastagereturnlist/wastages', '2':'/damages/damages'}
        history.push(`${obj[+radio]}`)
    }

    const handleOpenReceipt = () =>{
        history.push(`/manufacturerinv/${receipt_no}`)
    }


    const handleSubmitReturn = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const {date, manufacturer_id,  radio} = state
        const {total_discount, grand_total_price} = totals;
        formData.append('purchase_id',purchase_id);
        formData.append('manufacturer_id',manufacturer_id);
        formData.append('details',details);
        formData.append('radio',radio);
        formData.append('invoice_id','');
        formData.append('purchase_date',date);
        rows.filter(item=>item.rtn).forEach(dt=>{
            if (dt.rtn === true) {
                formData.append('product_id', dt.product_id);
                formData.append('ret_qty', dt.product_quantity);
                formData.append('available_quantity', dt.available_quantity);
                formData.append('product_quantity',  dt.purchase_quantity);
                formData.append('cartoon',0);
                formData.append('product_rate', dt.rate);
                formData.append('total_price', dt.total);
                formData.append('expire_date', dt.expire_date);
                formData.append('batch_id', dt.batch_id);
                formData.append('discount', +(dt.discount));
            }

        })
        formData.append('grand_total_price',grand_total_price);
        formData.append('total_deduction',total_discount);
        const checked = rows.some(item => item.rtn);
        const required = rows.filter(item => item.rtn).every(row => row.product_quantity && row.product_quantity <= row.purchase_quantity)
        setSubmitted(true)
        if (checked && required) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Creturn/return_manufacturers`, formData).then(response => {
                const data = response.data;
                const dt = data ?? '';
                setIsSubmitted('resolved')
                actions.snackbarActions.successSnackbar('Product(s) successfully returned')
                setReceiptNo(dt)
                const obj = {'0':'/manufreturnlist/supplier', '1':'/wastagereturnlist/wastages', '2':'/damages/damages'}
                history.push(`${obj[+radio]}`)
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions)
            });
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }
    const {message, variant, open} = snackbars;
    const {manufacturer_name, date} = state;
    const {total_discount, grand_total_price} = totals;
    const disabled = rows?.some(item => item.rtn);
        return (
            <div className='journals'>
                <ReusableDashboardHeader component='Return' heading="Return"
                                         subHeading='Return' link={history.location.pathname}/>
                <Card className="p-3">
                    <InvoicePrintDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog}
                                        print={handleOpenReceipt} cancel={handleCancel} message="Receipt" text='receipt'/>
                    <PharmacySnackbar open={open} message={message} variant={variant}
                                      handleCloseBar={closeSnackbar}/>
                    <form  onSubmit={handleSubmitReturn} autoComplete='off'>
                        <div className="row">
                            <div className="col-md-5">
                                <Form.Group as={Row}>
                                    <Label column sm={3} name="Supplier Name"/>
                                    <Col sm={6}>
                                        <TextField type="text" value={manufacturer_name} readOnly/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label column sm={3} name='Date'/>
                                    <Col sm={6}>
                                        <TextField type="text"
                                                   value={dateStringConvert(date)} readOnly/>
                                    </Col>
                                </Form.Group>
                            </div>
                            <div className="col-md-7"/>
                        </div>

                        <div className="">
                            <table className="table table-bordered table-sm custom-bordered-table">
                                <thead>
                                <tr>
                                    <th>Product</th>
                                    <th> Batch</th>
                                    <th>Purchase Quantity</th>
                                    <th> Stock</th>
                                    <th>Return Quantity</th>
                                    <th>Unit Cost</th>
                                    <th>Discount &#37;</th>
                                    <th>Total</th>
                                    <th>Check Return</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rows.map((row) => (
                                    <tr key={row.purchase_detail_id}>
                                        <td>
                                            {row.product_name}
                                        </td>
                                      <td>
                                            {row.batch_id}
                                        </td>
                                        <td>
                                            {row.purchase_quantity}
                                        </td>

                                        <td>
                                            {row.available_quantity}
                                        </td>
                                        <td>
                                            <TextField type="number" value={row.product_quantity} min="0"
                                                       onChange={(e) => handleChangeQuantity(e, row.purchase_detail_id)}/>
                                            {submitted && !row.product_quantity ?
                                                <div className="invalid-text">Quantity is required</div> :
                                                (row.product_quantity && row.product_quantity > row.purchase_quantity) ?
                                                    <div className="invalid-text">You can only return up
                                                        to {row.purchase_quantity}</div> : null}
                                        </td>
                                        <td>
                                            {row.rate}
                                        </td>
                                        <td>
                                            <TextField type="number" value={row.discount}
                                                       name="discount" min="0"
                                                       onChange={(e) => (handleChangeDiscount(e, row.purchase_detail_id))}/>

                                        </td>
                                        <td>
                                            {row.total}
                                        </td>
                                        <td>
                                            <Form.Check type="checkbox"
                                                        vlaue={row.rtn}
                                                        name="rtn"
                                                        onChange={(e) => changeCheckValue(e,row.purchase_detail_id)}
                                                        style={{transform: "scale(1)"}}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={ 6} rowSpan={2}>
                                        <Textarea  name="details" value={details}
                                                   onChange={handleChangeDetails}/>
                                        <div className="mt-4">
                                            <Form.Check type="radio" name='radio'
                                                        value={+(0)}
                                                        defaultChecked onChange={handleChangeState}
                                                        label="Return to Supplier"/>
                                            <Form.Check type="radio" name='radio'
                                                        value={+(1)} onChange={handleChangeState}
                                                        label="Wastage"/>
                                            <Form.Check type="radio" name='radio'
                                                        value={+(2)} onChange={handleChangeState}
                                                        label="Damage"/>
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        Total Deduction:
                                    </td>
                                    <td>
                                        {total_discount}
                                    </td>
                                    <td/>
                                </tr>
                                <tr>
                                    <td className="text-right">
                                      Net Return
                                    </td>
                                    <td>
                                        {grand_total_price}
                                    </td>
                                    <td/>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <button disabled={!(isSubmitted === 'pending' || disabled)}
                                className="btn pharmacy-btn btn-sm">{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>
                    </form>
                </Card>
            </div>
        );

}

function mapStateToProps(state) {
    return {
        snackbars:state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions:bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerReturnInvoice);
import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {history} from "../../Helpers/history";
import {useManageCustomer} from "./CustomHooks/useManageCustomer";
import {ReusableRouteTabs} from "../../Utils/Tabs/ReusableTabs";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {CustomPagination, EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import {CSVLink} from "react-csv";
import Workbook from "react-excel-workbook";
import Form from "react-bootstrap/Form";
import {splitText, titleCase} from "../../Utils/titleCaseFunction";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import DeactivateDialog from "../../Utils/DeactivateDialog";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";


const ManageCustomer = ({actions, snackbars, match}) => {
    const {component} = match.params;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permission = !singleUser.permission ? {} : singleUser.permission;
    const manage_customer = !permission.manage_customer ? {} : permission.manage_customer;
    const credit_customer = !permission.credit_customer ? {} : permission.credit_customer;
    const paid_customer = !permission.paid_customer ? {} : permission.paid_customer;
    const {variant, message, open} = snackbars;
    const { customer_count, activePage, customers, loading, searchValue, openDeactivate,
        openDeposit,  deposit_amount,  submitted, isSubmitted, handleChange,  downloadPDF,  handleCloseBar,
         handleOpenDeactivate, handleCloseDeactivate, deactivateCustomer, handlePageChange,status,
        handleOpenDeposit, handleCloseDeposit, handleChangeDeposit, handleSubmitDeposit} = useManageCustomer(actions,component)
    const allCustomers = !customers ? [] : customers;
    const components = [{label: 'All Customers', path: '/managecustomer/manage_customer'},
        {label: 'Cash Customers', path: '/managecustomer/paid_customer'},
        {label: 'Debtors', path: '/managecustomer/credit_customer'}]
    const title = {
        manage_customer: 'All Customers',
        paid_customer: 'Cash Customers',
        credit_customer: 'Debtors'
    }
    const permissions = {
        manage_customer,
        paid_customer,
        credit_customer
    }
    const headData = [
        {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Customer Name'},
        {id: 'address', numeric: false, disablePadding: false, label: 'Address'},
        {id: 'mobile', numeric: false, disablePadding: false, label: 'Mobile'},
        {id: 'balance', numeric: false, disablePadding: false, label: 'Balance'},
        (manage_customer.create || manage_customer.update || manage_customer.delete)
            ? {id: 'action', numeric: false, disablePadding: false, label: 'Action'}:
            {id: 'nonaction', numeric: false, disablePadding: false, label: ''},
    ];
    return (
        <div className='journals'>
            <ReusableDashboardHeader component={title[component]} heading="Customers"
                                     subHeading={title[component]} link={history.location.pathname}/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className='mui-tables mt-3'>
                <ReusableDialog handleClose={handleCloseDeposit} openDialog={openDeposit}
                                isSubmitted={isSubmitted} handleSubmit={handleSubmitDeposit} width='sm'
                                message='Make Deposit'>
                    <Form.Group as={Row}>
                        <Label name='Amount' type column sm={2}/>
                        <Col sm="8">
                            <TextField type='number' value={deposit_amount} name='deposit_amount'
                                       onChange={handleChangeDeposit}/>
                            {submitted && !deposit_amount ? <div>Amount is required</div> : null}
                        </Col>
                    </Form.Group>
                </ReusableDialog>
                <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={handleCloseBar}/>
                <DeactivateDialog openDialog={openDeactivate} deactivate={deactivateCustomer} handleClose={handleCloseDeactivate}
                message={status === 1 ? 'deactivate':'activate'} title={status === 1 ? 'Deactivate':'Activate'}/>
                <MuiPaper>
                    <EnhancedTableToolbar title={title[component]}>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                                   <CSVLink
                                       data={allCustomers}
                                       filename="Customers.csv"
                                       target="_blank"
                                   ><button className="btn btn-sm pharmacy-btn mr-1" >CSV</button></CSVLink>
                                <Workbook filename="Customers.xlsx" element={
                                    <span><button className="btn pharmacy-btn mr-1 btn-sm">Excel</button></span>
                                }>
                                    <Workbook.Sheet data={allCustomers} name="Invoices">
                                        <Workbook.Column label="SL" value="sl"/>
                                        <Workbook.Column label="Customer Name" value="customer_name"/>
                                        <Workbook.Column label="Address" value="address"/>
                                        <Workbook.Column label="Mobile" value="mobile"/>
                                        <Workbook.Column label="Balance" value="balance"/>
                                    </Workbook.Sheet>
                                </Workbook>

                                <span><button className="btn pharmacy-btn mr-1 btn-sm "
                                              onClick={downloadPDF}>Pdf</button></span>
                            </span>
                        <Form.Control type="text" value={searchValue ? titleCase(searchValue) : ''}
                                      onChange={handleChange}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="6" align="center"><img src="/images/listPreloader.gif"
                                                                           alt="loader"
                                                                           style={{width: '5%'}}/></TableCell>
                            </TableRow></TableBody> : allCustomers.length > 0 ?
                                <TableBody>
                                    {allCustomers.slice(0, 10).map((d, index) => {
                                        return (
                                            <TableRow
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className={d.status === 0 && 'mui-table-row disabled-table'}>
                                                <TableCell component="th" scope="row" padding="default"
                                                           className='mui-table-cell'>
                                                    <span>{d.sl}</span>
                                                </TableCell>
                                                <TableCell>
                                                    {d.status === 1 ?
                                                    <Link to={{pathname:  `/customer_ledger/${d.customer_id}`}}
                                                          style={{color: d.status===1 ? "#09905c" :"#333",textDecoration:'none'}}>
                                                        <span>{titleCase(d.customer_name)}</span></Link>: titleCase(d.customer_name)}
                                                </TableCell>
                                                <TableCell>
                                                    <span>{splitText(d.address)}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.mobile}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.balance}</span>
                                                </TableCell>
                                                {(permissions[component].update || permissions[component].delete) ?
                                                    <TableCell id="mainbuttons">
                                                                <span>
                                                                    {d.status === 1 &&
                                                                    (permissions[component].update) ?
                                                                        <Link to={{pathname:`/editcustomer/${d.customer_id}`}}>
                                                                            <button className="btn pharmacy-info-btn btn-sm mr-1 ">Edit</button>
                                                                        </Link>:null}
                                                                    {(component === 'manage_customer' && d.status === 1) && <button onClick={()=>handleOpenDeposit(d.customer_id)}
                                                                                                                   className="btn pharmacy-info-btn btn-sm mr-1 ">Make Deposit</button>}
                                                                    {permissions[component].delete ?
                                                                        <button className={d.status === 1 ? "btn pharmacy-grey-btn btn-sm":
                                                                            "btn pharmacy-info-btn btn-sm"}
                                                                                onClick={()=>handleOpenDeactivate(d.customer_id,d.status)}>
                                                                            {d.status === 1 ? 'Deactivate':'Activate'}
                                                                        </button>:null}
                                                                </span>
                                                    </TableCell> : <TableCell/>}
                                            </TableRow>);
                                    })}
                                </TableBody>

                                : <TableBody><TableRow>
                                    <TableCell colSpan="6" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
                    <CustomPagination data={allCustomers} records={10} activePage={activePage} total_count={customer_count}
                                      handleChangeNextPage={handlePageChange}/>
                </MuiPaper>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomer);
import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import TextField from '../Utils/FormInputs/TextField';
import Label from '../Utils/FormInputs/Label';
import * as mq from "./media-queries";
import styled from '@emotion/styled/macro';

const StyledForm  = styled.form({
    [mq.extraLarge]:{
        width:'80%'
    }
})

function DateFilter({start_date,end_date, handleChange, submitted,handleSubmit,isPending}) {
    return(
        <StyledForm onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-lg-5'>
                    <Form.Group as={Row}>
                        <Label name='Start Date' sm={4} column type/>
                        <Col sm={8}>
                            <TextField type='date' name='start_date' value={start_date} onChange={handleChange}/>
                            {submitted && !start_date ? <p className="invalid-text">This is a required field</p>: null}
                        </Col>
                    </Form.Group>
                </div>
                <div className='col-lg-7'>
                    <Form.Group as={Row}>
                        <Label name='End Date' sm={4} column type/>
                        <Col sm={6}>
                            <TextField type='date'  name='end_date' value={end_date} onChange={handleChange}/>
                            {end_date < start_date ? <p className="invalid-text">End date shouldn't be less than start date</p> :   submitted && !end_date ? <p className="invalid-text">This is a required field</p>: null}
                        </Col>
                        <Col sm={2}>
                            <button type='submit' className="btn pharmacy-btn btn-sm mr-1" disabled={isPending}>{isPending ? 'Searching...':'Search'}</button>
                        </Col>
                    </Form.Group>
                </div>
            </div>
        </StyledForm>
    ) 
}
export {DateFilter}

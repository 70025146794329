import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useSessionStorageState} from "./useSessionStorage";
import {history} from "../../../Helpers/history";

export const useSetUpTransactionTypes = (actions) => {
    const [journals, setJournals] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [categories, setCategories] = useState([]);
    const [transactions, setTransactions] = useState([{
        id: '',
        category_name: '',
        transaction_type: {value: '', label: ''},
        debit_ledger: {value: '', label: ''},
        credit_ledger: {value: '', label: ''}
    }]);
    const [transactionTypes, setTransactionTypes] = useState([]);
    const [confirmation, setConfirmation] = useState(false);

    const dep = isSubmitted === 'resolved'
    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/transaction_types`).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            const types = dt.data ? dt.data : [];
            setTransactionTypes(types)
        }).catch(err => {
            // errorMessages(err, null,actions);
        })
    }, [dep])

    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/chart/transaction_categories`).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            const cats = dt.data ? dt.data : [];
            setCategories(cats)
        }).catch(err => {
            // errorMessages(err, null,actions);
        })
    }, [])

    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            const journal = dt.data ? dt.data : [];
            const arr = journal.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setJournals(arr)
        }).catch(err => {
            // errorMessages(err, null,actions);
        })
    }, [])

    useEffect(() => {
        const arr = categories.map(item => ({
            id: item.id,
            category_name: item.category_name,
            transaction_type: {value: '', label: ''},
            debit_ledger: {value: '', label: ''},
            credit_ledger: {value: '', label: ''}
        }))
        setTransactions(arr)
    }, [categories]);


    const handleChangeType = (value, id) => {
        const arr = transactions.map(item => {
            if (item.id === id) {
                return {...item, transaction_type: value}
            }
            return item
        })
        setTransactions(arr)
    }

    const handleChangeDebit = (value, id) => {
        const arr = transactions.map(item => {
            if (item.id === id) {
                return {...item, debit_ledger: value}
            }
            return item
        })
        setTransactions(arr)
    }

    const handleChangeCredit = (value, id) => {
        const arr = transactions.map(item => {
            if (item.id === id) {
                return {...item, credit_ledger: value}
            }
            return item
        })
        setTransactions(arr)
    }

    const handleOpen = () => {
        setConfirmation(true)
    };

    const handleNext = () => {
        actions.steppersActions.handleNext(1)
        setConfirmation(false);
        localStorage.setItem('acc_status', JSON.stringify(1));
        history.push('/coa')

    }
    const handleBack = () => {
        actions.steppersActions.handlePrevious(1)
        history.push('/accounting/setup/coa')
    }

    const handleClose = () => {
        setConfirmation(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const arr = transactions.every(item => item.transaction_type.value && item.credit_ledger.value && item.debit_ledger.value)
        if (arr) {
            const data = transactions.map(item => ({
                transaction_category: item.id,
                transaction_category_name: item.category_name,
                transaction_type_name: item.transaction_type.label,
                related_debit_ledger: item.debit_ledger.value,
                related_credit_ledger: item.credit_ledger.value
            }))

            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/accounting/transaction_types/default/add`, {data}).then(() => {
                actions.snackbarActions.successSnackbar('Saved successfully')
                setIsSubmitted('resolved')
                setTransactions([{id: '', category_name: '',
                    transaction_type: {value: '', label: ''}, debit_ledger: {value: '', label: ''},
                    credit_ledger: {value: '', label: ''}}])
                setSubmitted(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    return {
        closeSnackbar, transactions, journals, handleChangeType, transactionTypes,
        handleChangeCredit, handleChangeDebit, handleSubmit, submitted, isSubmitted, confirmation, handleOpen,
        handleNext, handleBack, handleClose
    }
}
import * as types from "../../Actions/actionTypes";


const initialState = {
    open: false
};

export default function snackbarReducer(state = initialState, action) {
    switch (action.type) {
        case types.SUCCESS_SNACKBAR:
            return {
                ...state,
                open: true,
                variant: "success",
                message: action.message,

            };
        case types.ERROR_SNACKBAR:
            return {
                ...state,
                open: true,
                variant: "error",
                message: action.message
            };
        case types.WARNING_SNACKBAR:
            return {
                ...state,
                open: true,
                variant: "warning",
                message: action.message

            };
        case types.INFO_SNACKBAR:
            return {...state, open: true, variant: "info", message: action.message};
        case  types.HIDE_SNACKBAR:
            return {
                ...state,
                open: false
            };
        default:
            return state;

    }
}
import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";

export const useTransactionTypes = (actions) =>{
    const [transactionTypes, setTransactionTypes] = useState([]);
    const [transactionName, setTransactionName] = useState('');
    const [transactionType, setTransactionType] = useState('');
    const [debit, setDebit] = useState('');
    const [credit, setCredit] = useState('');
    const [journals, setJournals] = useState([]);
    const [open, setOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('')
    const [isSubmitted, setIsSubmitted] = useState('idle')

    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/chart/transaction_categories`).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            const cats = dt.data ? dt.data : [];
            const arr =cats.map(item=>({
                value:item.id,
                label:item.category_name
            }))
            setCategories(arr)
        }).catch(err => {
            // errorMessages(err, null,actions);
        })
    }, [])

    useEffect(()=>{
        axios.get(`${config.epharmUrl}/accounting/chart/journals`).then(res=>{
          const data = res.data;
          const dt = data ? data : [];
          const journal = dt.data ? dt.data : [];
          const arr =journal.map(item=>({
              value:item.id,
              label:item.head_name
          }))
          setJournals(arr)
        }).catch(err=>{
            // errorMessages(err, null,actions);
        })
    },[])

    const dep = isSubmitted === 'resolved'

    useEffect(()=>{
        axios.get(`${config.epharmUrl}/accounting/transaction_types`).then(res=>{
            const data = res.data;
            const dt = data ? data : [];
            const types = dt.data ? dt.data : [];
            setTransactionTypes(types)
        }).catch(err=>{
            // errorMessages(err, null,actions);
        })
    },[dep])

    const handleChangeName = (event) =>{
        setTransactionName(event.target.value)
    }

    const handleChangeType = (value) =>{
        setTransactionType(value)
    }


    const handleChangeCategory = (value) =>{
        setCategory(value)
    }


    const handleChangeCredit = (value) =>{
        setCredit(value)
    }

    const handleChangeDebit = (value) =>{
        setDebit(value)
    }

    const handleOpen = () =>{
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        const formData = new FormData()
        formData.append('transaction_type_name', transactionName)
        formData.append('transaction_type', transactionType.value)
        formData.append('related_credit_ledger', credit.value)
        formData.append('related_debit_ledger', debit.value)
        formData.append('related_transaction_category', category.value)
        setSubmitted(true);
        if (transactionName && transactionType && credit && debit && category){
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/accounting/transaction_types/add`, formData).then(()=>{
                actions.snackbarActions.successSnackbar('Saved successfully')
                setOpen(false)
                setIsSubmitted('resolved')
                setTransactionName('')
                setTransactionType(null)
                setDebit(null)
                setCredit(null)
                setCategory(null)
                setSubmitted(false)
            }).catch(err=>{
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }

    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }

    return {transactionTypes,closeSnackbar,transactionName, transactionType, debit, credit, journals,
        open, handleChangeName, handleChangeType, handleChangeCredit, handleChangeDebit, handleOpen,
        handleClose, handleSubmit, submitted, isSubmitted, category,handleChangeCategory, categories}
}
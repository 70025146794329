import React, {Component} from 'react';
import companyAddress from "../Helpers/companyAddress.json"

class CompanyAddress extends Component {
    render() {
        const {children} = this.props;
        return (
            <div>
                {children}
                <address>
                    {companyAddress.company_name}<br/>
                   {companyAddress.address1}<br/>
                    {/* {companyAddress.address2}<br/> */}
                    {companyAddress.mobile &&<p style={{margin:0}}>Mobile: {companyAddress.mobile}</p>}
                    {companyAddress.email && <p style={{margin:0}}>Email: {companyAddress.email}</p>}
                    {companyAddress.website && <p style={{margin:0}}>Website: <a href={companyAddress.website} target="__blank">https://marascientific.org</a></p>}
                </address>
            </div>
        );
    }
}

export default CompanyAddress;
import React from "react";
import MainLoanPayment from "./MainLoanPayment";
import CommonButtons from "./CommonButtons";


const AddPayment = () => {
    return (
        <MainLoanPayment addPayment personalLoan>
            <CommonButtons addPayment/>
        </MainLoanPayment>
    )
};
export default AddPayment
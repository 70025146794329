import  {useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useEffect} from "react";
import {history} from "../../../Helpers/history";

const createOption = (label) => ({
    label,
    value: label,
});

const defaultOptions = [
    createOption('10'),
    createOption('20'),
    createOption('30'),
    createOption('40'),
    createOption('50'),
    createOption('60'),
    createOption('70'),
    createOption('80'),
    createOption('90'),
    createOption('100'),
];
export const useAddMedicine = (actions, product_id=null, isPurchase=false) => {
    const [state, setState] = useState({
        product_name: '', strength: '', generic_name: '',
        actual_price: '', box_size: '', product_location: '', description: '', price_type: '',
        reorder_level: 10, expiry_limit: 0, maximum_stock:0           // zero for maximum stock implies no max stock
    });
    const [unit, setUnit] = useState(null);
    const [type_name, setTypeName] = useState(null);
    const [category_id, setCategoryId] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(defaultOptions);
    const [price, setPrice] = useState(null);
    const [product, setProduct] = useState({});
    const [categoryList, setCategories] = useState([])
    const [unitList, setUnits] = useState([])
    const [typeList, setTypes] = useState([])
    const [openProductDialog, setOpenProductDialog] = useState(false)
    const formRef = useRef();


    useEffect(()=>{
        axios.get(`${config.epharmUrl}/Cproduct/unit_list`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const units = dt.unit ? dt.unit : []
            setUnits(units)
        }).catch(error => {
            errorMessages(error, null,actions)
        })
    },[])

    useEffect(()=>{
        axios.get(`${config.epharmUrl}/Ccategory/manage_category`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const categories = dt.category_list ? dt.category_list : []
           setCategories(categories)
        }).catch(error => {
            errorMessages(error, null,actions)
        })
    },[])



    useEffect(()=>{
        axios.get(`${config.epharmUrl}/Cproduct/typeindex`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const types = dt.type_list ? dt.type_list : []
            setTypes(types)
        }).catch(error => {
            errorMessages(error, null,actions)
        })
    },[])
    
    useEffect(()=>{
        if (!product_id){
            return;
        }
        axios.get(`${config.epharmUrl}/Cproduct/product_update_form`, {params: {product_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            setProduct(dt);
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    },[])

    useEffect(()=>{
        if (!product_id) {
            return
        }
        const productDetails = !product ? {} : product;
        const product_name = !productDetails.product_name ? "" : productDetails.product_name;
        const generic_name = !productDetails.generic_name ? "" : productDetails.generic_name;
        const box_size = !productDetails.box_size ? "" : productDetails.box_size;
        const strength = !productDetails.strength ? "" : productDetails.strength;
        const product_location = !productDetails.product_location ? "" : productDetails.product_location;
        const product_details = !productDetails.product_details ? "" : productDetails.product_details;
        const category_selected = !productDetails.category_selected ? {} : productDetails.category_selected;
        const type_selected = !productDetails.seleceted_type ? {} : productDetails.seleceted_type;
        const price = !productDetails.price ? 0 : productDetails.price;
        const price_type = !productDetails.price_type ? 0 : productDetails.price_type;
        const unit_selected = !productDetails.seleceted_unit ? {} : productDetails.seleceted_unit;
        const reorder_level = !productDetails.reorder_level ? '' : productDetails.reorder_level;
        const expr_lmt = !productDetails.pr_details ? 0 : productDetails.pr_details['expiry_limit'];
        const max_stck = !productDetails.pr_details ? 0 : productDetails.pr_details['maximum_stock'];
        setState({product_name, strength, generic_name,
            actual_price:  price_type === 0 ? price : '', box_size, product_location,
            description:product_details, price_type:String(price_type), reorder_level,
            expiry_limit: expr_lmt, maximum_stock : max_stck
        })
        setCategoryId({value: !category_selected.category_id ?
                '' : category_selected.category_id, label: !category_selected.category_name ?
                'Category not selected' : category_selected.category_name})
        setTypeName({ value: type_selected.type_id, label: type_selected.type_name });
        setUnit( { value: unit_selected.id, label: unit_selected.unit_name })
        setPrice({value: price_type === 1 ? price : '' ,label:price_type === 1 ? price : '' })
    },[product, product_id])

    const handleOpenProductDialog = () => {
        setOpenProductDialog(true)
    };

    const handleCloseProductDialog = () => {
        setOpenProductDialog(false)
    };


    const handleChangeFields = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value});
    };

    const handleChangeCategory = (value) => {
        setCategoryId(value);
    };
    const handleChangeType = (value) => {
        setTypeName(value);
    };
    const handleChangeUnit = (value) => {
        setUnit(value);
    };

    const handleInputChange = (inputValue) => {
        setIsLoading(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoading(false);
            setOptions([...options, newOption]);
            setPrice(newOption);
        }, 1000);
    }

    const handleChange = (newValue) => {
        setPrice(newValue);
    }

    const handleSaveMedicine = (event) => {
        event.preventDefault();
        const {product_name, generic_name, actual_price, price_type} = state;
        setSubmitted(true);
        const isActual = price_type === '0' && actual_price;
        const markupObj = price ? price : {};
        const markup_value = markupObj.value ? markupObj.value : 0;
        const isMarkup = price_type === '1' && markup_value;
        if (product_name && generic_name && type_name && category_id && price_type && (isActual || isMarkup)) {
            const formData = new FormData(formRef.current);
            const pr = price_type === '1' ? markup_value : actual_price;
            if (product_id){
                formData.append('product_id', product_id);
            }
            formData.append('unit', !unit.label ? "" : unit.label);
            formData.append('type_name', !type_name.label ? "" : type_name.label);
            formData.append('category_id', +category_id.value ?? '');
            formData.append('price', +pr);
            formData.append('tax0', 0);
            formData.append('tax1', 0);
            setIsSubmitted('pending');
            const url = product_id ? 'product_update' : 'insert_product';
            axios.post(`${config.epharmUrl}/Cproduct/${url}`, formData).then((res) => {
                actions.snackbarActions.successSnackbar('Medicine Saved Successfully');
                setIsSubmitted('resolved');
                if(isPurchase){
                    setOpenProductDialog(false)
                }else {
                    history.push("/managemedicine");
                }

            }).catch(error => {
                errorMessages(error, null, actions);
                setIsSubmitted('rejected');
            })
        }

    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    const productProps = {state, unit, type_name, category_id, submitted, isSubmitted,
        isLoading, options, price, formRef, handleChangeFields, handleChangeCategory,
        handleChangeType, handleChangeUnit, handleInputChange, handleChange,
        handleSaveMedicine, handleCloseSnackbar, categoryList, unitList, typeList
    }
    return {productProps, handleCloseSnackbar, openProductDialog, handleCloseProductDialog, handleOpenProductDialog}
}
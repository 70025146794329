import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import axios from 'axios';
import config from '../../Helpers/config.json';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions'
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {errorMessages} from "../../Helpers/ErrorMessages";
import Grid from "@material-ui/core/Grid";
import ReusableStepper from "../../Utils/Snackbars/ReusableSteppers";
import {useAccountingSetUp} from "./CustomHooks/useAccountingSetUp";
import {history} from "../../Helpers/history";
import * as steppersActions from '../../Redux/Actions/steppersActions'
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";


const AccountingPeriod = ({ actions, snackbars, steppers}) => {
    const [period, setPeriod] = useState('');
    const {steps} = useAccountingSetUp()
    const options = [{value: 3, text: 3}, {value: 4, text: 4}, {value: 6, text: 6}, {value: 12, text: 12}]
    const handleChangePeriod = (event) => {
        setPeriod(event.target.value)
    }

    const handleNext = () => {
        const formData = new FormData();
        if (period) {
            formData.append('period', period);
            axios.post(`${config.epharmUrl}/accounting/setup`, formData).then(res => {
                const data = res.data;
                const dt = data ?? {};
                const msg = dt.data ?? '';
                if (msg === "accouting already setup, You cannot call this endpoint") {
                    // actions.snackbarActions.snackError(msg)
                } else {
                    actions.snackbarActions.successSnackbar('Period setup successfully')
                }
                actions.steppersActions.handleNext(0)
                history.push('setup/coa')

            }, err => {
                errorMessages(err, null, actions)
            })
        }

    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    const {open, variant, message} = snackbars;
    const {activeStep} = steppers;
    return (
        <div>
            <div className='journals'>
                <ReusableDashboardHeader component="Accounting Period" heading="Accounting"
                                         subHeading="Accounting period" link="/accounting/setup"/>
                <div className="general-ledger-header p-0">
                    <Grid container spacing={0}>
                        <Grid item xs={6} md={8}>
                            <ReusableStepper activeStep={activeStep} steps={steps}/>
                        </Grid>
                        <Grid item xs={6} md={4}>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="accounting-period">
                <PharmacySnackbar variant={variant} message={message} open={open} handleCloseBar={closeSnackbar}/>
                <form>
                    <Form.Group as={Row}>
                        <Label name='Accounting period' type sm='3' style={{textAlign: 'right', marginTop: '0.5rem'}}/>
                        <Col sm={6}>
                            <SelectInput options={options} onChange={handleChangePeriod} value={period}
                                         defaultOption='Choose period...'/>
                        </Col>
                    </Form.Group>
                </form>
                <div className="row">
                    <div className="col-md-7"/>
                    <div className="col-md-5">
                        <div className='text-center'>
                            {period ? <button type='button' onClick={handleNext}
                                              className='btn btn-sm pharmacy-info-btn mt-5'>Next</button> : null}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbars, steppers} = state;
    return {snackbars, steppers}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            steppersActions: bindActionCreators(steppersActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingPeriod);
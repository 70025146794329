import React, {useEffect, useState} from 'react';
import {usePeriodsFilter} from "./usePeriodsFilter";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {exportPDF} from "../../../Utils/exportPDF";
import {convDate} from "../../../Utils/titleCaseFunction";


export const useMedicineWise = (actions) => {
    const { from_date, to_date,  handleChangeDate} = usePeriodsFilter()
    const [sales, setSales] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/admin/product_sales_reports_date_wise/${activePage}`).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const arr = dt.product_report ? dt.product_report : [];
            const report_count = dt.total_count ? dt.total_count : 0;
            setSales(arr)
            setTotalCount(report_count)
            setLoading(false)
            this.setState({report_count, salesDataWise: dt, loading: false});
        }).catch(error => {
            // errorMessages(error, null, actions)
            setLoading(false)
        })

    }, [activePage])

    const handleFilter = (event) =>{
        event.preventDefault()
        const formData = new FormData();
        formData.append('from_date', from_date);
        formData.append('to_date', to_date)
        setSubmitted(true)
        if (from_date && to_date){
            setIsSubmitted('pending')
            setLoading(true)
            axios.post(`${config.epharmUrl}/admin/product_sales_search_reports`, formData).then(response => {
                const data = response.data;
                const dt = data ? data : {}
                const arr = dt.product_report ? dt.product_report : [];
                setSales(arr)
                setIsSubmitted('resolved')
                setLoading(false)
            }).catch(error => {
                setIsSubmitted('rejected')
                setLoading(false)
                // errorMessages(error, null, actions)
            })
        }

    }

    const handleChangeActivePage = (page) => {
        setActivePage(page)
    }


    const  downloadPDF = () => {
        exportPDF("Purchase Report", [["Date",  "Medicine", "Medicine Type",
                "Quantity","Unit Price","amount"]],
            sales.map(sale => [ sale.sales_date,  sale.product_name,
                sale.product_model,sale.quantity,sale.rate,sale.total_price]), "Sales Report(Medicine Wise).pdf",
            )
    };


    return {loading, sales, activePage, handleChangeActivePage, totalCount, handleFilter,
        from_date, to_date,  handleChangeDate, submitted, isSubmitted, downloadPDF}
}
import React, {useEffect, useState} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions"
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import Workbook from "react-excel-workbook";
import {exportPDF} from "../../Utils/exportPDF";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import axios from "axios";
import config from "../../Helpers/config";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {titleCase} from "../../Utils/titleCaseFunction";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {CSVLink} from "react-csv";
import {connect} from "react-redux";
import { useRef } from "react";


const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'medicine_name', numeric: false, disablePadding: false, label: 'Medicine Name'},
    {id: 'generic_name', numeric: false, disablePadding: false, label: 'Generic Name'},
    {id: 'strength', numeric: false, disablePadding: false, label: 'Strength'},
    {id: 'reorderlevel', numeric: false, disablePadding: false, label: 'Reorder Level'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
];


const OutOfStockReport = ({actions, snackbars}) => {
    const [outOfStock, setOutOfStock] = useState([]);
    const [stockCount, setStockCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [csvData, setCsvData] = useState([])
    const [loading, setLoading] = useState(false);
    const csvRef = useRef()

    useEffect(() => {
        const formData = new FormData();
        formData.append("draw", 1);
        formData.append("columns[0][data]", "sl");
        formData.append("columns[0][name]", "");
        formData.append("columns[0][searchable]", "true");
        formData.append("columns[0][orderable]", false);
        formData.append("columns[0][search][value]", "");
        formData.append("columns[0][search][regex]", false);
        formData.append("columns[1][data]", "product_name");
        formData.append("columns[1][name]", "");
        formData.append("columns[1][searchable]", true);
        formData.append("columns[1][orderable]", true);
        formData.append("columns[1][search][value]", "");
        formData.append('columns[1][search][regex]', false);
        formData.append("columns[2][data]", "generic_name");
        formData.append('columns[2][name]', "");
        formData.append("columns[2][searchable]", true);
        formData.append("columns[2][orderable]", false);
        formData.append("columns[2][search][value]", "");
        formData.append("columns[2][search][regex]", false);
        formData.append("columns[3][data]", "stock");
        formData.append("columns[3][name]", "");
        formData.append("columns[3][searchable]", true);
        formData.append("columns[3][orderable]", false);
        formData.append("columns[3][search][value]", "");
        formData.append("columns[3][search][regex]", false);
        formData.append("order[0][column]", 2);
        formData.append("order[0][dir]", "asc");
        formData.append("start", activePage);
        formData.append("length", 10);
        formData.append("search[value]", searchValue);
        formData.append("search[regex]", false);
        setLoading(true)
        axios.post(`${config.epharmUrl}/Creport/checkStockOutList`, formData).then(res => {
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.aaData ?? [];
            const stock_count = dt.iTotalRecords ?? 0;
            setOutOfStock(arr);
            setStockCount(stock_count)
            setLoading(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [actions, activePage, searchValue])

    const handleChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar()
    };


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const downloadPDF = () => {
        axios.get(`${config.epharmUrl}/Creport/retrieve_all_out_of_stock_drugs`).then(response => {
            const data = response.data
            const arr = data ?? []
            exportPDF("Out Of Stock", [["SL", "Medicine Name","Generic name","Strength",
                     "Reorder Level", "Stock"]],
                arr.map((stock, index) => [index += 1, stock.product_name,stock.generic_name,
                    stock.strength,stock.reorder_level, stock.stock]), "Out Of Stock.pdf");
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };

    const fetchCsvData = () => {
        axios.get(`${config.epharmUrl}/Creport/retrieve_all_out_of_stock_drugs`).then(response => {
            const res = response.data;
            const arr = res ?? [];
            const data = arr.map((item, index)=>({
                sl:index+=1,
                ...item
            }))
            setCsvData(data)
            if(data){
                csvRef.current.link.click()
            }
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };

    const headers = [{key:'sl', label:'Sl'},{key:'product_name', label:'Medicine Name'},
    {key:'generic_name', label:'Generic Name'},{key:'strength', label:'Strength'},
    {key:'reorder_level', label:'Reorder Level'},{key:'stock', label:'Stock'}]

    const outOfStockList = !outOfStock ? [] : outOfStock
    const {message, variant, open} = snackbars;
    return (
        <div>
            <ReusableDashboardHeader component='Out of Stock' heading="Stock"
                                     subHeading='Out of stock' link={history.location.pathname}/>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <MuiPaper>
                <EnhancedTableToolbar title='Out of Stock'>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                                 <span>
                                    <CSVLink
                                        data={csvData}
                                        filename="stock.csv"
                                        target="_blank"
                                        className="hidden"
                                        ref={csvRef}
                                        headers={headers}
                                    />
                                    <button className="btn btn-sm pharmacy-btn mr-1" onClick={fetchCsvData}>Excel</button>
                                </span>
                                   {/* <Workbook filename="Out of Stock.xlsx" element={
                                       <span><button className="btn btn-sm pharmacy-btn mr-1">Excel</button></span>
                                   }>
                                <Workbook.Sheet data={outOfStockList} name="Out of Stock">
                                    <Workbook.Column label="Medicine Name" value="product_name"/>
                                    <Workbook.Column label="Supplier Name" value="manufacturer_name"/>
                                    <Workbook.Column label="Generic Name" value="generic_name"/>
                                    <Workbook.Column label="Stock" value="stock"/>
                                </Workbook.Sheet>
                            </Workbook> */}
                            <span><button className="btn btn-sm pharmacy-btn mr-1"
                                          onClick={downloadPDF}>Pdf</button></span>
                            </span>
                    <Form.Control type="text" value={searchValue ? titleCase(searchValue) : ''}
                                  onChange={handleChange}
                                  className='form__control'
                                  placeholder="Search" style={{float: 'right'}}/>
                </EnhancedTableToolbar>
                <Table
                    aria-labelledby="tableTitle"
                    // size='medium'
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead headCells={headData}/>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="4" align="center"><img src="/images/listPreloader.gif"
                                                                   alt="loader"
                                                                   style={{width: '5%'}}/></TableCell>
                    </TableRow></TableBody> : outOfStockList.length > 0 ?
                        <TableBody>
                            {outOfStockList.slice(0, 10).map((d, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        className='mui-table-row'>
                                        <TableCell component="th" scope="row" padding="default"
                                                   className='mui-table-cell'>
                                            {d.sl}
                                        </TableCell>
                                        <TableCell>
                                            {titleCase(d.product_name)}
                                        </TableCell>
                                        <TableCell>
                                            {titleCase(d.generic_name)}
                                        </TableCell>
                                        <TableCell>
                                            {d.strength}
                                        </TableCell>
                                        <TableCell>
                                            {d.reorder_level}
                                        </TableCell>
                                        <TableCell>
                                            {d.stock}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>

                        : <TableBody><TableRow>
                            <TableCell colSpan="4" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </Table>
                <CustomPagination data={outOfStockList} records={10} activePage={activePage} total_count={stockCount}
                                  handleChangeNextPage={handlePageChange}/>
            </MuiPaper>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(OutOfStockReport);
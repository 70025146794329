import React, {useEffect, useState} from 'react';
import {convertDate} from "../../Utils/titleCaseFunction";
import CollapsibleTable from "../../Utils/Tables/CollapsibleTable";
import {CustomerRow} from "./CustomerRow";
import axios from "axios";
import config from "../../Helpers/config.json";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from 'react-redux';
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Pagination from "react-js-pagination";
import {titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Tabs/ReusableTabs";
import {ReusableSearchForm} from "../Report/ReusableSearchForm";
import {usePeriodsFilter} from "../Report/CustomHooks/usePeriodsFilter";


const headData = [{name: 'Date'}, {name: 'Invoice No.'}, {name: 'Amount'}]
const CustomerPurchases = ({actions, match, snackbars}) => {
    const {customer_id} = match.params;
    const {period, from_date, to_date, handleChangeDate,
        year, inputTypes, handleChangePeriod, handleChangeYear} = usePeriodsFilter();
    const [purchases, setPurchases] = useState([]);
    const [purchase_count, setPurchaseCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [customerInfo, setCustomerInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');

    const dep = period.value === "today" && from_date
    useEffect(() => {
        if (!from_date || !to_date){
            return
        }
        const formData = new FormData();
        formData.append('start_date', period.value === "today" ? convertDate() :from_date);
        formData.append('end_date', period.value === "today" ? convertDate() : to_date);
        formData.append('customer_id', customer_id);
        formData.append('page', +(activePage));
        setLoading(true)
        axios.post(config.epharmUrl + "/Ccustomer/customer_purchase_details", formData).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const customer = dt.customer ? dt.customer : {}
            const t_count = dt.total_count ? dt.total_count : 0;
            const pur = dt.customer_purchase_details ? dt.customer_purchase_details : [];
            setPurchases(pur);
            setCustomerInfo(customer);
            setPurchaseCount(t_count);
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        })
    }, [activePage, dep])

    const handleSubmitSearch = event => {
        event.preventDefault();
        setSubmitted(true)
        const end_date = (period.value === 'custom' && to_date) || (period.value !== 'custom' && !to_date)
        if (from_date && end_date) {
            const formData = new FormData();
            formData.append('start_date', period.value === "today" ? convertDate() :from_date);
            formData.append('end_date', period.value === "today" ? convertDate() : to_date);
            formData.append('customer_id', customer_id);
            formData.append('page', +(activePage));
            setLoading(true)
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Ccustomer/customer_purchase_details`, formData).then(response => {
                const data = response.data;
                const dt = !data ? {} : data;
                const customer = dt.customer ? dt.customer : {}
                const t_count = dt.total_count ? dt.total_count : 0;
                const pur = dt.customer_purchase_details ? dt.customer_purchase_details : [];
                setIsSubmitted('resolved')
                setPurchases(pur);
                setCustomerInfo(customer);
                setPurchaseCount(t_count);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                setIsSubmitted('rejected')
            })
        }
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();

    };


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const {customer_name, customer_address, customer_email, customer_mobile} = customerInfo;
    const {open, variant, message} = snackbars;
    const items = [{label: 'Ledger', path: `/customer_ledger/${customer_id}`},
        {label: 'Purchases', path: `/customerpurchases/${customer_id}`}];
    const periods = [{value: 'today', label: 'Today'}, {value: 'weekly', label: 'Weekly'},
        {value: 'monthly', label: 'Monthly'}, {value: 'custom', label: 'Custom'}]
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Customer Purchases' heading="Customer"
                                     subHeading='Customer Purchases' link={`/customerpurchases/${customer_id}`}/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={items}/>
            <PharmacySnackbar open={open} variant={variant} message={message}
                              handleCloseBar={handleCloseSnackbar}/>
            <div className='general-ledger-header'>
                <form onSubmit={handleSubmitSearch}>
                <ReusableSearchForm handleChangePeriod={handleChangePeriod} period={period}
                                    inputType={inputTypes} from_date={from_date} year={year} to_date={to_date}
                                    handleChangeYear={handleChangeYear} handleChangeDate={handleChangeDate}
                                    isSubmitted={isSubmitted} submitted={submitted} periods={periods}/>
                </form>
            </div>
            <div className="customer-info text-center mb-3 my-5">
                <p style={{margin: 0}}>{customer_name ? titleCase(customer_name) : ''}</p>
                {customer_mobile && <p style={{margin: 0}}>{customer_mobile ? customer_mobile : ''}</p>}
                {customer_email && <p style={{margin: 0}}>{customer_email ? customer_email : ''}</p>}
                <p style={{margin: 0}}>{customer_address ? titleCase(customer_address) : ''}</p>
            </div>

            {loading ? <div className='text-center'>
                <img src="/images/listPreloader.gif"
                     alt="loader"
                     style={{width: '5%'}}/>
            </div> :purchases.length > 0 ?
                <div>
                    <CollapsibleTable headData={headData}>
                        {purchases.slice(0, 10).map((row) => (
                            <CustomerRow key={row.invoice} row={row}/>
                        ))}
                    </CollapsibleTable>
                    <div className="row">
                        <div className="col-md-6"/>
                        <div className="col-md-6">
                            <div className="text-right">
                                {purchases.length > 0 && <Pagination
                                    prevPageText='Prev'
                                    nextPageText='Next'
                                    firstPageText='First'
                                    lastPageText='Last'
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={purchase_count}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                />}
                            </div>
                        </div>
                    </div>
                </div> : <p className="text-center">No Purchase made</p>}

        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPurchases);
import React, {useRef, useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {useFetchData} from "../CustomHooks/useFetchData";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {printStyles, ReusableReportHeader} from "./ReusableReportHeader";
import {printFunctionality} from "../../../Utils/printFunctionality";
import ReusableAccountingCollapsibleTable from "../ReusableAccountingCollapsibleTable";
import {PrintTableBalanceSheet} from "../PrintableDocs";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Collapse from "@material-ui/core/Collapse";
import {useCollapsibleRowStyles} from "../ReusableAccountingCollapsibleTable";
import {NoTransaction} from "../ReusableJournalTable";


const BalanceSheet = ({actions, snackbars}) => {
    const {date, closeSnackbar, period, handleChangePeriod, data,loading,
        inputType, handleChangeDate, year, handleChangeYear, totalAmount
    } = useFetchData(actions, 'get_balance_sheet')
    const {from_date, to_date} = date;
    const printRef = useRef();
    const rowClasses = useCollapsibleRowStyles()

    const [collapse, setCollapse] = useState({});

    const handleCollapse = (param) => {
        setCollapse(prevState => ({...prevState, [param]: !prevState[param]}));
    };


    const downloadPDF = () => {
        const doc = new jsPDF()
        autoTable(doc, {html: '#balance_table'})
        doc.save('Balance Sheet.pdf')
    };
    const {open, variant, message} = snackbars;
    const headers = [{key: 'name', value: 'Account'}, {key: 'resulting_amount', value: 'Amount'}]
    return (
        <div className="acc_reports journals">
            <PharmacySnackbar message={message} open={open} variant={variant} handleCloseBar={closeSnackbar}/>
            <ReusableReportHeader period={period} year={year}
                                  handlePrint={() => printFunctionality(printRef.current, printStyles())}
                                  from_date={from_date} handleChangeYear={handleChangeYear}
                                  handleChangePeriod={handleChangePeriod}
                                  to_date={to_date} downloadPdf={downloadPDF} inputType={inputType}
                                  handleChangeDate={handleChangeDate}
                                  title='Balance Sheet' subTitle='Balance Sheet'/>
            <div className='mui-tables'>
                <PrintTableBalanceSheet printRef={printRef} data={data}/>
                {data.length > 0 ? <ReusableAccountingCollapsibleTable headers={headers}>
                        <React.Fragment>
                            {data.map(item => (
                                <>
                                    <TableRow className={rowClasses.root}>
                                        <TableCell classes={{root: rowClasses.td}}>
                                            {item.category}
                                        </TableCell>
                                        <TableCell>{" "}</TableCell>
                                    </TableRow>
                                    {item.parent_accounts.length > 0 ? item.parent_accounts.map(({
                                                                                                     account_name: name,
                                                                                                     sub_accounts
                                                                                                 }, index) => (
                                        <React.Fragment key={index}>
                                            {sub_accounts.length > 0 ? <TableRow className={rowClasses.root}>
                                                <TableCell classes={{root: rowClasses.tableCell}}>
                                                    <IconButton aria-label="expand row" size="small"
                                                                onClick={() => handleCollapse(name)}>
                                                        {collapse[name] ?
                                                            <KeyboardArrowDownIcon classes={{root: rowClasses.icon}}/> :
                                                            <KeyboardArrowRightIcon classes={{root: rowClasses.icon}}/>}
                                                    </IconButton>{name}
                                                </TableCell>
                                                <TableCell>{" "}</TableCell>
                                            </TableRow> : null}
                                            {sub_accounts.map(it => (
                                                <TableRow key={it.name}>
                                                    {headers.map(({key, format = value => value}) => (
                                                        <TableCell
                                                            style={{paddingBottom: 0, paddingTop: 0, borderBottom: 0}}>
                                                            <Collapse in={collapse[name]} timeout="auto" unmountOnExit
                                                                      classes={{wrapperInner: rowClasses.wrapperInner}}>
                                                                {format(it[key])}
                                                            </Collapse>
                                                        </TableCell>))}
                                                </TableRow>
                                            ))}
                                        </React.Fragment>
                                    )) : null}
                                </>
                            ))}

                        </React.Fragment>
                    <TableRow>
                        <TableCell classes={{root:rowClasses.totalCell}}>TOTAL LIABILITIES & EQUITY</TableCell>
                        <TableCell classes={{root:rowClasses.totalCell}}></TableCell>
                    </TableRow>
                    </ReusableAccountingCollapsibleTable> :loading ?
                    <div className='text-center mt-5'><img src="/images/listPreloader.gif"
                                                           alt="loader" style={{width: '10%'}}/></div>:<NoTransaction text='transactions'/>}


            </div>

        </div>
    )

}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}


export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheet)

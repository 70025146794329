import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import CsvDialog from "../../Utils/CsvDialog";
import {history} from "../../Helpers/history"
import {CSVLink} from "react-csv";
import Row from 'react-bootstrap/Row'
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import {useAddStockAdjustment} from "./hooks/useAddStockAdjustment";
import {CollapsibleList, DataCell,  ModuleTable} from "../../Utils/List/CollapsibleList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import styled from "@emotion/styled/macro";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import TableCell from "@material-ui/core/TableCell";

const headData = [{item:'Batch#', align:'left',  isRequired:true},{item:'Expiry Date',align: 'center'},
    {item:'Stock', align:'right'},{item:'Physical Stock', align:'center', width:'120'},{item:'Unit Cost', align: 'center'}, {item:'Action', align:'right'}];

const Container = styled.div({
    padding:'15px 120px 15px 70px'
})

const StyledRemoveIcon = styled(RemoveCircleIcon)({
    width:'0.9em',
    height:'0.9em',
    fill:'#c4495c'
})

const StyledInput = styled(TextField)({
    display:'inline',
    borderRadius:'0.7rem'
}, ({width}) => ({width:width}))



const StockAdjustment = ({actions, snackbars, match:{params}}) => {
    const {products, product,drugs, showProduct,handleOpen, handleClickDrug, handleRetrieveDrugs,csvLink,
        purchaseDate, isPending,  submitted, handleAddBatch, handleRemoveBatch,
        handleRemoveProduct, handleChangeItem, handleChangePurchaseCsv, handleChangeDate, handleOpenDialog,
        fetchData, openDialog,allStock, handleCloseBar, handleCloseDialog,handleSubmit,handleUploadCsv} = useAddStockAdjustment(actions, params)
    const {product_name} = product
    const allDrugs = !drugs ? [] : drugs;
    const {open, message, variant} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const user_name = !singleUser.user_name ? "" : singleUser.user_name;
    return (
        <div >
            <ReusableDashboardHeader component='Stock Adjustment' heading="Stock"
                                     subHeading='Stock Adjustment' link={history.location.pathname}/>
            <CsvDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} stock
                       handleUploadCsv={handleUploadCsv} handleChangeUpload={handleChangePurchaseCsv}
                       message="Stock">
                    <span>
                        <button onClick={fetchData}
                                className="btn pharmacy-primary-btn btn-sm">Download Sample File</button>
                    <CSVLink
                        data={allStock}
                        ref={csvLink}
                        filename="StockAdjustment.csv"
                        className="hidden"
                    />
                    </span>
            </CsvDialog>
            <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={handleCloseBar}/>
            <Container>
                <div className="text-right">
                    <button className="btn pharmacy-info-btn mx-3 btn-sm"
                            onClick={handleOpenDialog}>Upload Stock CSV
                    </button>
                </div>
                <form onSubmit={handleSubmit}  autoComplete="off">
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group as={Row}>
                                <Label column sm="2" name='Date'/>
                                <Col sm="8">
                                    <TextField name="purchase_date" value={purchaseDate} type="date"
                                        onChange={handleChangeDate}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Label column sm="2" name=' Adjusted by'/>
                                <Col sm="8">
                                    <TextField value={user_name} type="text" readOnly/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Label type name='Product' column sm='2'/>
                                <Col sm='8'>
                                    <TextField type="text" name="product_name"
                                               value={product_name}
                                               onChange={handleRetrieveDrugs}
                                    />
                                    {/*{(submitted && !product_name &&) &&*/}
                                    {/*    <div className="invalid-text">Please enter the product name</div>}*/}

                                    {showProduct ?
                                        <Form.Control as="select" multiple className='drop-down width-max-content'>
                                            {allDrugs.map((product, index) =>
                                                <option key={index} value={product.value}
                                                        onClick={() => handleClickDrug({
                                                            value: product.value,
                                                            innerText: product.label
                                                        })}>{product.label}</option>)}
                                        </Form.Control> : null}
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="col-md-6"/>
                    </div>
                    {products.map((item, index)=>{
                        const batches = item.batches ? item.batches : []
                        return(
                            <CollapsibleList key={index} open={item.open} name={item.product_name} handleOpen={()=>handleOpen(index)} button={
                                <>
                                    <Tooltip title='Delete'>
                                        <IconButton onClick={()=>handleRemoveProduct(index)}>
                                            <StyledRemoveIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </>
                            } >
                               
                                    <ModuleTable headData={headData}>
                                        {batches.map((row, idx) => {
                                            const daysLeft = Math.floor((Date.parse(row.expiry_date) - Date.now()) / (24 * 60 * 60 * 1000));
                                            return(
                                                <TableRow
                                                    key={idx}
                                                >
                                                    <DataCell align='left'>
                                                        {
                                                         row.isBatch ?    row.batch_id:
                                                             <>
                                                                 <StyledInput onChange={e=>handleChangeItem(e, index, idx)} value={row.batch_id} name='batch_id' type='text' width='50%'/>
                                                                 {(submitted && !row.batch_id) &&
                                                                     <div className="invalid-text">Batch id is required</div>}
                                                             </>

                                                        }
                                                    </DataCell>
                                                    <DataCell align='center' width='200'>
                                                        {
                                                            row.isBatch ?    row.expiry_date:
                                                                <>
                                                                    <StyledInput onChange={e=>handleChangeItem(e, index, idx)} value={row.expiry_date} type='date' name='expiry_date' width='100%'/>
                                                                    {(submitted && !row.expiry_date) ?
                                                                        <div className="invalid-text">Expiry date is required</div>:
                                                                    item.expiry_limit > daysLeft ?  <div className="invalid-text">This batch is within the product's expiry limit</div>:null}
                                                                </>

                                                        }

                                                    </DataCell>
                                                    <TableCell align='right'>{row.stock}</TableCell>
                                                    <DataCell align='center'>
                                                        <StyledInput onChange={e=>handleChangeItem(e, index, idx)} value={row.physical_stock} width='40%' name='physical_stock' type='number'/>
                                                        {!row.isBatch ?
                                                            (submitted &&  !row.physical_stock ) &&
                                                            <div className="invalid-text">Physical stock is required</div> : null}
                                                    </DataCell>
                                                    <DataCell align='center'>
                                                        {
                                                            row.isBatch ?    row.unit_cost:
                                                                <>
                                                                    <StyledInput onChange={e=>handleChangeItem(e, index, idx)} value={row.unit_cost} name='unit_cost' type='number' width='40%'/>
                                                                    {(submitted && !row.unit_cost) &&
                                                                        <div className="invalid-text">Unit cost is required</div>}
                                                                </>

                                                        }

                                                    </DataCell>
                                                    <DataCell align='right'>
                                                        {!row.isBatch ?  <Tooltip title='Delete'>
                                                            <IconButton onClick={() => handleRemoveBatch(index, idx)}>
                                                                <StyledRemoveIcon/>
                                                            </IconButton>
                                                        </Tooltip>: null}
                                                    </DataCell>
                                                </TableRow>
                                            )})}
                                        <TableRow>
                                            <TableCell colSpan={headData.length}>
                                                <button type='button' onClick={()=>handleAddBatch(index)} className='btn btn-sm pharmacy-grey-btn'>Add another row</button>
                                            </TableCell>
                                        </TableRow>
                                    </ModuleTable> 
                            </CollapsibleList>
                        )
                    })}
                    {
                        products.length > 0 ?
                            <div className='text-right'>
                                <button type='submit' disabled={isPending} className='btn btn-sm pharmacy-btn'>{isPending ? 'Submitting....': 'Submit'}</button>
                            </div> : null
                    }

                </form>
            </Container>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockAdjustment);

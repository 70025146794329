import React from "react";
import {Link} from "react-router-dom";


const CommonButtons = (props) =>{
    const {addPerson,addLoan,addPayment, managePerson, personLoan} = props;
    return(
        <div className="styled-buttons">
            <Link to={addLoan || managePerson || personLoan
                ? "/addperson" : addPerson || addPayment ? "/addloan" : "/"}>
                <button className="btn pharmacy-info-btn mr-2 ">
                    {addLoan || managePerson ||personLoan ? "Add Person" :(addPerson || addPayment) ? "Add Loan" :""}</button></Link>
            <Link to={addPerson || addLoan || managePerson || personLoan ? "/addpayment" :"/addperson"}><button className="btn pharmacy-btn mr-2 ">
                {addPerson || addLoan || managePerson || personLoan ? "Add Payment" :"Add Person"}</button></Link>
            {personLoan &&<Link to="/addloan"><button className="btn pharmacy-info-btn mr-2">Add Loan</button></Link>}
            <Link to={managePerson ? "/addloan":"/manageperson"}><button className="btn pharmacy-primary-btn ">
                {managePerson ? "Add Loan":"Manage Person"}</button></Link>
        </div>

    )
};
export  default CommonButtons;
import * as types from "../actionTypes";


export function successSnackbar(message){
    return{
        type:types.SUCCESS_SNACKBAR,
        message
    }
}
export function errorSnackbar(message){
    return {
        type:types.ERROR_SNACKBAR,
        message
    }
}
export function warningSnackbar(message){
    return {
        type:types.WARNING_SNACKBAR,
        message
    }
}
export function hideSnackBar() {
    return {
        type:types.HIDE_SNACKBAR
    }

}
export function infoSnackbar(message) {
    return {
        type:types.INFO_SNACKBAR,
        message
    }

}
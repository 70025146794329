import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";



const EditAttendance = () =>{
    return (
        <div>
        <DashboardHeader attendance editAttendance/>
        <div className="card styled-card p-3">
            <h6>Edit Attendance</h6>
            <hr/>
            <Form autoComplete="off">
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Employee Name</Form.Label>
                    <Col sm={8}>
                        <Select/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Date</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="date"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Check In</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Check Out</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Stay Time</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text"/>
                    </Col>
                </Form.Group>
                <div className="text-center">
                    <button className="btn pharmacy-info-btn px-4">Update</button>
                </div>
            </Form>
        </div>
        </div>
    )
};
export default EditAttendance;
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";
import {exportPDF} from "../../../Utils/exportPDF";

export const useManageManufacturer = (actions, match = {}) => {
    const {manufacturer_id: id} = match;
    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [manufacturers, setManufacturers] = useState([]);
    const [manufacturer_id, setSupplierId] = useState('');
    const [total_count, setTotalCount] = useState(0);
    const [openDelete, setOpenDelete] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        manufacturer_name: '',
        mobile: '',
        address: '',
        details: '',
        previous_balance: '',
    });
    const [manufacturer, setManufacturer] = useState({})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const form = useRef();

    const dep = isSubmitted === 'resolved'
    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Cmanufacturer/manage_manufacturer/${activePage}`).then(response => {
            const data = response.data;
            const dt  = data ?? {};
            const manuf = dt.manufacturers_list ? dt.manufacturers_list :  [];
            const count = dt.total_count ? dt.total_count : 0;
            setManufacturers(manuf);
            setTotalCount(count)
            setLoading(false)
        }).catch(error => {
            // errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [activePage])

    useEffect(() => {
        if (!id) {
            return;
        }
        axios.get(`${config.epharmUrl}/Cmanufacturer/manufacturer_update_form`,
            {params: {manufacturer_id: id}}).then(response => {
            const data = response?.data;
            setManufacturer(data)
        }).catch(error => {
            // errorMessages(error, null, actions)
        })
    }, [])

    useEffect(() => {
        const {manufacturer_name, mobile, address, details, previous_balance} = manufacturer;
        setState({manufacturer_name, mobile, address, details, previous_balance});
    }, [manufacturer])


    const handleOpenDialog = () => {
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleOpenDelete = (_id) => {
        setOpenDelete(true);
        setSupplierId(_id)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleDeleteManufacturer = () => {
        axios.get(`${config.epharmUrl}/Cmanufacturer/manufacturer_delete`, {params: {manufacturer_id}}).then(() => {
            setManufacturers(data => data.filter(supplier => supplier.manufacturer_id !== manufacturer_id))
            actions.snackbarActions.successSnackbar('Supplier Deleted Successfully!');
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };

    const handler = (value) => {
        setTerm(value)
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    }

    const submit = () => {
        const {manufacturer_name, details, address, previous_balance, mobile} = state;
        if (id) {
            const formData = new FormData(form.current);
            formData.append('manufacturer_id', id);
            formData.append('oldname', manufacturer_name)
            return axios.post(`${config.epharmUrl}/Cmanufacturer/manufacturer_update`, formData)
        } else {
            return axios.post(`${config.epharmUrl}/Cmanufacturer/insert_manufacturer`, null, {params:{
                manufacturer_name,
                details : details ?? '',
                address,
                previous_balance,
                mobile
            }})
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const {manufacturer_name} = state;

        setSubmitted(true);
        if (manufacturer_name) {
            setIsSubmitted('pending');
            submit().then(() => {
                actions.snackbarActions.successSnackbar(`Supplier ${id ? 'updated' : 'added'} successfully`);
                setIsSubmitted('resolved')
                setOpenDialog(false);
                setSubmitted(false);
                setState({
                    manufacturer_name: '',
                    mobile: '',
                    address: '',
                    details: '',
                    previous_balance: '',
                });
                if (id) {
                    history.push('/managemanufacturer')
                }
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }

    const downloadPDF = () => {
        const manufs = manufacturers ?? [];
        exportPDF("Supplier Report", [["SL", "Supplier Name", "Address", "Mobile", "Details"]],
            manufs.map((manufacturer, index) => [index += 1, manufacturer.manufacturer_name, manufacturer.address,
                manufacturer.mobile, manufacturer.details]), "Suppliers.pdf")
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    return {
        term, activePage, manufacturers, total_count,
        openDelete, openDialog, loading, state,
        submitted, isSubmitted, form, handleOpenDialog, handleCloseDialog,
        handleOpenDelete, handleCloseDelete,
        handleDeleteManufacturer, handler, handlePageChange,
        handleChange, handleSubmit, handleCloseBar, downloadPDF
    }

}
import React from "react";
import CommonButtons from "./CommonButtons";
import MainPersonLoanDetails from "./MainPersonLoanDetails";


const PersonLoanDetails = () =>{
    return (
        <MainPersonLoanDetails personalLoan loanDetails>
            <CommonButtons personLoan/>
        </MainPersonLoanDetails>
    )
};
export default PersonLoanDetails;
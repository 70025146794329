import React, {useRef, useState} from 'react'
import Card from '@material-ui/core/Card';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions"
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {printFunctionality} from "../../../Utils/printFunctionality";
import {useAccountingStyles} from "../CustomHooks/useAccountingStyles";
import {useFetchData} from "../CustomHooks/useFetchData";
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";
import {printStyles, ReusableReportHeader} from "./ReusableReportHeader";
import ReusableAccountingCollapsibleTable from "../ReusableAccountingCollapsibleTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Collapse from "@material-ui/core/Collapse";
import {useCollapsibleRowStyles} from "../ReusableAccountingCollapsibleTable";
import {PrintableTrialBalance} from "../PrintableDocs";
import {NoTransaction} from "../ReusableJournalTable";

const TrialBalance = ({actions, snackbars}) => {
    const {
        date, closeSnackbar, period, handleChangePeriod, inputType,
        handleChangeDate, year, handleChangeYear, data, loading
    } = useFetchData(actions, 'get_trial_balance')
    const {from_date, to_date} = date;
    const rowClasses = useCollapsibleRowStyles();
    const [collapse, setCollapse] = useState({});

    const handleCollapse = (param) => {
        setCollapse(prevState => ({...prevState, [param]: !prevState[param]}));
    };

    const downloadPDF = () => {
        const doc = new jsPDF()
        autoTable(doc, {html: '#trial_balance'})
        doc.save('Trial Balance.pdf')
    };
    const {open, variant, message} = snackbars;
    const printRef = useRef()

    const headers = [{key: 'name', value: 'Account'}, {key: 'debit_amount', value: 'Debit'}, {
        key: 'credit_amount',
        value: 'Credit'
    }]
    return (
        <div className="acc_reports journals">
            <PharmacySnackbar handleCloseBar={closeSnackbar} variant={variant} open={open} message={message}/>
            <ReusableReportHeader handleChangeDate={handleChangeDate} to_date={to_date} inputType={inputType}
                                  handleChangePeriod={handleChangePeriod} period={period}
                                  handleChangeYear={handleChangeYear} year={year}
                                  downloadPdf={downloadPDF} from_date={from_date} title='Trial Balance'
                                  handlePrint={() => printFunctionality(printRef.current, printStyles())}
                                  subTitle='Trial balance'/>
            <div className="mui-tables">
                <PrintableTrialBalance printRef={printRef} data={data}/>
                {data.length > 0 ? <ReusableAccountingCollapsibleTable headers={headers}>
                    <React.Fragment>
                        {data.map(item => (
                            <>
                                {item.parent_accounts.map(({account_name: name, sub_accounts}, index) => (
                                    <React.Fragment>
                                        {sub_accounts.length > 0 ? <TableRow className={rowClasses.root} key={index}>
                                            <TableCell>
                                                <IconButton aria-label="expand row" size="small"
                                                            onClick={() => handleCollapse(name)}>
                                                    {collapse[name] ?
                                                        <KeyboardArrowDownIcon classes={{root: rowClasses.icon}}/> :
                                                        <KeyboardArrowRightIcon classes={{root: rowClasses.icon}}/>}
                                                </IconButton>{name}
                                            </TableCell>
                                            <TableCell>{" "}</TableCell>
                                            <TableCell>{" "}</TableCell>
                                        </TableRow> : null}
                                        {sub_accounts.map(it => (
                                            <TableRow key={it.name}>
                                                {headers.map(({key, format = value => value}) => (
                                                    <TableCell
                                                        style={{paddingBottom: 0, paddingTop: 0, borderBottom: 0}}>
                                                        <Collapse in={collapse[name]} timeout="auto" unmountOnExit
                                                                  classes={{wrapperInner: rowClasses.wrapperInner}}>
                                                            {format(it[key])}
                                                        </Collapse>
                                                    </TableCell>))}
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </>
                        ))}

                    </React.Fragment>
                </ReusableAccountingCollapsibleTable>:loading ?
                    <div className='text-center mt-5'><img src="/images/listPreloader.gif"
                                       alt="loader" style={{width: '10%'}}/></div>:<NoTransaction text='transactions'/>}
            </div>


        </div>
    );
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialBalance)

import React from "react";
import {dateStringConvert} from "../../Utils/titleCaseFunction";
import companyAddress from "../../Helpers/companyAddress.json";
import Card from "@material-ui/core/Card";


export const ReusablePurchaseOrderDetails = ({details, productList, pdfRef, instructions, sub_total,
                                                 arr, prepared_by,approved_by}) => {
    return (
        <Card>
            <div className='p-4' ref={pdfRef}>
                <table className='table-borderless table table-sm vendor-address-table'>
                    <tbody>
                        {/* <tr>
                            <td>
                            <img src={companyAddress.img_url} style={{width:"100px"}}
                                                 alt="Mpeke"/>
                            </td>
                            <td/>
                        </tr> */}
                    <tr>
                        <td><p>{companyAddress.company_name}</p></td>
                        <td align='right'><p className="purchase-order-title">PURCHASE ORDER</p></td>
                    </tr>
                    <tr>
                        <td><p>{companyAddress.address1}</p></td>
                        <td align='right'><p>Purchase Order#: {details.purchase_order_number}</p></td>
                    </tr>
                    <tr>
                        <td><p>{companyAddress.address2}</p></td>
                        <td/>
                    </tr>
                    <tr>
                        <td><p>{companyAddress.email}</p></td>
                        <td/>
                    </tr>
                    </tbody>
                </table>
                <table className='table-borderless table table-sm vendor-address-table'>
                    <tbody>
                    <tr>
                        <td><p>VENDOR ADDRESS</p></td>
                    </tr>
                    <tr>
                        <td><p>{details.manufacturer_name}</p></td>
                    </tr>
                    <tr>
                        <td><p>{details.address}</p></td>
                    </tr>
                    <tr>
                        <td><p>{details.mobile}</p></td>
                    </tr>
                    </tbody>
                </table>

                <table className='table-borderless table table-sm vendor-address-table'>
                    <tbody>
                    <tr>
                        <td><p>DELIVER TO</p></td>
                    </tr>
                    {arr.map((item, index) => (
                        <tr key={index}>
                            <td><p>{item}</p></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <table className='table-borderless table table-sm vendor-address-table'>
                    <tbody>
                    <tr>
                        <td/>
                        <td align='right'>
                            <p>Date: {details.create_date ? dateStringConvert(details.create_date) : ''}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table className="table table-borderless table-sm  vendor-table product-table">
                    <thead>
                    <tr>
                        <th>Item &#35;</th>
                        <th>Item Details</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {productList.map((product, index) => {
                        const count = index += 1;
                        return (
                            <tr key={index} className="vendor-row">
                                <td align='center'>{count}</td>
                                <td align='center'>{product.product_name}</td>
                                <td align='center'>{product.quantity}</td>
                                <td align='center'>{product.rate}</td>
                                <td>{product.total}</td>
                            </tr>)
                    })}
                    <tr>
                        <td colSpan={4} align="right">Sub Total</td>
                        <td align="center">{sub_total}</td>
                    </tr>
                    <tr>
                        <td colSpan={4} align="right">Tax %</td>
                        <td align="center">{details.tax}</td>
                    </tr>
                    <tr>
                        <td colSpan={4} align="right">Total</td>
                        <td align="center">{details.grand_total}</td>
                    </tr>
                    </tbody>
                </table>
                <table className='table-borderless table table-sm vendor-address-table'>
                    <tbody>
                    <tr>
                        <td><p className="purchase-comment">Comments or Special Instructions</p></td>
                    </tr>
                    <tr>
                        <td><p>{instructions}</p></td>
                    </tr>
                    </tbody>

                </table>
                <table className='table-borderless table table-sm vendor-address-table' style={{marginBottom: 0}}>
                    <tbody>
                    <tr>
                        <td><p>Prepared by:</p></td>
                        <td align='right'><p>Approved by:</p></td>
                    </tr>
                    <tr>
                        <td><p className="purchase-comment">{prepared_by}</p></td>
                        <td align='right'><p className="purchase-comment">{approved_by}</p></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    )
}
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFax} from "@fortawesome/free-solid-svg-icons/faFax";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faWindowRestore} from "@fortawesome/free-solid-svg-icons/faWindowRestore";
import Paper from "@material-ui/core/Paper";
import {EnhancedToolbar,EnhancedPharmacyListHead, stableSort, getSorting, useStyles} from "../../Utils/Tables/MuiTables";
import DrugsAvailableTablePagination from "../../Utils/Tables/MuiTablePagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FilterResults from 'react-filter-search';
import TablePagination from '@material-ui/core/TablePagination';
import {withStyles} from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import compose from "recompose/compose";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    },
    subMenuLink: {
        display: "block",
        color: "#757575",
        textDecoration: "none"
        // padding: theme.spacing.unit * 6,
    },
    itemStyle: {
        backgroundColor: "#f9f9f9"
    },
    itemOdd: {
        backgroundColor: "#fff"
    }
});

const data = [
    {
        id: 1,
        invoice: 111,
        name: "Customer 1",
        date: "2019-11-27",
        amount: 20000
    },
    {
        id: 2,
        invoice: 222,
        name: "Customer 2",
        date: "2019-11-27",
        amount: 20000
    },
    {
        id: 3,
        invoice: 333,
        name: "Customer 3",
        date: "2019-11-27",
        amount:20000
    },
    {
        id: 4,
        invoice: 444,
        name: "Customer 4",
        date: "2019-11-27",
        amount:20000
    },
    {
        id: 5,
        invoice: 555,
        name: "Customer 5",
        date: "2019-11-27",
        amount:20000
    },
];

class ManageServiceInvoice extends Component{
    constructor(props){
        super(props);
        this.state={
            term: "",
            order: 'asc',
            setOrder: 'asc',
            orderBy: 'person',
            selected: [],
            setSelected: [],
            page: 0,
            setPage: 0,
            dense: false,
            setDense: false,
            rowsPerPage: 5,
        };
        this.handler = this.handler.bind(this);
        this.ref = React.createRef();
    }
    handler(value) {
        this.setState({term: value})
    }
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0});
    };
    render() {
        const {order, dense, orderBy, selected, rowsPerPage, page, term} = this.state;
        const {classes} = this.props;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        return(
            <div>
            <DashboardHeader service manageServiceInvoice/>
            <div className="card my-3 p-3">
                {/* <div className="pos-buttons">
                    <Link to="/createinvoice" style={{textDecoration:"none", color:"inherit"}}>
                        <button type="button" className="btn mr-2 pharmacy-btn">
                        <FontAwesomeIcon icon={faPlus} /> New Invoice</button></Link>
                    <Link to="/createposinvoice"><button type="button" className="btn pharmacy-primary-btn mr-2">
                        <FontAwesomeIcon icon={faList} /> POS Invoice</button></Link>
                </div> */}
                <div className="card mt-4 p-2">
                    <Form style={{paddingTop: 10}}
                          autoComplete="off"
                    >
                        <Form.Row>
                            <Form.Group
                                as={Row}
                                controlId="formHorizontaldate">
                                <Form.Label column sm="4" className="date-picker-labels"
                                            style={{marginLeft: 5}}>Start Date:</Form.Label>
                                <Col sm="7">
                                    <Form.Control type="date" placeholder="Start date"/>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                controlId="formHorizontaldate">
                                <Form.Label column sm="4" className="date-picker-labels"
                                            >
                                    End Date:</Form.Label>
                                <Col sm="8">
                                    <Form.Control type="date"/>
                                </Col>
                            </Form.Group>
                            </Form.Row>
                    </Form>
                </div>
                <div className="card mt-4">
                    <Paper className={`${classes.paper} table-responsive `}>
                        <EnhancedToolbar numSelected={selected.length} handler={this.handler} targetRef={this.ref} data={data} serviceInvoice/>
                        <div className={classes.tableWrapper} ref={this.ref}>
                            <Table
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                // className={classes.table}
                                id="section-to-print"
                                >
                                <EnhancedPharmacyListHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                />
                                <FilterResults
                                    value={term}
                                    data={data}
                                    renderResults={results => (
                                        <TableBody>
                                            {stableSort(results, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((d, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={d.id}
                                                            className='mui-table-row'>
                                                            <TableCell component="th" scope="row" padding="default"
                                                                       className='mui-table-cell'>
                                                                <span>{d.id}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.invoice}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.name}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.date}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.amount}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>
                                                                    <Tooltip title="Invoice" placement="left-start">
                                                                    <button className="btn pharmacy-btn btn-sm mr-1 ">
                                                                        <FontAwesomeIcon icon={faWindowRestore}/>
                                                                    </button>
                                                                    </Tooltip>
                                                                    <Tooltip title="POS Invoice" placement="left-start">
                                                                        <button className="btn pharmacy-amber-btn btn-sm mr-1 ">
                                                                        <FontAwesomeIcon icon={faFax} />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Update" placement="left-start">
                                                                         <Link to="/serviceinvoice">
                                                                    <button className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                                                    </button></Link>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete" placement="left-start">
                                                                    <button className="btn pharmacy-btn-dismiss btn-sm  ">
                                                                        <FontAwesomeIcon icon={faTrash}/>
                                                                    </button>
                                                                    </Tooltip>
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    )}
                                />
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[ 5, 10, 25]}
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={DrugsAvailableTablePagination}
                        />
                    </Paper>
                </div>
            </div>
            </div>
        )
    }

}
function mapStateToProps(state) {
    return {
        invoice:state.invoice
    }

}
function mapDispatchToProps(dispatch) {
    return{
        actions:{
            invoiceActions:bindActionCreators(invoiceActions, dispatch)
        }
    }

}
export default compose(connect(mapStateToProps, mapDispatchToProps), (withStyles(useStyles, styles) ))(ManageServiceInvoice);
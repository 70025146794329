import React, {Component} from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {lighten} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {titleCase} from "../titleCaseFunction";
import TextField from "../FormInputs/TextField";



export const useStyles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 2000,
    },
    tableWrapper: {
        overflowX: 'auto',

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

export function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

export function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const user = JSON.parse(sessionStorage.getItem('user'));
const singleUser = !user ? {} : user;
const permissions = !singleUser.permission ? {} : singleUser.permission;
const manage_invoice = !permissions.manage_invoice ? {} : permissions.manage_invoice;
const manage_customer = !permissions.manage_customer ? {} : permissions.manage_customer;
const manage_medicine = !permissions.manage_medicine ? {} : permissions.manage_medicine;
const manage_manufacturer = !permissions.manage_manufacturer ? {} : permissions.manage_manufacturer;
const manage_purchase = !permissions.manage_purchase ? {} : permissions.manage_purchase;
const manage_supplier  = !permissions.manage_supplier ? {} : permissions.manage_supplier;
const manage_users = !permissions.manage_users ? {} : permissions.manage_users;
// const role_list = !permissions.role_list ? {} : permissions.role_list;
// const credit_customer = !permissions.credit_customer ? {} : permissions.credit_customer;
// const paid_customer = !permissions.paid_customer ? {} : permissions.paid_customer;
const  unit_list = !permissions.unit_list ? {} : permissions.unit_list;
// const user_type= !singleUser.user_type ? "" : singleUser.user_type;


const invoiceListRows = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'invoice', numeric: false, disablePadding: true, label: 'Invoice No'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Customer Name'},
    {id: 'date', numeric: true, disablePadding: false, label: 'Date'},
    {id: 'total', numeric: true, disablePadding: false, label: 'Total Amount'},
    (manage_invoice.create || manage_invoice.update || manage_invoice.delete)
    ? {id: 'action', numeric: true, disablePadding: false, label: 'Action'}:
    {id: 'nonaction', numeric: true, disablePadding: false, label: ''}
];
const customerList = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Customer Name'},
    {id: 'address', numeric: true, disablePadding: false, label: 'Address'},
    {id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile'},
    {id: 'balance', numeric: true, disablePadding: false, label: 'Balance'},
    (manage_customer.create || manage_customer.update || manage_customer.delete)
    ? {id: 'action', numeric: true, disablePadding: false, label: 'Action'}:
    {id: 'nonaction', numeric: true, disablePadding: false, label: ''},
];
const manufList = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Supplier Name'},
    {id: 'address', numeric: true, disablePadding: false, label: 'Address'},
    {id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile'},
    {id: 'details', numeric: true, disablePadding: false, label: 'Details'},
    (manage_manufacturer.create || manage_manufacturer.update || manage_manufacturer.delete)
    ? {id: 'action', numeric: true, disablePadding: false, label: 'Action'}:
    {id: 'nonaction', numeric: true, disablePadding: false, label: ''}
];
const supplierList = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Supplier Name'},
    {id: 'address', numeric: true, disablePadding: false, label: 'Address'},
    {id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile'},
    {id: 'details', numeric: true, disablePadding: false, label: 'Details'},
    (manage_supplier.create || manage_supplier.update || manage_supplier.delete)
     ? {id: 'action', numeric: true, disablePadding: false, label: 'Action'}:
     {id: 'nonaction', numeric: true, disablePadding: false, label: ''}
];

const medicineList = [
    {id: 'sl', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: true, disablePadding: true, label: 'Medicine Name'},
    {id: 'generic', numeric: true, disablePadding: false, label: 'Generic Name'},
    {id: 'type', numeric: true, disablePadding: false, label: 'Medicine Type'},
    {id: 'unit', numeric: true, disablePadding: false, label: 'Unit'},
    {id: 'shelf', numeric: true, disablePadding: false, label: 'Shelf'},
    {id: 'strength', numeric: true, disablePadding: false, label: 'Strength'},
    {id: 'reorderlevel', numeric: true, disablePadding: false, label: 'Reorder Level'},
    {id: 'images', numeric: true, disablePadding: false, label: 'Images'},
    (manage_medicine.create || manage_medicine.update || manage_medicine.delete)
     ? {id: 'action', numeric: false, disablePadding: true, label: 'Action'}:
     {id: 'nonaction', numeric: true, disablePadding: false, label: ''},
];
const typeList = [
    {id: 'type', numeric: false, disablePadding: true, label: 'Type Name'},
    {id: 'action', numeric: true, disablePadding: false, label: 'Action'},
];
const unitList = [
    {id: 'unit', numeric: false, disablePadding: true, label: 'Unit Name'},
    (unit_list.create || unit_list.update || unit_list.delete)
    ? {id: 'action', numeric: true, disablePadding: false, label: 'Action'}:
    {id: 'nonaction', numeric: true, disablePadding: false, label: ''},
];
const categoryList = [
    {id: 'id', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Category Name'},
    {id: 'action', numeric: true, disablePadding: false, label: 'Action'},
];
const purchaseList = [
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'invoiceNo', numeric: false, disablePadding: true, label: 'Invoice No'},
    {id: 'invoiceId', numeric: true, disablePadding: false, label: 'Invoice#'},
    {id: 'manufName', numeric: true, disablePadding: false, label: 'Supplier Name'},
    {id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity'},
    {id: 'purchasePrice', numeric: true, disablePadding: false, label: 'Purchase price'},
    {id: 'amount', numeric: true, disablePadding: false, label: 'Total Amount'},
];

const saleList = [
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'invoiceNo', numeric: false, disablePadding: true, label: 'Invoice NO'},
    {id: 'invoiceId', numeric: true, disablePadding: false, label: 'Invoice#'},
    {id: 'customerName', numeric: true, disablePadding: false, label: 'Customer Name'},
    {id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity'},
    {id: 'salePrice', numeric: true, disablePadding: false, label: 'Sale price'},
    {id: 'amount', numeric: true, disablePadding: false, label: 'Total Amount'},
];
const ledgerList = [
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'description', numeric: false, disablePadding: true, label: 'Description'},
    {id: 'invoiceno', numeric: true, disablePadding: false, label: 'Invoice NO'},
    {id: 'deposit', numeric: true, disablePadding: false, label: 'Deposit ID'},
    {id: 'debit', numeric: true, disablePadding: false, label: 'Debit'},
    {id: 'credit', numeric: true, disablePadding: false, label: 'Credit'},
    {id: 'Balance', numeric: true, disablePadding: false, label: 'Balance'},
];
const serviceList = [
    {id: 'sl2', numeric: true, disablePadding: true, label: 'SL.'},
    {id: 'serviceName', numeric: false, disablePadding: true, label: 'Service Name'},
    {id: 'charge', numeric: false, disablePadding: false, label: 'Charge'},
    {id: 'description', numeric: true, disablePadding: false, label: 'Description'},
    {id: 'tax', numeric: false, disablePadding: false, label: 'Tax'},
    {id: 'vat', numeric: true, disablePadding: false, label: 'VAT'}
];

const bankList = [
    {id: 'sl1', numeric: true, disablePadding: true, label: 'SL.'},
    {id: 'bankName', numeric: false, disablePadding: true, label: 'Bank Name'},
    {id: 'accountName', numeric: false, disablePadding: false, label: 'Account Name'},
    {id: 'accountNo', numeric: true, disablePadding: false, label: 'Account No'},
    {id: 'branch', numeric: false, disablePadding: false, label: 'Branch'},
    {id: 'balance', numeric: true, disablePadding: false, label: 'Balance'},
    {id: 'signature', numeric: true, disablePadding: false, label: 'Signature'},
    {id: 'action', numeric: true, disablePadding: false, label: 'Action'}
];

const ledgerList2 = [
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'description', numeric: false, disablePadding: true, label: 'Description'},
    {id: 'invoiceno', numeric: true, disablePadding: false, label: 'Invoice NO'},
    {id: 'deposit', numeric: true, disablePadding: false, label: 'Deposit ID'},
    {id: 'debit', numeric: true, disablePadding: false, label: 'Debit'},
    {id: 'credit', numeric: true, disablePadding: false, label: 'Credit'},
    {id: 'Balance', numeric: true, disablePadding: false, label: 'Balance'},
];
const purchaseInfo = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'medicineName', numeric: false, disablePadding: true, label: 'Medicine Name'},
    {id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity'},
    {id: 'batch', numeric: true, disablePadding: false, label: 'Batch ID'},
    {id: 'expiry', numeric: true, disablePadding: false, label: 'Expiry Date'},
    {id: 'purchase', numeric: true, disablePadding: false, label: 'Purchase Price'},
    {id: 'amount', numeric: true, disablePadding: false, label: 'Total Amount'}
];
const manufSales = [
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'medicineName', numeric: false, disablePadding: true, label: 'Medicine Name'},
    {id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity'},
    {id: 'purchase', numeric: true, disablePadding: false, label: 'Sale Price'},
    {id: 'amount', numeric: true, disablePadding: false, label: 'Amount'}
];
const purchases = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'invoiceNo', numeric: false, disablePadding: true, label: 'Invoice No'},
    {id: 'purchaseID', numeric: true, disablePadding: false, label: 'Purchase ID'},
    {id: 'manufName', numeric: true, disablePadding: false, label: 'Supplier Name'},
    {id: 'purchaseDate', numeric: true, disablePadding: false, label: 'Purchase Date'},
    {id: 'tAmount', numeric: true, disablePadding: false, label: 'Total Amount'},
    (manage_purchase.create || manage_purchase.update || manage_purchase.delete)
    ? {id: 'action', numeric: true, disablePadding: false, label: 'Action'}:
    {id: 'nonaction', numeric: true, disablePadding: false, label: ''}
];
const purchaseOrder = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'orderNo', numeric: false, disablePadding: true, label: 'Purchase Order No'},
    {id: 'manufName', numeric: true, disablePadding: false, label: 'Supplier Name'},
    {id: 'purchaseDate', numeric: true, disablePadding: false, label: 'Order Date'},
    {id: 'tAmount', numeric: true, disablePadding: false, label: 'Total Amount'},
    {id: 'action', numeric: true, disablePadding: false, label: 'Action'}
];
const stockList = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'medicineName', numeric: false, disablePadding: true, label: 'Medicine Name'},
    {id: 'medicineType', numeric: true, disablePadding: false, label: 'Medicine Type'},
    {id: 'inQuantity', numeric: true, disablePadding: false, label: 'In Quantity'},
    {id: 'soldQuantity', numeric: true, disablePadding: false, label: 'Sold Quantity'},
    {id: 'stock', numeric: true, disablePadding: false, label: 'Stock'},
];
const stockBatch = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'Sl'},
    {id: 'medicineName', numeric: false, disablePadding: true, label: 'Medicine Name'},
    {id: 'strength', numeric: true, disablePadding: false, label: 'Strength'},
    {id: 'bactchId', numeric: true, disablePadding: false, label: 'Batch ID'},
    {id: 'expiryDate', numeric: true, disablePadding: false, label: 'Expiry Date'},
    {id: 'inQuantity', numeric: true, disablePadding: false, label: 'In Quantity'},
    {id: 'soldQuantity', numeric: true, disablePadding: false, label: 'Sold Quantity'},
    {id: 'stock', numeric: true, disablePadding: false, label: 'Stock'},
];
const tSalesReport = [
    {id: 'invoice', numeric: false, disablePadding: true, label: 'Invoice No'},
    {id: 'sdate', numeric: false, disablePadding: true, label: 'Sales Date'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Customer'},
    {id: 'sold_by', numeric: false, disablePadding: true, label: 'Sold by'},
    {id: 'product', numeric: false, disablePadding: true, label: 'Product Name'},
    {id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity Sold'},
    {id: 'purchase', numeric: false, disablePadding: true, label: 'Purchase Price'},
    {id: 'price', numeric: false, disablePadding: true, label: 'Sale Price'},
    {id: 'purchaseAmount', numeric: true, disablePadding: false, label: 'Total Purchase Amount'},
    {id: 'total', numeric: true, disablePadding: false, label: 'Total Sale Amount'},
    {id: 'profit', numeric: true, disablePadding: false, label: 'Profit/Loss'},
];
const tPurchaseReport = [
    {id: 'invoice', numeric: false, disablePadding: true, label: 'Invoice No'},
    {id: 'pdate', numeric: false, disablePadding: true, label: 'Purchase Date'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Supplier Name'},
    {id: 'product', numeric: false, disablePadding: true, label: 'Product Name'},
    {id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity'},
    {id: 'price', numeric: false, disablePadding: true, label: 'Price'},
    {id: 'total', numeric: true, disablePadding: false, label: 'Total Amount'},
];

const medicineWiseTable = [
    {id: 'salesdate', numeric: false, disablePadding: true, label: 'Sales Date'},
    {id: 'medicinename', numeric: false, disablePadding: true, label: 'Medicine Name'},
    {id: 'medicinetype', numeric: false, disablePadding: true, label: 'Medicine Type'},
    {id: 'customername', numeric: false, disablePadding: true, label: 'Customer Name'},
    {id: 'salesprice', numeric: true, disablePadding: true, label: 'Sale Price'},
    {id: 'totalamount', numeric: false, disablePadding: false, label: 'Total Amount'},
];

const manageco = [
    {id: 'sl1', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'companyName', numeric: false, disablePadding: true, label: 'Company Name'},
    {id: 'address', numeric: false, disablePadding: true, label: 'Address'},
    {id: 'mobile', numeric: true, disablePadding: true, label: 'Mobile'},
    {id: 'website', numeric: false, disablePadding: true, label: 'Website'},
    {id: 'action', numeric: true, disablePadding: false, label: 'Action'},
];

const manageusers = [
    {id: 'sl2', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'email', numeric: false, disablePadding: true, label: 'Email'},
    {id: 'status', numeric: false, disablePadding: true, label: 'Status'},
    (manage_users.create || manage_users.update || manage_users.delete)
    ? {id: 'action', numeric: false, disablePadding: false, label: 'Action'}:
    {id: 'nonaction', numeric: true, disablePadding: false, label: ''},
];

const currency = [
    {id: 'sl3', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'currencyName', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'currencyIcon', numeric: false, disablePadding: true, label: 'Email'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const outOfStock = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'medicine_name', numeric: false, disablePadding: true, label: 'Medicine Name'},
    // {id: 'manufacturer_name', numeric: false, disablePadding: true, label: 'Supplier Name'},
    {id: 'generic_name', numeric: false, disablePadding: true, label: 'Generic Name'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
];
const expiryList = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'medicine_name', numeric: false, disablePadding: true, label: 'Medicine Name'},
    {id: 'batch_id', numeric: false, disablePadding: true, label: 'Batch ID'},
    {id: 'expiry_date', numeric: false, disablePadding: true, label: 'Expiry Date'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
    {id: 'total_cost', numeric: false, disablePadding: false, label: 'Total Cost'},
];

const manageattendance = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'checkin', numeric: true, disablePadding: true, label: 'Check In'},
    {id: 'checkout', numeric: false, disablePadding: true, label: 'Check Out'},
    {id: 'stay', numeric: false, disablePadding: true, label: 'Stay'},
    {id: 'action', numeric: true, disablePadding: false, label: 'Action'},
];

const managedesignationList = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'designation', numeric: false, disablePadding: true, label: 'Designation'},
    {id: 'details', numeric: false, disablePadding: true, label: 'Details'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];

const manageemployeeList = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'designation', numeric: false, disablePadding: true, label: 'Designation'},
    {id: 'phone', numeric: false, disablePadding: true, label: 'Phone'},
    {id: 'email', numeric: false, disablePadding: true, label: 'Email'},
    {id: 'picture', numeric: false, disablePadding: true, label: 'Picture'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const manageBenefits = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'benefits', numeric: false, disablePadding: true, label: 'Benefits'},
    {id: 'benefitType', numeric: false, disablePadding: true, label: 'Benefit Type'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const manageSetUp = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'empName', numeric: false, disablePadding: true, label: 'Employee Name'},
    {id: 'salaryType', numeric: false, disablePadding: true, label: 'Salary Type'},
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const salaryGenerate = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'month', numeric: false, disablePadding: true, label: 'Salary Month'},
    {id: 'generator', numeric: false, disablePadding: true, label: 'Generated By'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const salaryPayment = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Employee Name'},
    {id: 'month', numeric: false, disablePadding: true, label: 'Salary Month'},
    {id: 'hours', numeric: false, disablePadding: true, label: 'Total Working Hours'},
    {id: 'days', numeric: false, disablePadding: true, label: 'Total Working Days'},
    {id: 'salary', numeric: false, disablePadding: true, label: 'Total Salary'},
    {id: 'type', numeric: false, disablePadding: true, label: 'Payment Type'},
    {id: 'date', numeric: false, disablePadding: true, label: 'Payment Date'},
    {id: 'paid_by', numeric: false, disablePadding: true, label: 'Paid By'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];

const expenseItem = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Expense Item Name'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const expense = [
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'type', numeric: false, disablePadding: true, label: 'Type'},
    {id: 'amount', numeric: false, disablePadding: true, label: 'Amount'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const fixedAssets = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'item_code', numeric: false, disablePadding: true, label: 'Item Code'},
    {id: 'item_name', numeric: false, disablePadding: true, label: 'Item Name'},
    {id: 'price', numeric: false, disablePadding: true, label: 'Price'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const fixedPurchase = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'supplier_name', numeric: false, disablePadding: true, label: 'Supplier Name'},
    {id: 'price', numeric: false, disablePadding: true, label: 'Price'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const managePerson = [
    {id: 'name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'address', numeric: false, disablePadding: true, label: 'Address'},
    {id: 'phone', numeric: false, disablePadding: true, label: 'Phone'},
    {id: 'balance', numeric: false, disablePadding: true, label: 'Balance'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const officePerson = [
    {id: 'name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'address', numeric: false, disablePadding: true, label: 'Address'},
    {id: 'phone', numeric: false, disablePadding: true, label: 'Phone'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const addAttendance = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Name'},
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'check_in', numeric: false, disablePadding: true, label: 'Check In'},
    {id: 'check_out', numeric: false, disablePadding: true, label: 'Check Out'},
    {id: 'stay', numeric: false, disablePadding: true, label: 'Stay'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const attendanceReport = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Employee Name'},
    {id: 'date', numeric: false, disablePadding: true, label: 'Date'},
    {id: 'check_in', numeric: false, disablePadding: true, label: 'Check In'},
    {id: 'check_out', numeric: false, disablePadding: true, label: 'Check Out'},
    {id: 'stay', numeric: false, disablePadding: true, label: 'Stay'},
];
const manageDesignation = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'Designation', numeric: false, disablePadding: true, label: 'Designation'},
    {id: 'details', numeric: false, disablePadding: true, label: 'Details'},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action'},
];
const productList = [
    {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'productName', numeric: false, disablePadding: true, label: 'Product Name'},
    {id: 'unit', numeric: true, disablePadding: false, label: 'Unit'},
    {id: 'expiryDate', numeric: true, disablePadding: false, label: 'Expiry Date'},
    {id: 'purchaseDate', numeric: true, disablePadding: false, label: 'Purchase Date'},
    {id: 'manufacturer', numeric: true, disablePadding: false, label: 'Supplier'},
    {id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity'},
    {id: 'purchasePrice', numeric: true, disablePadding: false, label: 'Purchase Price'},
    {id: 'totalPurchasePrice', numeric: true, disablePadding: false, label: 'Total Purchase Amount'},
    {id: 'totalSalePrice', numeric: true, disablePadding: false, label: 'Total Sale Amount'},
    {id: 'salePrice', numeric: true, disablePadding: false, label: 'Sale Price'},
    {id: 'soldQuantity', numeric: true, disablePadding: false, label: 'Sold Quantity'},
    {id: 'stock', numeric: true, disablePadding: false, label: 'Available Stock'},
    {id: 'profit', numeric: true, disablePadding: false, label: 'Net Profit/Loss'},
];

EnhancedPharmacyListHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// General List
export function EnhancedPharmacyListHead(props) {
    const userRoles = [{id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
    {id: 'name', numeric: false, disablePadding: true, label: 'User Name'},
    {id: 'role', numeric: false, disablePadding: true, label: 'Role'},
    ]
    const stockReturn = [
        {id: 'sl', numeric: false, disablePadding: true, label: 'SL'},
        {id: 'invoice', numeric: false, disablePadding: true, label: props.stockreturn ? 'Invoice No' : 'Purchase ID'},
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: props.stockreturn ? 'Customer Name' : 'Supplier Name'
        },
        {id: 'date', numeric: true, disablePadding: false, label: 'Date'},
        {id: 'total', numeric: true, disablePadding: false, label: 'Total Amount'},
        {id: 'action', numeric: true, disablePadding: false, label: 'Action'},
    ];


    const {classes,  order, orderBy, onRequestSort} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {props.customer ?
                    customerList.map(row => {

                            return (<TableCell
                                key={row.id}
                                align={row.numeric ? 'left' : 'left'}
                                padding={'default'}
                                sortDirection={orderBy === row.id ? order : false}
                                id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                            >
                                <TableSortLabel
                                    active={orderBy === row.id}
                                    direction={order}
                                    onClick={createSortHandler(row.id)}
                                >
                                    {row.label}
                                    {orderBy === row.id ? (
                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>)
                        }
                    ) :props.purchaseOrder ?
                        purchaseOrder.map(row => {

                                return (<TableCell
                                    key={row.id}
                                    align={row.numeric ? 'left' : 'left'}
                                    padding={'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={createSortHandler(row.id)}
                                    >
                                        {row.label}
                                        {orderBy === row.id ? (
                                            <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>)
                            }
                        ):props.userroles ?
                        userRoles.map(row => {

                                return (<TableCell
                                    key={row.id}
                                    align={row.numeric ? 'left' : 'left'}
                                    padding={'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={createSortHandler(row.id)}
                                    >
                                        {row.label}
                                        {orderBy === row.id ? (
                                            <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>)
                            }
                        )
                    :props.productListReport ?
                        productList.map(row => {

                                return (<TableCell
                                    key={row.id}
                                    align={row.numeric ? 'left' : 'left'}
                                    padding={'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={createSortHandler(row.id)}
                                    >
                                        {row.label}
                                        {orderBy === row.id ? (
                                            <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>)
                            }
                        ) : props.managedesignation ?
                        manageDesignation.map(row => {

                                return (<TableCell
                                    key={row.id}
                                    align={row.numeric ? 'left' : 'left'}
                                    padding={'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={createSortHandler(row.id)}
                                    >
                                        {row.label}
                                        {orderBy === row.id ? (
                                            <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>)
                            }
                        )
                        : props.attendanceReport ?
                            attendanceReport.map(row => {

                                    return (<TableCell
                                        key={row.id}
                                        align={row.numeric ? 'left' : 'left'}
                                        padding={'default'}
                                        sortDirection={orderBy === row.id ? order : false}
                                        id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={createSortHandler(row.id)}
                                        >
                                            {row.label}
                                            {orderBy === row.id ? (
                                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>)
                                }
                            )
                            : props.addAttendance ?
                                addAttendance.map(row => {

                                        return (<TableCell
                                            key={row.id}
                                            align={row.numeric ? 'left' : 'left'}
                                            padding={'default'}
                                            sortDirection={orderBy === row.id ? order : false}
                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                        >
                                            <TableSortLabel
                                                active={orderBy === row.id}
                                                direction={order}
                                                onClick={createSortHandler(row.id)}
                                            >
                                                {row.label}
                                                {orderBy === row.id ? (
                                                    <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>)
                                    }
                                )
                                : props.officePerson ?
                                    officePerson.map(row => {

                                            return (<TableCell
                                                key={row.id}
                                                align={row.numeric ? 'left' : 'left'}
                                                padding={'default'}
                                                sortDirection={orderBy === row.id ? order : false}
                                                id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === row.id}
                                                    direction={order}
                                                    onClick={createSortHandler(row.id)}
                                                >
                                                    {row.label}
                                                    {orderBy === row.id ? (
                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>)
                                        }
                                    )
                                    : props.managePerson ?
                                        managePerson.map(row => {

                                                return (<TableCell
                                                    key={row.id}
                                                    align={row.numeric ? 'left' : 'left'}
                                                    padding={'default'}
                                                    sortDirection={orderBy === row.id ? order : false}
                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === row.id}
                                                        direction={order}
                                                        onClick={createSortHandler(row.id)}
                                                    >
                                                        {row.label}
                                                        {orderBy === row.id ? (
                                                            <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>)
                                            }
                                        )
                                        : props.fixedPurchase ?
                                            fixedPurchase.map(row => {

                                                    return (<TableCell
                                                        key={row.id}
                                                        align={row.numeric ? 'left' : 'left'}
                                                        padding={'default'}
                                                        sortDirection={orderBy === row.id ? order : false}
                                                        id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                    >
                                                        <TableSortLabel
                                                            active={orderBy === row.id}
                                                            direction={order}
                                                            onClick={createSortHandler(row.id)}
                                                        >
                                                            {row.label}
                                                            {orderBy === row.id ? (
                                                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                            ) : null}
                                                        </TableSortLabel>
                                                    </TableCell>)
                                                }
                                            )
                                            : props.fixedAssets ?
                                                fixedAssets.map(row => {

                                                        return (<TableCell
                                                            key={row.id}
                                                            align={row.numeric ? 'left' : 'left'}
                                                            padding={'default'}
                                                            sortDirection={orderBy === row.id ? order : false}
                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                        >
                                                            <TableSortLabel
                                                                active={orderBy === row.id}
                                                                direction={order}
                                                                onClick={createSortHandler(row.id)}
                                                            >
                                                                {row.label}
                                                                {orderBy === row.id ? (
                                                                    <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                ) : null}
                                                            </TableSortLabel>
                                                        </TableCell>)
                                                    }
                                                )
                                                : props.expense ?
                                                    expense.map(row => {

                                                            return (<TableCell
                                                                key={row.id}
                                                                align={row.numeric ? 'left' : 'left'}
                                                                padding={'default'}
                                                                sortDirection={orderBy === row.id ? order : false}
                                                                id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                            >
                                                                <TableSortLabel
                                                                    active={orderBy === row.id}
                                                                    direction={order}
                                                                    onClick={createSortHandler(row.id)}
                                                                >
                                                                    {row.label}
                                                                    {orderBy === row.id ? (
                                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                    ) : null}
                                                                </TableSortLabel>
                                                            </TableCell>)
                                                        }
                                                    )
                                                    : props.expenseItem ?
                                                        expenseItem.map(row => {

                                                                return (<TableCell
                                                                    key={row.id}
                                                                    align={row.numeric ? 'left' : 'left'}
                                                                    padding={'default'}
                                                                    sortDirection={orderBy === row.id ? order : false}
                                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                >
                                                                    <TableSortLabel
                                                                        active={orderBy === row.id}
                                                                        direction={order}
                                                                        onClick={createSortHandler(row.id)}
                                                                    >
                                                                        {row.label}
                                                                        {orderBy === row.id ? (
                                                                            <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                        ) : null}
                                                                    </TableSortLabel>
                                                                </TableCell>)
                                                            }
                                                        )
                                                        : props.salaryPayment ?
                                                            salaryPayment.map(row => {
                                                                    // console.log(orderBy);
                                                                    return (<TableCell
                                                                        key={row.id}
                                                                        align={row.numeric ? 'left' : 'left'}
                                                                        padding={'default'}
                                                                        sortDirection={orderBy === row.id ? order : false}
                                                                        id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                    >
                                                                        <TableSortLabel
                                                                            active={orderBy === row.id}
                                                                            direction={order}
                                                                            onClick={createSortHandler(row.id)}
                                                                        >
                                                                            {row.label}
                                                                            {orderBy === row.id ? (
                                                                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                            ) : null}
                                                                        </TableSortLabel>
                                                                    </TableCell>)
                                                                }
                                                            )
                                                            : props.salaryGenerate ?
                                                                salaryGenerate.map(row => {
                                                                        // console.log(orderBy);
                                                                        return (<TableCell
                                                                            key={row.id}
                                                                            align={row.numeric ? 'left' : 'left'}
                                                                            padding={'default'}
                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                        >
                                                                            <TableSortLabel
                                                                                active={orderBy === row.id}
                                                                                direction={order}
                                                                                onClick={createSortHandler(row.id)}
                                                                            >
                                                                                {row.label}
                                                                                {orderBy === row.id ? (
                                                                                    <span
                                                                                        className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                                ) : null}
                                                                            </TableSortLabel>
                                                                        </TableCell>)
                                                                    }
                                                                )
                                                                : props.manageBenefits ?
                                                                    manageBenefits.map(row => {
                                                                            // console.log(orderBy);
                                                                            return (<TableCell
                                                                                key={row.id}
                                                                                align={row.numeric ? 'left' : 'left'}
                                                                                padding={'default'}
                                                                                sortDirection={orderBy === row.id ? order : false}
                                                                                id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                            >
                                                                                <TableSortLabel
                                                                                    active={orderBy === row.id}
                                                                                    direction={order}
                                                                                    onClick={createSortHandler(row.id)}
                                                                                >
                                                                                    {row.label}
                                                                                    {orderBy === row.id ? (
                                                                                        <span
                                                                                            className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                                    ) : null}
                                                                                </TableSortLabel>
                                                                            </TableCell>)
                                                                        }
                                                                    )
                                                                    : props.manageSetUp ?
                                                                        manageSetUp.map(row => {
                                                                                // console.log(orderBy);
                                                                                return (<TableCell
                                                                                    key={row.id}
                                                                                    align={row.numeric ? 'left' : 'left'}
                                                                                    padding={'default'}
                                                                                    sortDirection={orderBy === row.id ? order : false}
                                                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                >
                                                                                    <TableSortLabel
                                                                                        active={orderBy === row.id}
                                                                                        direction={order}
                                                                                        onClick={createSortHandler(row.id)}
                                                                                    >
                                                                                        {row.label}
                                                                                        {orderBy === row.id ? (
                                                                                            <span
                                                                                                className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                                        ) : null}
                                                                                    </TableSortLabel>
                                                                                </TableCell>)
                                                                            }
                                                                        )
                                                                        : props.medicine ?
                                                                            medicineList.map(row => {
                                                                                    // console.log(orderBy);
                                                                                    return (<TableCell
                                                                                        key={row.id}
                                                                                        align={row.numeric ? 'left' : 'left'}
                                                                                        padding={'default'}
                                                                                        sortDirection={orderBy === row.id ? order : false}
                                                                                        id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                    >
                                                                                        <TableSortLabel
                                                                                            active={orderBy === row.id}
                                                                                            direction={order}
                                                                                            onClick={createSortHandler(row.id)}
                                                                                        >
                                                                                            {row.label}
                                                                                            {orderBy === row.id ? (
                                                                                                <span
                                                                                                    className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                                            ) : null}
                                                                                        </TableSortLabel>
                                                                                    </TableCell>)
                                                                                }
                                                                            ) : props.type ?
                                                                                typeList.map(row => {
                                                                                        // console.log(orderBy);
                                                                                        return (<TableCell
                                                                                            key={row.id}
                                                                                            align={row.numeric ? 'left' : 'left'}
                                                                                            padding={'default'}
                                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                        >
                                                                                            <TableSortLabel
                                                                                                active={orderBy === row.id}
                                                                                                direction={order}
                                                                                                onClick={createSortHandler(row.id)}
                                                                                            >
                                                                                                {row.label}
                                                                                                {orderBy === row.id ? (
                                                                                                    <span
                                                                                                        className={classes.visuallyHidden}>
              {order === 'desc' ? '' : ''}
            </span>
                                                                                                ) : null}
                                                                                            </TableSortLabel>
                                                                                        </TableCell>)
                                                                                    }
                                                                                )
                                                                                : props.category ?
                                                                                    categoryList.map(row => {
                                                                                            // console.log(orderBy);
                                                                                            return (<TableCell
                                                                                                key={row.id}
                                                                                                align={row.numeric ? 'left' : 'left'}
                                                                                                padding={'default'}
                                                                                                sortDirection={orderBy === row.id ? order : false}
                                                                                                id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                            >
                                                                                                <TableSortLabel
                                                                                                    active={orderBy === row.id}
                                                                                                    direction={order}
                                                                                                    onClick={createSortHandler(row.id)}
                                                                                                >
                                                                                                    {row.label}
                                                                                                    {orderBy === row.id ? (
                                                                                                        <span
                                                                                                            className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                                                    ) : null}
                                                                                                </TableSortLabel>
                                                                                            </TableCell>)
                                                                                        }
                                                                                    )
                                                                                    : props.unit ?
                                                                                        unitList.map(row => {
                                                                                                // console.log(orderBy);
                                                                                                return (<TableCell
                                                                                                    key={row.id}
                                                                                                    align={row.numeric ? 'left' : 'left'}
                                                                                                    padding={'default'}
                                                                                                    sortDirection={orderBy === row.id ? order : false}
                                                                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                >
                                                                                                    <TableSortLabel
                                                                                                        active={orderBy === row.id}
                                                                                                        direction={order}
                                                                                                        onClick={createSortHandler(row.id)}
                                                                                                    >
                                                                                                        {row.label}
                                                                                                        {orderBy === row.id ? (
                                                                                                            <span
                                                                                                                className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                                                        ) : null}
                                                                                                    </TableSortLabel>
                                                                                                </TableCell>)
                                                                                            }
                                                                                        )
                                                                                        : props.manufacturer ?
                                                                                            manufList.map(row => {
                                                                                                    // console.log(orderBy);
                                                                                                    return (<TableCell
                                                                                                        key={row.id}
                                                                                                        align={row.numeric ? 'left' : 'left'}
                                                                                                        padding={'default'}
                                                                                                        sortDirection={orderBy === row.id ? order : false}
                                                                                                        id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                    >
                                                                                                        <TableSortLabel
                                                                                                            active={orderBy === row.id}
                                                                                                            direction={order}
                                                                                                            onClick={createSortHandler(row.id)}
                                                                                                        >
                                                                                                            {row.label}
                                                                                                            {orderBy === row.id ? (
                                                                                                                <span
                                                                                                                    className={classes.visuallyHidden}>
                    {order === 'desc' ? '' : ''}
                    </span>
                                                                                                            ) : null}
                                                                                                        </TableSortLabel>
                                                                                                    </TableCell>)
                                                                                                }
                                                                                            )

                                                                                            : props.bank ?
                                                                                                bankList.map(row => {
                                                                                                        // console.log(orderBy);
                                                                                                        return (<TableCell
                                                                                                            key={row.id}
                                                                                                            align={row.numeric ? 'left' : 'left'}
                                                                                                            padding={'default'}
                                                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                        >
                                                                                                            <TableSortLabel
                                                                                                                active={orderBy === row.id}
                                                                                                                direction={order}
                                                                                                                onClick={createSortHandler(row.id)}
                                                                                                            >
                                                                                                                {row.label}
                                                                                                                {orderBy === row.id ? (
                                                                                                                    <span
                                                                                                                        className={classes.visuallyHidden}>
                            {order === 'desc' ? '' : ''}
                        </span>
                                                                                                                ) : null}
                                                                                                            </TableSortLabel>
                                                                                                        </TableCell>)
                                                                                                    }
                                                                                                )

                                                                                                : props.supplier ?
                                                                                                    supplierList.map(row => {
                                                                                                            // console.log(orderBy);
                                                                                                            return (
                                                                                                                <TableCell
                                                                                                                    key={row.id}
                                                                                                                    align={row.numeric ? 'left' : 'left'}
                                                                                                                    padding={'default'}
                                                                                                                    sortDirection={orderBy === row.id ? order : false}
                                                                                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                >
                                                                                                                    <TableSortLabel
                                                                                                                        active={orderBy === row.id}
                                                                                                                        direction={order}
                                                                                                                        onClick={createSortHandler(row.id)}
                                                                                                                    >
                                                                                                                        {row.label}
                                                                                                                        {orderBy === row.id ? (
                                                                                                                            <span
                                                                                                                                className={classes.visuallyHidden}>
                        {order === 'desc' ? '' : ''}
                        </span>
                                                                                                                        ) : null}
                                                                                                                    </TableSortLabel>
                                                                                                                </TableCell>)
                                                                                                        }
                                                                                                    )

                                                                                                    : props.service ?
                                                                                                        serviceList.map(row => {
                                                                                                                // console.log(orderBy);
                                                                                                                return (
                                                                                                                    <TableCell
                                                                                                                        key={row.id}
                                                                                                                        align={row.numeric ? 'left' : 'left'}
                                                                                                                        padding={'default'}
                                                                                                                        sortDirection={orderBy === row.id ? order : false}
                                                                                                                        id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                    >
                                                                                                                        <TableSortLabel
                                                                                                                            active={orderBy === row.id}
                                                                                                                            direction={order}
                                                                                                                            onClick={createSortHandler(row.id)}
                                                                                                                        >
                                                                                                                            {row.label}
                                                                                                                            {orderBy === row.id ? (
                                                                                                                                <span
                                                                                                                                    className={classes.visuallyHidden}>
                        {order === 'desc' ? '' : ''}
                        </span>
                                                                                                                            ) : null}
                                                                                                                        </TableSortLabel>
                                                                                                                    </TableCell>)
                                                                                                            }
                                                                                                        )
                                                                                                        : props.medPurchase ?
                                                                                                            purchaseList.map(row => {
                                                                                                                    // console.log(orderBy);
                                                                                                                    return (
                                                                                                                        <TableCell
                                                                                                                            key={row.id}
                                                                                                                            align={row.numeric ? 'left' : 'left'}
                                                                                                                            padding={'default'}
                                                                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                        >
                                                                                                                            <TableSortLabel
                                                                                                                                active={orderBy === row.id}
                                                                                                                                direction={order}
                                                                                                                                onClick={createSortHandler(row.id)}
                                                                                                                            >
                                                                                                                                {row.label}
                                                                                                                                {orderBy === row.id ? (
                                                                                                                                    <span
                                                                                                                                        className={classes.visuallyHidden}>
                        {order === 'desc' ? '' : ''}
                        </span>
                                                                                                                                ) : null}
                                                                                                                            </TableSortLabel>
                                                                                                                        </TableCell>)
                                                                                                                }
                                                                                                            )

                                                                                                            : props.sale ?
                                                                                                                saleList.map(row => {
                                                                                                                        // console.log(orderBy);
                                                                                                                        return (
                                                                                                                            <TableCell
                                                                                                                                key={row.id}
                                                                                                                                align={row.numeric ? 'left' : 'left'}
                                                                                                                                padding={'default'}
                                                                                                                                sortDirection={orderBy === row.id ? order : false}
                                                                                                                                id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                            >
                                                                                                                                <TableSortLabel
                                                                                                                                    active={orderBy === row.id}
                                                                                                                                    direction={order}
                                                                                                                                    onClick={createSortHandler(row.id)}
                                                                                                                                >
                                                                                                                                    {row.label}
                                                                                                                                    {orderBy === row.id ? (
                                                                                                                                        <span
                                                                                                                                            className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
                                                                                                                                    ) : null}
                                                                                                                                </TableSortLabel>
                                                                                                                            </TableCell>)
                                                                                                                    }
                                                                                                                )
                                                                                                                : props.ledger ?
                                                                                                                    ledgerList.map(row => {
                                                                                                                            // console.log(orderBy);
                                                                                                                            return (
                                                                                                                                <TableCell
                                                                                                                                    key={row.id}
                                                                                                                                    align={row.numeric ? 'left' : 'left'}
                                                                                                                                    padding={'default'}
                                                                                                                                    sortDirection={orderBy === row.id ? order : false}
                                                                                                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                >
                                                                                                                                    <TableSortLabel
                                                                                                                                        active={orderBy === row.id}
                                                                                                                                        direction={order}
                                                                                                                                        onClick={createSortHandler(row.id)}
                                                                                                                                    >
                                                                                                                                        {row.label}
                                                                                                                                        {orderBy === row.id ? (
                                                                                                                                            <span
                                                                                                                                                className={classes.visuallyHidden}>
                                            {order === 'desc' ? '' : ''}
                                            </span>
                                                                                                                                        ) : null}
                                                                                                                                    </TableSortLabel>
                                                                                                                                </TableCell>)
                                                                                                                        }
                                                                                                                    )

                                                                                                                    : props.ledger2 ?
                                                                                                                        ledgerList2.map(row => {
                                                                                                                                // console.log(orderBy);
                                                                                                                                return (
                                                                                                                                    <TableCell
                                                                                                                                        key={row.id}
                                                                                                                                        align={row.numeric ? 'left' : 'left'}
                                                                                                                                        padding={'default'}
                                                                                                                                        sortDirection={orderBy === row.id ? order : false}
                                                                                                                                        id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                    >
                                                                                                                                        <TableSortLabel
                                                                                                                                            active={orderBy === row.id}
                                                                                                                                            direction={order}
                                                                                                                                            onClick={createSortHandler(row.id)}
                                                                                                                                        >
                                                                                                                                            {row.label}
                                                                                                                                            {orderBy === row.id ? (
                                                                                                                                                <span
                                                                                                                                                    className={classes.visuallyHidden}>
                                            {order === 'desc' ? '' : ''}
                                            </span>
                                                                                                                                            ) : null}
                                                                                                                                        </TableSortLabel>
                                                                                                                                    </TableCell>)
                                                                                                                            }
                                                                                                                        )
                                                                                                                        : props.purchaseInfo ?
                                                                                                                            purchaseInfo.map(row => {
                                                                                                                                    // console.log(orderBy);
                                                                                                                                    return (
                                                                                                                                        <TableCell
                                                                                                                                            key={row.id}
                                                                                                                                            align={row.numeric ? 'left' : 'left'}
                                                                                                                                            padding={'default'}
                                                                                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                                                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                        >
                                                                                                                                            <TableSortLabel
                                                                                                                                                active={orderBy === row.id}
                                                                                                                                                direction={order}
                                                                                                                                                onClick={createSortHandler(row.id)}
                                                                                                                                            >
                                                                                                                                                {row.label}
                                                                                                                                                {orderBy === row.id ? (
                                                                                                                                                    <span
                                                                                                                                                        className={classes.visuallyHidden}>
                                              {order === 'desc' ? '' : ''}
                                            </span>
                                                                                                                                                ) : null}
                                                                                                                                            </TableSortLabel>
                                                                                                                                        </TableCell>)
                                                                                                                                }
                                                                                                                            )
                                                                                                                            : props.manufacturerSales ?
                                                                                                                                manufSales.map(row => {
                                                                                                                                        // console.log(orderBy);
                                                                                                                                        return (
                                                                                                                                            <TableCell
                                                                                                                                                key={row.id}
                                                                                                                                                align={row.numeric ? 'left' : 'left'}
                                                                                                                                                padding={'default'}
                                                                                                                                                sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                            >
                                                                                                                                                <TableSortLabel
                                                                                                                                                    active={orderBy === row.id}
                                                                                                                                                    direction={order}
                                                                                                                                                    onClick={createSortHandler(row.id)}
                                                                                                                                                >
                                                                                                                                                    {row.label}
                                                                                                                                                    {orderBy === row.id ? (
                                                                                                                                                        <span
                                                                                                                                                            className={classes.visuallyHidden}>
                                              {order === 'desc' ? '' : ''}
                                            </span>
                                                                                                                                                    ) : null}
                                                                                                                                                </TableSortLabel>
                                                                                                                                            </TableCell>)
                                                                                                                                    }
                                                                                                                                )
                                                                                                                                : props.purchase ?
                                                                                                                                    purchases.map(row => {
                                                                                                                                            // console.log(orderBy);
                                                                                                                                            return (
                                                                                                                                                <TableCell
                                                                                                                                                    key={row.id}
                                                                                                                                                    align={row.numeric ? 'left' : 'left'}
                                                                                                                                                    padding={'default'}
                                                                                                                                                    sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                >
                                                                                                                                                    <TableSortLabel
                                                                                                                                                        active={orderBy === row.id}
                                                                                                                                                        direction={order}
                                                                                                                                                        onClick={createSortHandler(row.id)}
                                                                                                                                                    >
                                                                                                                                                        {row.label}
                                                                                                                                                        {orderBy === row.id ? (
                                                                                                                                                            <span
                                                                                                                                                                className={classes.visuallyHidden}>
                                              {order === 'desc' ? '' : ''}
                                            </span>
                                                                                                                                                        ) : null}
                                                                                                                                                    </TableSortLabel>
                                                                                                                                                </TableCell>)
                                                                                                                                        }
                                                                                                                                    )
                                                                                                                                    : props.stock ? stockList.map(row => {
                                                                                                                                            // console.log(orderBy);
                                                                                                                                            return (
                                                                                                                                                <TableCell
                                                                                                                                                    key={row.id}
                                                                                                                                                    align={row.numeric ? 'left' : 'left'}
                                                                                                                                                    padding={'default'}
                                                                                                                                                    sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                >
                                                                                                                                                    <TableSortLabel
                                                                                                                                                        active={orderBy === row.id}
                                                                                                                                                        direction={order}
                                                                                                                                                        onClick={createSortHandler(row.id)}
                                                                                                                                                    >
                                                                                                                                                        {row.label}
                                                                                                                                                        {orderBy === row.id ? (
                                                                                                                                                            <span
                                                                                                                                                                className={classes.visuallyHidden}>
                                      {order === 'desc' ? '' : ''}
                                    </span>
                                                                                                                                                        ) : null}
                                                                                                                                                    </TableSortLabel>
                                                                                                                                                </TableCell>)
                                                                                                                                        }
                                                                                                                                        )
                                                                                                                                        : props.stockbatch ?
                                                                                                                                            stockBatch.map(row => {
                                                                                                                                                    // console.log(orderBy);
                                                                                                                                                    return (
                                                                                                                                                        <TableCell
                                                                                                                                                            key={row.id}
                                                                                                                                                            align={row.numeric ? 'left' : 'left'}
                                                                                                                                                            padding={'default'}
                                                                                                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                        >
                                                                                                                                                            <TableSortLabel
                                                                                                                                                                active={orderBy === row.id}
                                                                                                                                                                direction={order}
                                                                                                                                                                onClick={createSortHandler(row.id)}
                                                                                                                                                            >
                                                                                                                                                                {row.label}
                                                                                                                                                                {orderBy === row.id ? (
                                                                                                                                                                    <span
                                                                                                                                                                        className={classes.visuallyHidden}>
                                          {order === 'desc' ? '' : ''}
                                        </span>
                                                                                                                                                                ) : null}
                                                                                                                                                            </TableSortLabel>
                                                                                                                                                        </TableCell>)
                                                                                                                                                }
                                                                                                                                            )
                                                                                                                                            : (props.stockreturn || props.manufreturn || props.wastage || props.damage) ?
                                                                                                                                                stockReturn.map(row => {
                                                                                                                                                        // console.log(orderBy);
                                                                                                                                                        return (
                                                                                                                                                            <TableCell
                                                                                                                                                                key={row.id}
                                                                                                                                                                align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                padding={'default'}
                                                                                                                                                                sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                            >
                                                                                                                                                                <TableSortLabel
                                                                                                                                                                    active={orderBy === row.id}
                                                                                                                                                                    direction={order}
                                                                                                                                                                    onClick={createSortHandler(row.id)}
                                                                                                                                                                >
                                                                                                                                                                    {row.label}
                                                                                                                                                                    {orderBy === row.id ? (
                                                                                                                                                                        <span
                                                                                                                                                                            className={classes.visuallyHidden}>
                                          {order === 'desc' ? '' : ''}
                                        </span>
                                                                                                                                                                    ) : null}
                                                                                                                                                                </TableSortLabel>
                                                                                                                                                            </TableCell>)
                                                                                                                                                    }
                                                                                                                                                )
                                                                                                                                                : props.tSalesReport ?
                                                                                                                                                    tSalesReport.map(row => {
                                                                                                                                                            // console.log(orderBy);
                                                                                                                                                            return (
                                                                                                                                                                <TableCell
                                                                                                                                                                    key={row.id}
                                                                                                                                                                    align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                    padding={'default'}
                                                                                                                                                                    sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                >
                                                                                                                                                                    <TableSortLabel
                                                                                                                                                                        active={orderBy === row.id}
                                                                                                                                                                        direction={order}
                                                                                                                                                                        onClick={createSortHandler(row.id)}
                                                                                                                                                                    >
                                                                                                                                                                        {row.label}
                                                                                                                                                                        {orderBy === row.id ? (
                                                                                                                                                                            <span
                                                                                                                                                                                className={classes.visuallyHidden}>
                                          {order === 'desc' ? '' : ''}
                                        </span>
                                                                                                                                                                        ) : null}
                                                                                                                                                                    </TableSortLabel>
                                                                                                                                                                </TableCell>)
                                                                                                                                                        }
                                                                                                                                                    )

                                                                                                                                                    : props.managedesignation ?
                                                                                                                                                        managedesignationList.map(row => {
                                                                                                                                                                // console.log(orderBy);
                                                                                                                                                                return (
                                                                                                                                                                    <TableCell
                                                                                                                                                                        key={row.id}
                                                                                                                                                                        align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                        padding={'default'}
                                                                                                                                                                        sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                        id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                    >
                                                                                                                                                                        <TableSortLabel
                                                                                                                                                                            active={orderBy === row.id}
                                                                                                                                                                            direction={order}
                                                                                                                                                                            onClick={createSortHandler(row.id)}
                                                                                                                                                                        >
                                                                                                                                                                            {row.label}
                                                                                                                                                                            {orderBy === row.id ? (
                                                                                                                                                                                <span
                                                                                                                                                                                    className={classes.visuallyHidden}>
                                      {order === 'desc' ? '' : ''}
                                    </span>
                                                                                                                                                                            ) : null}
                                                                                                                                                                        </TableSortLabel>
                                                                                                                                                                    </TableCell>)
                                                                                                                                                            }
                                                                                                                                                        )

                                                                                                                                                        : props.manageemployee ?
                                                                                                                                                            manageemployeeList.map(row => {
                                                                                                                                                                    // console.log(orderBy);
                                                                                                                                                                    return (
                                                                                                                                                                        <TableCell
                                                                                                                                                                            key={row.id}
                                                                                                                                                                            align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                            padding={'default'}
                                                                                                                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                        >
                                                                                                                                                                            <TableSortLabel
                                                                                                                                                                                active={orderBy === row.id}
                                                                                                                                                                                direction={order}
                                                                                                                                                                                onClick={createSortHandler(row.id)}
                                                                                                                                                                            >
                                                                                                                                                                                {row.label}
                                                                                                                                                                                {orderBy === row.id ? (
                                                                                                                                                                                    <span
                                                                                                                                                                                        className={classes.visuallyHidden}>
                                      {order === 'desc' ? '' : ''}
                                    </span>
                                                                                                                                                                                ) : null}
                                                                                                                                                                            </TableSortLabel>
                                                                                                                                                                        </TableCell>)
                                                                                                                                                                }
                                                                                                                                                            )
                                                                                                                                                            : props.medicineWise ?
                                                                                                                                                                medicineWiseTable.map(row => {
                                                                                                                                                                        // console.log(orderBy);
                                                                                                                                                                        return (
                                                                                                                                                                            <TableCell
                                                                                                                                                                                key={row.id}
                                                                                                                                                                                align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                                padding={'default'}
                                                                                                                                                                                sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                                id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                            >
                                                                                                                                                                                <TableSortLabel
                                                                                                                                                                                    active={orderBy === row.id}
                                                                                                                                                                                    direction={order}
                                                                                                                                                                                    onClick={createSortHandler(row.id)}
                                                                                                                                                                                >
                                                                                                                                                                                    {row.label}
                                                                                                                                                                                    {orderBy === row.id ? (
                                                                                                                                                                                        <span
                                                                                                                                                                                            className={classes.visuallyHidden}>
                                          {order === 'desc' ? '' : ''}
                                        </span>
                                                                                                                                                                                    ) : null}
                                                                                                                                                                                </TableSortLabel>
                                                                                                                                                                            </TableCell>)
                                                                                                                                                                    }
                                                                                                                                                                )
                                                                                                                                                                : props.tpurchaseReport ?
                                                                                                                                                                    tPurchaseReport.map(row => {
                                                                                                                                                                            // console.log(orderBy);
                                                                                                                                                                            return (
                                                                                                                                                                                <TableCell
                                                                                                                                                                                    key={row.id}
                                                                                                                                                                                    align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                                    padding={'default'}
                                                                                                                                                                                    sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                                >
                                                                                                                                                                                    <TableSortLabel
                                                                                                                                                                                        active={orderBy === row.id}
                                                                                                                                                                                        direction={order}
                                                                                                                                                                                        onClick={createSortHandler(row.id)}
                                                                                                                                                                                    >
                                                                                                                                                                                        {row.label}
                                                                                                                                                                                        {orderBy === row.id ? (
                                                                                                                                                                                            <span
                                                                                                                                                                                                className={classes.visuallyHidden}>
                                          {order === 'desc' ? '' : ''}
                                        </span>
                                                                                                                                                                                        ) : null}
                                                                                                                                                                                    </TableSortLabel>
                                                                                                                                                                                </TableCell>)
                                                                                                                                                                        }
                                                                                                                                                                    )

                                                                                                                                                                    : props.manageco ?
                                                                                                                                                                        manageco.map(row => {
                                                                                                                                                                                // console.log(orderBy);
                                                                                                                                                                                return (
                                                                                                                                                                                    <TableCell
                                                                                                                                                                                        key={row.id}
                                                                                                                                                                                        align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                                        padding={'default'}
                                                                                                                                                                                        sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                                        id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                                    >
                                                                                                                                                                                        <TableSortLabel
                                                                                                                                                                                            active={orderBy === row.id}
                                                                                                                                                                                            direction={order}
                                                                                                                                                                                            onClick={createSortHandler(row.id)}
                                                                                                                                                                                        >
                                                                                                                                                                                            {row.label}
                                                                                                                                                                                            {orderBy === row.id ? (
                                                                                                                                                                                                <span
                                                                                                                                                                                                    className={classes.visuallyHidden}>
                                          {order === 'desc' ? '' : ''}
                                        </span>
                                                                                                                                                                                            ) : null}
                                                                                                                                                                                        </TableSortLabel>
                                                                                                                                                                                    </TableCell>)
                                                                                                                                                                            }
                                                                                                                                                                        )

                                                                                                                                                                        : props.manageattendance ?
                                                                                                                                                                            manageattendance.map(row => {
                                                                                                                                                                                    // console.log(orderBy);
                                                                                                                                                                                    return (
                                                                                                                                                                                        <TableCell
                                                                                                                                                                                            key={row.id}
                                                                                                                                                                                            align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                                            padding={'default'}
                                                                                                                                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                                        >
                                                                                                                                                                                            <TableSortLabel
                                                                                                                                                                                                active={orderBy === row.id}
                                                                                                                                                                                                direction={order}
                                                                                                                                                                                                onClick={createSortHandler(row.id)}
                                                                                                                                                                                            >
                                                                                                                                                                                                {row.label}
                                                                                                                                                                                                {orderBy === row.id ? (
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        className={classes.visuallyHidden}>
                                          {order === 'desc' ? '' : ''}
                                        </span>
                                                                                                                                                                                                ) : null}
                                                                                                                                                                                            </TableSortLabel>
                                                                                                                                                                                        </TableCell>)
                                                                                                                                                                                }
                                                                                                                                                                            )


                                                                                                                                                                            : props.manageusers ?
                                                                                                                                                                                manageusers.map(row => {
                                                                                                                                                                                        // console.log(orderBy);
                                                                                                                                                                                        return (
                                                                                                                                                                                            <TableCell
                                                                                                                                                                                                key={row.id}
                                                                                                                                                                                                align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                                                padding={'default'}
                                                                                                                                                                                                sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                                                id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                                            >
                                                                                                                                                                                                <TableSortLabel
                                                                                                                                                                                                    active={orderBy === row.id}
                                                                                                                                                                                                    direction={order}
                                                                                                                                                                                                    onClick={createSortHandler(row.id)}
                                                                                                                                                                                                >
                                                                                                                                                                                                    {row.label}
                                                                                                                                                                                                    {orderBy === row.id ? (
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            className={classes.visuallyHidden}>
                                          {order === 'desc' ? '' : ''}
                                        </span>
                                                                                                                                                                                                    ) : null}
                                                                                                                                                                                                </TableSortLabel>
                                                                                                                                                                                            </TableCell>)
                                                                                                                                                                                    }
                                                                                                                                                                                )

                                                                                                                                                                                : props.currency ?
                                                                                                                                                                                    currency.map(row => {
                                                                                                                                                                                            // console.log(orderBy);
                                                                                                                                                                                            return (
                                                                                                                                                                                                <TableCell
                                                                                                                                                                                                    key={row.id}
                                                                                                                                                                                                    align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                                                    padding={'default'}
                                                                                                                                                                                                    sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                                                    id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <TableSortLabel
                                                                                                                                                                                                        active={orderBy === row.id}
                                                                                                                                                                                                        direction={order}
                                                                                                                                                                                                        onClick={createSortHandler(row.id)}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        {row.label}
                                                                                                                                                                                                        {orderBy === row.id ? (
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                className={classes.visuallyHidden}>
                                          {order === 'desc' ? '' : ''}
                                        </span>
                                                                                                                                                                                                        ) : null}
                                                                                                                                                                                                    </TableSortLabel>
                                                                                                                                                                                                </TableCell>)
                                                                                                                                                                                        }
                                                                                                                                                                                    )
                                                                                                                                                                                    : props.outOfStock ?
                                                                                                                                                                                        outOfStock.map(row => {
                                                                                                                                                                                                // console.log(orderBy);
                                                                                                                                                                                                return (
                                                                                                                                                                                                    <TableCell
                                                                                                                                                                                                        key={row.id}
                                                                                                                                                                                                        align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                                                        padding={'default'}
                                                                                                                                                                                                        sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                                                        id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <TableSortLabel
                                                                                                                                                                                                            active={orderBy === row.id}
                                                                                                                                                                                                            direction={order}
                                                                                                                                                                                                            onClick={createSortHandler(row.id)}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <strong>{row.label}</strong>
                                                                                                                                                                                                            {orderBy === row.id ? (
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    className={classes.visuallyHidden}>
                                      {order === 'desc' ? '' : ''}
                                    </span>
                                                                                                                                                                                                            ) : null}
                                                                                                                                                                                                        </TableSortLabel>
                                                                                                                                                                                                    </TableCell>)
                                                                                                                                                                                            }
                                                                                                                                                                                        )
                                                                                                                                                                                        : props.expiryList ?
                                                                                                                                                                                            expiryList.map(row => {
                                                                                                                                                                                                    // console.log(orderBy);
                                                                                                                                                                                                    return (
                                                                                                                                                                                                        <TableCell
                                                                                                                                                                                                            key={row.id}
                                                                                                                                                                                                            align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                                                            padding={'default'}
                                                                                                                                                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <TableSortLabel
                                                                                                                                                                                                                active={orderBy === row.id}
                                                                                                                                                                                                                direction={order}
                                                                                                                                                                                                                onClick={createSortHandler(row.id)}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <strong>{row.label}</strong>
                                                                                                                                                                                                                {orderBy === row.id ? (
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        className={classes.visuallyHidden}>
                                      {order === 'desc' ? '' : ''}
                                    </span>
                                                                                                                                                                                                                ) : null}
                                                                                                                                                                                                            </TableSortLabel>
                                                                                                                                                                                                        </TableCell>)
                                                                                                                                                                                                }
                                                                                                                                                                                            )
                                                                                                                                                                                            : invoiceListRows.map((row) => {
                                                                                                                                                                                                    return (
                                                                                                                                                                                                        <TableCell
                                                                                                                                                                                                            key={row.id}
                                                                                                                                                                                                            align={row.numeric ? 'left' : 'left'}
                                                                                                                                                                                                            padding={'default'}
                                                                                                                                                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                                                                                                                                                            id={row.label === 'Action' ? 'mainbuttons' : 'buttons'}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <TableSortLabel
                                                                                                                                                                                                                active={orderBy === row.id}
                                                                                                                                                                                                                direction={order}
                                                                                                                                                                                                                onClick={createSortHandler(row.id)}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <strong>{row.label}</strong>
                                                                                                                                                                                                                {orderBy === row.id ? (
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        className={classes.visuallyHidden}>
                                      {order === 'desc' ? '' : ''}
                                    </span>
                                                                                                                                                                                                                ) : null}
                                                                                                                                                                                                            </TableSortLabel>
                                                                                                                                                                                                        </TableCell>)
                                                                                                                                                                                                }
                                                                                                                                                                                            )
                }
            </TableRow>
        </TableHead>
    );
}

EnhancedPharmacyListHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export const useToolbarStyles = theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    billHighlight:
        theme.palette.type === 'light'
            ? {
                color: "#f2f2f2",
                backgroundColor: lighten("#42ab9a", 0),
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                marginRight: "-0.1em",
                marginLeft: "-0.1em"
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    drugHighlight:
        theme.palette.type === 'light'
            ? {
                color: "#f2f2f2",
                backgroundColor: lighten("#1d72b1", 0.3),
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                marginRight: "-0.1em",
                marginLeft: "-0.1em"
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});
// const options = {
//     orientation: 'landscape',
//     unit: 'in',
// };


class EnhancedTableToolbar extends Component {

    handleChange = event => {
        this.props.handler(event.target.value);
    };

    render() {
        const {classes} = this.props;
        const {numSelected} = this.props;
        return (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                <div className={classes.title}>
                    <Typography variant="h5" id="tableTitle">
                            <span>{
                                this.props.supplier ? "Supplier List" : this.props.medicine ? "Medicine List"
                                    : this.props.type ? "Medicine Types" : this.props.category ? "Category" : this.props.unit ? "Unit List" : this.props.manufacturer ? "Suppliers" : this.props.bank ? "Bank details" :
                                        this.props.service ? "Manage Service" : this.props.medPurchase ? "Purchase" : this.props.sale ? "Sales" : (this.props.ledger || this.props.ledger2 ||
                                            this.props.manufacturerSales || this.props.purchaseInfo || this.props.purchase || this.props.service || this.props.stock || this.props.stockbatch ||
                                            this.props.stockreturn || this.props.manufreturn || this.props.wastage || this.props.tSalesReport || this.props.tpurchaseReport || this.props.manageco || this.props.manageattendance ||
                                            this.props.manageusers || this.props.currency || this.props.medicineWise || this.props.outOfStock || this.props.managedesignation || this.props.manageemployee
                                            || this.props.expiryList || this.props.manageBenefits || this.props.manageSetUp || this.props.salaryGenerate ||
                                            this.props.salaryPayment || this.props.expenseItem || this.props.expense || this.props.fixedAssets
                                            || this.props.fixedPurchase || this.props.managePerson ||
                                            this.props.officePerson || this.props.addAttendance || this.props.attendanceReport || this.props.customer ||this.props.productListReport
                                             || this.props.purchaseOrder || this.props.damage || this.props.userroles) ? " " : "Invoice List"}</span>
                    </Typography>

                </div>
                <div className={classes.spacer}/>
                {!(this.props.type || this.props.unit || this.props.medPurchase || this.props.supplier || this.props.ledger || this.props.ledger2
                    || this.props.purchaseInfo || this.props.manufacturerSales || this.props.stockreturn ||
                    this.props.manufreturn || this.props.wastage  || this.props.manageco || this.props.manageattendance || this.props.manageusers ||
                    this.props.currency || this.props.medicineWise || this.props.salaryGenerate ||
                    this.props.salaryPayment || this.props.expense || this.props.productListReport ||this.props.damage ||
                    this.props.fixedAssets || this.props.fixedPurchase || this.props.managePerson || this.props.officePerson ||
                    this.props.addAttendance || this.props.attendanceReport || this.props.purchaseOrder || this.props.userroles) &&
                <span className="btn-group">
                        <span><button onClick={this.props.copyTable} className="btn  pharmacy-btn mr-1 btn-sm ">Copy</button></span>
                    {/*<CSVLink data={this.props.data} target="_blank"*/}
                    {/*         filename={this.props.customer ? "Customer List.csv" :*/}
                    {/*             this.props.paidCustomer ? "Paid Customers List.csv" : this.props.creditCustomer ? "Credit Customer List.csv" :*/}
                    {/*                 this.props.medicine ? "Medicine List.csv" : this.props.manufacturer ? "Manufacturer List.csv" : this.props.purchase ? "Purchase List.csv" :*/}
                    {/*                     this.props.stockbatch ? "Stock (Batch Wise) Report.csv" : this.props.stock ? "Stock Report.csv" : this.props.bank ? "Bank Data.csv" :*/}
                    {/*                         this.props.service ? "Service List.csv" : this.props.serviceInvoice ? "Service Invoice List.csv" :*/}
                    {/*                             this.props.outOfStock ? "Out Of Stock List.csv" : this.props.expiryList ? "Expiry List.csv" :*/}
                    {/*                                 this.props.invoice ? "Invoice List.csv" : ""}>*/}
                    {/*    <button className="btn  pharmacy-btn mr-1 btn-sm">CSV</button></CSVLink>*/}
                    {this.props.children}

                </span>}
                <div className={classes.spacer}/>
                <div className="col col-md-4 col-sm-3" style={{minWidth: 200}} id="mainbuttons">
                    {this.props.customSearch ? 
                    <TextField type="text" placeholder="Search" style={{float: 'right', width:'50%'}} value={titleCase(this.props.search_value)}
                                  onChange={this.props.handleChangeValue}/>
                       
                   :<TextField type="text" placeholder={this.props.searchProduct ? "Search product": "Search"}  style={{float: 'right', width:'50%'}}
                              onChange={this.handleChange}/>
                    }
                    
                </div>
            </Toolbar>
        );
    }
}

export const EnhancedToolbar = withStyles(useToolbarStyles)(EnhancedTableToolbar);
// export const EnhancedToolbar = withStyles(useToolbarStyles)(EnhancedTableToolbar);
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import Paper from "@material-ui/core/Paper";
import {EnhancedToolbar, EnhancedPharmacyListHead, stableSort, getSorting, useStyles} from "../../Utils/Tables/MuiTables";
import DrugsAvailableTablePagination from "../../Utils/Tables/MuiTablePagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FilterResults from 'react-filter-search';
import TablePagination from '@material-ui/core/TablePagination';
import {withStyles} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {compose} from "recompose";
import * as purchaseActions from "../../Redux/Actions/PurchaseActions/PurchaseActions";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import {titleCase} from "../../Utils/titleCaseFunction";
import address from "../../Helpers/companyAddress.json"

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    },
    subMenuLink: {
        display: "block",
        color: "#757575",
        textDecoration: "none"
        // padding: theme.spacing.unit * 6,
    },
    itemStyle: {
        backgroundColor: "#f9f9f9"
    },
    itemOdd: {
        backgroundColor: "#fff"
    }
});

// const data = [
//     {
//         sl: 1,
//         medicineName: "Acidex 20mg",
//         quantity: 30,
//         purchasePrice: "Ugx 100",
//         amount: "Ugx 30,000"
//     },
//
// ];

class ManufacturerInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            term: "",
            order: 'asc',
            setOrder: 'asc',
            orderBy: 'person',
            selected: [],
            setSelected: [],
            page: 0,
            setPage: 0,
            dense: false,
            setDense: false,
            rowsPerPage: 5,
            openTable: true,
            openLedger: false
        };
        this.handler = this.handler.bind(this);
    }

    componentDidMount() {
        const {actions} = this.props;
        const {purchase_id} = this.props.match.params;
        actions.purchaseActions.productPurchaseDetails(purchase_id);
    }

    handleSearch = (event) => {
        event.preventDefault();
        this.setState(prevState => ({
            openLedger: !prevState.openLedger, openTable: !prevState.openTable
        }))
    };

    handler(value) {
        this.setState({term: value})
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0});
    };

    render() {
        const {order, dense, orderBy, selected, rowsPerPage, page, term} = this.state;
        const {classes} = this.props;
        const {productPurchase} = this.props.purchases;
        const purchase = !productPurchase ? {} : productPurchase;
        const purchaseData = !purchase.purchase_all_data ? [] : purchase.purchase_all_data
        // const emptyRows = rowsPerPage - Math.min(rowsPerPage, purchaseData.length - page * rowsPerPage);

        return (
            <div>
                <DashboardHeader purchase purchaseDetails/>
                <div className="mx-3 mt-5">
                    <div className="pos-buttons">
                        <Link to="/addpurchase" style={{textDecoration: "none", color: "inherit"}}>
                            <button type="button" className="btn mr-2 pharmacy-btn">
                                <FontAwesomeIcon icon={faPlus}/> Add Purchase
                            </button>
                        </Link>
                        <Link to="/managepurchase">
                            <button type="button" className="btn pharmacy-info-btn mr-2">
                                <FontAwesomeIcon icon={faList}/> Manage Purchase
                            </button>
                        </Link>
                    </div>
                    <div className="card mt-4 p-2">
                        <div className="text-center">
                            <img style={{maxWidth:"100px"}}  src={address.img_url} alt="mpeke"/>
                            <p style={{fontSize: "16px"}}>{titleCase(purchase.manufacturer_name)}<br/>
                            Date :&nbsp;{purchase.final_date} <br/>Invoice No: &nbsp; {purchase.chalan_no}
                            <br/> Details: &nbsp;{titleCase(purchase.purchase_details)}</p>
                        </div>
                        <hr/>
                        <Paper className={`${classes.paper} table-responsive`}>
                            <EnhancedToolbar numSelected={selected.length} handler={this.handler} purchaseInfo/>
                            <div className={classes.tableWrapper}>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                    // className={classes.table}
                                >
                                    <EnhancedPharmacyListHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={purchaseData.length}
                                        purchaseInfo
                                    />

                                    <FilterResults
                                        value={term}
                                        data={purchaseData}
                                        renderResults={results => (
                                            <TableBody>
                                                {stableSort(results, getSorting(order, orderBy))
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((d, index) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={index}
                                                                className='mui-table-row'>
                                                                <TableCell component="th" scope="row" padding="default"
                                                                           className='mui-table-cell'>
                                                                    <span>{d.sl}</span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span><Link
                                                                        to={{pathname: `/medicinedetails/${d.product_id}`}}
                                                                        style={{color: "#09905c"}}>{titleCase(d.product_name)}</Link></span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{d.quantity}</span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{d.batch_id}</span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{d.expeire_date}</span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{d.manufacturer_price}</span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{d.total_amount}</span>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}

                                            </TableBody>
                                        )}
                                    />
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                count={purchaseData.length}
                                rowsPerPage={rowsPerPage}
                                component="div"
                                page={page}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={DrugsAvailableTablePagination}
                            />
                        </Paper>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        purchases: state.purchases
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            purchaseActions: bindActionCreators(purchaseActions, dispatch)
        }
    }

}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(useStyles, styles))(ManufacturerInvoice);
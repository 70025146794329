import React from 'react';


const PageNotFound = () =>{
    return (
       <div className="text-center mt-5">
           <h1 style={{fontSize:"10rem"}}>404</h1>
           <h1>Oops! ,Page not Found</h1>
           <h5>Sorry, but the page you are looking for is not found. Please make sure you typed the correct URL</h5>
       </div>
    )
};
export default PageNotFound
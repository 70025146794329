import React, {useEffect, useState} from 'react';
import MainInvoiceDetails from "../Invoice/MainInvoiceDetails"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {splitText, titleCase} from "../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";


const headData = ["Sl", "Medicine Name", "Quantity", "Discount(%)", "Unit Price", "Amount"]
const CustomerReturnInvoiceDetails = ({actions, snackbars, match: {params}}) => {
    const {return_id} = params;
    const [invoice, setInvoice] = useState({});
    const [medicines, setMedicines] = useState([])
    const [loading, setLoading] = useState('idle')
    useEffect(() => {
        setLoading('pending')
        axios.get(`${config.epharmUrl}/Creturn/invoice_inserted_data`, {params: {ret_id: return_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            const meds = data.invoice_all_data ?? [];
            setMedicines(meds)
            setInvoice(dt)
            setLoading('resolved')
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('rejected')
        })
    }, [actions, return_id])

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    const {user_first_name, user_last_name, customer_name: name, customer_address: user_address, customer_mobile: mobile,
        subTotal_ammount, total_amount, note, total_deduct, invoice_id, final_date
    } = invoice;
    const user_name = titleCase(`${!user_first_name ? "" : user_first_name} ${!user_last_name ? "" : user_last_name}`);
    const {variant, message, open} = snackbars;

    const arr = medicines.map(item => ({
        amount: item.net_total_amount, id: item.product_id,
        product_name:item.product_name,
        quantity:item.ret_qty,
        discount:item.deduction,
        rate:item.product_rate
    }))
    const invArr = [{name:'Grand Total', value:total_amount},{name:'Total Discount', value:total_deduct}]
    const customerTotal = (
        <table className="table table-borderless table-sm">
            <tr>
                <td><p>Note:{note ? splitText(note) : ""} </p></td>
                <td/>
            </tr>
            {invArr.map((item,index)=>(
                <tr key={index}>
                    <td align="right"/>
                    <td align="right"/>
                    <td align="right"/>
                    <td align="right"/>
                    <td align="right">{item.name}</td>
                    <td align="center">{item.value}</td>
                </tr>
            ))}
        </table>
    )
    const customerReturnAddress = (
        <address className="mt-2">
            <span>Return# : {return_id}</span><br/>
            <span>Invoice No. : {invoice_id}</span><br/>
            <span>Billing Date:{final_date}</span><br/>
        </address>
    )
    const isLoading = loading === 'pending';
    const isSuccess = loading === 'resolved';
    const isError = loading === 'rejected';
    return (
        <>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseSnackbar}/>
            <MainInvoiceDetails user_name={user_name} headData={headData} address={customerReturnAddress}
                                user={{name, user_address, mobile}}
                                title='Invoice' total_amount={subTotal_ammount} heading='Invoice' medicines={arr}
                                totalSummary={customerTotal} isLoading={isLoading} isSuccess={isSuccess}
                                isError={isError}/>
        </>

    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReturnInvoiceDetails);
import React from "react";
import MainFixedPurchaseAsset from "./MainFixedpurchaseAsset";

const EditPurchaseFixedAsset = () => {
    const [assets,setAsset] = React.useState([{}]);
    const addNewRows = () =>{
        const asset = {item_code:'', item_name:'', item_qty:'', item_price:'', total_price:''};
        setAsset([...assets,asset]);
    };
    const deleteRow = (index) =>{
        if(assets.length > 1){
            const newRows = assets.splice(0, index).concat(assets.splice(index + 1)) ;
            setAsset(newRows);
        }else{
            alert('You cannot delete one row');
        }

    };
    return (
        <MainFixedPurchaseAsset editAsset assets={assets} addNewRows={addNewRows} deleteRow={deleteRow}/>
    )
};
export default EditPurchaseFixedAsset;
import React, {useRef} from 'react'
import {useGeneralLedger} from "../CustomHooks/useGeneralLedger";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import {dateStringConvert} from "../../../Utils/titleCaseFunction";
import {printFunctionality} from "../../../Utils/printFunctionality";
import {connect} from "react-redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import Card from '@material-ui/core/Card';
import {ReusableReportHeader} from "./ReusableReportHeader";
import {printStyles} from "./ReusableReportHeader";


export const ledgerStyles = {
    header: {
        backgroundColor: '#5e5e5e',
        color: '#fff'
    },
    td: {
        borderBottom: '1px solid #e4e4e4'
    },
    filters: {
        padding: '5px 0',
        height: '30px',
    },
    view: {
        textTransform: 'uppercase',
        verticalAlign: 'bottom',
        color: '#777'
    },
    sort: {
        borderRight: '1px solid #eee',
        padding: '5px 10px'
    },
    span: {
        verticalAlign: 'bottom',
        color: '#777',
        padding: '5px 10px',
        fontSize:'14px'
    }

}
const GeneralLedger = ({actions, snackbars}) => {
    const {
        date, generalLedger, downloadPDF, closeSnackbar, period, handleChangePeriod,
        inputType, handleChangeDate, year, handleChangeYear
    } = useGeneralLedger(actions)
    const {from_date, to_date} = date;
    const printRef = useRef();
    const {variant, message, open:openBar} = snackbars;
    return (
        <div className="acc_reports journals">
            <PharmacySnackbar open={openBar} handleCloseBar={closeSnackbar} variant={variant}
                              message={message}/>
            <ReusableReportHeader title='General Ledger' subTitle='General ledger'
            handleChangeDate={handleChangeDate} to_date={to_date} from_date={from_date} handleChangePeriod={handleChangePeriod}
            inputType={inputType} downloadPdf={downloadPDF} handleChangeYear={handleChangeYear} year={year}
            handlePrint={()=>printFunctionality(printRef.current, printStyles())} period={period}/>
            <Card className='p-3 mt-2' elevation={0}>
                <div ref={printRef} id="general-ledger-table" className='mui-tables'>
                    <table className='table table-borderless profile-table general-ledger-table'>
                        <thead>
                        <tr>
                            <td style={ledgerStyles.header}>Date</td>
                            <td style={ledgerStyles.header}>Account</td>
                            <td style={ledgerStyles.header}>Description</td>
                            <td style={ledgerStyles.header}>Debit</td>
                            <td style={ledgerStyles.header}>Credit</td>
                        </tr>
                        </thead>
                        <tbody>
                        {generalLedger.map(item => (
                            <tr key={item.id}>
                                <td>{item.date ? dateStringConvert(item.date) : item.date}</td>
                                <td>{`To ${item.account}`}</td>
                                <td>{item.description}</td>
                                <td>{item.debit.toLocaleString()}</td>
                                <td>{item.credit.toLocaleString()}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralLedger)



import React from 'react';
import {dateStringConvert} from "../../Utils/titleCaseFunction";
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

const style = {
    border: {
        borderTop: '1px solid #606060',
        borderBottom: '1px solid #606060'
    },
    image: {
        width: 130,
        border: '1px solid #e4e4e4',
        borderRadius: '60px',
        background: '#e4e4e4'
    },
    p: {
        margin: 0
    },
    parent: {
        textAlign: 'center',
        marginTop: '5em'
    }
}


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: 0,
    },
    inputBase: {
        fontSize: '0.9em',
        padding: '2px'
    }
}));

export const ReusableJournalTable = ({totalCredit, totalDebit,data=[]}) => {
    return (
            <table className='table table-borderless trial-table table-sm mt-1'>
                <thead>
                <tr>
                    <td colSpan={5}>Recent Transactions
                    </td>
                </tr>
                <tr>
                    <td style={style.border}><strong>Date</strong></td>
                    <td style={style.border}><strong>Memo</strong></td>
                    <td style={style.border} width="150"><strong>Debit</strong></td>
                    <td style={style.border}/>
                    <td style={style.border} width="150"><strong>Credit</strong></td>
                </tr>
                </thead>
                <tbody>
                {data.map(item => (
                    <tr key={item.id}>
                        <td>{item.date ? dateStringConvert(item.date) : item.date}</td>
                        <td>{item.details}</td>
                        <td>{item.debit.toLocaleString()}</td>
                        <td/>
                        <td>{item.credit.toLocaleString()}</td>
                    </tr>
                ))}
                </tbody>
                {/*<tfoot>*/}
                {/*<tr>*/}
                {/*    <td colSpan={2}/>*/}
                {/*    <td>{totalDebit}</td>*/}
                {/*    <td/>*/}
                {/*    <td>{totalCredit}</td>*/}
                {/*</tr>*/}
                {/*</tfoot>*/}
            </table>
    )

}

export const NoTransaction = ({text}) => {
    return (
        <div style={style.parent}>
            <img src="/images/transaction.png" alt='transaction' style={style.image}/>
            <p className="text-center" style={style.p}>{`There are no ${text} available`}</p>
        </div>
    )
}

export function JournalSelect({input, children, journal, handleChange}) {
    const classes = useStyles();
    return (
        <FormControl className={classes.margin} classes={{root: classes.inputBase}}>
            <NativeSelect
                value={journal}
                onChange={handleChange}
                input={input}
                inputProps={{
                    name: 'name',
                    id: 'name-native-disabled',
                }}
                classes={{select: classes.inputBase}}
            >
                {children}
            </NativeSelect>
        </FormControl>
    );
}

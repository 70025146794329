import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowRestore} from "@fortawesome/free-solid-svg-icons/faWindowRestore";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {convert,  titleCase} from "../../Utils/titleCaseFunction";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import Workbook from "react-excel-workbook";
import DeleteDialog from "../../Utils/DeleteDialog";
import {CSVLink} from "react-csv";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import {useManagePurchase} from "./CustomHooks/useManagePurchase";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";

const useStyles = makeStyles((theme) => ({
    icon: {
        width: '.95rem',
    },
    approved: {
        color: "green"
    },
    canceled: {
        color: '#D9512C'
    },
    pending: {
        color: "#F29339"
    }
}));

const ManagePurchase = ({actions, snackbars}) => {
    const {purchases, totalCount, activePage, search, from_date, to_date,
        handleChangeDate, totalAmount, loading, openDialog, handleOpenDialog,
        handleCloseDialog, handleDeletePurchase, handleCloseBar, downloadPdf,
        copyTable, fetchData, handleSearch, handleChangePage, handleChangeSearch
    } = useManagePurchase(actions)
    const {open, message, variant} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const manage_purchase = !permissions.manage_purchase ? {} : permissions.manage_purchase;
    const headData = [
        {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
        {id: 'invoiceNo', numeric: false, disablePadding: false, label: 'Invoice No'},
        {id: 'purchaseID', numeric: false, disablePadding: false, label: 'Purchase ID'},
        {id: 'manufName', numeric: false, disablePadding: false, label: 'Supplier Name'},
        {id: 'purchaseDate', numeric: false, disablePadding: false, label: 'Purchase Date'},
        {id: 'tAmount', numeric: false, disablePadding: false, label: 'Total Amount'},
        (manage_purchase.create || manage_purchase.update || manage_purchase.delete)
            ? {id: 'action', numeric: false, disablePadding: false, label: 'Action'} :
            {id: 'nonaction', numeric: false, disablePadding: false, label: ''}
    ];
    const classes = useStyles();
    return (
        <div className='journals'>
            <DashboardHeader purchase managePurchase/>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <DeleteDialog openDialog={openDialog} handleClose={handleCloseDialog}
                          handleDelete={handleDeletePurchase} message="Purchase"/>
            <div className="general-ledger-header mb-3">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        <form onSubmit={handleSearch}>
                            <Form.Group as={Row} style={{marginBottom: '-1%'}}>
                                <Col sm={4}>
                                    <Form.Group as={Row}>

                                        <Label name='From' column sm={2} style={{textAlign: 'right'}}/>
                                        <Col sm={10}>
                                            <TextField type='date' onChange={handleChangeDate}
                                                       value={from_date} name='from_date'/>
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col sm={4}>
                                    <Form.Group as={Row}>
                                        <Label name='To' column sm={2}/>
                                        <Col sm={10}>
                                            <TextField type='date' value={to_date} onChange={handleChangeDate}
                                                       name='to_date'/>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col sm={1}>
                                    <button type='submit' className='btn btn-sm pharmacy-btn-outline'>Filter</button>
                                </Col>
                            </Form.Group>
                        </form>
                    </Grid>

                    <Grid item xs={6} md={4}>
                        <div className="text-right">
                            <Link to='/addpurchase'>
                                <button className='btn btn-sm pharmacy-info-btn py-1'><AddIcon
                                    classes={{root: classes.icon}}/> New
                                </button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="mui-table">
                <MuiPaper>
                    <EnhancedTableToolbar title='Purchases'>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                                {/*<span><button onClick={copyTable}*/}
                                {/*              className="btn  pharmacy-btn mr-1 btn-sm ">Copy</button></span>*/}
                                                                      <CSVLink
                                                                          data={purchases}
                                                                          filename="Purchase List.csv"
                                                                          target="_blank"
                                                                      >
                                        <button className="btn btn-sm pharmacy-btn mr-1">CSV</button>
                                    </CSVLink>

                                <Workbook filename="Purchase.xlsx" element={
                                    <span><button className="btn btn-sm mr-1 pharmacy-btn">Excel</button></span>
                                }>
                                    <Workbook.Sheet data={purchases} name="Purchase">
                                        <Workbook.Column label="Invoice NO" value="chalan_no"/>
                                        <Workbook.Column label="Purchase ID" value="purchase_id"/>
                                        <Workbook.Column label="Manufacturer Name" value="manufacturer_name"/>
                                        <Workbook.Column label="Date" value="purchase_date"/>
                                        <Workbook.Column label="Total Amount" value="total_amount"/>
                                    </Workbook.Sheet>
                                </Workbook>
                                <span><button className="btn btn-sm pharmacy-btn mr-1"
                                              onClick={downloadPdf}>Pdf</button></span>
                            </span>
                        <Form.Control type="text" value={search ? titleCase(search) : ''}
                                      onChange={handleChangeSearch}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    <div>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="7" align="center"><img src="/images/listPreloader.gif"
                                                                           alt="loader"
                                                                           style={{width: '5%'}}/></TableCell>
                            </TableRow></TableBody> : purchases.length > 0 ?
                                <TableBody>
                                    {purchases.slice(0, 10).map((d, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'>
                                                <TableCell component="th" scope="row" padding="default"
                                                           className='mui-table-cell'>
                                                    <span>{d.sl}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.chalan_no}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.purchase_id}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{titleCase(d.manufacturer_name)}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{convert(d.purchase_date)}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.total_amount}</span>
                                                </TableCell>
                                                <TableCell id="mainbuttons">
                                                    {(manage_purchase.update || manage_purchase.delete) ?
                                                        <span>
                                                                    {/*<Tooltip title="Invoice" placement="left-start">*/}
                                                            <Link
                                                                to={{pathname: `/manufacturerinvoice/${d.purchase_id}`}}>
                                                                    <button className="btn pharmacy-btn btn-sm mr-1 ">
                                                                        <FontAwesomeIcon icon={faWindowRestore}/>
                                                                    </button></Link>
                                                            {/*</Tooltip>*/}
                                                            {/*<Tooltip title="Update" placement="left-start">*/}
                                                            {manage_purchase.update ?
                                                                <Link
                                                                    to={{pathname: `/editpurchase/${d.purchase_id}`}}>
                                                                    <button
                                                                        className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                        <FontAwesomeIcon
                                                                            icon={faPencilAlt}/>
                                                                    </button>
                                                                </Link> : null}
                                                            {/*</Tooltip>*/}
                                                            {/*<Tooltip title="Delete" placement="left-start">*/}
                                                            {manage_purchase.delete ?
                                                                <button
                                                                    className="btn pharmacy-btn-dismiss btn-sm "
                                                                    onClick={() => handleOpenDialog(d.purchase_id)}>
                                                                    <FontAwesomeIcon icon={faTrash}/>
                                                                </button> : null}
                                                            {/*</Tooltip>*/}
                                                                </span> : <span/>}
                                                </TableCell>
                                            </TableRow>);
                                    })}
                                    <TableRow>
                                        <TableCell colSpan={5} className="text-right">
                                            Total:
                                        </TableCell>
                                        <TableCell>{totalAmount}</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableBody>

                                : <TableBody><TableRow>
                                    <TableCell colSpan="7" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
                    </div>
                    <CustomPagination data={purchases} records={10} activePage={activePage} total_count={totalCount}
                                      handleChangeNextPage={handleChangePage}/>
                </MuiPaper>
            </div>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePurchase);
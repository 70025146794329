import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useAddCustomer = (actions, match) => {
    const {customer_id} = match;
    const [state, setState] = useState({customerName: '', customerEmail: '',
        mobile: '', address: '', previousBalance: '', credit_limit: '',});
    const [selectedFile, setSelectedFile] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [customer, setCustomer] = useState({});

    useEffect(() => {
        if (!customer_id) {
            return
        }
        axios.post(`${config.epharmUrl}/Ccustomer/customer_update_form/${customer_id}`).then(response => {
            const data = response.data;
            const dt = data ?? {};
            setCustomer(dt)
        }).catch(error => {
            errorMessages(error, null, actions)
        })

    }, [actions, customer_id])

    useEffect(() => {
        if (!customer_id){
            return
        }
        const {
            customer_name,
            customer_email,
            customer_mobile,
            customer_address,
            previous_balance,
            credit_limit
        } = customer;
        setState({
            customerName: customer_name ?? '',
            customerEmail: customer_email ?? '',
            mobile: customer_mobile ?? '',
            address: customer_address ?? '',
            previousBalance: previous_balance ?? 0,
            credit_limit: credit_limit ?? 0,
        })
    }, [customer, customer_id])


    const handleFileUpload = event => {
        if (event.target.files[0].name.substr(event.target.files[0].name.length - 4) === ".csv") {
            setSelectedFile(event.target.files[0]);
        } else {
            setSelectedFile(null)
            actions.snackbarActions.errorSnackbar('Please upload only csv file format');
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    };
    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const fileUploader = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('upload_csv_file', selectedFile ?? '');
        axios.post(`${config.epharmUrl}/Ccustomer/uploadCsv_Customer`, formData).then(() => {
            actions.snackbarActions.successSnackbar('File uploaded successfully')
        }).catch((error) => {
            errorMessages(error, null, actions)
        })
    };

    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleSubmit = event => {
        event.preventDefault();
        const {customerName, customerEmail, mobile, address, previousBalance, credit_limit} = state;
        const {customer_name} = customer;
        const limit = !credit_limit ? 0 : credit_limit;
        setSubmitted(true)
        if (customerName) {
            setIsSubmitted('pending')
            const formData = new FormData();
            if (customer_id){
                formData.append('customer_id', customer_id);
                formData.append('oldname', customer_name ?? '');
            }
            formData.append('customer_name', customerName);
            formData.append('email', customerEmail);
            formData.append('mobile', mobile);
            formData.append('address', address);
            formData.append('previous_balance', previousBalance);
            formData.append('credit_limit', limit);
            const url = customer_id ? 'customer_update' : 'insert_customer'
            axios.post(`${config.epharmUrl}/Ccustomer/${url}`, formData)
                .then(() => {
                    setIsSubmitted('resolved')
                    actions.snackbarActions.successSnackbar(`Customer ${customer_id ? 'updated':'saved'} successfully!`);
                    history.push('/managecustomer/manage_customer')
                }).catch(error => {
                setIsSubmitted('rejected')
                errorMessages(error, null, actions)
            })
        }

    };
    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };
    return {state, selectedFile, openDialog, submitted,
        isSubmitted, handleFileUpload, handleOpenDialog,
        handleCloseDialog, fileUploader, handleChange, handleSubmit, handleCloseBar}
}
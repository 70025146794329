import React from "react";
import {Link} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {EnhancedTableHead, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import TextField from "../../Utils/FormInputs/TextField";
import {CSVLink} from "react-csv";
import Workbook from "react-excel-workbook";
import { DateFilter } from "../../Containers/DateFilter";
import { useManufacturerSalesDetails } from "./CustomHooks/useManufacturerSalesDetails";

const headData = [
    {id: 'purchase_date', numeric: false, disablePadding: false, label: 'Purchase Date'},
    {id: 'invoice_number', numeric: false, disablePadding: false, label: 'Invoice Number'},
    {id: 'medicine_name', numeric: false, disablePadding: false, label: 'Medicine Name'},
    {id: 'batch_id', numeric: false, disablePadding: false, label: 'Batch ID'},
    {id: 'expiry_date', numeric: false, disablePadding: false, label: 'Expiry Date'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'purchase_price', numeric: false, disablePadding: false, label: 'Purchase Price'},
    {id: 'total_price', numeric: false, disablePadding: false, label: 'Total Price'}
];

/**
 * Component to show the list of the Purchases from a specific supplier
 * @param {match, actions} param0
 * @returns component
 */
const ManufacturerSalesDetails = ({match, actions}) => {

    const {
        total_count, activePage, loading, manufacturer_name, componentRef, manufacturer_id, handleChange, submitted,
        handler, data, csv_dt, downloadPDF, handlePageChange, handleSubmit, isPending, start_date, end_date, 
    }  = useManufacturerSalesDetails(match);

    return (
        <div className='journals' data-testid="manufacturer_sales_details">
            <ReusableDashboardHeader component="Purchases from Supplier" heading="Supplier"
                                     subHeading="Purchases from Supplier" link={`/manufacturersales/${manufacturer_id}`}/>
            <div className='back-button-spacing'>
                <Link data-testid="back-to-suppliers-from-manufacturer-sales-details"
                    to={{pathname: `/managemanufacturer`}}>
                    <button
                        className="btn pharmacy-btn btn-sm mr-1 ">
                        <FontAwesomeIcon
                            icon={faArrowLeft}/>
                        {' '}    
                        Back to Suppliers
                    </button>
                </Link>
            </div>

            <div className='general-ledger-header' style={{display:'flex', justifyContent:'space-between'}}>
                <h5 className='my-0'>Purchases from <strong>{manufacturer_name}</strong></h5>

                <div>
                    <span className="btn-group" style={{flex: '1 1 100%'}}>
                        <CSVLink data-testid="csv-manufacturer-sales-details"
                            data={csv_dt}
                            filename={`Supplier_${manufacturer_name}.csv`}
                            target="_blank"
                        >
                            <button className="btn btn-sm pharmacy-btn mr-1">CSV</button>
                        </CSVLink>
                        <Workbook filename={`Supplier_${manufacturer_name}.xlsx`} element={
                            <span><button className="btn btn-sm pharmacy-btn mr-1">Excel</button></span>
                        } data-testid="excel-manufacturer-sales-details">
                            <Workbook.Sheet data={data} name="Suppliers">
                                <Workbook.Column label="Purchase Date" value="purchase_date"/>
                                <Workbook.Column label="Invoice Number" value="invoice_no"/>
                                <Workbook.Column label="Medicine Name" value="product_name"/>
                                <Workbook.Column label="Batch ID" value="batch_id"/>
                                <Workbook.Column label="Expiry Date" value="expeire_date"/>
                                <Workbook.Column label="Quantity" value="quantity"/>
                                <Workbook.Column label="Purchase Price" value="manufacturer_price"/>
                                <Workbook.Column label="Total Price" value="total_amount"/>
                            </Workbook.Sheet>
                        </Workbook>
                        <span data-testid="pdf-manufacturer-sales-details">
                            <button className="btn pharmacy-btn mr-1 btn-sm" onClick={downloadPDF}>
                            Pdf</button>
                        </span>
                    </span>
                </div>
            </div>

            <MuiPaper>
                <div className='row' style={{marginLeft:'10px', marginRight: '15px'}}>
                    <div className="col-lg-7" style={{float:'left', padding: '10px'}} data-testid="date-filter-manufacturer-sales-details">
                        <DateFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending}}/>
                    </div>
                    <div className="col-lg-5" style={{padding: '10px'}} data-testid="search-field-manufacturer-sales-details">
                        <TextField type="text"
                            onChange={event => handler(event)}
                            placeholder="Search by Medicine Name or Invoice Number" style={{float: 'right'}}/>
                    </div>
                </div>

                <TableContainer>
                    <Table data-testid="list-of-purchases-manufacturer-sales-details"
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                        ref={componentRef}
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? 
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan="5" align="center"><img src="/images/listPreloader.gif" alt="loader" style={{width: '5%'}}/></TableCell>
                            </TableRow>
                        </TableBody> 
                        : data.length > 0 ?
                            <TableBody>
                                {data.slice(0, 10)
                                    .map((d, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'>

                                                <TableCell component="th" scope="row"
                                                            padding="default"
                                                            className='mui-table-cell'>
                                                    <span>{d.purchase_date}</span>
                                                </TableCell>
                                                
                                                <TableCell>
                                                    <span>{d.invoice_no}</span>
                                                </TableCell>

                                                <TableCell>
                                                        <span><Link
                                                            to={{pathname: `/medicinedetails/${d.product_id}`}}
                                                            style={{color: "#09905c"}}>{titleCase(d.product_name)}</Link></span>
                                                </TableCell>

                                                <TableCell>
                                                    <span>{d.batch_id}</span>
                                                </TableCell>

                                                <TableCell>
                                                    <span>{d.expeire_date}</span>
                                                </TableCell>
                                                
                                                <TableCell>
                                                    <span>{d.quantity}</span>
                                                </TableCell>
                                                
                                                <TableCell>
                                                    <span>{d.manufacturer_price}</span>
                                                </TableCell>
                                                
                                                <TableCell>
                                                    <span>{d.total_amount}</span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        : 
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan="5" align="center">No records found</TableCell>
                            </TableRow>
                        </TableBody>}
                    </Table>
                </TableContainer>
                <CustomPagination data={data} records={10} activePage={activePage}
                                  total_count={total_count}
                                  handleChangeNextPage={handlePageChange}/>
            </MuiPaper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)

        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerSalesDetails);
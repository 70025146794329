import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../../Helpers/config.json';
import {errorMessages} from "../../../Helpers/ErrorMessages";

export const useChartOfAccounts = (actions) =>{
    const [coa, setCoa] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [refresh, setRefresh] = useState(false)

    useEffect(()=>{
        axios.get(`${config.epharmUrl}/accounting/chart/accounts`).then(res=>{
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : [];
            const obj = {};
            obj['id'] = 'root';
            obj['name'] = 'COA';
            obj['children'] = arr
            setCoa(obj)
        }).catch(err=>{
            errorMessages(err, null, actions)
        })
    }, [refresh])

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
      setOpenDialog(false)
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }
    return {coa,closeSnackbar, openDialog, handleOpenDialog, handleCloseDialog, refresh, setRefresh}
}
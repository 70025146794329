import React from "react";
import Paper from "@material-ui/core/Paper";
import {EnhancedPharmacyListHead, EnhancedToolbar, getSorting, stableSort, useStyles} from "../../../Utils/Tables/MuiTables";
import Table from "@material-ui/core/Table";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import DrugsAvailableTablePagination from "../../../Utils/Tables/MuiTablePagination";
import {withStyles} from "@material-ui/core/styles";


const data = [
    {
        sl: 1,
        name: "John Doe",
        date: "12-03-2020",
        check_in:"",
        check_out:"",
        stay:"",
    },
];

const MainAttendanceReport = (props) =>{
    const [term, setTerm] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const ref = React.useRef();
    const {classes} = props;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handler = (value) => {
        setTerm(value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0)
    };
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return(
        <Paper className={`${classes.paper} table-responsive`}>
            <EnhancedToolbar handler={handler} targetRef={ref} data={data}
                             attendanceReport/>
            <div className={classes.tableWrapper} ref={ref}>
                <Table
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedPharmacyListHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                        attendanceReport
                    />
                    <FilterResults
                        value={term}
                        data={data}
                        renderResults={results => (
                            <TableBody>
                                {stableSort(results, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((d, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'>
                                                <TableCell component="th" scope="row" padding="default"
                                                           className='mui-table-cell'>
                                                    <span>{d.sl}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.name}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.date}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.check_in}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.check_out}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.stay}</span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 49 * emptyRows}}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        )}
                    />
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                component="div"
                page={page}
                SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={DrugsAvailableTablePagination}
            />
        </Paper>
    )
};
export default (withStyles(useStyles)) (MainAttendanceReport);
import React from "react";
import MainLoanPayment from "./MainLoanPayment";
import CommonButtons from "./CommonButtons";


const AddLoan = () => {
    return (
        <MainLoanPayment addLoan personalLoan>
            <CommonButtons addLoan/>
        </MainLoanPayment>
    )
};
export default AddLoan
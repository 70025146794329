import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {titleCase} from "../../Utils/titleCaseFunction";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import {useManageManufacturer} from "./CustomHooks/useManageManufacturer";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import Card from '@material-ui/core/Card'


const EditManufacturer = ({actions, snackbars, match: {params}}) => {
    const {
        state, submitted, isSubmitted, form,
        handleChange, handleSubmit, handleCloseBar
    } = useManageManufacturer(actions, params)
    const {manufacturer_name, address, mobile, details, previous_balance} = state;
    const {open, message, variant} = snackbars;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Edit Supplier" heading="Supplier"
                                     subHeading="Edit Supplier" link={history.location.pathname}/>
            <div className="general-ledger-header">
                <h6 className='mt-2 mb-0'>Edit Supplier</h6>
            </div>
            <div className='styled-component'>
                <Card className="p-3">
                    <PharmacySnackbar open={open} message={message} variant={variant}
                                      handleCloseBar={handleCloseBar}/>
                    <form onSubmit={handleSubmit} autoComplete="off" ref={form}>
                        <Form.Group as={Row}>
                            <Label name='Supplier Name' type sm={2} column/>
                            <Col sm={7}>
                                <TextField type="text" value={titleCase(manufacturer_name)}
                                           name="manufacturer_name" onChange={handleChange}/>
                                {(submitted && !manufacturer_name) ?
                                    <div className="invalid-text">Supplier name is required</div> : null}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Label name='Mobile' sm={2} column/>
                            <Col sm={7}>
                                <TextField type="text" value={mobile}
                                           name="mobile" onChange={handleChange}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Label name='Address' sm={2} column/>
                            <Col sm={7}>
                                <Textarea value={address}
                                          name="address" onChange={handleChange}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Label name='Details' sm={2} column/>
                            <Col sm={7}>
                                <Textarea value={details}
                                          name="details" onChange={handleChange}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Label name='Previous Bal.' sm={2} column/>
                            <Col sm={7}>
                                <TextField type="number" value={previous_balance}
                                           name="previous_balance" onChange={handleChange}/>
                            </Col>
                        </Form.Group>
                        <button type="submit" disabled={isSubmitted === 'pending'}
                                className="btn pharmacy-btn offset-md-5">{isSubmitted === 'pending' ? "Submitting..." : "Submit"}</button>
                    </form>
                </Card>
            </div>
        </div>
    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars,
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(EditManufacturer);
import * as types from "../../Actions/actionTypes";


const initialState = {
    customers: [],
    paidCustomers: [],
    creditCustomers: [],
    instant_customer: {},
    customer: {},
    customerUpdate: {},
    customerLedger: {},
    loading:false,
    customer_id: ''
};

export default function customerReducer(state = initialState, action) {
    switch (action.type) {
        case types.CUSTOMER_GIF:
            return {
                loading:true
            };
        case types.CUSTOMER_SUCCESS:
            return {submitting:false};
        case types.CUSTOMER_ERROR:
            return {submitting:false};
        case types.CUSTOMER_REQUEST:
            return {submitting:true};
        case types.EDITCUST_SUCCESS:
            return {submitting:false};
        case types.EDITCUST_ERROR:
            return {submitting:false};
        case types.EDITCUST_REQUEST:
            return {submitting:true};
        case types.MANAGE_CUSTOMERS:
            return {...state, customers: action.customers,loading:false};
        case types.PAID_CUSTOMERS:
            return {...state, paidCustomers: action.paidCustomers,loading:false};
        case types.CREDIT_CUSTOMERS:
            return {...state, creditCustomers: action.creditCustomers,loading:false};
        case types.INSTANT_CUSTOMER:
            return {...state, instant_customer: action.instant_customer};
        case types.RETRIEVE_CUSTOMER_DETAILS:
            return {...state, customer: action.customer,loading: false};
        case types.CUSTOMER_LEDGER:
            return {...state, customerLedger: action.customerLedger,loading: false};
        case types.CUSTOMER_UPDATE_FORM:
            return {...state, customerUpdate: action.customerUpdate};
        case types.DELETE_PAID_CUSTOMER:
            return {
                ...state,
                paidCustomers: state.paidCustomers.filter(customer => customer.customer_id !== action.customer_id)
            };
        case types.DELETE_CREDIT_CUSTOMER:
            return {
                ...state,
                creditCustomers: state.creditCustomers.filter(customer => customer.customer_id !== action.customer_id)
            };
        case types.CUSTOMER_DELETE:
            return {
                ...state,
                customers: state.customers.filter(customer => customer.customer_id !== action.customer_id)
            };
        default:
            return state;
    }

}
import React  from "react";
import {Link} from "react-router-dom"
import DashboardHeader from "../Containers/Dasboard/DashboardHeader";



export default function SubscriptionExpired() {
    return (
        <div>
            <DashboardHeader subscription/>
        <div className="card styled-expired-card">
            <div className="card-header expired-heading">
                Your License has expired
            </div>
            <div className="card-body text-center">
                <p>Dear our esteemed customer your license has expired and you will not be in position to use </p>
                <p>Please contact the <Link to="/support">Marascientific support team</Link> to get your subscription renewed</p>
            </div>
        </div>
        </div>
    )

}
import * as types from "../../Actions/actionTypes";

const initialState = {
    fullReportList: {},
    purchaseList: {},
    salesList: {},
    salesDataWise: [],
    profitManufacturer: {},
    profitProductWise: {},
    purchaseDateWise: [],
    loading:false,
    report_count: 0
};
export default function reportReducer(state = initialState, action) {
    switch (action.type) {
        case types.RERIEVE_ALL_REPORT:
            return {...state, fullReportList: action.fullReportList,loading:false};
        case types.RETRIEVE_TODAYS_SALES_REPORT:
            return {...state, salesList: action.salesList,loading:false};
        case types.RETRIEVE_TODAYS_PURCHASE_REPORT:
            return {...state, purchaseList: action.purchaseList,loading:false};
        case types.SALES_REPORT_DATA_WISE:
            return {...state, salesDataWise: action.salesDataWise};
        case types.PROFIT_MANUFACTURER:
            return {...state, profitManufacturer: action.profitManufacturer};
        case types.PROFIT_PRODUCT_WISE:
            return {...state, profitProductWise: action.profitProductWise};
        case types.RETRIEVE_PURCHASE_REPORT_DATEWISE:
            return {...state, purchaseDateWise: action.purchaseActions};
        case types.REPORT_COUNT:
            return {...state, report_count: action.report_count};
        case types.REPORT_GIF:
            return {
                loading:true
            }
        default:
            return {...state};

    }

}
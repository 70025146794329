import * as types from "../../Actions/actionTypes";

const user = JSON.parse(sessionStorage.getItem("user"));
const initialState = { modalData: {},
    users: [],
    user: {},
    user_id: '',
    roles: [],
    id: '',
    out_of_stock: [],
    expired_drugs: [],
    role_details:{},
    loggedUser: user ? user : {}
};


export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                loading:true
            };
        case types.LOGIN_ERROR:
            return {
                loading:false
            };
        case types.MODAL_DISPLAY:
            return {...state, modalData: action.modalData};
        case types.MANAGE_USERS:
            return {...state, users: action.users};
        case types.GET_SINGLE_USER:
            return {...state, user: action.user};
        case types.ROLE_LIST:
            return {...state, roles: action.roles};
        case types.DELETE_USER:
            return {...state, users: state.users.filter(user => user.user_id !== action.user_id)};
        case types.DELETE_ROLE:
            return {...state, roles: state.roles.filter(role => role.id !== action.id)};
        case types.OUT_OF_STOCK:
            return {...state, out_of_stock: action.out_of_stock};
        case types.EXPIRED_DRUGS:
            return {...state, expired_drugs: action.expired_drugs};
        case types.EDIT_OF_ROLE:
            return {...state,role_details:action.role_details};
        case types.USER_LOGIN:
            return {...state, loggedUser: action.loggedUser,loading: false};
        case types.LOGIN_ERROR:
            return {loading:false};
        case types.USER_LOGOUT:
            return {};
        case types.FORGOTPASSWORD_SUCCESS:
            return {
                submitting:false
            };
        case types.FORGOTPASSWORD_ERROR:
            return {
                submitting:false
            };
        case types.FORGOTPASSWORD_REQUEST:
            return{
                submitting:true
            };
        case types.RESETPASSWORD_SUCCESS:
            return {
                submitting:false
            };
        case types.RESETPASSWORD_ERROR:
            return {
                submitting:false
            };
        case types.RESETPASSWORD_REQUEST:
            return {
                submitting:true
            };
        case types.ADDROLE_SUCCESS:
            return {
                submitting:false
            };
        case types.ADDROLE_ERROR:
            return {
                submitting:false
            };
        case types.ADDROLE_REQUEST:
            return {
                submitting:true
            };
        case types.ADDUSER_SUCCESS:
            return{
                submitting:false
            };
        case types.ADDUSER_ERROR:
            return{
                submitting:false
            };
        case types.ADDUSER_REQUEST:
            return{
                submitting:true
            };
        case types.ASSIGNROLE_SUCCESS:
            return{
                submitting:false
            };
        case types.ASSIGNROLE_ERROR:
            return{
                submitting:false
            };
        case types.ASSIGNROLE_REQUEST:
            return{
                submitting:true
            };
        case types.EDITROLE_SUCCESS:
            return{
                submitting:false
            };
        case types.EDITROLE_ERROR:
            return{
                submitting:false
            };
        case types.EDITROLE_REQUEST:
            return{
                submitting:true
            };
        case types.EDITUSER_SUCCESS:
            return{
                submitting:false
            };
        case types.EDITUSER_ERROR:
            return{
                submitting:false
            };
        case types.EDITUSER_REQUEST:
            return{
                submitting:true
            };
        default:
            return state;
        
    }
}

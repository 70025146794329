import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export const useCollapsibleRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
        '&:nth-child(2) > *':{
            borderBottom:0
        }
    },
    icon: {
        width: '.95rem'
    },
    wrapperInner:{
       padding:'6px 24px 6px 16px'
    },
    tableCell:{
        paddingTop:0,
        paddingBottom:0
    },
    td:{
        paddingBottom:0
    },
    totalCell:{
       borderTop:'2px double gray'
    }
});



export default function ReusableAccountingCollapsibleTable({children,headers=[]}) {
    return (
            <Table aria-label="collapsible table" size='small'>
                <TableHead>
                    <TableRow>
                        {headers.map(({key, value})=>(
                            <TableCell key={key}>{value}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
    );
}

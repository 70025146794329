import React, {useEffect, useRef, useState} from 'react';
import {useDates} from "../../Accounting/CustomHooks/useDates";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/exportPDF";
import {copyToClipboard} from "../../../Utils/copyToClipboard";
import {convert} from "../../../Utils/titleCaseFunction";


export const useManagePurchase = (actions) => {
    const [purchases, setPurchases] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalAmount, setAmount] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [purchase_id, setPurchaseId] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [date, handleChangeDate] = useDates();
    const {from_date, to_date} = date;
    const csvLink = useRef();
    const componentRef = useRef();

    const calculateTotalAmount = (arr) => {
        const sum = arr.reduce((a, purchase) => {
            return +(a + purchase.total_amount);
        }, 0);
        setAmount(sum)
    };

    useEffect(() => {
        const formData = new FormData();
        formData.append("draw", 1);
        formData.append("columns[0][data]", "sl");
        formData.append("columns[0][name]", "");
        formData.append("columns[0][searchable]", true);
        formData.append("columns[0][orderable]", false);
        formData.append("columns[0][search][value]", "");
        formData.append("columns[0][search][regex]", false);
        formData.append("columns[1][data]", "chalan_no");
        formData.append("columns[1][name]", "");
        formData.append("columns[1][searchable]", true);
        formData.append("columns[1][orderable]", true);
        formData.append("columns[1][search][value]", "");
        formData.append('columns[1][search][regex]', false);
        formData.append("columns[2][data]", "purchase_id");
        formData.append("columns[2][name]", "");
        formData.append("columns[2][searchable]", true);
        formData.append("columns[2][orderable]", false);
        formData.append("columns[2][search][value]", "");
        formData.append("columns[2][search][regex]", false);
        formData.append("columns[3][data]", "manufacturer_name");
        formData.append('columns[3][name]', "");
        formData.append("columns[3][searchable]", true);
        formData.append("columns[3][orderable]", false);
        formData.append("columns[3][search][value]", "");
        formData.append("columns[3][search][regex]", false);
        formData.append("columns[4][data]", "purchase_date");
        formData.append("columns[4][name]", "");
        formData.append("columns[4][searchable]", true);
        formData.append("columns[4][orderable]", false);
        formData.append("columns[4][search][value]", "");
        formData.append("columns[4][search][regex]", false);
        formData.append("columns[5][data]", "total_amount");
        formData.append("columns[5][name]", "");
        formData.append("columns[5][searchable]", true);
        formData.append("columns[5][orderable]", false);
        formData.append("columns[5][search][value]", "");
        formData.append("columns[5][search][regex]", false);
        formData.append('columns[6][data]', "button");
        formData.append("columns[6][name]", "");
        formData.append("columns[6][searchable]", true);
        formData.append("columns[6][orderable]", false);
        formData.append("columns[6][search][value]", "");
        formData.append("columns[6][search][regex]", false);
        formData.append("order[0][column]", 4);
        formData.append("order[0][dir]", "desc");
        formData.append("start", activePage);
        formData.append("length", 10);
        formData.append("search[value]", search);
        formData.append("search[regex]", false);
        formData.append("fromdate", from_date);
        formData.append("todate", to_date);
        setLoading(true)
        axios.post(`${config.epharmUrl}/Cpurchase/CheckPurchaseList`, formData).then(response => {
            const res = response.data;
            const dt = res ? res : {}
            const data = dt.aaData ? dt.aaData : [];
            // data.sort((a, b) => {
            //     return new Date(b.purchase_date) - new Date(a.purchase_date)
            // });
            const purchase_count = dt.iTotalRecords ? dt.iTotalRecords : 0;
            setPurchases(data);
            setTotalCount(purchase_count);
            calculateTotalAmount(data);
            setLoading(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [search, activePage, submitted])
    
    const handleOpenDialog = id => {
        setPurchaseId(id);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDeletePurchase = () => {
        axios.get(`${config.epharmUrl}/Cpurchase/delete_purchase`, {params: {purchase_id}}).then(() => {
            const arr = purchases.filter(purchase => purchase.purchase_id !== purchase_id)
            actions.snackbarActions.successSnackbar('Purchase deleted successfully');
            setPurchases(arr);
            setOpenDialog(false)
        }).catch((error) => {
            errorMessages(error, null, actions)
        });
    };

   const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const downloadPdf = () => {
        exportPDF("Purchase Report", [["SL", "Invoice NO", "Purchase ID", "Manufacturer Name", "Date", "Amount"]],
            purchases.map((purchase, index) => [index += 1, purchase.chalan_no, purchase.purchase_id,
                purchase.manufacturer_name, purchase.purchase_date ? convert(purchase.purchase_date) : '', purchase.total_amount]), "Purchase.pdf");
    };
    
    const copyTable = () => {
        copyToClipboard(actions, 10, componentRef)
    };

    const fetchData = () => {
        csvLink.current.link.click()
    };

    const handleSearch = (event) =>{
        event.preventDefault();
        if (from_date && to_date){
            setSubmitted(!submitted)
        }
    }

    const handleChangePage = (page) => {
        setActivePage(page)
    }

    const handleChangeSearch = (event) => {
        setSearch(event.target.value)
    }
    return {purchases, totalCount, activePage, search, from_date, to_date,
        handleChangeDate,totalAmount,loading, openDialog,handleOpenDialog,
        handleCloseDialog, handleDeletePurchase, handleCloseBar, downloadPdf,
        copyTable, fetchData, handleSearch, handleChangePage, handleChangeSearch}
}
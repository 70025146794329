import React from "react";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const EmployeeDetails = () =>{
    return(
        <div>
        <DashboardHeader hrm employeeDetails/>
        <div className="style-employee-details">
        <div className="row">
            <div className="col-md-4">
                <div className="card styled-person-table styled-employee-card mb-3 ">
                    <div className="employee-header"/>
                    <div className="employee-image">
                        <img src="/images/profile.png" alt="user"/>
                    </div>
                    <div className="p-2">
                    <div className="text-center mb-4">
                        <h6>John Doe</h6>
                        <h6>Designation: Manager</h6>
                        <h6>Phone: </h6>
                    </div>
                        <table className="table">
                            <tr>
                                <td>Name</td>
                                <td>John Doe</td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>+256778900900</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td/>
                            </tr>
                            <tr>
                                <td>Country</td>
                                <td>Uganda</td>
                            </tr>
                            <tr>
                                <td>City</td>
                                <td/>
                            </tr>
                            <tr>
                                <td>Zip Code</td>
                                <td/>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <div className="card styled-person-table p-1">
                    <table className="table mt-5">
                        <tr>
                            <td>Designation</td>
                            <td>Manager</td>
                        </tr>
                        <tr>
                            <td>Rate Type</td>
                            <td>Salary</td>
                        </tr>
                        <tr>
                            <td>Hourly Rate / Salary</td>
                            <td/>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        </div>
        </div>
    )

};
export default EmployeeDetails;
import React from "react";


const  ResetEmailText = () =>{
    return(
        <div className="text-center styled-reset-page p-3">
            <img src='/images/mpeke3.png' style={{width:"300px", marginBottom:50}}/>
            <h2>Check your email</h2>
            <h5>We've sent a link to your email. Click the link to reset <br/> your password.</h5>
            <h6><a href="https://mail.google.com/" target="__blank">open Gmail</a></h6>
        </div>
    )

};
export default ResetEmailText;

import {useEffect,  useState} from "react";
import {useDates} from "../../../Components/Accounting/CustomHooks/useDates";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {history} from "../../../Helpers/history";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useMuiPagination} from "../DashboardTables";

export const retrieveAllCreditCustomers = (pg=1, search_value='') => {
    const formData = new FormData();
    formData.append("draw", 1);
    formData.append("columns[0][data]", "sl");
    formData.append("columns[0][name]", "");
    formData.append("columns[0][searchable]", "true");
    formData.append("columns[0][orderable]", false);
    formData.append("columns[0][search][value]", "");
    formData.append("columns[0][search][regex]", false);
    formData.append("columns[1][data]", "customer_name");
    formData.append("columns[1][name]", "");
    formData.append("columns[1][searchable]", true);
    formData.append("columns[1][orderable]", true);
    formData.append("columns[1][search][value]", "");
    formData.append('columns[1][search][regex]', false);
    formData.append("columns[2][data]", "address");
    formData.append("columns[2][name]", "");
    formData.append("columns[2][searchable]", true);
    formData.append("columns[2][orderable]", false);
    formData.append("columns[2][search][value]", "");
    formData.append("columns[2][search][regex]", false);
    formData.append("columns[3][data]", "mobile");
    formData.append('columns[3][name]', "");
    formData.append("columns[3][searchable]", true);
    formData.append("columns[3][orderable]", false);
    formData.append("columns[3][search][value]", "");
    formData.append("columns[3][search][regex]", false);
    formData.append("columns[4][data]", "balance");
    formData.append("columns[4][name]", "");
    formData.append("columns[4][searchable]", true);
    formData.append("columns[4][orderable]", false);
    formData.append("columns[4][search][value]", "");
    formData.append("columns[4][search][regex]", false);
    formData.append('columns[5][data]', "button");
    formData.append("columns[5][name]", "");
    formData.append("columns[5][searchable]", true);
    formData.append("columns[5][orderable]", false);
    formData.append("columns[5][search][value]", "");
    formData.append("columns[5][search][regex]", false);
    formData.append("order[0][column]", 1);
    formData.append("order[0][dir]", "asc");
    formData.append("start", pg);
    formData.append("length", 10);
    formData.append("search[value]", search_value);
    formData.append("search[regex]", false);
    return axios.post(config.epharmUrl + "/Ccustomer/checkCreditCustomerList", formData)

}

export const useDashboardContent = (actions) => {
    const CURRENT_YEAR = new Date().getFullYear();
    const years = [{value: 2020, text: 2020}, {value: 2021, text: 2021}, {value: 2022, text: 2022}, {
        value: 2023,
        text: 2023
    },
        {value: 2024, text: 2024}, {value: 2025, text: 2025}, {value: 2026, text: 2026},
        {value: 2027, text: 2027}, {value: 2028, text: 2028}, {value: 2029, text: 2029}, {value: 2030, text: 2030}];
    const [start, setStart] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [statistics, setStatistics] = useState({});
    const [remainingDays, setRemainingDays] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [expired_drugs, setExpiredDrugs] = useState([]);
    const [out_of_stock, setOutOfStock] = useState([]);
    const [date, handleChangeDate, setDate] = useDates();
    const [period, setPeriod] = useState('yearly');
    const [year, setYear] = useState(CURRENT_YEAR)
    const {from_date, to_date} = date;
    const [loading, setLoading] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [debtors, setDebtors] = useState([]);
    const [cashPayments, setCashPayments] = useState([])
    const [totals, setTotals] = useState({total_purchases:0, total_sales:0})

    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const inputType = {
        'weekly': 'week',
        'monthly': 'month',
        'custom': 'date'
    }
    const isVisited =  JSON.parse(sessionStorage.getItem('isVisited'))


    const calculateTotal = (arr) => {
        const total_sales = arr.reduce((a, sale) => {
            return Number(a + sale.total_sales);
        }, 0);
        const total_purchases = arr.reduce((a, purchase) => {
            return Number(a + purchase.total_purchases);
        }, 0);
        setTotals({total_purchases, total_sales})
    };



    useEffect(() => {
        const s_date = period === 'yearly' ? year : from_date;
        const pd = period === 'yearly' ? '2' : period === 'monthly' ? '1' : period === 'custom' ? '3' : 0
        if((period === 'custom' && from_date && to_date) || (period !== 'custom' && (from_date || year))) {
            axios.post(`${config.epharmUrl}/admin/progress_report`, null,
                {params: {start_date: s_date, end_date: to_date, option: pd}}).then(response => {
                const data = response.data;
                const dt = data ?? [];
                calculateTotal(dt)         
                setGraphData(dt)
            }).catch(error => {
                errorMessages(error, null, actions)
            })
        }
    }, [from_date, to_date, year])

    useEffect(() => {
        setLoading(true)
        const formData = new FormData();
        formData.append('search','')
        axios.post(`${config.epharmUrl}/admin/cash_payments/1`, formData).then(response => {
            const data = response.data;
            const dt = data ?? {};
            const cash = dt.cash_list ?? [];
            const cashArr = cash.map(item => ({
                id: item.customer_id,
                date: item.date,
                invoice_no: item.invoice,
                name: item.customer_name,
                amount: item.paid_amount
            }))
            setCashPayments(cashArr)
            setLoading(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [])


    useEffect(() => {
        sessionStorage.setItem('isVisited', JSON.stringify('visited'))
        setLoading(true)
        retrieveAllCreditCustomers().then(response => {
            const data = response.data;
            const dt = data ?? {};
            const debtData = dt.aaData ?? [];
            const debtArr = debtData.map(item => ({
                id: item.customer_id,
                date: item.date,
                invoice_no: item.invoice_no,
                name: item.customer_name,
                amount: item.balance
            }))
            setDebtors(debtArr)
            setLoading(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [])


    useEffect(() => {

        (async () => {
                try {
                    const getExpiredDrugs = axios.get(`${config.epharmUrl}/User/expired_products`);
                    const getOutOfStock = axios.get(`${config.epharmUrl}/User/out_of_stock`);
                    const [expiredResponse, outOfStockResponse] = await Promise.all([getExpiredDrugs, getOutOfStock]);
                    const expired = expiredResponse.data;
                    const dt = !expired ? {} : expired;
                    const exp = dt.expired_products ? dt.expired_products : [];
                    const stock = outOfStockResponse.data ? outOfStockResponse.data : [];
                    setExpiredDrugs(exp)
                    setOutOfStock(stock)
                    if (isVisited !== 'visited') {
                        setOpenDialog(true);
                    }
                } catch (error) {
                    // errorMessages(error, null, actions)
                }
            }
        )();
    }, [])


    useEffect(() => {
        axios.get(`${config.epharmUrl}/admin/remaining_days`).then(response => {
            const data = response.data;
            const dt = data ?? {};
            const days = dt.days ?? 0;
            if (days <= 30) {
                setRemainingDays(days)
                setOpenAlert(history.length <= 4 && true);
            }
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    }, [])

    useEffect(() => {
        axios.get(config.epharmUrl + "/admin/").then(response => {
            const data = response.data;
            const stat = data ?? {};
            setStatistics(stat);
        }).catch(error => {
            // errorMessages(error,null,actions);
        });
    }, [])

    useEffect(() => {
        setInterval(() => {
            if (start < statistics.total_customer) {
                setStart(start + 1)
            }
        }, 1000)
    }, [start, statistics])


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleChangePeriod = (event) => {
        const {value} = event.target;
        setPeriod(value);
        setDate({from_date: '', to_date: ''})
    }

    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }


    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const expiredDrugs = !expired_drugs ? [] : expired_drugs;
    const outOfStockDrugs = !out_of_stock ? [] : out_of_stock;
    const customers = !statistics.total_customer ? 0 : statistics.total_customer;
    const manufacturers = !statistics.total_manufacturers ? 0 : statistics.total_manufacturers;
    const products = !statistics.total_product ? 0 : statistics.total_product;
    const outOfStock = !statistics.out_of_stock ? 0 : statistics.out_of_stock;
    const outOfDate = !statistics.out_of_date ? 0 : statistics.out_of_date;
    const totalSales = !statistics.total_sales ? 0 : statistics.total_sales;
    const salesAmount = !statistics.sales_amount ? 0 : statistics.sales_amount;
    const purchaseAmount = !statistics.purchase_amount ? 0 : statistics.purchase_amount;
    const stat = Object.keys(statistics).length;

    return {
        years, openDialog, remainingDays, openAlert, handleChangeDate, period, year, from_date,
        to_date, inputType, handleOpenDialog, handleCloseDialog, handleChangePeriod, debtors, loading,
        handleChangeYear, handleCloseBar, expiredDrugs, outOfStockDrugs, customers, manufacturers,
        products, outOfStock, outOfDate, totalSales, salesAmount, purchaseAmount, stat, graphData,
        cashPayments,page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, totals
    }
}
import React, {useRef, useState} from 'react'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions'
import {printFunctionality} from "../../../Utils/printFunctionality";
import {printStyles, ReusableReportHeader} from "./ReusableReportHeader";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {useFetchData} from "../CustomHooks/useFetchData";
import {PrintablePLStatement} from "../PrintableDocs";
import ReusableAccountingCollapsibleTable, {useCollapsibleRowStyles} from "../ReusableAccountingCollapsibleTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Collapse from "@material-ui/core/Collapse";
import {NoTransaction} from "../ReusableJournalTable";


const ProfitLoss = ({actions, snackbars}) => {
    const {
        date, closeSnackbar, period, handleChangePeriod,
        inputType, handleChangeDate, year, handleChangeYear, data, loading
    } = useFetchData(actions, 'get_income_statement')
    const {from_date, to_date} = date;
    const rowClasses = useCollapsibleRowStyles()

    const [collapse, setCollapse] = useState({});

    const handleCollapse = (param) => {
        setCollapse(prevState => ({...prevState, [param]: !prevState[param]}));
    };

    const downloadPDF = () => {
        const doc = new jsPDF()
        autoTable(doc, {html: '#pl_statement'})
        doc.save('Profit & Loss Statement.pdf')
    };
    const headers = [{key: 'name', value: 'Account'}, {key: 'resulting_amount', value: 'Amount'}]
    const printRef = useRef();
    const {open, variant, message} = snackbars

    return (
        <div className="acc_reports journals">
            <PharmacySnackbar handleCloseBar={closeSnackbar} variant={variant} open={open} message={message}/>
            <ReusableReportHeader handleChangeDate={handleChangeDate} to_date={to_date} inputType={inputType}
                                  handleChangePeriod={handleChangePeriod} period={period}
                                  handleChangeYear={handleChangeYear} year={year}
                                  downloadPdf={downloadPDF} from_date={from_date} title='Profit & Loss Statement'
                                  handlePrint={() => printFunctionality(printRef.current, printStyles())}
                                  subTitle='Profit & loss statement'/>
            <div className='mui-tables'>
                <PrintablePLStatement printRef={printRef} data={data}/>
                {data.length > 0 ? <ReusableAccountingCollapsibleTable headers={headers}>
                    <React.Fragment>
                        {data.map(item=>(
                            <>
                                <TableRow className={rowClasses.root} >
                                    <TableCell classes={{root:rowClasses.td}}>
                                        {item.category}
                                    </TableCell>
                                    <TableCell>{" "}</TableCell>
                                </TableRow>
                                {item.parent_accounts.map(({account_name:name, sub_accounts}, index)=>(
                                    <React.Fragment key={index}>
                                        <TableRow className={rowClasses.root}>
                                            <TableCell classes={{root:rowClasses.tableCell}}>
                                                <IconButton aria-label="expand row" size="small" onClick={() => handleCollapse(name)}>
                                                    {collapse[name] ?
                                                        <KeyboardArrowDownIcon classes={{root: rowClasses.icon}}/> :
                                                        <KeyboardArrowRightIcon classes={{root: rowClasses.icon}}/>}
                                                </IconButton>{name}
                                            </TableCell>
                                            <TableCell>{" "}</TableCell>
                                        </TableRow>
                                        {sub_accounts.map(it => (
                                            <TableRow key={it.name}>
                                                {headers.map(({key, format = value => value}) => (
                                                    <TableCell style={{paddingBottom: 0, paddingTop: 0, borderBottom:0}}>
                                                        <Collapse in={collapse[name]} timeout="auto" unmountOnExit classes={{wrapperInner:rowClasses.wrapperInner}}>
                                                            {format(it[key])}
                                                        </Collapse>
                                                    </TableCell>))}
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </>
                        ))}

                    </React.Fragment>
                    <TableRow>
                        <TableCell classes={{root:rowClasses.totalCell}}>NET PROFIT/LOSS</TableCell>
                        <TableCell classes={{root:rowClasses.totalCell}}></TableCell>
                    </TableRow>
                </ReusableAccountingCollapsibleTable>:loading ?
                    <div className='text-center mt-5'><img src="/images/listPreloader.gif"
                               alt="loader" style={{width: '10%'}}/></div>:<NoTransaction text='transactions'/>}
            </div>
        </div>
    )
        ;
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfitLoss)

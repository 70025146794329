import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from '../../../Helpers/config.json';
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useChartOfAccounts} from "./useChartOfAccounts";


export const useNewCOAccount = (actions) =>{
    const [accountType, setAccountType] = useState('');
    const [state, setState] = useState({account_name:'', account_code:'', description:'',
        isSubAccount:false, opening_balance:0, balance_date:''});
    const [parentAccount,setParentAccount] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [defaultAccounts, setDefaultAccount] = useState([]);
    const [subAccounts, setSubAccounts] = useState([]);
    const [entryType, setEntryType] = useState('');
    const {coa,  refresh, setRefresh,closeSnackbar, handleCloseDialog, handleOpenDialog,openDialog} = useChartOfAccounts(actions);

    useEffect(()=>{
        axios.get(`${config.epharmUrl}/accounting/chart/accounting_categories`).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.data ?? [];
            const items = arr.map(item=>({
                value:item.id,
                label:`${item.category_code} - ${item.category_name}`
            }))
            setDefaultAccount(items)
        },err=>console.log(err))
    },[])

    const retrieveSubAccount = (id) =>{
        const formData = new FormData()
        formData.append('account_category', id)
        axios.post(`${config.epharmUrl}/accounting/chart/parent_accounts`, formData).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.data ?? [];
            const items = arr.map(item=>({
                value:item.head_code,
                label:`${item.head_code} - ${item.head_name}`
            }))
            setSubAccounts(items)
        },err=>console.log(err))
    }

    const handleChangeAccountType = (value) =>{
        setAccountType(value)
        retrieveSubAccount(value.value)
    }

    const handleChangeState = (event) =>{
        const {value,checked,type,name} = event.target;
        const val = type === 'checkbox' ? checked : value
        setState({...state,[name]:val})
    }

    const handleChangeParentAccount = (value) =>{
        setParentAccount(value)
    }

    const handleChangeEntryType = (value) =>{
        setEntryType(value)
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        const {account_code,account_name,isSubAccount,description, opening_balance} = state;
        setSubmitted(true);
        const formData = new FormData();
        formData.append('account_category',accountType.value);
        formData.append('account_name', account_name);
        if (isSubAccount && parentAccount){
            formData.append('parent_account_code', parentAccount.value);
            formData.append('opening_balance', opening_balance ? opening_balance : 0);
            formData.append('entry_type', entryType.value);
        }
        if (accountType && account_name && ((isSubAccount && parentAccount && entryType) || !isSubAccount)){
            setIsSubmitted('pending')
            const url = isSubAccount && parentAccount ? 'sub_accounts/add' : 'parent_accounts/add'
            axios.post(`${config.epharmUrl}/accounting/chart/${url}`, formData).then(()=>{
                actions.snackbarActions.successSnackbar('Account created successfully')
                setIsSubmitted('resolved')
                handleCloseDialog();
                setRefresh(!refresh)
                setEntryType('')
                setAccountType('')
                setParentAccount('')
                setState({account_name:'', account_code:'', description:'',
                    isSubAccount:false, opening_balance:0})
                setSubmitted(false)
            }).catch(err=>{
                errorMessages(err, null,actions);
                setIsSubmitted('rejected')
            })
        }
    }

    return {accountType,state,parentAccount,handleChangeAccountType, handleChangeState,handleChangeParentAccount,
    submitted, isSubmitted,handleSubmit, defaultAccounts, subAccounts, coa, closeSnackbar,
    entryType, handleChangeEntryType, openDialog,handleOpenDialog, handleCloseDialog}
}
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const MainPersonLoanDetails = (props) =>{
    const {children,personalLoan,officeLoan,personLedger,loanDetails} = props;
    return (
        <div>
        <DashboardHeader personalLoan={personalLoan} personLedger={personLedger}
         officeLoan={officeLoan} loanDetails={loanDetails}/>
        }
        <div className="styled-manage-person">
            {children}
            <div className="card styled-person-table p-3">
                <Form autoComplete="off">
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Name<span className="text-danger asterics">*</span></Form.Label>
                        <Col sm={8}>
                            <Select/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Phone<span className="text-danger asterics">*</span></Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>From<span className="text-danger asterics">*</span></Form.Label>
                        <Col sm={8}>
                            <Form.Control type="date"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>To<span className="text-danger asterics">*</span></Form.Label>
                        <Col sm={8}>
                            <Form.Control type="date"/>
                        </Col>
                    </Form.Group>
                    <div className="text-center">
                        <button className="btn pharmacy-primary-btn px-4">Save</button>
                    </div>
                </Form>
            </div>
            <div className="card styled-person-table p-3 mt-4">
                <h6>Personal Ledger</h6>
                <hr/>
                <div className="text-center">
                    <h6>John Doe</h6>
                    <h6>Phone: </h6>
                    <h6>Address: </h6>
                    <h6>Print Date:</h6>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <td>Date</td>
                            <td>Details</td>
                            <td>Debit</td>
                            <td>Credit</td>
                            <td>Balance</td>

                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan={2} align="right">Grand Total</td>
                            <td/>
                            <td/>
                            <td/>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </div>
    )
};
export default MainPersonLoanDetails;
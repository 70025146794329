import React, {useState, useEffect,useRef} from "react";


export const  useSessionStorageState =(
    key,
    defaultValue = '',
    {serialize = JSON.stringify, deserialize = JSON.parse} = {},
) =>{
    const [state, setState] = useState(() => {
        const valueInLocalStorage = window.localStorage.getItem(key)
        if (valueInLocalStorage) {
            try {
                return deserialize(valueInLocalStorage)
            } catch (error) {
                window.sessionStorage.removeItem(key)
            }
        }
        return typeof defaultValue === 'function' ? defaultValue() : defaultValue
    })

    const prevKeyRef = useRef(key)
    useEffect(() => {
        const prevKey = prevKeyRef.current
        if (prevKey !== key) {
            window.sessionStorage.removeItem(prevKey)
        }
        prevKeyRef.current = key
        window.sessionStorage.setItem(key, serialize(state))
    }, [key, state, serialize])

    return [state, setState]
}
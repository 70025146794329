import React, {Component} from "react";
import EditAddEmployee from "./AddEditEmployee";


class EditEmployee extends Component {
    render() {
        return (
            <EditAddEmployee editEmployee/>
        );
    }
}

export default EditEmployee;
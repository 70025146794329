import {makeStyles} from "@material-ui/core/styles";

export const useAccountingStyles = makeStyles((theme) => ({
    icon:{
        width:'0.9em',
        height:'0.9em'
    },
    trialBalance:{
        width:'90%',
        maxWidth:'1200px',
        margin:'0 auto'
    },
    item:{
        display:'flex',
        justifyContent:'flex-end'
    },
    body1: {
        fontSize: '0.98em'
    },
    customSelect:{
        width:'50%',
    },

}));
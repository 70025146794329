import {convDate, convertDate,  removeTime, titleCase} from "../../../Utils/titleCaseFunction";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useCreateInvoice = (actions,  match={}) => {
    const {invoice_id:_id,component} = match.params;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const user_id = !singleUser.user_id ? 1 : singleUser.user_id;
    const [showNewCustomer, setShowNewCustomer] = useState(false);
    const [selectCustomer, setSelectCustomer] = useState('Walking Customer');
    const [showCustomer, setShowCustomer] = useState(false);
    const [showBanks, setShowBanks] = useState(false);
    const [showTax, setShowTax] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [rows, setRows] = useState([{
        product_name: '', batch_id: '', batch_info: [], available_quantity: "", expiry: "", unit: "",
        product_quantity: "", product_rate: "", discount: "", total_price: "", discount_type: 1,
        total_discount: 0, tax: "", dosage: '', product_id: '', showProduct: false
    }])
    const [customer, setCustomer] = useState({
        customer_name_other: '', email: '', mobile: '', address: '',
        previous_balance: '', credit_limit: '', customer_name_other_address: ''
    });
    const [state, setState] = useState({invoice_date: convertDate(), inva_details: '',invdcount:''});
    const [grand_total_price, setGrandTotal] = useState('')
    const [n_total, setNetTotal] = useState('')
    const [change, setChange] = useState('')
    const [due_amount, setDueAmount] = useState('')
    const [paid_amount, setPaidAmount] = useState('')
    const [previous_amount, setPreviousAmount] = useState(0);
    const [total_discount_amount, setTotalDiscount] = useState('')
    const [bank_id, setBankId] = useState('');
    const [customer_id, setCustomerId] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [invoice_id, setInvoiceId] = useState('');
    const [previous, setPrevious] = useState({});
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [paytype, setPayType] = useState('1');
    const [submitCustomer, setSubmitCustomer] = useState(false);
    const [isSubmitCustomer, setIsSubmitCustomer] = useState('idle');
    const [invoice, setInvoice] = useState({})
    const ref = useRef();

    useEffect(()=>{
        if (!_id){
            return
        }
        axios.get(`${config.epharmUrl}/Cinvoice/invoice_update_form`, {params: {invoice_id:_id}}).then(response => {
            const updateDetail = response.data;
            const inv = !updateDetail ? {} : updateDetail;
           setInvoice(inv)
        }).catch(() => {
            //errorMessages(error,null, actions)
        })
    },[_id])

    useEffect(()=>{
        if (!_id){
            return
        }
        const details = !invoice ? {} : invoice;
        const invoice_rows = !details.invoice_all_data ? [] : details.invoice_all_data;
        const data = invoice_rows.map(item => ({
            product_name: item.product_name,
            product_id: item.product_id,
            batch_id: item.batch_id,
            available_quantity: item.available_quantity,
            expiry: item.expeire_date ? removeTime(item.expeire_date) : '',
            unit: item.unit,
            product_quantity: item.quantity,
            product_rate: item.rate,
            discount: item.discount,
            total_price: item.total_price,
            discount_type: 1,
            total_discount: item.total_discount,
            batch_info: [item.batch_id],
            tax: 0,
            dosage: item.dosage

        }))
        setRows(data);
        setGrandTotal(details.total_amount ? details.total_amount : 0);
        setNetTotal(details.net_total ? details.net_total : 0);
        setDueAmount(details.due_amount ? details.due_amount : 0);
        setPaidAmount(details.paid_amount ? details.paid_amount : 0);
        setTotalDiscount(details.total_discount ? details.total_discount : 0);
        setPreviousAmount(details.prev_due ? details.prev_due : 0);
        setSelectCustomer(details.customer_name ? details.customer_name : '');
        setState({invoice_date: convDate(details.date) ?? ''});
        setPayType(details.paytype ? details.paytype : '');
        setCustomerId(details.customer_id ? details.customer_id : '')
    },[_id, invoice])
    
    // useEffect(()=>{
    //     if (customer_id !== 1 && showCustomer){
    //         return
    //     }
    //     axios.get(`${config.epharmUrl}/Cinvoice/previous`, {params: {customer_id: 1}}).then(res => {
    //         const previous = res.data;
    //         const dt = !previous ? {} : previous
    //         const prev_amount = dt.balance ? dt.balance : 0;
    //         const credit_amount = dt.credit_limit ? dt.credit_limit : 0;
    //         if (prev_amount > credit_amount && credit_amount !== 0) {
    //             actions.snackbarActions.infoSnackbar('Customer has reached their credit limit');
    //             setPrevious(dt)
    //         } else {
    //             setPrevious(dt)
    //             setPreviousAmount(prev_amount)
    //         }
    //     }).catch(error => {
    //         // errorMessages(error, null, actions)
    //     })
    // },[customer_id])


    const handleChangeFormFields = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value});
    };

    const handleChangePaymentMethod = (event) => {
        const {value} = event.target
        setPayType(value)
        if (value === "2") {
            setShowBanks(true)
        } else {
            setShowBanks(false)
        }
    };

    const handleChange = (event, index) => {
        const {value, name} = event.target;
        const arr = rows.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value}
            }
            return value;
        })
        setRows(arr)
    };
    const handleChangeBank = e => {
        setBankId(e.target.value)
    };

    const handleChangeBatch = (event, idx) => {   
        const rowsCopy = [...rows];
        rowsCopy[idx]['batch_id'] = event.target ? event.target.value : event;
        axios.get(`${config.epharmUrl}/Cinvoice/retrieve_product_batch_id`, {
            params: {
                batch_id: event.target ? event.target.value : event,
                product_id: rowsCopy[idx]['product_id']
            }
        }).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const t_product = !dt.total_product ? 0 : dt.total_product;
            const exp_date = !dt.expire_date ? "" : dt.expire_date;
            const daysLeft = Math.floor((Date.parse(exp_date) - Date.now()) / (24 * 60 * 60 * 1000));
            if (daysLeft < 0) {
                rowsCopy[idx]['available_quantity'] = "";
                rowsCopy[idx]['expiry'] = '';
                rowsCopy[idx]['expired'] = true;
                rowsCopy[idx]['product_rate'] = 0;
                rowsCopy[idx]['unit'] = '';
                rowsCopy[idx]['batch_id'] = '';
                rowsCopy[idx]['dosage'] = '';
                actions.snackbarActions.infoSnackbar('Drug Expired, Please Choose another')
            } else {
                rowsCopy[idx]['product_rate'] = dt.price ? dt.price : 0;
                rowsCopy[idx]['available_quantity'] = t_product < 0 ? 0 : t_product;

                rowsCopy[idx]['product_rate'] = dt.price ? +dt.price : 0;
                rowsCopy[idx]['expiry'] = exp_date;
            }
            setRows(rowsCopy);
        }).catch(error => {
            errorMessages(error, null, actions)
        });

    };

    const handleAddRow = () => {
        const item = {
            product_name: '', batch_id: '', batch_info: [], available_quantity: "", expiry: "",
            unit: "", product_quantity: "", product_rate: "", discount: 0, total_price: "", discount_type: 1,
            total_discount: 0, product_id: '', tax: 0, dosage: '', showProduct: false
        };
        setRows([...rows, item])
    };
    const handleRemoveSpecificRow = (idx) => {
        const arr = rows.filter((_, index) => index !== idx)
        calculateGrandTotal(arr);
        calculateTotalDiscount(arr);
        setRows(arr)
    };

    const handleOpenDialog = (inv_id) => {
        setOpenDialog(true);
        setInvoiceId(inv_id)
    };
    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleSaveInvoice = event => {
        event.preventDefault();
        setSubmitted(true)
        const {invoice_date, inva_details,invdcount} = state;
        const previousObj = !previous ? {} : previous;
        const credit_limit = !previousObj.credit_limit ? 0 : previousObj.credit_limit;
        const formData = new FormData();
        const arr = rows.every(item => item.batch_id && item.product_name && item.product_quantity)
        if (arr && paid_amount) {
            setIsSubmitted('pending')
            if (due_amount <= credit_limit || credit_limit === 0) {
                formData.append('invoice_date', invoice_date);
                formData.append('customer_id', customer_id);
                formData.append('customer_name', selectCustomer);
                formData.append('paytype', paytype);
                // formData.append('bank_id', 1);
                if (_id){
                    formData.append('invoice_id', _id);
                }
                for (let row in rows) {
                    if (rows.hasOwnProperty(row)) {
                        if (rows[row].product_name && rows[row].product_id && rows[row].batch_id &&
                            rows[row].product_quantity && rows[row].product_rate &&
                            rows[row].total_price) {
                            formData.append('product_name', rows[row].product_name);
                            formData.append('product_id', rows[row].product_id);
                            formData.append('batch_id', rows[row].batch_id);
                            formData.append('product_quantity', rows[row].product_quantity);
                            formData.append('discount', rows[row].discount);
                            formData.append('product_rate', rows[row].product_rate);
                            formData.append('total_price', rows[row].total_price);
                            formData.append('tax', 0);
                            formData.append('cartoon', 0);
                        }
                    }
                }
                formData.append('total_tax', 0);
                formData.append('total_tax1', 0);
                formData.append('total_tax0', 0);
                formData.append('inva_details', inva_details ?? '');
                formData.append('invdcount', invdcount ?? 0);
                formData.append('total_discount', total_discount_amount);
                formData.append('total_discount_amount', total_discount_amount);
                formData.append('grand_total_price', grand_total_price);
                formData.append('previous', previous_amount);
                formData.append('n_total', n_total);
                formData.append('paid_amount', paid_amount);
                formData.append('due_amount', due_amount);
                formData.append('change', change);
                formData.append('discount_type', 1);

                const url = _id ? 'invoice_update':'insert_invoice'
                axios.post(`${config.epharmUrl}/Cinvoice/${url}`, formData, {
                    headers: {
                        'content-Type': 'multipart/form-data',
                        user_id
                    },
                }).then(response => {
                    const data = response.data ?? {};
                    const inv_id = data.invoice_id ?? '';
                    if (data) {
                        actions.snackbarActions.successSnackbar("Invoice saved successfully!");
                        setIsSubmitted('resolved')
                        setSubmitted(false)
                        setRows([{
                            product_name: '',
                            batch_id: '',
                            batch_info: [],
                            available_quantity: "",
                            expiry: "",
                            unit: "",
                            product_quantity: "",
                            product_rate: "",
                            discount: 0,
                            total_price: "",
                            discount_type: 1,
                            total_discount: 0,
                            product_id: '',
                            tax: 0,
                            dosage: '',
                            showProduct: false
                        }])
                        setSelectCustomer('Walk-in Customer')
                        setGrandTotal('');
                        setNetTotal('');
                        setDueAmount('');
                        setChange('');
                        setTotalDiscount('');
                        setPaidAmount('')
                        setPrevious({})
                        handleOpenDialog(inv_id)
                    }
                }).catch(error => {
                    errorMessages(error, null, actions)
                    setIsSubmitted('rejected')
                })
            } else {
                let msg = {
                    message: `The customer has reached their credit limit of
             ${credit_limit.toLocaleString()}. Please clear the balance before purchasing more items`, duration: 10000
                };
                actions.snackbarActions.infoSnackbar(msg)
            }
        }


    };
    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const calculateGrandTotal = (arr) => {
        const sum = arr.reduce((a, row) => {
            return Number(a + row.total_price);
        }, 0);
        const net_total = +(sum + previous_amount);
        const amount = +(net_total - paid_amount);
        const final_amount = amount > 0 ? amount : 0;
        const change = +(paid_amount - net_total);
        const final_change = change > 0 ? change : 0;
        setGrandTotal(sum)
        setNetTotal(net_total);
        setDueAmount(final_amount);
        setChange(final_change)
    };
    const calculateTotalDiscount = (arr) => {
        const discount = arr.reduce((a, row) => {
            let total_apparent = +(row.product_rate * row.product_quantity);
            let dis = !row.discount ? 0 : +(row.discount * total_apparent);
            let disc = dis / 100;
            return a + disc;
        }, 0);
        calculateGrandTotal(arr);
        setTotalDiscount( +(discount))
    };

    const getTotalApparent = (index) => {
        const rowsCopy = [...rows]
        return +(rowsCopy[index]['product_rate'] * rowsCopy[index]['product_quantity']);

    };

    const getDiscount = (index) => {
        const rowsCopy = [...rows]
        const discount_value = !rowsCopy[index]['discount'] ? 0 : +(rowsCopy[index]['discount']);
        calculateTotalDiscount(rowsCopy);
        return getTotalApparent(index) * discount_value / 100
    };

    const getFinalTotal = (event, index) => {
        const rowsCopy = [...rows]
        rowsCopy[index]['total_price'] = +(getTotalApparent(index) - getDiscount(index));
        calculateGrandTotal(rowsCopy);
    };

    const handleChangeQuantity = (event, index) => {
        const {value} = event.target;
        const rowsCopy = [...rows];
        if (rowsCopy[index]) {
            rowsCopy[index]["product_quantity"] = value;
            if (rowsCopy[index]["product_quantity"] > rowsCopy[index]["available_quantity"]) {
                actions.snackbarActions.warningSnackbar(`You cannot sell more than ${rowsCopy[index]["available_quantity"]} Item(s)`);
                rowsCopy[index]["product_quantity"] = '';
                rowsCopy[index]["total_price"] = ''
            } else {
                getFinalTotal(event, index);
            }
        }
        setRows(rowsCopy)
    };

    const handleChangeDiscount = (event, index) => {
        const {value} = event.target;
        const rowsCopy = [...rows];
        if (rowsCopy[index]) {
            rowsCopy[index]["discount"] = value;
            getFinalTotal(event, index);
            calculateTotalDiscount(rowsCopy, index)

        }
        setRows(rowsCopy)
    };

    const calculateChange = (value) => {
        const due_amount = +n_total - +value;
        const change = +value - +n_total;
        const final_due = due_amount > 0 ? due_amount : 0;
        const final_change = change > 0 ? change : 0;
        setDueAmount(final_due);
        setChange(final_change)
    };

    const handleChangeBalance = (event) => {
        const {value} = event.target;
        setPaidAmount(value)
        calculateChange(value)
    };


    const handleShowTax = () => {
        setShowTax(!showTax)
    };


    const handleRetrieveCustomerInfo = event => {
        if (event.target.value !== ' ') {
            actions.invoiceActions.fetchCustomers(titleCase(event.target.value));
        }
        setShowCustomer(true);
        setSelectCustomer(event.target.value)
    };

    const handleClickCustomer = event => {
        const {innerText, value} = event;
        setSelectCustomer(innerText);
        setCustomerId(value);
        setShowCustomer(false)
        axios.get(`${config.epharmUrl}/Cinvoice/previous`, {params: {customer_id: value}}).then(res => {
            const previous = res.data;
            const dt = !previous ? {} : previous
            const prev_amount = dt.balance ? dt.balance : 0;
            const credit_amount = dt.credit_limit ? dt.credit_limit : 0;
            if (prev_amount > credit_amount && credit_amount !== 0) {
                actions.snackbarActions.infoSnackbar('Customer has reached their credit limit');
                setPrevious(dt)
            } else {
                setPrevious(dt)
                setPreviousAmount(prev_amount)
            }
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };

    const handleRetrieveDrugs = (event, index) => {
        const rowsCopy = [...rows];
        if (rowsCopy[index]) {
            rowsCopy[index]['product_name'] = event.target.value;
        }
        rowsCopy[index]['batch_id'] = '';
        rowsCopy[index]['batch_info'] = [];
        rowsCopy[index]['available_quantity'] = '';
        rowsCopy[index]['expiry'] = '';
        rowsCopy[index]['expiry'] = '';
        rowsCopy[index]['unit'] = '';
        rowsCopy[index]['product_quantity'] = '';
        rowsCopy[index]['dosage'] = '';
        rowsCopy[index]['discount'] = '';
        rowsCopy[index]['product_rate'] = 0;
        rowsCopy[index]['total_price'] = 0;
        const tmp = event.target.value.trim();
        if (tmp !== '') {
            actions.invoiceActions.getDrugs(titleCase(tmp));
            rowsCopy[index]['showProduct'] = true;
        } else {
            rowsCopy[index]['showProduct'] = false;
        }
        setRows(rowsCopy);
        calculateGrandTotal(rowsCopy)
    };

    const handleClickDrug = (event, index) => {
        const {value, innerText} = event;
        const rowsCopy = [...rows];
        if (rowsCopy[index]) {
            rowsCopy[index]['product_name'] = innerText;
            rowsCopy[index]['product_id'] = value;
            axios.post(`${config.epharmUrl}/Cinvoice/retrieve_product_data_inv`, null,
                {params: {product_id: value}}).then(response => {
                const data = response.data;
                const dt = !data ? {} : data;
                const batchArr = dt.batch ? dt.batch : [];
                rowsCopy[index].batch_info = batchArr.map(({batch_id}) => batch_id)
                rowsCopy[index].unit = dt.unit ? dt.unit : '';
                setRows(rowsCopy);
                const first_btach = rowsCopy[index].batch_info.length > 0 ? rowsCopy[index].batch_info[0] : '';
                handleChangeBatch(first_btach, index);
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        }
        rowsCopy[index]['showProduct'] = false;
    };

    const handleCloseCustomerField = () => {
        setShowCustomer(false)
    };

    const handleShowNewCustomer = () => {
        setShowNewCustomer(true)
    };
    const handleCloseCustomer = () => {
        setShowNewCustomer(false)
    };

    const submitNewCustomer = event => {
        event.preventDefault();
        const {customer_name_other, email, mobile, address, previous_balance, credit_limit} = customer;
        const formData = new FormData();
        const limit = credit_limit ? credit_limit : 0;
        formData.append('customer_name', customer_name_other);
        formData.append('email', email);
        formData.append('mobile', mobile);
        formData.append('address', address);
        formData.append('previous_balance', previous_balance);
        formData.append('credit_limit', limit)
        setSubmitCustomer(true)
        if (customer_name_other){
            setIsSubmitCustomer('pending')
            axios.post(`${config.epharmUrl}/Ccustomer/insert_customer`, formData, {
                headers: {
                    'content-Type': 'multipart/form-data',
                    user_id
                },
            }).then(_ => {
                actions.snackbarActions.successSnackbar("Customer Saved Successfully!");
                setIsSubmitCustomer('resolved')
                setCustomer({customer_name_other: '', email: '', mobile: '',
                    address: '', previous_balance: '', credit_limit: '',})
                setShowNewCustomer(false)
            }).catch(error => {
                setIsSubmitCustomer('rejected')
                errorMessages(error, null, actions)
            })
        }

    };
    const handleChangeCustomer = event => {
        const {name, value} = event.target;
        setCustomer({...customer,[name]: value})
    };

    const handlePrint = () => {
        const url = {
            'pos-invoice':'posdetails',
            'new-invoice':'invoicedetails',
            'update-invoice':'posdetails'
        }
        history.push(`/${url[component]}/${invoice_id}`)
    };

    return {showNewCustomer, selectCustomer, showCustomer, showBanks, showTax, submitted, rows, customer, state,
        bank_id,  customer_id, openDialog, invoice_id, previous, isSubmitted, paytype, ref,
        handleChangeFormFields, handleChangePaymentMethod, handleChange, handleChangeBank, handleChangeBatch, handleAddRow,
        handleRemoveSpecificRow, handleCloseDialog, handleSaveInvoice, handleCloseSnackbar, handleChangeQuantity,
        handleChangeDiscount, handleChangeBalance, handleShowTax, handleRetrieveCustomerInfo, handleClickCustomer, handleRetrieveDrugs, handleClickDrug,
        handleCloseCustomerField, handleShowNewCustomer, handleCloseCustomer, submitNewCustomer,
        handleChangeCustomer, handlePrint, submitCustomer, isSubmitCustomer, grand_total_price, n_total, change, due_amount,
        paid_amount,  total_discount_amount, previous_amount}
}
import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from '../../../Helpers/config.json';
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useDates} from "./useDates";
import {exportPDF} from "../../../Utils/exportPDF";
import {convertDate, dateStringConvert} from "../../../Utils/titleCaseFunction";

export const useGeneralLedger = (actions) => {
    const CURRENT_YEAR = new Date().getFullYear();
    const [generalLedger, setGeneralLedger] = useState([]);
    const [date, handleChangeDate, setDate] = useDates();
    const [period, setPeriod] = useState('today');
    const [year, setYear] = useState(CURRENT_YEAR)
    const {from_date, to_date} = date;
    const inputType = {
        'weekly': 'week',
        'monthly': 'month',
        'custom': 'date'
    }

    useEffect(() => {
        const formData = new FormData();
        formData.append('from_date', period === 'yearly' ? year : period === 'today' ? convertDate() : from_date);
        formData.append('to_date', to_date)
        axios.get(`${config.epharmUrl}/accounting/chart/general_ledger`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : [];
            const list = arr.map(item => ({
                id: item.id,
                date: item.date_added,
                account: item.head_name,
                description: item.entry_detail,
                credit: item.entry_category === 'CREDIT' ? item.entry_amount : '',
                debit: item.entry_category === 'DEBIT' ? item.entry_amount : ''
            }))
            setGeneralLedger(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })

    }, [year, from_date, to_date])

    const handleChangePeriod = (event) => {
        const {value} = event.target;
        setPeriod(value);
        setDate({from_date: '', to_date: ''})

    }

    const downloadPDF = () => {
        exportPDF("General Ledger", [["Date", "Account", "Description", "Debit", "Credit"]],
            generalLedger.map((item) => [item.date ? dateStringConvert(item.date) : item.date, item.account, item.description,
                item.debit, item.credit]), "General Ledger.pdf");
    };


    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    return {date,  generalLedger, handleChangeDate,  closeSnackbar, downloadPDF, period,
        handleChangePeriod, inputType, year, handleChangeYear}
}
import * as types from "../actionTypes";
import config from "../../../Helpers/config";
import axios from "axios";
import {errorMessages} from "../../../Helpers/ErrorMessages";

const configuration = {
    headers:{
        'content-Type':'multipart/form-data',
        'user_id':1
    }
};


function getStock(stockList) {
    return{
        type:types.RETRIEVE_STOCK_REPORT,
        stockList
    }

}
function getBatchStock(stockBatch) {
    return{
        type:types.RETRIEVE_BATCH_STOCK,
        stockBatch
    }
}
function outOfStockList(outOfStock) {
    return{
        type:types.OUT_OF_STOCK_LIST,
        outOfStock
    }
}
function expiryList(expiryList) {
    return {
        type:types.EXPIRY_LIST,
        expiryList
    }

}
// function stockCount(stock_count) {
//     return{
//         type:types.STOCK_COUNT,
//         stock_count
//     }
//
// }
export function retrieveExpiryList(formData) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Creport/checkExpireList", formData, configuration).then(response=>{
            const data = response.data;
            // const stock_count = response.data.iTotalRecords;
            dispatch(expiryList(data));
            // dispatch(stockCount(stock_count));

        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })
    }

}
export  function retrieveOutOfStockList(formData) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Creport/checkStockOutList", formData, configuration).then(response=>{
            const data = response.data;
            // const stock_count = response.data.iTotalRecords;
            dispatch(outOfStockList(data));
            // dispatch(stockCount(stock_count));
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })
    }

}

export function retrieveStock(formData) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Creport/checkList", formData, configuration).then(response=>{
            const data = response.data;
            // const stock_count = response.data.iTotalRecords;
            dispatch(getStock(data));
            // dispatch(stockCount(stock_count));
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }

}
export function retrieveBatchStock(formData) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Creport/checkbatchstock", formData, configuration).then(res=>{
            const data = res.data;
            // const stock_count = res.data.iTotalRecords;
            dispatch(getBatchStock(data));
            // dispatch(stockCount(stock_count));
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }

}
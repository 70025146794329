import {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../../Helpers/config.json'
import {errorMessages} from "../../../Helpers/ErrorMessages";

export const useEditBank = (actions, match) => {
    const [state, setState] = useState({
        bank_name: '', branch: '', account_no: '',
        account_name: '', opening_balance: ''
    })
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [data, setData] = useState({})
    const {bank_id} = match.params;

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`${config.epharmUrl}/accounting/bank/update_form`)
                let _data = res.data
                let dt = _data.data ?? {};
                setData(dt)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [])

    useEffect(() => {
        const {account_name, branch, account_number: account_no, opening_balance, bank_name} = data
        setState({account_name, branch, account_no, opening_balance, bank_name})
    }, [data])

    const handleChange = (e) => {
        const {name, value} = e.target
        setState({...state, [name]: value})
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        const {account_name, branch, account_no, opening_balance, bank_name} = state
        const formData = new FormData()
        formData.append('bank_id', bank_id)
        formData.append('bank_name', bank_name)
        formData.append('branch', branch)
        formData.append('account_number', account_no)
        formData.append('account_name', account_name)
        formData.append('opening_balance', opening_balance)
        setSubmitted(true);
        if (bank_name && account_name && account_no) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/accounting/bank/update`, formData).then(() => {
                actions.snackbarActions.successSnackbar('Bank updated successfully')
                setIsSubmitted('resolved')
                setState({
                    bank_name: '', branch: '', account_no: '',
                    account_name: '', opening_balance: ''
                });
                setSubmitted(false);
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }
    }


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }


    return {data, handleSubmit, state, handleChange, closeSnackbar, submitted, isSubmitted}
}
import React from "react";
import MainManagePerson from "../PersonalLoan/MainManagePerson";
import {Link} from "react-router-dom";


const ManageOfficePerson = () =>{
    return(
        <MainManagePerson officePerson officeLoan>
            <div className="styled-buttons">
                <Link to="/addpersonoffice"><button className="btn pharmacy-info-btn">Add Person</button></Link>
            </div>
        </MainManagePerson>
    )
};
export default ManageOfficePerson;
import * as types from "../actionTypes";
import axios from "axios";
import config from "../../../Helpers/config"
import {errorSnackbar, infoSnackbar, successSnackbar} from "../SnackbarActions/SnackbarActions";
import {history} from "../../../Helpers/history"
import {errorMessages} from "../../../Helpers/ErrorMessages";


const user = JSON.parse(sessionStorage.getItem('user'));
const singleUser = !user ? {} : user;
const  user_id = !singleUser.user_id ? 1 :singleUser.user_id;
const configuration = {
    headers: {
        'content-Type': 'multipart/form-data',
        user_id
    },
};

// function saveInvoice(invoice) {
//     return {
//         type: types.CREATE_POS_INVOICE,
//         invoice
//     }
// }

function retrieveDrugs(drugs) {
    return {
        type: types.RETRIEVE_DRUGS,
        drugs
    }
}

function getCustomers(customers) {
    return {
        type: types.RETRIEVE_CUSTOMERS,
        customers
    }
}

function retrieveProductInv(rows) {
    return {
        type: types.RETRIEVE_PRODUCT_INVENTORY,
        rows
    }

}

function retrieveBatchInfo(batchInfo) {
    return {
        type: types.RETRIEVE_PRODUCT_BATCH_INFO,
        batchInfo
    }
}

function retrieveInvoices(invoices) {
    return {
        type: types.RETRIEVE_INVOICES,
        invoices

    }

}

function getGuiList(guiList) {
    return {
        type: types.GUI_ITEM_LIST,
        guiList
    }
}

function retrieveGuiItems(guiItems) {
    return {
        type: types.RETRIEVE_GUI_INVOICE_ITEMS,
        guiItems

    }

}

function retriveGuiPos(guiPos) {
    return {
        type: types.RETRIEVE_GUI_POS,
        guiPos
    }

}

function getPrevious(previous) {
    return {
        type: types.GET_CUSTOMER_PREVIOUS_BALANCE,
        previous
    }

}

function invoiceUpdate(updateDetails) {
    return {
        type: types.INVOICE_UPDATE_FORM,
        updateDetails
    }

}

function invoiceInserted(invoiceIData) {
    return {
        type: types.INVOICE_INSERTED_DATA,
        invoiceIData
    }

}

function posInvoiceInserted(posIData) {
    return {
        type: types.POS_INVOICE_INSERTED_DATA,
        posIData
    }

}

function deleteInvoice(invoice_id) {
    return {
        type: types.DELETE_INVOICE,
        invoice_id
    }

}
function getInvoicesRequest(){
    return {
        type:types.GET_ALL_INVOICES_REQUEST
    }
}
function editInvRequest(){
    return {
        type:types.EDITINV_REQUEST
    }
}
function editInvError(){
    return {
        type:types.EDITINV_ERROR
    }
}
function editInvSuccess(){
    return {
        type: types.EDITINV_SUCCESS
    }
}

export function deleteSpecificInvoice(invoice_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cinvoice/invoice_delete", {params: {invoice_id}}).then(response => {
            // const invoice = response.data;
            if (response.data) {
                dispatch(deleteInvoice(invoice_id));
                dispatch(successSnackbar('Invoice Deleted Successfully'));
            } else {
                dispatch(errorSnackbar("Operation unsucessful. A network related error occurred while connecting to the server"));
            }
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }

}

export function updateInvoice(formData) {
    return function (dispatch) {
        dispatch(editInvRequest());
        return axios.post(config.epharmUrl + "/Cinvoice/invoice_update", formData, configuration).then(response => {
            // const data = response.data;
            if (response.data) {
                dispatch(editInvSuccess());
                dispatch(successSnackbar('Invoice Updated Successfully'));
                setTimeout(() => {
                    history.push("/manageinvoice")
                }, 1000)
            } else {
                dispatch(errorSnackbar("Operation unsucessful. A network related error occurred while connecting to the server"));
            }


        }).catch(error => {
            dispatch(editInvError());
            errorMessages(error, dispatch, null)
        })
    }

}

export function posInvoiceInsertedData(invoice_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cinvoice/pos_invoice_inserted_data", {params: {invoice_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            dispatch(posInvoiceInserted(dt))
        }).catch(error => {
            errorMessages(error,dispatch,null)
        })
    }
}

export function invoiceInsertedData(invoice_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cinvoice/invoice_inserted_data", {params: {invoice_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            dispatch(invoiceInserted(dt))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }
}

export function invoiceUpdateForm(invoice_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cinvoice/invoice_update_form", {params: {invoice_id}}).then(response => {
            const updateDetails = {};
            updateDetails.details = response.data;
            const product_details = updateDetails.details.invoice_all_data;
            product_details.forEach((product, index) => {
                const batch_id = product.batch_id;
                axios.get(config.epharmUrl + "/Cinvoice/retrieve_product_batch_id", {params: {batch_id}}).then(response => {
                    const batch = response.data;
                    product.expire_date = batch.expire_date;
                    product.total_product = batch.total_product;
                }).catch(error => {
                })
            });
            dispatch(invoiceUpdate(updateDetails));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function getPreviousBalance(customer_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cinvoice/previous", {params: {customer_id}}).then(res => {
            const previous = res.data;
            const data = !previous ? {} : previous;
            const previous_amount = data.balance ? data.balance : 0;
            const credit_amount = data.credit_limit ? data.credit_limit : 0;
            if(previous_amount >= credit_amount){
                dispatch(infoSnackbar('Customer has reached their credit limit'));
                dispatch(getPrevious(previous));
            }else {
                dispatch(getPrevious(previous));
            }

        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function retrieveGuiPosData() {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Cinvoice/gui_pos", null, configuration).then(response => {
            const data = response.data;
            const todaysInvoice = !data.todays_invoice ? [] : data.todays_invoice;
            const total_amount = todaysInvoice.reduce((a, b) => {
                return a + b.total_amount
            },0)
            data.total_invoice_amount = total_amount;
            const itemList = response.data.itemlist;
            dispatch(retriveGuiPos(data));
            dispatch(getGuiList(itemList));
        }).catch(error => {
            errorMessages(error,dispatch, null)
        })
    }

}

export function retrieveGuiInvoiceItems(product_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cinvoice/gui_pos_invoice", {parmas: {product_id}}).then(response => {
            const guiItems = response.data;
            dispatch(retrieveGuiItems(guiItems));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }
}

export function getGuiItemList(product_name, category_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cinvoice/getitemlist", {
            params: {
                product_name,
                category_id
            }
        }).then(response => {
            const data = response.data.itemlist;
            dispatch(getGuiList(data))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function getAllInvoices(formData) {
    return function (dispatch) {
        dispatch(getInvoicesRequest());
        axios.post(config.epharmUrl + "/Cinvoice/CheckInvoiceList", formData, configuration).then(response => {
            if (response) {
                const invoices = response.data.aaData;
                if (invoices) {
                    invoices.sort((a, b) => {
                        return new Date(b.date) - new Date(a.date)
                    });
                }
                dispatch(retrieveInvoices(invoices));
            }
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }
}

export function getProductBatchInfo(batch_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cinvoice/retrieve_product_batch_id", {params: {batch_id}}).then(response => {
            const batch = response.data;
            dispatch(retrieveBatchInfo(batch))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function getProductInfo(product_id) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Cinvoice/retrieve_product_data_inv", null, {params: {product_id}}).then(response => {
            const product = response.data;
            dispatch(retrieveProductInv(product));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function createPosInvoice(formData) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Cinvoice/insert_invoice", formData, configuration).then(response => {
            if (response.data) {
                dispatch(successSnackbar("Invoice saved successfully!"));
                history.push('/manageinvoice');
            } else {
                dispatch(errorSnackbar("Unable to save invoice. A network related error occurred while connecting to the server"));
            }

        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function getDrugs(product_name) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cinvoice/autocompleteproductsearch", {params: {product_name}}).then(response => {
            const drugs = response.data;
            const dt = !drugs ? [] :  drugs;
            dispatch(retrieveDrugs(dt));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function fetchCustomers(customer_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cinvoice/customer_autocomplete", {params: {customer_id}}).then(response => {
            const customers = response.data;
            const dt = !customers ? [] : customers;
            dispatch(getCustomers(dt))
        }).catch(error => {
            errorMessages(error,dispatch,null)
        })
    }
}

export function uploadInvoiceCsv(formData) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Cinvoice/uploadCsv_Invoice", formData, configuration).then(response => {
            dispatch(successSnackbar('Upload Successful'))
        }).catch(error => {
           errorMessages(error, dispatch, null)

        })

    }

}
import React, {useRef} from "react";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {ReusablePeriodFilter} from "../../Utils/EpharmHeadings/ReusablePeriodFilter";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useExpenses} from "./CustomHooks/useExpenses";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions'
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CSVLink} from "react-csv";
import {printFunctionality} from "../../Utils/printFunctionality";
import {printStyles} from "./Reports/ReusableReportHeader";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";

const headData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'receiptno', numeric: false, disablePadding: false, label: 'Receipt No.'},
    {id: 'expense', numeric: false, disablePadding: false, label: 'Expense'},
    {id: 'Amount', numeric: false, disablePadding: false, label: 'Amount'}
];

const Expenses = ({actions, snackbars}) => {
    const {
        openDialog, handleCloseDialog, handleOpenDialog, period, handleChangePeriod, inputType, date,
        handleChangeDate, year, handleChangeYear, expenses, handleSubmitExpense, handleChangeExpenseType,
        expenseType, expenseTypes, state, handleChange, closeSnackbar, submitted, isSubmitted, headers, csvRef,
        page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, loading, downloadPDF,
        openExpense,submitNewExpense,new_expense,handleChangeNewExpense,handleOpenExpense,
        handleCloseExpense, submitExpense, isSubmitExpense
    } = useExpenses(actions);
    const printRef = useRef();
    const {from_date, to_date} = date
    const {open, variant, message} = snackbars;
    const {receipt_no, amount} = state
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Expenses" heading="Accounting"
                                     subHeading="Expenses" link="/expenses"/>
            <ReusablePeriodFilter period={period} handleChangePeriod={handleChangePeriod} inputType={inputType}
                                  from_date={from_date} to_date={to_date} handleChangeDate={handleChangeDate}
                                  year={year}
                                  handleChangeYear={handleChangeYear} handleFilter={() => {
            }}>
                <div className='text-right'>
                    <button onClick={handleOpenDialog} className='btn btn-sm pharmacy-info-btn mr-2 mb-2'>Record Expense</button>
                    <button onClick={handleOpenExpense} className='btn btn-sm pharmacy-info-btn mb-2 mr-2'>New Expense</button>
                </div>
            </ReusablePeriodFilter>
            <ReusableDialog openDialog={openExpense} isSubmitted={isSubmitExpense} handleSubmit={submitNewExpense}
                            handleClose={handleCloseExpense} message='New Expense'>
                <Form.Group as={Row}>
                    <Label name='Expense' type column sm={2}/>
                    <Col sm={8}>
                        <TextField type='text' value={new_expense} name='new_expense' onChange={handleChangeNewExpense}/>
                        {submitExpense && !new_expense ? <div className='invalid-text'>Expense is required</div> : null}
                    </Col>
                </Form.Group>
            </ReusableDialog>
            <ReusableDialog handleSubmit={handleSubmitExpense} openDialog={openDialog}
                            handleClose={handleCloseDialog} message='Record Expense' isSubmitted={isSubmitted}>
                <Form.Group as={Row}>
                    <Label name='Expense' type column sm={3} style={{textAlign: 'right'}}/>
                    <Col sm={7}>
                        <CustomSelect value={expenseType} onChange={handleChangeExpenseType} options={expenseTypes}/>
                        {submitted && !expenseType ? <div className='invalid-text'>Expense is required</div> : null}
                    </Col>
                    <Col sm={1}>
                        <button type='button' className='btn btn-sm pharmacy-btn-outline' onClick={handleOpenExpense}>
                            <FontAwesomeIcon icon={faPlus}/></button>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label name='Receipt No.' type column sm={3} style={{textAlign: 'right'}}/>
                    <Col sm={8}>
                        <TextField type='text' value={receipt_no} name='receipt_no' onChange={handleChange}/>
                        {submitted && !receipt_no ? <div className='invalid-text'>Receipt No. is required</div> : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Label name='Amount' type column sm={3} style={{textAlign: 'right'}}/>
                    <Col sm={8}>
                        <TextField type='number' value={amount} name='amount' onChange={handleChange}/>
                        {submitted && !amount ? <div className='invalid-text'>Amount is required</div> : null}
                    </Col>
                </Form.Group>
            </ReusableDialog>
            <div className="mui-tables">
                <div className="text-right">
                    <div className='btn-group  mb-2'>
                        <button className='btn btn-sm pharmacy-grey-btn'
                                onClick={() => csvRef.current.link.click()}>CSV
                        </button>
                        <button className='btn btn-sm pharmacy-grey-btn' onClick={downloadPDF}>PDF</button>
                        <button className='btn btn-sm pharmacy-grey-btn'
                                onClick={() => printFunctionality(printRef.current, printStyles())}>Print
                        </button>
                    </div>
                </div>
                <CSVLink
                    data={expenses}
                    filename={`Expenses.csv`}
                    className="hidden"
                    headers={headers}
                    ref={csvRef}
                    target="_blank"
                />
            </div>
            <PharmacySnackbar handleCloseBar={closeSnackbar} variant={variant} open={open} message={message}/>
            <MuiPaper>
                <EnhancedTableToolbar title='Expenses'/>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="4" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader"
                                                                       style={{width: '5%'}}/></TableCell>
                        </TableRow></TableBody> : expenses.length > 0 ?
                            <TableBody>
                                {expenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((d, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={d.expense_id}
                                                className='mui-table-row'>
                                                <TableCell component="th" scope="row" padding="default"
                                                           className='mui-table-cell'>
                                                    {d.date}
                                                </TableCell>
                                                <TableCell>
                                                    {d.receipt_no}
                                                </TableCell>
                                                <TableCell>
                                                    {d.expense_type_name}
                                                </TableCell>
                                                <TableCell>
                                                    {d.amount}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            : <TableBody><TableRow>
                                <TableCell colSpan="4" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                </TableContainer>
                <MuiPagination data={expenses} rowsPerPage={rowsPerPage} page={page}
                               handleChangeRowsPerPage={handleChangeRowsPerPage}
                               handleChangePage={handleChangePage} rows={[10, 20, 30, 40, 50]} colSpan={3}/>
            </MuiPaper>
            <div ref={printRef} style={{display: 'none'}}>
                <table className='table table-borderless profile-table  table-sm'>
                    <thead>
                    <tr>
                        <th align='left'>Date</th>
                        <th align='left'>Receipt No.</th>
                        <th align='left'>Expense</th>
                        <th align='left'>Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    {expenses.map(item => (
                        <tr key={item.expense_id}>
                            <td>{item.date}</td>
                            <td>{item.receipt_no}</td>
                            <td>{item.expense_type_name}</td>
                            <td>{item.amount}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(Expenses)
import React, {Component} from 'react';
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";

const style = {
    maxWidth:'1000px',
    width:'90%',
    margin:'0 auto'
}

function ProductstrendsGraph(props) {
    const {products_data, product, sort, handleClickSort, from_date,to_date} = props
    return (
        <div style={style}>
            {products_data.length < 1 ?
                <div style={{textAlign: 'center', marginTop: '100px'}}><span style={{fontSize: '0.895rem'}}>{
                  product ? 'Please select period' : 'Please Select a Product'}</span>
                </div> :
                <div className='sort-by mt-2'>
                    <div className="row">
                        <div className="col-md-3"/>
                        <div className="col-md-5">
                            <div className="text-center">
                                <Form.Group as={Row}>
                                    <Label name='Sort by:' column sm={3} style={{textAlign:'right'}}/>
                                    <Col sm={6}>
                                        <SelectInput onChange={handleClickSort} value={sort}
                                                     options={[{value: 'amount', text: 'Amount'}, {
                                                         value: 'quantity',
                                                         text: 'Quantity'
                                                     }]}/>
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-md-4"/>
                    </div>
                    <LineChart width={900} height={350} data={products_data}
                               margin={{top: 5, right: 30, left: 20, bottom: 5,}}>
                        <CartesianGrid strokeDasharray="2 2"/>
                        <XAxis dataKey="date"/>
                        <YAxis dataKey={sort}/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="monotone" dataKey={sort} stroke="#8884d8" activeDot={{r: 8}}/>

                    </LineChart></div>}
        </div>
    );

}

export default ProductstrendsGraph;
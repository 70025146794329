import React,{useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {convertGmtTime} from "../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../Helpers/config.json";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    h6:{
        fontSize:'14px'
    }
});

export function CustomerRow({row}) {
    const classes = useRowStyles();
    const [invoiceDetails,setInvoice] = useState([])

    const [open, setOpen] = useState(false)

    const handleOpen = () =>{
        setOpen(!open)
        axios.get(`${config.epharmUrl}/Cinvoice/invoice_inserted_data`, {params: {invoice_id:row.invoice_id}}).then(response => {
            const data = response.data;
            const dt = !data ? {}: data
            const inv = dt.invoice_all_data ? dt.invoice_all_data : []
            setInvoice(inv)
        }).catch(() => {
            // errorMessages(error, null, actions)
        })
    
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell component="th" scope="row">
                    {convertGmtTime(row.date)}
                </TableCell>
                <TableCell>{row.invoice}</TableCell>
                <TableCell>{row.total_amount}</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div" classes={{h6:classes.h6}}>
                                Purchased Items
                            </Typography>
                            <table className="table-sm table table-bordered mpeke-customer-table">
                                <thead>
                                <tr>
                                    <td>Product Name</td>
                                    <td>Quantity</td>
                                    <td>Amount</td>
                                </tr>
                                </thead>
                                <tbody>
                                {invoiceDetails.map(item=>(
                                <tr key={item.product_id}>
                                    <td>{item.product_name}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.total_price}</td>
                                </tr>
                                    ))}
                              
                                </tbody>
                            </table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
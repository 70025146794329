import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";



const MainDateWiseReport = (props) =>{
    const {dateWise, timeWise, type} = props;
    return (
        <div>
        <DashboardHeader attendance dateWise={dateWise} timeWise={timeWise}/>
            <div className="card styled-report-card p-3">
                <hr/>
                <Form autoComplete="off">
                    {timeWise && <Form.Group as={Row}>
                        <Form.Label column sm={2}>Report Date</Form.Label>
                        <Col sm={8}>
                            <Form.Control type="date"/>
                        </Col>
                    </Form.Group>}
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>{dateWise ? "Start Date" :"Start Time"}</Form.Label>
                        <Col sm={8}>
                            <Form.Control type={type}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>{dateWise ? "End Date" : "End Date"}</Form.Label>
                        <Col sm={8}>
                            <Form.Control type={type}/>
                        </Col>
                    </Form.Group>
                    <div className="text-center">
                        <button className="btn pharmacy-info-btn px-4">Search</button>
                    </div>
                </Form>
            </div>
            </div>
    )
};
export default MainDateWiseReport;
import * as types from "../Actions/actionTypes";

export default function alertReducer (state={},action){
    switch (action.type) {
        case types.ALERT_SUCCESS:
            return {...state, type:"alert-success",message:action.message};
        case types.ALERT_ERROR:
            return {...state, type: "alert-danger", message: action.message};
        case types.ALERT_CLEAR:
            return {};
        default:
            return state;
    }

};
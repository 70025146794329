import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import config from "../../Helpers/config";
import axios from "axios";
import {history} from '../../Helpers/history';
import {errorMessages} from "../../Helpers/ErrorMessages";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Card from "@material-ui/core/Card";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import {convertDate} from "../../Utils/titleCaseFunction";
import Textarea from "../../Utils/FormInputs/Textarea";
import InvoicePrintDialog from "../Invoice/InvoicePrintDialog";


const CustomerReturnInvoice = ({actions, snackbars, match: {params}}) => {
    const {invoice_no} = params;
    const [rows, setRows] = useState([{
        product_name: '', product_id: '', quantity: '', product_quantity: '',
        rate: 0, total: 0, invoice_detail_id: '', rtn: false, discount: ''
    }]);
    const [data, setData] = useState({});
    const [totals, setTotals] = useState({total_discount: 0, grand_total_price: 0});
    const [state, setState] = useState({customer_name: '', date: '', customer_id: '', invoice_id: ''});
    const [details, setDetails] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [receipt_no, setReceiptNo] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const formData = new FormData();
        formData.append('invoice_no', invoice_no);
        axios.post(`${config.epharmUrl}/Creturn/invoice_return_form`, formData).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            setData(dt)
        }).catch((err) => {
            errorMessages(err, null, actions)
        })
    }, [actions, invoice_no])

    useEffect(() => {
        const {invoice_all_data, customer_name, invoice_id, customer_id, date} = data;
        const invoiceArr = invoice_all_data ?? []
        const arr = invoiceArr.map(item => ({
            ...item, product_quantity: '', total: 0,
            rtn: false, discount: ''
        }))
        setState({
            customer_name: customer_name ?? '',
            invoice_id: invoice_id ?? '',
            customer_id: customer_id ?? '',
            date: date ?? ''
        })
        setRows(arr)
    }, [data])

    const handleChangeDetails = event => {
        setDetails(event.target.value)
    };

    const calculateTotal = (item) => {
        return +(item.product_quantity) * +item.rate;
    };

    const calculateDiscount = (item) => {
        let discount_value = !item.discount ? 0 : item.discount;
        let val = +(discount_value) / 100;
        return calculateTotal(item) * val;
    };

    const calculateTotalAmount = (arr) => {
        let total_discount = arr.reduce((acc, row) => {
            const discount = !row.discount ? 0 : row.discount;
            const product_quantity = !row.product_quantity ? 0 : row.product_quantity;
            const total = product_quantity * row.rate;
            const val = total * discount / 100;
            return acc + val
        }, 0);
        let grand_total_price = arr.reduce((acc, row) => {
            const dt = row.total ? row.total : 0
            return acc + dt;
        }, 0);
        setTotals({total_discount, grand_total_price});
    };

    const calculateFinalTotal = (item) => {
        return calculateTotal(item) - calculateDiscount(item);
    };


    const changeCheckValue = (e, invoice_detail_id) => {
        const {checked} = e.target;
        const arr = rows.map(item => {
            if (item.invoice_detail_id === invoice_detail_id) {
                return {...item, rtn: checked, total: calculateFinalTotal(item)}
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };


    const handleChangeQuantity = (event, invoice_detail_id) => {
        const {value} = event.target;
        const arr = rows.map(item => {
            if (item.invoice_detail_id === invoice_detail_id) {
                return {
                    ...item,
                    product_quantity: value,
                    total: calculateFinalTotal({
                        rate: item.rate, product_quantity: value, discount: item.discount
                    })
                }
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };

    const handleChangeDiscount = (event, invoice_detail_id) => {
        const {value} = event.target;
        const arr = rows.map(item => {
            if (item.invoice_detail_id === invoice_detail_id) {
                return {
                    ...item,
                    discount: value,
                    total: calculateFinalTotal({
                        rate: item.rate,
                        product_quantity: item.product_quantity,
                        discount: value
                    })
                }
            }
            return item
        })
        calculateTotalAmount(arr);
        setRows(arr)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleCancel = () =>{
        history.push('/stockreturnlist')
    }

    const handleOpenReceipt = () =>{
        history.push(`/stockreturndetails/${receipt_no}`)
    }


    const handleSubmitReturn = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const {invoice_id, customer_id, date} = state
        const {total_discount, grand_total_price} = totals;
        formData.append('invoice_id', invoice_id);
        formData.append('customer_id', customer_id);
        formData.append('invoice_date', date);
        formData.append('payment_type', '');
        rows.filter(item => item.rtn).forEach(item => {
            formData.append('product_id', item.product_id);
            formData.append('sold_qty', +(item.quantity));
            formData.append('product_quantity', +(!item.product_quantity ? 0 : item.product_quantity));
            formData.append('product_rate', item.rate);
            formData.append('total_price', item.total);
            formData.append('discount', +(item.discount));
            formData.append('batch_id', item.batch_id);
            formData.append('tax', 0);
        })
        formData.append('total_discount', total_discount);
        formData.append('details', details);
        formData.append('total_tax', 0);
        formData.append('grand_total_price', grand_total_price);
        const checked = rows.some(item => item.rtn);
        const required = rows.filter(item => item.rtn).every(row => row.product_quantity && row.product_quantity <= row.quantity)
        setSubmitted(true)
        if (checked && required) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Creturn/return_invoice`, formData).then(response => {
                const data = response.data;
                const dt = data ?? '';
                setIsSubmitted('resolved')
                actions.snackbarActions.successSnackbar('Product(s) successfully returned')
                setReceiptNo(dt)
                setOpenDialog(true)
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions)
            });
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }
    const {message, variant, open} = snackbars;
    const {customer_name, date} = state;
    const {total_discount, grand_total_price} = totals;
    const disabled = rows?.some(item => item.rtn);
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Return to Customer' heading="Return"
                                     subHeading='Return to Customer' link={history.location.pathname}/>
            <Card className="p-3">
                <InvoicePrintDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog}
                                    print={handleOpenReceipt} cancel={handleCancel} message="Receipt" text='receipt'/>
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={closeSnackbar}/>
                <form onSubmit={handleSubmitReturn} autoComplete='off'>
                    <div className="row">
                        <div className="col-md-5">
                            <Form.Group as={Row}>
                                <Label column sm={3} name="Customer Name"/>
                                <Col sm={6}>
                                    <TextField type="text" value={customer_name} readOnly/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Label column sm={3} name='Date'/>
                                <Col sm={6}>
                                    <TextField type="text" value={convertDate(date)} readOnly/>
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="col-md-7"/>
                    </div>

                    <div className="">
                        <table className="table table-bordered table-sm custom-bordered-table">
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th>Sold Quantity</th>
                                <th>Return Quantity<span className='invalid-text'>*</span></th>
                                <th>Unit Price</th>
                                <th>Discount &#37;</th>
                                <th>Total</th>
                                <th>Check Return</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows.map((row) => (
                                <tr key={row.invoice_detail_id}>
                                    <td>
                                        {row.product_name}
                                    </td>
                                    <td>
                                        {row.quantity}
                                    </td>
                                    <td>
                                        <TextField type="number" value={row.product_quantity} min="0"
                                                   onChange={(e) => handleChangeQuantity(e, row.invoice_detail_id)}/>
                                        {submitted && !row.product_quantity ?
                                            <div className="invalid-text">Quantity is required</div> :
                                            (row.product_quantity && row.product_quantity > row.quantity) ?
                                                <div className="invalid-text">You can only return up
                                                    to {row.quantity}</div> : null}
                                    </td>
                                    <td>
                                        {row.rate}
                                    </td>
                                    <td>
                                        <TextField type="number" value={row.discount}
                                                   name="discount" min="0"
                                                   onChange={(e) => (handleChangeDiscount(e, row.invoice_detail_id))}/>

                                    </td>
                                    <td>
                                        {row.total}
                                    </td>
                                    <td>
                                        <Form.Check type="checkbox"
                                                    vlaue={row.rtn}
                                                    name="rtn"
                                                    onChange={(e) => changeCheckValue(e, row.invoice_detail_id)}
                                                    style={{transform: "scale(1)"}}
                                        />
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={4} rowSpan={2}>
                                    <Textarea name="details" value={details}
                                              onChange={handleChangeDetails}/>
                                </td>
                                <td className="text-right">
                                    Total Deduction:
                                </td>
                                <td>
                                    {total_discount}
                                </td>
                                <td/>
                            </tr>
                            <tr>
                                <td className="text-right">
                                    Total Return
                                </td>
                                <td>
                                    {grand_total_price}
                                </td>
                                <td/>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <button disabled={!(isSubmitted === 'pending' || disabled)}
                            className="float-right btn pharmacy-btn btn-sm">{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>
                </form>
            </Card>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReturnInvoice);
import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from '../../../Helpers/config.json';
import {useDates} from "./useDates";
import {convertDate} from "../../../Utils/titleCaseFunction";

export const useFetchData = (actions, url) => {
    const CURRENT_YEAR = new Date().getFullYear();
    const [data, setData] = useState([]);
    const [date, handleChangeDate, setDate] = useDates();
    const [period, setPeriod] = useState('today');
    const [year, setYear] = useState(CURRENT_YEAR);
    const [loading, setLoading] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0)

    const {from_date, to_date} = date;
    const inputType = {
        'weekly': 'week',
        'monthly': 'month',
        'custom': 'date'
    }

    const calculateSum = (arr) =>{
        let sum = arr.reduce((a, b)=>{
            return a + b
        },0)
        setTotalAmount(sum)
    }

    useEffect(() => {
        // const formData = new FormData();
        // formData.append('from_date', period === 'yearly' ? year : period === 'today' ? convertDate() : from_date);
        // formData.append('to_date', to_date)
        setLoading(true)
        axios.get(`${config.epharmUrl}/accounting/reports/${url}`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : [];
            const d = arr.map(item=>item.parent_accounts.map(parent=>parent.sub_accounts.map(sub=>[sub.resulting_amount])))
            setData(arr)
            const sumArr = d.flat(3)
            calculateSum(sumArr)
            setLoading(false)
        }).catch(err => {
            // errorMessages(err, null, actions)
            setLoading(false)
        })

    }, [url])

    const handleChangePeriod = (event) => {
        const {value} = event.target;
        setPeriod(value);
        setDate({from_date: '', to_date: ''})

    }

    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    return {
        date, data, handleChangeDate, closeSnackbar, period,
        handleChangePeriod, inputType, year, handleChangeYear, loading, totalAmount
    }
}
import jsPDF from "jspdf";
import "jspdf-autotable";
import companyAddress from '../Helpers/companyAddress.json'


export const exportPDF = (heading, column_headers, information, download_title, footer) => {
    const {company_name, address1, address2, mobile, email, website} = companyAddress
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 3;
    const doc = new jsPDF(orientation, unit, size);
    const title = heading;
    doc.setFontSize(11);
    let content = {
        startY: 90,
        margin: 3,
        head: column_headers,
        headStyles:{fontStyle: 'bold',fontSize:10},
        body: information,
        bodyStyles:{fontSize:10},
        didDrawPage: function (data) {
            // doc.text(`${company_name}\n${address1}\n${address2}\n${mobile}\n${email}\n${website ? website : ''}`,400,50)
        },
        foot: !footer ? [[]] : footer,
        showFoot: 'lastPage',
        footStyles: {fillColor: '#fff', textColor: '#000000DE', fontStyle: 'normal'},
    };

    doc.text(title, marginLeft,83);
    doc.setFontSize(11);
    doc.setFontType('normal')
    doc.autoTable(content);
    doc.save(download_title);
};

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import {makeStyles} from '@material-ui/core/styles';
import ReusableList from "../ReusableList";
import {JournalSelect} from "../ReusableJournalTable";
import TextField from "../../../Utils/FormInputs/TextField";
import {titleCase} from "../../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";

export  const useRowStyles = makeStyles(() => ({
    item: {
        borderRight: '1px solid #c7c6c7',
        borderLeft: '1px solid #c7c6c7',
        height: '100vh',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    },
    rounded: {
        borderRadius: 0
    },
    body1: {
        fontSize: '0.98em'
    },
    body2: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.85em'
    },


}));
const ReusableSalesTemplate = ({search, handleSearch, placeholder,filteredList, inputValue,handleChangeInput,
                                   handleClick, itemList,icon,  children,options=[],secondaryText}) => {
    const classes = useRowStyles()
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Debtors Invoices" heading="Accounting"
                                     subHeading="Debtors invoices" link="/accounting/invoicereportdetails/1"/>
            <Card elevation={0} classes={{root: classes.rounded}}>
                <Grid container spacing={0}>
                    <Grid item xs={6} md={4} classes={{item: classes.item}}>
                        <div className="top-bar all-journals">
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    {options.length > 0 ? <JournalSelect journal={inputValue} handleChange={handleChangeInput}>
                                        {options.map((opt)=>(
                                            <option key={opt.value} value={opt.value}>{opt.text}</option>
                                        ))}
                                    </JournalSelect>:null}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField type='text' value={titleCase(search)} name='search'
                                               onChange={handleSearch}
                                               placeholder={placeholder}/>
                                </Grid>
                            </Grid>

                        </div>
                        {filteredList(itemList).map(item => (
                            <ReusableList key={item.id} primaryText={item.text} icon={icon}
                                           onClick={() => handleClick(item)} secondaryText={secondaryText}/>
                        ))}

                    </Grid>
                    <Grid item xs={6} md={8}>
                        {children}
                    </Grid>
                </Grid>
            </Card>

        </div>
    )
}
export default ReusableSalesTemplate
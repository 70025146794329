import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions,DialogTitle,DialogContent} from "./Dialog/pharmacyDialog";


const DeactivateDialog = ({openDialog, handleClose,deactivate, message='', title=''}) =>{
    return(
        <Dialog open={openDialog}>
            <DialogTitle onClose={handleClose}>
                <div className="text-center">
                    {title} Customer
                </div>
            </DialogTitle>
            <DialogContent>
                <h6>{`Are you sure you want to ${message} this Customer?`}</h6>
            </DialogContent>
            <DialogActions>
                <button className="btn pharmacy-btn-dismiss"
                        onClick={deactivate}>Yes</button>
                <button className="btn pharmacy-grey-btn" onClick={handleClose}>No</button>
            </DialogActions>
        </Dialog>
    )

};
export default DeactivateDialog;
import React from "react";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const EditSalarySetUp = () =>{
    return(
        <div>
        <DashboardHeader payroll editSetup/>
        <div className="card p-3 styled-table-card">
            <h6 className="heading-text">Edit Salary SetUp</h6>
            <hr/>
            <Form autoComplete="off">
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Employee Name<span className="text-danger asterics">*</span></Form.Label>
                    <Col sm={9}>
                        <Select/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Salary Type<span className="text-danger asterics">*</span></Form.Label>
                    <Col sm={9}>
                        <Form.Control type="text" disabled={true}/>
                    </Col>
                </Form.Group>
                <div className="row  py-3">
                    <div className="col">
                        <h6 className="text-center mb-4 underline-text">Addition</h6>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Basic</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" disabled={true}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Health (%)</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>House Rent (%)</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" />
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col">
                        <h6 className="text-center mb-4 underline-text">Deduction</h6>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>PF (%)</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Tax (%)</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" />
                                <div className="text-right">
                                    <Form.Group >
                                        <Form.Check type="checkbox" label="Tax Manager" />
                                    </Form.Group>
                                </div>
                            </Col>
                        </Form.Group>

                    </div>
                </div>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Gross Salary</Form.Label>
                    <Col sm={9}>
                        <Form.Control type="text" disabled={true}/>
                    </Col>
                </Form.Group>
                <div className="text-center my-4">
                    <button className="btn pharmacy-btn px-4">Update</button>
                </div>
            </Form>
        </div>
        </div>
    )
};
export default EditSalarySetUp
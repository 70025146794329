import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import * as steppersActions from '../../Redux/Actions/steppersActions';
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {useSetUpTransactionTypes} from "./CustomHooks/useSetUpTransactionTypes";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Grid from "@material-ui/core/Grid";
import ReusableStepper from "../../Utils/Snackbars/ReusableSteppers";
import {useAccountingSetUp} from "./CustomHooks/useAccountingSetUp";
import {TransactionsTable} from "./TransactionTypes";
import PrintDialog from "../../Utils/Dialog/PrintDialog";
import Card from "@material-ui/core/Card";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";

const AddDefaultTypes = ({
                             handleSubmit, transactions, types, handleChangeType,
                             submitted, journals, handleChangeDebit, handleChangeCredit, isSubmitted
                         }) => {
    return (
        <form onSubmit={handleSubmit}>
            <table className='table table-sm table-bordered'>
                <thead>
                <th>Transaction category</th>
                <th>Transaction type<span className='help-block'>*</span></th>
                <th>Debit ledger<span className='help-block'>*</span></th>
                <th>Credit ledger<span className='help-block'>*</span></th>
                </thead>
                <tbody>
                {transactions.map(item => (
                    <tr key={item.id}>
                        <td>{item.category_name}</td>
                        <td>
                            <CustomSelect value={item.transaction_type} options={types}
                                          onChange={(value) => handleChangeType(value, item.id)}/>
                            {(submitted && !item.transaction_type) &&
                            <div className="help-block">Transaction type is required</div>}
                        </td>
                        <td>
                            <CustomSelect value={item.debit_ledger} options={journals}
                                          onChange={(value) => handleChangeDebit(value, item.id)}/>
                            {(submitted && !item.debit_ledger) &&
                            <div className="help-block">Debit ledger is required</div>}
                        </td>
                        <td>
                            <CustomSelect value={item.credit_ledger} options={journals}
                                          onChange={(value) => handleChangeCredit(value, item.id)}/>
                            {(submitted && !item.credit_ledger) &&
                            <div className="help-block">Credit ledger is required</div>}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="text-center">
                <button type='submit' disabled={isSubmitted === 'pending'} className='btn btn-sm pharmacy-info-btn'>
                    {isSubmitted === 'pending' ? 'Submitting' : 'Submit'}</button>
            </div>
        </form>
    )
}


const AddDefaultTransactionTypes = ({actions, snackbars, steppers}) => {
    const {steps} = useAccountingSetUp()
    const {
        closeSnackbar, transactions, journals, handleChangeType,
        handleChangeCredit, handleChangeDebit, handleSubmit, handleClose,
        submitted, isSubmitted, transactionTypes, handleNext, handleBack, handleOpen, confirmation
    } = useSetUpTransactionTypes(actions)
    const {open, variant, message} = snackbars
    const types = [{value: 'SALES', label: 'SALES'}, {value: 'PURCHASES', label: 'PURCHASES'},
        {value: 'RENT', label: 'RENT'}, {value: 'SALARIES', label: 'SALARIES'}]
    const props = {
        handleSubmit, transactions, types, handleChangeType,
        submitted, journals, handleChangeDebit, handleChangeCredit, isSubmitted
    };
    const {activeStep} = steppers
    return (
        <div>
            <div className='journals'>
                <ReusableDashboardHeader component="Add Transaction Types" heading="Accounting"
                                         subHeading="Add transaction types" link="/accounting/setup/setuptypes"/>
                <PrintDialog message='Confirm default transaction types' text='Please confirm that you have set the right
            transaction types before finishing up the setup'
                             handleClick={handleNext} handleClose={handleClose} openDialog={confirmation}
                             handleCancel={handleClose}
                             next='Finish' preview='Preview'/>
                <div className="general-ledger-header p-0">
                    <Grid container spacing={0}>
                        <Grid item xs={6} md={8}>
                            <ReusableStepper activeStep={activeStep} steps={steps}/>
                        </Grid>
                        <Grid item xs={6} md={4}>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <PharmacySnackbar open={open} handleCloseBar={closeSnackbar} variant={variant} message={message}/>

            {transactionTypes.length > 0 ?
                <div className='mui-tables'>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <div className='mt-3'>
                                <AddDefaultTypes {...props}/>
                            </div>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Card className='p-3' elevation={0}>
                                <TransactionsTable transactionTypes={transactionTypes} text='preview'/>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
                :
                <div className='mt-3'><AddDefaultTypes {...props}/></div>
            }
            <div className="row">
                <div className="col-md-7"/>
                <div className="col-md-5">
                    <div className="text-center">
                        <div className="btn-group">
                            <button type='button' onClick={handleBack} className='btn btn-sm  mt-5'>Back</button>
                            <button type='button' onClick={handleOpen} className='btn btn-sm pharmacy-info-btn mt-5'>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

function mapStateToProps(state) {
    const {snackbars, steppers} = state;
    return {snackbars, steppers}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            steppersActions: bindActionCreators(steppersActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDefaultTransactionTypes);
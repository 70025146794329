import * as types from "../../Actions/actionTypes";

const initialState = {
    suppliers: [],
    supplier: {},
    supplier_id: '',
    ledgerReport: {},
    supplierLedger: {},
    supplierLedgerInfo: {},
    supplier_count:0,
    allSuppliers:[]
};


export default function supplierReducer(state = initialState, action) {
    switch (action.type) {
        case types.MANAGE_SUPPLIER:
            return {...state, suppliers: action.suppliers};
        case types.SUPPLIER_COUNT:
            return {...state, supplier_count: action.supplier_count};
        case types.SUPPLIER_UPDATE_FORM:
            return {...state, supplier: action.supplier};
        case types.DELETE_SUPPLIER:
            return {
                ...state,
                suppliers:state.suppliers.filter(supplier=>supplier.supplier_id !== action.supplier_id)
            };
        case types.SUPPLIER_LEDGER_REPORT:
            return {...state, ledgerReport: action.ledgerReport};
        case types.SUPPLIER_LEDGER:
            return {...state, supplierLedger: action.supplierLedger};
        case types.SUPPLIER_LEDGER_INFo:
            return {...state, supplierLedgerInfo: action.supplierLedgerInfo};
        case types.RETRIEVE_ALL_SUPPLIERS:
            return {...state, allSuppliers: action.allSuppliers};
        case types.SUPPLY_SUCCESS:
            return{
                submitting:false
            };
        case types.SUPPLY_ERROR:
            return{
                submitting:false
            };
        case types.SUPPLY_REQUEST:
            return{
                submitting:true
            };
        case types.EDITSUPPLY_SUCCESS:
            return{
                submitting:false
            };
        case types.EDITSUPPLY_ERROR:
            return{
                submitting:false
            };
        case types.EDITSUPPLY_REQUEST:
            return{
                submitting:true
            };
        default:
            return state;

    }

}
import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import MainCustomerDetails from "./MainCustomerDetails";
import {dateStringConvert, titleCase} from "../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";

const CustomerDetails = ({actions, snackbars, match: {params}}) => {
    const {customer_id} = params;
    const [customer, setCustomer] = useState({});
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Ccustomer/customer_ledger/${customer_id}`).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            setCustomer(dt)
            setLoading(false)
        }).catch((error) => {
            errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [customer_id, actions])

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const {open, variant, message} = snackbars;
    const customerLedger = !customer ? {} : customer
    const invoice_information = !customerLedger.invoice_info ? [] : customerLedger.invoice_info;
    const headData = ["Date", "Receipt No", "Description", "Amount"]
    const tableData = (
        <tbody>
        {invoice_information.map((invoice, index) => {
            return (
                <tr key={index}>
                    <td>{dateStringConvert(invoice.date)}</td>
                    <td>{invoice.invoice_no}</td>
                    <td>{titleCase(invoice.description)}</td>
                    <td>{invoice.amount}</td>
                </tr>)
        })}
        <tr>
            <td colSpan={3} className="text-right">Total:</td>
            <td>{customer.invoice_amount}</td>
        </tr>
        </tbody>
    )
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Customer Ledger' heading="Customer"
                                     subHeading='Customer Ledger' link={history.location.pathname}/>
            <PharmacySnackbar open={open} variant={variant} message={message} handleCloseBar={handleCloseBar}/>
            <MainCustomerDetails customer={customer} data={invoice_information}
                                 headData={headData} tableData={tableData}
                                 loading={loading} title='Customer Ledger'/>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Table from "@material-ui/core/Table";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Workbook from "react-excel-workbook";
import {titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import DeleteDialog from "../../Utils/DeleteDialog";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import {useMedicineCategory} from "./CustomHooks/useMedicineCategory";
import {CSVLink} from "react-csv";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Category Name'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];

const MedicineCategory = ({actions, snackbars}) => {
    const {
        category_name,  openDialog, openDelete, categories, submitted, isSubmitted,
        loading,  page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleChange,
        handleSubmitCategory, handleOpenDelete, handleCloseDelete, deleteCategory, handleOpenDialog,
        handleCloseDialog, handleCloseSnackbar, downloadPDF, copyTable, componentRef,
        term, handleSearch
    } = useMedicineCategory(actions)
    const {open, message, variant} = snackbars;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Medicine Categories' heading="Medicine"
                                     subHeading='Medicine Categories' link='/managecategory'/>
            <div className="general-ledger-header">
                <div className="row">
                    <div className="col-md-6">
                        <h6 className='mt-2 mb-0'>Medicine Categories</h6>
                    </div>
                    <div className="col-md-6">
                        <div className="text-right">
                            <button type='button' onClick={handleOpenDialog}
                                    className='btn btn-sm pharmacy-info-btn'>Add Category
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseSnackbar}/>
            <DeleteDialog handleClose={handleCloseDelete} openDialog={openDelete} message='category'
                          handleDelete={deleteCategory}/>
            <ReusableDialog message='New Category' openDialog={openDialog}
                            handleSubmit={handleSubmitCategory} isSubmitted={isSubmitted}
                            handleClose={handleCloseDialog}
                            width='sm'>
                <Form.Group as={Row}>
                    <Label column sm="3" type name='Category Name'/>
                    <Col sm="7">
                        <TextField type="text" value={category_name} name="category_name"
                                   onChange={handleChange}/>
                        {submitted && !category_name ? <div>Category name is required</div> : null}
                    </Col>
                </Form.Group>
            </ReusableDialog>
            <div className="mui-tables">
                <MuiPaper>
                    <EnhancedTableToolbar title={''}>
                        <span className="btn-group" style={{flex: '1 1 100%'}}>
                                  <CSVLink
                                      data={categories}
                                      filename="Medicine Categories.csv"
                                      target="_blank"
                                  >
                            <button className="btn btn-sm pharmacy-btn mr-1">CSV</button>
                        </CSVLink>
                        <Workbook filename="Categories.xlsx" element={
                            <span><button className="btn pharmacy-btn btn-sm mr-1">Excel</button></span>
                        }>
                            <Workbook.Sheet data={categories} name="Product Categories">
                                <Workbook.Column label="SL" value="sl"/>
                                <Workbook.Column label="Category Name" value="category_name"/>
                            </Workbook.Sheet>

                        </Workbook>
                        <span><button className="btn pharmacy-btn mr-1 btn-sm"
                                      onClick={downloadPDF}>Pdf</button></span>
                        </span>
                        <Form.Control type="text"
                                      onChange={event => handleSearch(event.target.value)}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='small'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="3" align="center"><img src="/images/listPreloader.gif"
                                                                           alt="loader"
                                                                           style={{width: '5%'}}/></TableCell>
                            </TableRow></TableBody> : categories.length > 0 ?
                                <FilterResults
                                    value={term}
                                    data={categories}
                                    renderResults={results => (
                                        <TableBody>
                                            {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((info, index) => {
                                                    const count = index += 1;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={info.category_id}
                                                            className='mui-table-row'>
                                                            <TableCell component="th" scope="row"
                                                                       padding="default"
                                                                       className='mui-table-cell'>
                                                                <span>{count}</span>
                                                            </TableCell>
                                                            <TableCell>{titleCase(info.category_name)}</TableCell>
                                                            <TableCell id="mainbuttons">
                                                                <Tooltip title="Update" placement="left-start">
                                                                    <Link to={{
                                                                        pathname: `/category_update/${info.category_id}`
                                                                    }}>
                                                                        <button
                                                                            className="btn btn-sm pharmacy-info-btn mr-3">
                                                                            <FontAwesomeIcon
                                                                                icon={faPencilAlt}/></button>
                                                                    </Link>
                                                                </Tooltip>
                                                                <Tooltip title="Delete" placement="left-start">
                                                                    <button
                                                                        className="btn btn-sm pharmacy-btn-dismiss"
                                                                        onClick={() => handleOpenDelete(info.category_id)}>
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}/></button>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    )}
                                />
                                : <TableBody><TableRow>
                                    <TableCell colSpan="3" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
                    </TableContainer>
                    <MuiPagination data={categories} rowsPerPage={rowsPerPage} page={page}
                                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                                   handleChangePage={handleChangePage} rows={[10, 20, 30, 50, 100]} colSpan={3}/>
                </MuiPaper>
            </div>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicineCategory);
import React, {Component} from 'react';
import MainSupplierLedger from "./MainSupplierLedger";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as supplierActions from "../../Redux/Actions/SupplierActions/SupplierActions";
import {convertDate} from "../../Utils/titleCaseFunction";


class SupplierLedgerInfo extends Component {

    constructor() {
        super();
        this.state = {
            supplier_value:'',
            from_date:convertDate,
            to_date:convertDate(),
            openLedger:false
        }
    }


    componentDidMount() {
        const {supplier_id} = this.props.match.params;
        const {actions} = this.props;
        actions.supplierActions.supplierLedgerInfo(supplier_id);
        actions.supplierActions.retrieveAllSuppliers()
    }

    handleChange = (event) =>{
        const {name, value} = event.target;
        this.setState({[name]:value})
    };

    handleChangeSupplier = (value) =>{
        this.setState({supplier_value:value})
    };
    submitLedger = event =>{
        event.preventDefault();
        const {actions} = this.props;
        const {from_date,to_date, supplier_value} = this.state;
        actions.supplierActions.retrieveSupplierLedger(from_date, to_date, supplier_value.value);
        this.setState(prevState =>({
            openLedger: !prevState.openLedger
        }))
    };

    render() {
        const {supplierLedgerInfo,allSuppliers} = this.props.suppliers;
        const supplier = !supplierLedgerInfo ? {} : supplierLedgerInfo;
        const ledger = !supplier.ledger ? [] : supplier.ledger;
        const {supplier_id} = this.props.match.params;
        const {from_date, to_date,supplier_value,openLedger} = this.state;
        const suppliers = !allSuppliers ? [] : allSuppliers;
        return (
            <div>
                <MainSupplierLedger
                    sledgerInfo
                    ledgerData={ledger}
                    supplierLedgerInfo={supplier}
                    _id={supplier_id}
                    from_date={from_date}
                    to_date={to_date}
                    openLedger={openLedger}
                    handleChange={this.handleChange}
                    handleChangeSupplier={this.handleChangeSupplier}
                    submitLedger={this.submitLedger}
                    supplierList={suppliers}
                    supplier_value={supplier_value}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        suppliers:state.suppliers
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions:{
            supplierActions:bindActionCreators(supplierActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (SupplierLedgerInfo);
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import Card from "@material-ui/core/Card";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import {useMedicineCategory} from "./CustomHooks/useMedicineCategory";


const  CategoryUpdate = ({actions, snackbars,match:{params}}) => {
    const {category_name,  submitted, isSubmitted, handleChange,
        handleSubmitCategory, handleCloseSnackbar} = useMedicineCategory(actions,params)
        const {open, message, variant} = snackbars;
        return (
            <div className='journals'>
                <ReusableDashboardHeader component=' Edit Category' heading="Medicine"
                                         subHeading=' Edit Category' link={history.location.pathname}/>
                <div className="general-ledger-header">
                    <h6 className="mt-2 mb-0">Edit Category</h6>
                </div>
                <div className="mini-styled-component">
                    <Card className='p-3' style={{width:'500px'}}>
                        <PharmacySnackbar open={open} message={message} variant={variant}
                                          handleCloseBar={handleCloseSnackbar}/>
                        <form onSubmit={handleSubmitCategory} autoComplete="off" className="general-card">
                            <Form.Group as={Row}>
                                <Label column sm="4" type name='Category Name'/>
                                <Col sm="7">
                                    <TextField type="text" value={category_name} name="category_name"
                                               onChange={handleChange}/>
                                    {submitted && !category_name ? <div>Category name is required</div> : null}
                                </Col>
                            </Form.Group>
                            <div className="text-center">
                                <button type="submit" className="btn btn-sm pharmacy-info-btn mt-2"
                                        disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryUpdate);
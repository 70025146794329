import * as types from "../../Actions/actionTypes";


const isMobile = window.innerWidth < 768;
const initialState = {
    sideBar:!isMobile,
};

export default function pharmacySidebarReducer(state=initialState, action){
    switch (action.type) {
        case types.SIDEBAR_OPEN:
            return{...state, sideBar: state.sideBar};
        case types.CLOSE_SIDEBAR:
            return {...state, sideBar: !state.sideBar};
        default:
            return state;
    }
}
import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import axios from "axios";
import config from "../../Helpers/config"
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import Card from '@material-ui/core/Card';
import {errorMessages} from "../../Helpers/ErrorMessages";

const ManufacturerReturn = ({actions, match: {params}}) => {
    const {manufacturer_id} = params;
    const [purchases, setPurchases] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Creturn/search_manufacturer`,
            {params: {manufacturer_id}}).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const purchase_list = !dt.purchases_list ? [] : dt.purchases_list;
            setPurchases(purchase_list)
            setLoading(false)
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading(false)
        })
    },[actions, manufacturer_id])

    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Supplier Return' heading="Return"
                                     subHeading='Supplier Return' link={history.location.pathname}/>
            <Card className="p-2">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>SL</th>
                            <th>Purchase ID</th>
                            <th>Supplier Name</th>
                            <th>Purchase Date</th>
                            <th>Total Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ? <tr>
                            <td colSpan='5' align='center'><img src="/images/listPreloader.gif"
                                                                alt="loader"
                                                                style={{width: '5%'}}/></td>
                        </tr> : purchases.length > 0 ? purchases.map((purchase, index) =>
                            <tr key={index}>
                                <td>{purchase.sl}</td>
                                <td><Link to={{pathname: `/manufacturerreturninvoice/${purchase.purchase_id}`}}
                                          style={{color: "#09905c"}}>{purchase.purchase_id}</Link></td>
                                <td>{purchase.manufacturer_name}</td>
                                <td>{purchase.final_date}</td>
                                <td>{purchase.grand_total_amount}</td>
                            </tr>) : <tr>
                            <td colSpan='5' align='center'>No records found</td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
            </Card>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerReturn);
import * as types from "../../Actions/actionTypes";


const initialState = {
    drugs: [],
    customers: [],
    batchInfo: {},
    productInfo: {},
    invoices: [],
    guiList: [],
    guiItems: {},
    guiPos: [],
    previous: {},
    updateDetails: {},
    invoiceIData: {},
    posIData: {},
    invoice_id: '',
    rows: []

};

export default function invoiceReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_INVOICES_REQUEST:
        return{
            loading:true
        };
        case types.RETRIEVE_DRUGS:
            return {...state, drugs: action.drugs};
        case types.RETRIEVE_CUSTOMERS:
            return {...state, customers: action.customers};
        case types.RETRIEVE_PRODUCT_INVENTORY:
            return {...state, productInfo: action.productInfo};
        case types.RETRIEVE_PRODUCT_BATCH_INFO:
            return {...state, batchInfo: action.batchInfo};
        case types.RETRIEVE_INVOICES:
            return {...state, invoices: action.invoices,loading:false};
        case types.GUI_ITEM_LIST:
            return {...state, guiList: action.guiList};
        case types.RETRIEVE_GUI_INVOICE_ITEMS:
            return {...state, guiItems: action.guiItems};
        case types.RETRIEVE_GUI_POS:
            return {...state, guiPos: action.guiPos};
        case types.GET_CUSTOMER_PREVIOUS_BALANCE:
            return {...state, previous: action.previous};
        case types.INVOICE_UPDATE_FORM:
            return {...state, updateDetails: action.updateDetails};
        case types.POS_INVOICE_INSERTED_DATA:
            return {...state, posIData: action.posIData};
        case types.INVOICE_INSERTED_DATA:
            return {...state, invoiceIData: action.invoiceIData};
        case types.DELETE_INVOICE:
            return {
                ...state, invoices: state.invoices.filter(invoice => invoice.invoice_id !== action.invoice_id)
            };
            case types.EDITINV_SUCCESS:
                return {
                    submitting:false

                };
            case types.EDITINV_ERROR:
                return {
                    submitting:false

                };
            case types.EDITINV_REQUEST:
                return {
                    submitting:true

                };
        default:
            return state;
    }
}
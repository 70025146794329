import React, {Component} from 'react';
import {Form} from "react-bootstrap";
// import {Link} from "react-router-dom";
import * as userActions from "../../Redux/Actions/UserActions/UserActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faEye} from "@fortawesome/free-solid-svg-icons";
import {history} from "../../Helpers/history";
import * as alertActions from "../../Redux/Actions/AlertActions";


class PasswordReset extends Component {
    constructor() {
        super();
        this.state = {
            new_password: '',
            re_password:'',
            submitted:false
        };
        history.listen((location, action)=>{
            const {actions} = this.props;
            actions.alertActions.clearAlert();
        });
    }


    handleChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    };
    handleSetPassword = event => {
        event.preventDefault();
        const {actions} = this.props;
        const {new_password,re_password} = this.state;
        const {reset_token} = this.props.match.params;
        const formData = new FormData();
        formData.append('reset_token',reset_token);
        formData.append('new_password',new_password);
        formData.append('reset_password',re_password);
        actions.userActions.setPassword(formData);
        this.setState({email:'',submitted:true});
    };

    render() {
        const {new_password,re_password,submitted} = this.state;
        const {type, message} = this.props.alert;
        const {submitting}=this.props.users;
        return (
            <div className="styled-reset">
                {message && <div className={` alert ${type}`}>{message}</div>}
                <div className="card styled-reset-card p-4">
                    <div className="py-4">
                        <div className="text-center styled-reset-content mb-3">
                            <h4>Set Password</h4>
                        </div>
                        <Form onSubmit={this.handleSetPassword} autoComplete="off">
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" value={new_password} name="new_password"
                                              onChange={this.handleChange}/>
                                {(submitted && !new_password) && <div className="invalid-text">Please enter your password</div>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder=" Confirm Password" value={re_password} name="re_password"
                                              onChange={this.handleChange}/>
                                {(submitted && !new_password) ? <div className="invalid-text">Please enter your password</div>:
                                (new_password !== re_password) && <div className="invalid-text">The two passwords don't match</div>}
                            </Form.Group>
                            <div className="text-center">
                                <button className="btn pharmacy-btn px-5 mt-3" 
                                disabled={!!submitting}style={{width: "100%"}}>{submitting ? "Resetting....":"Reset Password"}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        users:state.users
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            userActions: bindActionCreators(userActions, dispatch),
            alertActions:bindActionCreators(alertActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

import React from 'react';
import Paper from "@material-ui/core/Paper";
import {EnhancedPharmacyListHead, EnhancedToolbar, getSorting, stableSort, useStyles} from "../../../Utils/Tables/MuiTables";
import Table from "@material-ui/core/Table";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import TablePagination from "@material-ui/core/TablePagination";
import DrugsAvailableTablePagination from "../../../Utils/Tables/MuiTablePagination";
import {withStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";

const data = [
    {
        name: "John Doe",
        address: "Kololo",
        phone: "",
        balance:20000
    },
    {
        name: "Jane Doe",
        address: "",
        phone: "",
        balance: ""

    },

];
const MainManagePerson = (props) => {
    const [term, setTerm] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('benefits');
    const ref = React.useRef();
    const {classes, children, managePerson, officePerson,personalLoan,
    officeLoan} = props;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handler = (value) => {
        setTerm(value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0)
    };
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);


    return (
        <div>
        <DashboardHeader personalLoan={personalLoan} officeLoan={officeLoan} manageOfficePerson={officePerson}
         managePerson={managePerson}/>
        <div className="styled-manage-person">
            {children}
            <div className="card styled-person-table p-3">
                <h6 className="heading-text">Manage Person</h6>
                <hr/>
                <Paper className={`${classes.paper} table-responsive`}>
                    <EnhancedToolbar handler={handler} targetRef={ref} data={data}
                                     managePerson={managePerson} officePerson={officePerson}/>
                    <div className={classes.tableWrapper} ref={ref}>
                        <Table
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedPharmacyListHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                                managePerson={managePerson} officePerson={officePerson}
                            />
                            <FilterResults
                                value={term}
                                data={data}
                                renderResults={results => (
                                    <TableBody>
                                        {stableSort(results, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((d, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                        className='mui-table-row'>
                                                        <TableCell component="th" scope="row" padding="default"
                                                                   className='mui-table-cell'>
                                                            <span>{d.name}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.address}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.phone}</span>
                                                        </TableCell>
                                                        {!officePerson &&<TableCell>
                                                            <span>{d.balance}</span>
                                                        </TableCell>}
                                                        <TableCell>
                                                        <span>
                                                            <Link to={{pathname: managePerson ? `/personloandetails/${index}` :`/personledger/${index}`}}><button
                                                                className="btn pharmacy-btn btn-sm mr-1 ">
                                                                <FontAwesomeIcon icon={faEye}/>
                                                            </button></Link>

                                                            <Link to={{pathname:managePerson ? `/editperson/${index}` : `/editpersonloan/${index}`}}>
                                                                <button className="btn pharmacy-info-btn btn-sm">
                                                                <FontAwesomeIcon icon={faPencilAlt}/>
                                                            </button></Link>
                                                        </span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 49 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                            />
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        component="div"
                        page={page}
                        SelectProps={{
                            inputProps: {'aria-label': 'rows per page'},
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={DrugsAvailableTablePagination}
                    />
                </Paper>
            </div>
        </div>
        </div>
    );
};

export default (withStyles(useStyles))(MainManagePerson);
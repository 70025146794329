import React, {useRef, useState} from "react";
import  {CustomPagination, EnhancedTableHead, EnhancedTableToolbar,
    MuiPaper} from "../../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ReusableSalesHeader from "./ReusableSalesHeader";
import {Link} from "react-router-dom";
import {useReusableDataFetch} from "../CustomHooks/useReusableDataFetch";
import {exportPDF} from "../../../Utils/exportPDF";
import {titleCase} from "../../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Form from "react-bootstrap/Form";


const headData = [{id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'invoice', numeric: false, disablePadding: false, label: 'Invoice No.'},
    {id: 'customer', numeric: false, disablePadding: false, label: 'Customer Name'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Total'},
];

const AccountReceivables = ({actions, snackbars}) => {
    const csvRef = useRef();
    const [allDebtors, setAllDebtors] = useState([]);
    const {loading, activePage, search,
        handleChangeActivePage, handleChangeSearch, closeSnackbar,
        reusableProps} = useReusableDataFetch(actions, 'accounts_receivables')
    const patients = [{patient_id: 12, patient_name: 'John Doe', invoice_no: 10, date: '2021-10-20', amount: 2000}];
    const downloadPDF = () => {
        axios.get(`${config.epharmUrl}/accounting/getalldebtors`).then(response => {
            const res = response.data;
            const dt = res ? res : [];
            exportPDF("Debtors", [["Date", "Invoice No.", "Customer Name", "Total"]],
                dt.map((item) => [item.date, item.invoice_no, item.patient_name,
                    item.amount]), "Debtors.pdf");
        }).catch(error => {
            errorMessages(error, null, actions)
        });

    };
    const fetchInvoiceData = () => {
        axios.get(`${config.epharmUrl}/accounting/getalldebtors`).then(response => {
            const res = response.data;
            const dt = res ? res : [];
            setAllDebtors(dt)
            csvRef.current.link.click();
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    }
    const headers = [{label: 'Date', key: 'date'}, {label: 'Invoice No.', key: 'invoice_no'},
        {label: 'Customer Name', key: 'patient_name'}, {label: 'Total', key: 'amount'}]
    const {open, variant, message} = snackbars;
    return (
        <ReusableSalesHeader downLoadPdf={downloadPDF}  {...reusableProps} csvRef={csvRef} csvData={allDebtors}
                             headers={headers} fetchCsvData={fetchInvoiceData}>
            <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={closeSnackbar}/>
            <div className="mui-tables">
                <MuiPaper>
                    <EnhancedTableToolbar title='Debtors'>
                        <Form.Control type="text" value={search ? titleCase(search) : ''} onChange={handleChangeSearch}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="4" align="center"><img src="/images/smsloader.gif"
                                                                           alt="loader"/></TableCell>
                            </TableRow></TableBody> : patients.length > 0 ?
                                <TableBody>
                                    {patients.slice(0, 10)
                                        .map((item, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={item.patient_id}
                                                    className='mui-table-row'>
                                                    <TableCell>{item.date}</TableCell>
                                                    <TableCell><Link
                                                        to='/accounting/invoicereportdetails/1'>{item.invoice_no}</Link></TableCell>
                                                    <TableCell>{item.patient_name}</TableCell>
                                                    <TableCell>{item.amount}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                                : <TableBody><TableRow>
                                    <TableCell colSpan="4" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
                    </TableContainer>
                    <CustomPagination data={patients} records={10} activePage={activePage} total_count={1}
                                      handleChangeNextPage={handleChangeActivePage}/>
                </MuiPaper>
            </div>
        </ReusableSalesHeader>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbars}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountReceivables);

import * as types from "../actionTypes";
import config from "../../../Helpers/config";
import axios from 'axios';
import {errorMessages} from "../../../Helpers/ErrorMessages";



const getAllStatistics = statistics => {
    return{
        type:types.GET_DASHBOARD_STATISTICS,
        statistics
    }
};



export function getDashboardStatistics(){
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/admin/")
        .then(response=>{
            const stat = response.data;
            dispatch(getAllStatistics(stat))
        }).catch(error=>{
            errorMessages(error, dispatch, null)

        })
    }
};
import React from "react";
import Form from "react-bootstrap/Form"


const Label = ({name, type, ...rest}) => {
    return (
        <Form.Label className="form__label"  {...rest} >{name}{type && <span className="ml-1 help-block">*</span>}</Form.Label>
    )
};
export default Label;

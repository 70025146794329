import * as types from "../actionTypes";
import axios from "axios";
import config from "../../../Helpers/config";
import {errorSnackbar, successSnackbar} from "../SnackbarActions/SnackbarActions";
import {history} from "../../../Helpers/history"
import {errorAlert, successAlert} from "../AlertActions";
import {errorMessages} from "../../../Helpers/ErrorMessages";


const modalData = new FormData();
modalData.append('is_modal_shown', 1);
const user = JSON.parse(sessionStorage.getItem('user'));
const singleUser = !user ? {} : user;
const  user_id = !singleUser.user_id ? 1 :singleUser.user_id;

const configuration = {
    headers: {
        'content-Type': 'multipart/form-data',
        'user_id': user_id
    }
};

const base_url = '/User';
const role_url = '/Permission';

function manageUsers(users) {
    return {
        type: types.MANAGE_USERS,
        users
    }
}

function getUser(user) {
    return {
        type: types.GET_SINGLE_USER,
        user
    }
}

function deleteUser(user_id) {
    return {
        type: types.DELETE_USER,
        user_id
    }
}

function manageRoles(roles) {
    return {
        type: types.ROLE_LIST,
        roles
    }

}

function roleDelete(id) {
    return {
        type: types.DELETE_ROLE,
        id
    }

}

function expiredDrugs(expired_drugs) {
    return {
        type: types.EXPIRED_DRUGS,
        expired_drugs
    }

}

function outOfStockDrugs(out_of_stock) {
    return {
        type: types.OUT_OF_STOCK,
        out_of_stock
    }
}

function roleUpdate(role_details) {
    return {
        type: types.EDIT_OF_ROLE,
        role_details
    }
}

function logoutUser() {
    return {
        type: types.USER_LOGOUT
    }
}

function loginUser(loggedUser) {
    return {
        type: types.USER_LOGIN,
        loggedUser
    }
}
function loginRequest() {
    return{
        type:types.LOGIN_REQUEST
    }

}
function forgotPasswordRequest(){
    return {
        type:types.FORGOTPASSWORD_REQUEST
    }
}
function forgotPasswordError(){
    return {
        type:types.FORGOTPASSWORD_ERROR
    }
}
function forgotPasswordSuccess(){
    return {
        type:types.FORGOTPASSWORD_SUCCESS
    }
}
function resetPasswordRequest(){
    return {
        type:types.RESETPASSWORD_REQUEST
    }
}
function resetPasswordError(){
    return {
        type:types.RESETPASSWORD_ERROR

    }
}
function resetPasswordSuccess(){
    return {
        type:types.RESETPASSWORD_SUCCESS
    }
}
function addroleRequest(){
    return {
        type: types.ADDROLE_REQUEST
    }
}
function addroleError(){
    return {
        type:types.ADDROLE_ERROR
    }
}
function addroleSuccess(){
    return {
        type:types.ADDROLE_SUCCESS
    }
}
function adduserRequest(){
    return {
        type:types.ADDUSER_REQUEST
    }
}
function adduserError(){
    return{
        type:types.ADDUSER_ERROR
    }
}
function adduserSuccess(){
    return{
        type:types.ADDUSER_SUCCESS
    }
}
function assignroleRequest(){
    return{
        type:types.ASSIGNROLE_REQUEST
    }
}
function assignroleError(){
    return{
        type:types.ASSIGNROLE_ERROR
    }
}
function assignroleSuccess(){
    return{
        type:types.ASSIGNROLE_SUCCESS
    }
}
function editroleRequest(){
    return{
        type:types.EDITROLE_REQUEST
    }
}
function editroleError(){
    return{
        type:types.EDITROLE_ERROR
    }
}
function editroleSuccess(){
    return{
        type:types.EDITROLE_SUCCESS
    }
}
function edituserRequest(){
    return{
        type:types.EDITUSER_REQUEST
    }
}
function edituserError(){
    return{
        type:types.EDITUSER_ERROR
    }
}
function edituserSuccess(){
    return{
        type:types.EDITUSER_SUCCESS
    }
}

export const modalDisplay = () => {
    return async (dispatch) => {
        try {
            const getExpiredDrugs = axios.get(`${config.epharmUrl}${base_url}/expired_products`);
            const getOutOfStock = axios.get(`${config.epharmUrl}${base_url}/out_of_stock`);
            const [expiredResponse, outOfStockResponse] = await Promise.all([getExpiredDrugs, getOutOfStock]);
            const expired_drugs = expiredResponse.data.expired_products;
            const out_of_stock = outOfStockResponse.data;
            dispatch(expiredDrugs(expired_drugs));
            dispatch(outOfStockDrugs(out_of_stock));
        } catch (error) {
            errorMessages(error, dispatch, null)
        }

    }
};

export const assignUserRole = (formData) => {
    return dispatch => {
        dispatch(assignroleRequest());
        return axios.post(`${config.epharmUrl}${role_url}/usercreate`, formData, configuration).then(res => {
            if (res.data === "saved_successfully") {
                dispatch(assignroleSuccess());
                dispatch(successSnackbar('User role assigned successfully'));
            } else {
                dispatch(assignroleError());
                dispatch(errorSnackbar('User has already been assigned a role'));
            }


        }).catch((error) => {
            dispatch(assignroleError());
            errorMessages(error, dispatch, null)

        })
    }
};
export const addRole = (formData) => {
    return dispatch => {
        dispatch(addroleRequest());
        return axios.post(`${config.epharmUrl}${role_url}/add_role`, formData, configuration).then(() => {
            axios.post(`${config.epharmUrl}${role_url}/create`, formData,configuration).then(() => {
                dispatch(addroleSuccess());
                dispatch(successSnackbar('Role added successfully!'));
                setTimeout(() => {
                    history.push('/rolelist')
                }, 2000)
            }).catch(error=>{
                dispatch(addroleError());
                errorMessages(error, dispatch, null)
            })
        }).catch((error) => {
            dispatch(addroleError());
            errorMessages(error, dispatch, null)

        })
    }
};

export const editRole = (formData) => {
    return dispatch => {
        dispatch(editroleRequest());
        return axios.post(`${config.epharmUrl}${role_url}/update`, formData, configuration).then(() => {
            dispatch(editroleSuccess());
            dispatch(successSnackbar('Update is successful!'));
            setTimeout(() => {
                history.push('/rolelist')
            }, 2000)

        }).catch((error) => {
            dispatch(editroleError());
            errorMessages(error, dispatch, null)
        })
    }
};


export const deleteRole = (id) => {
    return dispatch => {
        return axios.post(`${config.epharmUrl}${role_url}/role_delete`, null, {params: {id}})
            .then(res => {
                dispatch(roleDelete(id));
                dispatch(successSnackbar('Role Delete Successfully'))
            }).catch(error => {
                errorMessages(error, dispatch, null)

            })
    }
};
export const roleList = () => {
    return dispatch => {
        return axios.get(`${config.epharmUrl}${role_url}/role_list`).then(res => {
            const dt = res.data;
            const list = dt ? dt : {};
            const data = list.user_list ? list.user_list : [];
            data.reverse();
            dispatch(manageRoles(data));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
};

export const userLogout = () => {
    return (dispatch) => {
        // return axios.post(`${config.epharmUrl}/admin/log_out`).then(res => {
        //     history.push('/')
        // }).catch((err) => {
        // })
        sessionStorage.removeItem('user');
        dispatch(logoutUser())
    }

};

export const setPassword = (formData) => {
    return dispatch => {
        dispatch(resetPasswordRequest());
        return axios.post(`${config.epharmUrl}/admin/reset`,formData).then(() => {
            dispatch(resetPasswordSuccess());
            dispatch(successAlert('Password set successfully '));
            setTimeout(() => {
                history.push('/login')
            }, 500)
        }).catch(error => {
            dispatch(resetPasswordError());
            errorMessages(error, dispatch, null)
        })
    }
};
export const resetPassword = (username) => {
    return dispatch => {
        dispatch(forgotPasswordRequest());
        return axios.post(`${config.epharmUrl}/admin/forgot_password`, null,
            {params: {username}}).then(() => {
                dispatch(forgotPasswordSuccess());
                dispatch(successAlert('Link sent successfully'));
                setTimeout(() => {
                    history.push('/resetemail')
                }, 500)
        }).catch(error => {
            dispatch(forgotPasswordError());
            errorMessages(error, dispatch, null)
        })
    }
};
function loginError() {
    return{
        type:types.LOGIN_ERROR
    }

}

export function userLogin(formData) {
    return function (dispatch) {
        dispatch(loginRequest());
        return fetch(`${config.epharmUrl}/admin/do_login`, {
            method: 'POST',
            body:formData
        }).then(response => response.json())
            .then(data => {
                const tokenObj = {};
                const user = data.sid_web[1] ?? {};
                tokenObj.sid_web = data.sid_web[0];
                sessionStorage.setItem('token', JSON.stringify(tokenObj));
                sessionStorage.setItem('user', JSON.stringify(user));
                dispatch(loginUser(user));
                dispatch(successAlert('User Login Successful!'));
                const user_type = user.user_type ?? '';
                const route = user_type === 1 ? '/' :'createinvoice/pos-invoice'
                history.push(route)
            }).catch((error) => {
                dispatch(errorAlert('Username or Password is Incorrect!'))
                dispatch(loginError())


            })
    }
}

export const deleteExistingUser = user_id => {
    return dispatch => {
        return axios.post(`${config.epharmUrl}${base_url}/user_delete`, null, {params: {user_id}}).then(response => {
            dispatch(deleteUser(user_id));
            dispatch(successSnackbar('User Deleted Successfully!'))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
};
export const updateUser = (formData) => {
    return (dispatch) => {
        dispatch(edituserRequest());
        return axios.post(`${config.epharmUrl}${base_url}/user_update`, formData, configuration).then(response => {
            dispatch(edituserSuccess());
            dispatch(successSnackbar('User updated successfully'));
            history.push("/manageusers")
        }).catch(error => {
            dispatch(edituserError());
            errorMessages(error, dispatch, null)

        })
    }
};
export const getSingleUser = (user_id) => {
    return (dispatch) => {
        return axios.post(`${config.epharmUrl}${base_url}/user_update_form`, null, {params: {user_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            dispatch(getUser(dt));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
};

export function retrieveAllUsers() {
    return function (dispatch) {
        return axios.get(`${config.epharmUrl}${base_url}/manage_user`).then(response => {
            const res = response.data;
            const dt = res ? res : {};
            const data = dt.user_list ? dt.user_list : [];
            data.reverse();
            dispatch(manageUsers(data));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }

}


export function insertUser(formData) {
    return function (dispatch) {
        dispatch(adduserRequest());
        return axios.post(`${config.epharmUrl}${base_url}/insert_user`, formData, configuration).then(res => {
            if (res.data === "User Already Exists") {
                dispatch(adduserSuccess());
                dispatch(errorSnackbar('User Already Exists'))
            } else {
                dispatch(adduserSuccess());
                dispatch(successSnackbar('User added successfully!'));
                setTimeout(() => {
                    history.push("/manageusers")
                }, 1000)
            }
        }).catch(error => {
            dispatch(adduserError());
            errorMessages(error, dispatch, null)

        })
    }
}

export function roleUpdateDetails(id) {
    return function (dispatch) {
        return axios.get(`${config.epharmUrl}${role_url}/edit_role`, {params: {id}}).then(res => {
            const dt = res.data;
            const roles = dt ? dt :{};
            dispatch(roleUpdate(roles))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}
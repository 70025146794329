import {useEffect, useRef, useState} from "react";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useManageUsers = (actions, match={}) =>{
    const {user_id:id} = match;
    const [state, setState] = useState({ first_name: '',
        last_name: '',
        email: '',
        password: '',
        gender: '',
        date_of_birth: ''});
    const [user_id, setUserId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [users, setUsers] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [term, setTerm] = useState('')
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const componentRef = useRef();
    const formRef = useRef();

    const dep = isSubmitted === 'resolved';
    useEffect(() => {
        if (id){
            return
        }
        setLoading(true)
        axios.get(`${config.epharmUrl}/User/manage_user`).then(response => {
            const data = response.data
            const dt = data ?? {};
            const list = dt.user_list ?? [];
            setUsers(list);
            setLoading(false)
        }).catch(error => {
            //errorMessages(error, null, actions)
            setLoading(false);
        })
    }, [dep])

    useEffect(() => {
        if (!id) {
            return;
        }
        axios.post(`${config.epharmUrl}/User/user_update_form`, null, {params: {user_id:id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            setUser(dt)
        }).catch(error => {
            //errorMessages(error, null, actions)
        })
    }, [id])

    useEffect(() => {
        const {first_name, last_name, email, password, gender, date_of_birth} = user;
        setState({first_name, last_name, email, password, gender, date_of_birth})
    }, [user])

    const handleSearch = (value) =>{
        setTerm(value)
    }

    const handleChange = event => {
        const {value, name} = event.target;
        setState({...state,[name]:value})
    };
    const handleSubmit = event => {
        event.preventDefault();
        const {first_name, last_name, email, gender} = state;
        const formData = new FormData(formRef.current);
        if (id) {
            formData.append('user_id', id);
        }
        setSubmitted(true)
        if (first_name && last_name && email && gender) {
            setIsSubmitted('pending');
            const url = id ? 'user_update' : 'insert_user'
            axios.post(`${config.epharmUrl}/User/${url}`, formData).then(() => {
                actions.snackbarActions.successSnackbar(`User ${id ? 'updated':'added'}  successfully`)
                setIsSubmitted('resolved');
                setOpenDialog(false);
                setState({ first_name: '', last_name: '', email: '',
                    password: '', gender: '', date_of_birth: ''});
                if(id){
                    history.push('/manageusers')
                }
            }).catch(error => {
                errorMessages(error, null, actions)
                setIsSubmitted('rejected')
            })
        }
    };

    const handleOpenDelete = (_id = '') => {
        setUserId(_id)
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    }

    const deleteUser = () => {
        axios.post(`${config.epharmUrl}/User/user_delete`, null, {params: {user_id}}).then(() => {
            actions.snackbarActions.successSnackbar('User deleted successfully');
            const arr = users.filter(item => item.user_id !== user_id)
            setUsers(arr)
            setOpenDelete(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };


    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    return {state, user_id, openDialog, openDelete, users, submitted, isSubmitted,
        loading, user, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleChange,
        handleSubmit, handleOpenDelete, handleCloseDelete, deleteUser, handleOpenDialog,
        handleCloseDialog, handleCloseSnackbar,componentRef, term, handleSearch, formRef}
}
import React,{Component} from "react";
import Form from "react-bootstrap/Form";
// import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";
import Card from 'react-bootstrap/Card';
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";



class ManageIncomeTax extends Component{
    render(){
        return(
            <div>
                <DashboardHeader manage taxIncome/>
               <Card className="card p-3">
                    <h6>Manage Income Tax</h6>
                    <hr/>
                    <Form autoComplete="off">
                        <table className="table ">
                            <th>SL.</th>
                            <th>Start Amount</th>
                            <th>End Amount</th>
                            <th>Sale Price</th>
                            <th>Action</th>

                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>250000.00</td>
                                    <td>300000.00</td>
                                    <td>2.00%</td>
                                    <td>
                                    <div  className="text-left">
                                        <button type="submit" className="btn  pharmacy-info-btn btn-sm mr-1">
                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                        </button>
                                        
                                        <button type="submit" className="btn btn-sm pharmacy-btn-dismiss ">
                                        <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                    </div></td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>300001.00</td>
                                    <td>350000.00</td>
                                    <td>3.00%</td>
                                    <td>
                                    <div  className="text-left">
                                        <button type="submit" className="btn  pharmacy-info-btn btn-sm mr-1">
                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                        </button>
                                        
                                        <button type="submit" className="btn btn-sm pharmacy-btn-dismiss ">
                                        <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                    </div>
                                    

                                    </td>
                                </tr>
                            </tbody>

                        </table>


                
                

                    </Form>
                    

            </Card>
           </div>
       );
   }
}
export default ManageIncomeTax;
        
    

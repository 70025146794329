import * as types from "../actionTypes";
import axios from "axios";
import config from "../../../Helpers/config";
// import {fetchCustomers} from "../InvoiceActions/PosInvoiceActions"
import {successSnackbar} from "../SnackbarActions/SnackbarActions";
import {history} from "../../../Helpers/history";
import {errorMessages} from "../../../Helpers/ErrorMessages";

const user = JSON.parse(sessionStorage.getItem('user'));
const loggedUser = !user ? {} : user;
const user_id = !loggedUser.user_id ? "" : loggedUser.user_id;
const configuration = {
    headers: {
        'content-Type': 'multipart/form-data',
        user_id
    }
};
function manageCustomers(customers) {
    return {
        type: types.MANAGE_CUSTOMERS,
        customers
    }
}

function paidCustomers(paidCustomers) {
    return {
        type: types.PAID_CUSTOMERS,
        paidCustomers
    }
}

function creditCustomers(creditCustomers) {
    return {
        type: types.CREDIT_CUSTOMERS,
        creditCustomers
    }
}

// function saveInstantCustomer(instant_customer) {
//     return {
//         type: types.INSTANT_CUSTOMER,
//         instant_customer
//     }
// }

function customerDetails(customer) {
    return {
        type: types.RETRIEVE_CUSTOMER_DETAILS,
        customer
    }

}

function customerUpdateDetails(customerUpdate) {
    return {
        type: types.CUSTOMER_UPDATE_FORM,
        customerUpdate
    }

}

function customerLedger(customerLedger) {
    return {
        type: types.CUSTOMER_LEDGER,
        customerLedger
    }

}

function customerDelete(customer_id) {
    return {
        type: types.CUSTOMER_DELETE,
        customer_id
    }

}

function deleteCreditCustomer(customer_id) {
    return {
        type: types.DELETE_CREDIT_CUSTOMER,
        customer_id
    }
}

function deletePaidCustomer(customer_id) {
    return {
        type: types.DELETE_PAID_CUSTOMER,
        customer_id
    }

}

function customerRequest(){
    return{
        type:types.CUSTOMER_GIF,
        
    }
}
function addCustomerRequest(){
    return {
        type:types.CUSTOMER_REQUEST,
    }
}
function addCustomerError(){
    return {
        type:types.CUSTOMER_ERROR,
    }
}
function addCustomerSuccess(){
    return {
        type:types.CUSTOMER_SUCCESS,
    }
}
function editCustRequest(){
    return {
        type:types.EDITCUST_REQUEST,
    }
}
function editCustError(){
    return {
        type:types.EDITCUST_ERROR,
    }
}
function editCustSuccess(){
    return {
        type:types.EDITCUST_SUCCESS,
    }
}

export function deleteCustomer(customer_id) {
    return function (dispatch) {
        return axios.post(`${config.epharmUrl}/Ccustomer/customer_delete`, null, {params: {customer_id}})
            .then(() => {
                switch (history.location.pathname) {
                    case "/managecustomer":
                        dispatch(customerDelete(customer_id));
                        break;
                    case "/creditcustomer":
                        dispatch(deleteCreditCustomer(customer_id));
                        break;
                    case "/paidcustomer":
                        dispatch(deletePaidCustomer(customer_id));
                        break;
                    default:
                        return null;
                }
                dispatch(successSnackbar('Customer deleted successfully'));

            }).catch((error) => {
                errorMessages(error, dispatch, null)

            })

    }

}

export function retrieveLedgerDetails(customer_id) {
    return function (dispatch) {
        dispatch(customerRequest());
        return axios.get(`${config.epharmUrl}/Ccustomer/customerledger/${customer_id}`).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            dispatch(customerLedger(dt));
        }).catch((error) => {
            errorMessages(error, dispatch, null)
        })
    }
}

export function customerUpdateForm(customer_id) {
    return function (dispatch) {
        return axios.post(`${config.epharmUrl}/Ccustomer/customer_update_form/${customer_id}`).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            dispatch(customerUpdateDetails(dt))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }

}

export function editCustomer(formData) {
    return function (dispatch) {
        dispatch(editCustRequest());
        return axios.post(`${config.epharmUrl}/Ccustomer/customer_update`, formData).then(() => {
            dispatch(editCustSuccess());
            dispatch(successSnackbar('Customer successfully updated'))
            history.push('/managecustomer')
        }).catch((error) => {
            dispatch(editCustError());
            errorMessages(error, dispatch, null)
        })
    }
}

export function retrieveCustomerDetails(customer_id) {
    return function (dispatch) {
        dispatch(customerRequest());
        return axios.get(config.epharmUrl + `/Ccustomer/customer_ledger/${customer_id}`).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            dispatch(customerDetails(dt))
        }).catch((error) => {
            errorMessages(error, dispatch, null)
        })

    }

}

export function createCustomer(formData) {
    return function (dispatch) {
        dispatch(addCustomerRequest());
        return axios.post(config.epharmUrl + "/Ccustomer/insert_customer", formData, configuration)
            .then(response => {
                dispatch(addCustomerSuccess());
                dispatch(successSnackbar("Customer Saved Successfully!"));
                if (history.location.pathname === "/addcustomer"){
                    setTimeout(() => {
                        history.push('/managecustomer')
                    }, 2000)
                }

            }).catch(error => {
                dispatch(addCustomerError());
                errorMessages(error, dispatch, null)
            })
    }
}

export function instantCustomer(formData) {
    return function (dispatch) {
        const configuration = {
            headers: {
                'content-Type': 'multipart/form-data',
                user_id: 1
            }
        };
        return axios.post(config.epharmUrl + "/Cinvoice/instant_customer", formData, configuration)
            .then(response => {
                // const customer_id = response.data.customer_name;
                dispatch(successSnackbar("Customer Saved Successfully!"));

            }).catch(error => {
                errorMessages(error, dispatch, null)
                
            })
    }
}

export function manageAllCustomer(formData) {
    
    return function (dispatch) {
        dispatch(customerRequest())
        return axios.post(config.epharmUrl + "/Ccustomer/checkCustomerList",formData).then(response => {
            const customers = response.data.aaData;
            dispatch(manageCustomers(customers));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    };
}

export function getPaidCustomers(formData) {
    return function (dispatch) {
        dispatch(customerRequest())
        return axios.post(config.epharmUrl + "/Ccustomer/checkPaidCustomerList", formData).then(response => {
            const customers = response.data.aaData;
            dispatch(paidCustomers(customers));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}
export function uploadCustomerCsv(formData) {
    return function (dispatch) {
        return axios.post(`${config.epharmUrl}/Ccustomer/uploadCsv_Customer`, formData,configuration).then(()=>{
            dispatch(successSnackbar('File uploaded successfully'))
        }).catch((error)=>{
            errorMessages(error, dispatch, null)
           
        })

    }

}

export function getCreditCustomers(formData) {
    return function (dispatch) {
        dispatch(customerRequest())
        return axios.post(config.epharmUrl + "/Ccustomer/checkCreditCustomerList", formData).then(response => {
            const customers = response.data.aaData;
            dispatch(creditCustomers(customers));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }
}
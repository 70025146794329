import  {useState} from 'react';
import {useDates} from "../../Accounting/CustomHooks/useDates";


export const usePeriodsFilter = () =>{
    const CURRENT_YEAR = new Date().getFullYear();
    const [period, setPeriod] = useState({value:'today', label:'Today'});
    const [date, handleChangeDate, setDate] = useDates();
    const [year, setYEar] = useState(CURRENT_YEAR);

    const {from_date, to_date} = date;
    const inputTypes = {
        weekly:'week',
        monthly:'month',
        custom:'date'
    };

    const handleChangePeriod = (value) =>{
        setPeriod(value);
        setDate({from_date:'', to_date:''})
    };

    const handleChangeYear = (event) => {
        setYEar(event.target.value);
    };


    return { period, from_date, to_date, handleChangeDate,setDate,
        year, inputTypes, handleChangePeriod,  handleChangeYear}
}
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import DeleteDialog from "../../Utils/DeleteDialog";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useMedicineUnit} from "./CustomHooks/useMedicineUnit";

const headData = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Unit Name'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];
const AddUnit = ({actions, snackbars}) => {
    const {
        unit_name,  openDialog, openDelete, units, submitted, isSubmitted,
        loading,  page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleChange,
        handleSubmitUnit, handleOpenDelete, handleCloseDelete, deleteUnit,
        handleOpenDialog, handleCloseDialog, handleCloseSnackbar
    } = useMedicineUnit(actions)
    const {open, message, variant} = snackbars;
    return (
        <div>
            <div className='journals'>
                <ReusableDashboardHeader component='Medicine Unit' heading="Medicine"
                                         subHeading='Medicine Unit' link='/addunit'/>
                <div className="general-ledger-header">
                    <div className="row">
                        <div className="col-md-6">
                            <h6 className='mt-2 mb-0'>Units</h6>
                        </div>
                        <div className="col-md-6">
                            <div className="text-right">
                                <button type='button' onClick={handleOpenDialog}
                                        className='btn btn-sm pharmacy-info-btn'>Add Unit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={handleCloseSnackbar}/>
                <DeleteDialog handleClose={handleCloseDelete} openDialog={openDelete} message='unit'
                              handleDelete={deleteUnit}/>
                <ReusableDialog message='New Unit' openDialog={openDialog}
                                handleSubmit={handleSubmitUnit} isSubmitted={isSubmitted}
                                handleClose={handleCloseDialog}
                                width='sm'>
                    <Form.Group as={Row}>
                        <Label column sm="3" type name='Unit Name'/>
                        <Col sm="7">
                            <TextField type="text" value={unit_name} name="unit_name"
                                       onChange={handleChange}/>
                            {submitted && !unit_name ? <div>Unit name is required</div> : null}
                        </Col>
                    </Form.Group>
                </ReusableDialog>
                <div className="mui-tables">
                    <MuiPaper>
                        <EnhancedTableToolbar title={''}/>
                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size='small'
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead headCells={headData}/>
                                {loading ? <TableBody><TableRow>
                                    <TableCell colSpan="2" align="center"><img src="/images/listPreloader.gif"
                                                                               alt="loader"
                                                                               style={{width: '5%'}}/></TableCell>
                                </TableRow></TableBody> : units.length > 0 ?
                                    <TableBody>
                                        {units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((unit) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={unit.id}
                                                    >
                                                        <TableCell>{unit.unit_name}</TableCell>
                                                        <TableCell>
                                                            <Link to={{pathname: `/editunit/${unit.id}`}}>
                                                                <button className="btn btn-sm pharmacy-info-btn mr-1">
                                                                    <FontAwesomeIcon icon={faPencilAlt}/></button>
                                                            </Link>

                                                            <button className="btn btn-sm pharmacy-btn-dismiss"
                                                                    onClick={() => handleOpenDelete(unit.id)}>
                                                                <FontAwesomeIcon icon={faTrash}/></button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                    : <TableBody><TableRow>
                                        <TableCell colSpan="2" align="center">No records found</TableCell>
                                    </TableRow></TableBody>}
                            </Table>
                        </TableContainer>
                        <MuiPagination data={units} rowsPerPage={rowsPerPage} page={page}
                                       handleChangeRowsPerPage={handleChangeRowsPerPage}
                                       handleChangePage={handleChangePage} rows={[10, 20, 30, 50 ,100]} colSpan={2}/>
                    </MuiPaper>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AddUnit);
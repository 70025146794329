import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';


const SelectInput = ({name, value, onChange, options, defaultOption, ...props}) => {
    return (
        <Form.Control as="select" name={name} value={value} onChange={onChange}
                      className='form__control' {...props}>
            {defaultOption && <option value=''>{defaultOption}</option>}
            {options.map(option => (
                <option key={option.value} value={option.value}>{option.text}</option>
            ))}
        </Form.Control>
    )
};
export default SelectInput;

const customStyles = {
    control: provided => ({
        ...provided,
        backgroundColor: 'white',
        borderRadius: '.7rem',
        fontSize: '13px',
    }),
    option: (provided) => {
        return {
            ...provided,
            backgroundColor: '#ffff',
            color: '#181818',
            cursor: 'pointer',
            fontSize: '13px',
            ':hover': {
                ...provided[':provided'],
                backgroundColor: 'rgba(20,1,125,0.1)',
            },
            ':active': {
                ...provided[':active'],
                backgroundColor: '#ffff',
                color: '#0b0b0b'
            },

        };
    },
    singleValue: (styles) => ({
        ...styles,
    })
};
export const CustomSelect = ({onChange, value, dataTest, options = [], ...rest}) => {
    return (
        <Select onChange={onChange} styles={customStyles}
                value={value}
                options={options} data-test={dataTest} {...rest}/>
    )
};

export const CreatableCustomSelect = ({isLoading, onChange,onCreateOption, options, value, ...props}) => (
    <CreatableSelect
    isClearable
    isDisabled={isLoading}
    isLoading={isLoading}
    onChange={onChange}
    onCreateOption={onCreateOption}
    options={options}
    value={value}
    styles={customStyles}
    {...props}/>)


import React, {Component} from "react";
import EditAddEmployee from "./AddEditEmployee";


class AddEmployee extends Component {
    render() {
        return (
           <EditAddEmployee addEmployee/>
        );
    }
}

export default AddEmployee;
import React from 'react';
import {ReusableSalesAndPurchaseHeader} from "./ReusableSalesAndPurchaseHeader";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import {EnhancedTableHead, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {convDate, titleCase} from "../../Utils/titleCaseFunction";
import {useMuiPagination} from "../../Containers/Dasboard/DashboardTables";
import {usePurchaseReport} from "./CustomHooks/usePurchaseReport";
import {ReusableSearchForm} from "./ReusableSearchForm";

const headData = [
    {id: 'pdate', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'purchaseId', numeric: false, disablePadding: false, label: 'Invoice#'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Supplier Name'},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product Name'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'price', numeric: false, disablePadding: false, label: 'Unit Cost'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Total Amount'},
];


const AdvancedPurchaseReport = () => {
    const {
        supplier, suppliers, handleChangeSupplier, period, from_date, to_date, handleChangeDate,
        year, inputTypes, handleChangePeriod, handleChangeYear, purchaseReport, loading,
        purchase_amount, submitted, isSubmitted, handleSubmit, downloadPDF, csvRef,downloadCsv
    } = usePurchaseReport()
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const headers = [{key: 'purchase_date', label: 'Date'}, {key: 'chalan_no', label: 'Invoice#'},
         {key: 'manufacturer_name', label: 'Supplier'},{key: 'product_name', label: 'Medicine'},
        {key: 'quantity', label: 'Quantity'},{key: 'manufacturer_price', label: 'Unit Cost'},
        {key: 'total_amount', label: 'Amount'}]
    const periods = [{value: 'today', label: 'Today'}, {value: 'weekly', label: 'Weekly'},
        {value: 'monthly', label: 'Monthly'},
        {value: 'custom', label: 'Custom'}]
    return (
        <div className='journals'>
            <ReusableSalesAndPurchaseHeader downLoadPdf={downloadPDF} handleSubmit={handleSubmit} headers={headers}
            csvRef={csvRef} csvData={purchaseReport} fetchCsvData={downloadCsv} csvTitle='Purchase Report'>
                <ReusableSearchForm handleChangePeriod={handleChangePeriod} period={period}
                                    inputType={inputTypes} from_date={from_date} year={year} to_date={to_date}
                                    handleChangeYear={handleChangeYear} handleChangeDate={handleChangeDate}
                                    isSubmitted={isSubmitted} submitted={submitted} periods={periods}/>
            </ReusableSalesAndPurchaseHeader>
            <div className="mx-1">
                <MuiPaper>
                    <div className="p-4 ml-2">
                        <div className="row">
                            <div className="col-md-3">
                                <Form.Group as={Row}>
                                    <Label name='Supplier:' sm={2} style={{marginTop: '10px'}}/>
                                    <Col sm={8}>
                                        <CustomSelect onChange={handleChangeSupplier} value={supplier}
                                                      options={suppliers}/>

                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    {/*<EnhancedTableToolbar title=''/>*/}
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="7" align="center"><img src="/images/listPreloader.gif"
                                                                            alt="loader"
                                                                            style={{width: '5%'}}/></TableCell>
                            </TableRow></TableBody> : purchaseReport.length > 0 ?
                                <TableBody>
                                    {purchaseReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((d, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell>
                                                        <span>{convDate(d.purchase_date)}</span>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="default"
                                                               className='mui-table-cell'>

                                                        <span>{d.chalan_no}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{titleCase(d.manufacturer_name)}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.product_name}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.quantity}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.manufacturer_price}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.total_amount}</span>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    <TableRow>
                                        <TableCell colSpan={6} align="right">Total Purchase Amount:</TableCell>
                                        <TableCell>{purchase_amount}</TableCell>
                                    </TableRow>
                                </TableBody>
                                : <TableBody><TableRow>
                                    <TableCell colSpan="7" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
                    </TableContainer>
                    <MuiPagination data={purchaseReport} rowsPerPage={rowsPerPage} page={page}
                                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                                   handleChangePage={handleChangePage} rows={[10, 20, 30, 40, 50]} colSpan={6}/>
                </MuiPaper>
            </div>
        </div>
    )
}
export default AdvancedPurchaseReport;
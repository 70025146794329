import React,{Component} from "react";
import Card from 'react-bootstrap/Card';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {convertDate} from "../../Utils/titleCaseFunction";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";






class TaxReport extends Component{
    constructor(props) {
        super(props);
        this.state = {
            start_date: '',
            end_date: convertDate(),
        };
        
        this.ref = React.createRef();
    }
    changeDate = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    };



    render(){
        const {start_date, end_date,} = this.state;
        // const {classes} = this.props;
        return(
            <div>
                <DashboardHeader ta taxReport/>
                <Card className="card p-3">
                    <h6>Tax Report</h6>
                
                     <hr/>
                     <Form autoComplete="off">
                        <Form.Row>
                                <Col>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2" className="date-picker-labels"
                                                    >Start Date:</Form.Label>
                                        <Col sm="2">
                                        <Form.Control type="date" name="start_date"
                                                          value={start_date} onChange={this.changeDate}/>

                                        </Col>
                                        <Col>
                                    <Form.Group
                                        as={Row}
                                    >
                                        <Form.Label column sm="2" className="date-picker-labels"
                                        >
                                            End Date:</Form.Label>
                                        <Col sm="4">
                                            <Form.Control type="date" name="end_date" value={end_date}
                                                          onChange={this.changeDate}/>
                                        </Col>
                                        <div  className="text-center">
                                        <button type="submit" className="btn  pharmacy-info-btn btn-sm mr-1">Search
                                        
                                        </button>
                                        
                                        <button type="submit" className="btn  pharmacy-info-btn btn-sm ">Print
                                        
                                        </button>
                                    </div>
                                    </Form.Group>
                                </Col>
            
                             </Form.Group>
                             </Col>

                        </Form.Row>

                        
                         <table className="table">
                             <thead>
                         
                             <th>SL.</th>
                             <th>Invoice No/Service Id</th>
                             <th>Date</th>
                             <th>Tax</th>
                             <th>Vat</th>
                             </thead>

                             <tbody>
                                 <tr>
                                     <td>1</td>
                                     <td>1011</td>
                                     <td>2020-06-10</td>
                                     <td></td>
                                     <td></td>
                                 </tr>
                                 <tr>
                                    
                                     <td colSpan="3" className="text-right">Total:</td>
                                     <td></td>
                                     <td></td>
                                        
                                 </tr>
                                 
                             </tbody>

                         </table>

                     

                    </Form>
                </Card>
            </div>
         );
     }
}
export default TaxReport;
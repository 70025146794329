import React, {Component} from 'react';


class CustomPagination extends Component {
    render() {
        const {pages, addToMenu, start, end, retrieveDetails, removeFromMenu, item_count, manufacturer, purchase, stockBatch,stockOut,
            disabledPrevious, disabledNext, medReport, medicine, wastageReturn, stockReturn, manufacturerReturn, stockReport,
            invoice,customers
        } = this.props;
        return (
            <div className="row">
                <div className="col-md-6">
                    {pages.length > 0 &&
                    <span>{item_count} {manufacturer ? "Manufacturers" : stockReturn
                        ? "Stock Returns" : manufacturerReturn ? "Manufacturer Returns" : wastageReturn ? "Wastage Returns" :
                            medReport ? "Sales" : medicine ? "Products" : stockReport  ? "Stock" : invoice ? "Invoices":
                               stockBatch ? "Batch Stock" : stockOut ? "Out Of Stock" :purchase ? "Purchases" : customers ? "Customers":"Suppliers"}</span>}
                </div>
                <div className="col-md-6">
                    <div className="text-right">
                        <div id="pagination" className="btn-group">
                            <button type="button" className="btn grey-btn"
                                    onClick={() => removeFromMenu(start - 1, end - 1)}
                                    disabled={disabledPrevious}
                            >Prev
                            </button>
                            {pages.slice(start, end).map((page, index) => (
                                <button type="button" key={page} onClick={() => retrieveDetails(page)}
                                        className="btn grey-btn">{page}</button>))}
                            <button type="button" onClick={() => addToMenu(start + 1, end + 1)} className="btn grey-btn"
                                    disabled={disabledNext}>Next
                            </button>

                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default CustomPagination;
import {useEffect, useState} from "react";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useMedicineUnit = (actions, match={}) =>{
    const {id} = match;
    const [unit_name, setUnitName] = useState('');
    const [unit_id, setUnitId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [units, setUnits] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState(false);
    const [unit, setUnit] = useState({});
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();

    const dep = isSubmitted === 'resolved';
    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Cproduct/unit_list`).then(response => {
            const data = response.data
            const dt = data ? data : {};
            const list = dt.unit ? dt.unit : []
            setUnits(list);
            setLoading(false)
        }).catch(error => {
            //errorMessages(error, null, actions)
            setLoading(false);
        })
    }, [dep])

    useEffect(() => {
        if (!id) {
            return;
        }
        const formData = new FormData();
        formData.append('id', id);
        formData.append('unit_name', '');
        formData.append('status', '');
        axios.post(`${config.epharmUrl}/Cproduct/unit_form`, formData).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            const un = dt.unit ? dt.unit : {}
            setUnit(un)
        }).catch(error => {
            //errorMessages(error, null, actions)
        })
    }, [id])

    useEffect(() => {
        const {unit_name:name} = unit
        setUnitName(name)
    }, [unit])

    const handleChange = event => {
        const {value} = event.target;
        setUnitName(value)
    };
    const handleSubmitUnit = event => {
        event.preventDefault();
        const formData = new FormData();
        if (id) {
            formData.append('id', id);
        }
        formData.append('unit_name', unit_name);
        formData.append('status', 1);
        setSubmitted(true)
        if (unit_name) {
            setIsSubmitted('pending');
            axios.post(`${config.epharmUrl}/Cproduct/unit_form`, formData).then(() => {
                actions.snackbarActions.successSnackbar('unit added successfully')
                setIsSubmitted('resolved')
                setOpenDialog(false)
                setUnitName('')
                if (id){
                    history.push('/addunit')
                }
            }).catch(error => {
                errorMessages(error, null, actions)
                setIsSubmitted('rejected')
            })
        }
    };

    const handleOpenDelete = (_id = '') => {
        setUnitId(_id)
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    }

    const deleteUnit = () => {
        axios.get(`${config.epharmUrl}/Cproduct/delete_unit/${unit_id}`).then(() => {
            actions.snackbarActions.successSnackbar('Unit deleted successfully');
            const arr = units.filter(item => item.id !== unit_id)
            setUnits(arr)
            setOpenDelete(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    return {unit_name, unit_id, openDialog, openDelete, units, submitted, isSubmitted,
        loading, unit, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleChange,
        handleSubmitUnit, handleOpenDelete, handleCloseDelete, deleteUnit,
        handleOpenDialog, handleCloseDialog, handleCloseSnackbar}
}
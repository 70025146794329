import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";

const EditAddPerson = (props) => {
    const {addPerson, officePerson, children, editPerson,personalLoan,officeLoan,editPersonLoan} = props;
    return (
        <div>
        <DashboardHeader personalLoan={personalLoan} officeLoan={officeLoan} editPersonLoan={editPersonLoan}
         addPerson={addPerson} editPerson={editPerson} addOfficePerson={officePerson}/>
        <div className="styled-component">
            {children}
            <div className="card styled-person-card p-3">
                <h6>{addPerson || officePerson ? "Add Person" : "Edit Person"}</h6>
                <hr/>
                <Form autoComplete="off">
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Name<span className="text-danger asterics">*</span></Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Phone<span className="text-danger asterics">*</span></Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Address{!officePerson &&
                        <span className="text-danger asterics">*</span>}</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="textarea"/>
                        </Col>
                    </Form.Group>
                    <div className="text-center">
                        <button className="btn pharmacy-btn px-5">Save</button>
                    </div>
                </Form>
            </div>
        </div>
        </div>
    )
};
export default EditAddPerson;
import React from "react";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const AddExpense = () => {

    return (
        <div>
        <DashboardHeader expense addExpense/>
        <div className="card p-3  styled-card">
            <h6 className="heading-text">Add Expense</h6>
            <hr/>
            <Form autoComplete="off">
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Date<span
                        className="text-danger asterics">*</span></Form.Label>
                    <Col sm={8}>
                        <Form.Control type="date"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Expense Type<span
                        className="text-danger asterics">*</span></Form.Label>
                    <Col sm={8}>
                        <Select/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Payment Type<span
                        className="text-danger asterics">*</span></Form.Label>
                    <Col sm={8}>
                       <Select/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Amount<span
                        className="text-danger asterics">*</span></Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text"/>
                    </Col>
                </Form.Group>

                <div className="text-center">
                    <button className="btn pharmacy-btn px-4 mt-3">Save</button>
                </div>
            </Form>
        </div>
        </div>
    )
};
export default AddExpense;
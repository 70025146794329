import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent, DialogTitle,DialogActions} from "./pharmacyDialog";


const PrintDialog = ({openDialog, handleClose,handleClick,message,text, handleCancel, sample, next, preview}) =>{
    return(
        <Dialog open={openDialog}>
            <DialogTitle onClose={handleClose}>
                <div style={{fontSize:15}} className="text-center">
                     {message}
                </div>
            </DialogTitle>
            <DialogContent>
                <h6 className='text-center' style={{fontSize:15}}>{text}</h6>
            </DialogContent>
            {!sample && <DialogActions>
                <button id='yesBtn' className="btn pharmacy-info-btn btn-sm" onClick={handleClick}>{next ? next:'Yes'}</button>
                <button id='noBtn' className="btn pharmacy-grey-btn btn-sm" onClick={handleCancel}>{preview ? preview:'No'}</button>
            </DialogActions>}
        </Dialog>
    )

};
export default PrintDialog;

import {combineReducers} from "redux";
import dashboard from "./DashboardReducer/DasboardReducer";
import invoices from "./InvoiceReducer/InvoiceReducer";
import pharmacySidebar from "./ResponsiveReducer/SidebarReducer";
import customers from "./CustomerReducer/CustomerReducer";
import snackbars from "./SnackbarReducer/SnackbarReducer";
import medicines from "./MedicineReducer/MedicineReducer";
import manufacturers from "./ManufacturerReducer/ManufacturerReducer";
import purchases from "./PurchaseReducer/PurchaseReducer";
import stock from "./StockReducer/StockReducer";
import suppliers from "./SupplierReducer/SupplierReducer";
import reports from "./ReportReducer/ReportReducer"
import users from "./UserReducer/UserReducer";
import returns from "./ReturnReducer/ReturnReducer";
import alert from "./AlertReducer"
import steppers from './steppersReducer'

const rootReducer = combineReducers({
    dashboard,
    steppers,
    invoices,
    pharmacySidebar,
    customers,
    snackbars,
    medicines,
    manufacturers,
    purchases,
    stock,
    suppliers,
    reports,
    users,
    returns,
    alert
});

export default rootReducer;
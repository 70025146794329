import React, {Component} from 'react';
import ManufacturerLedger from "./ManufacturerLedger";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import {convertDate} from "../../Utils/titleCaseFunction";

class ManufacturerLedgerReport extends Component {
    state = {
        manufacturer_id: '',
        from_date: convertDate(),
        to_date: convertDate(),
        openLedger:false
    };

    componentDidMount() {
        const {actions} = this.props;
        actions.manufacturerActions.getManufacturerLedgerReport();
    }

    handleChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };
    handleChangeManufacturer = value=> {
        this.setState({manufacturer_id:value})
    };

    handleSearch = (event) => {
        event.preventDefault();
        const {actions} = this.props;
        const {from_date, to_date, manufacturer_id} = this.state;
        actions.manufacturerActions.manufacturerLedger(from_date, to_date, manufacturer_id.value);
        this.setState(prevState =>({
            openLedger: !prevState.openLedger
        }))
    };

    render() {
        const {ledgerReport,loading} = this.props.manufacturers;
        const {manufacturer_id, from_date, to_date,openLedger} = this.state;
        const ledger = !ledgerReport ? {} : ledgerReport;
        const ledgerData = !ledger.ledger ? [] : ledger.ledger;
        return (
            <div>
                <ManufacturerLedger
                    ledgerReport
                    ledgerInformation = {ledger}
                    ledgerData = {ledgerData}
                    manufacturer_id={manufacturer_id}
                    from_date={from_date}
                    to_date={to_date}
                    handleChange={this.handleChange}
                    handleChangeManufacturer={this.handleChangeManufacturer}
                    handleSearch={this.handleSearch}
                    openLedger={openLedger}
                    loading={loading}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        manufacturers: state.manufacturers
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            manufacturerActions: bindActionCreators(manufacturerActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerLedgerReport);
import React from "react";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions'
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {Link} from 'react-router-dom';
import {useEditBank} from "./CustomHooks/useEditBank";
import Card from "@material-ui/core/Card";


const EditBank = ({actions, snackbars, match}) => {
    const {
        handleSubmit, state, handleChange, closeSnackbar, submitted,
        isSubmitted
    } = useEditBank(actions, match);
    const {bank_id} = match.params;
    const {open, variant, message} = snackbars;
    const {account_name, branch, account_no, opening_balance, bank_name} = state
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Bank" heading="Edit Bank"
                                     subHeading="Edit Bank" link={`/editbank/${bank_id}`}/>
            <div className='general-ledger-header'>
                <div className='text-right'>
                    <Link to='/banks'>
                        <button className='btn btn-sm pharmacy-info-btn mr-2 mb-2'>Banks</button>
                    </Link>
                </div>
            </div>
            <PharmacySnackbar handleCloseBar={closeSnackbar} variant={variant} open={open} message={message}/>
            <Card className='p-3 mt-2 accounting-period'>
                <form onSubmit={handleSubmit}>
                    <Form.Group as={Row}>
                        <Label name='Bank Name' type column sm={2}/>
                        <Col sm={7}>
                            <TextField type='text' value={bank_name} name='bank_name' onChange={handleChange}/>
                            {submitted && !bank_name ? <div className='invalid-text'>Bank Name is required</div> : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name='Branch' column sm={2}/>
                        <Col sm={7}>
                            <TextField type='text' value={branch} name='branch' onChange={handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name='Account No.' type column sm={2}/>
                        <Col sm={7}>
                            <TextField type='text' value={account_no} name='account_no' onChange={handleChange}/>
                            {submitted && !account_no ?
                                <div className='invalid-text'>Account No. is required</div> : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name='Account Name.' type column sm={2}/>
                        <Col sm={7}>
                            <TextField type='text' value={account_name} name='account_name' onChange={handleChange}/>
                            {submitted && !account_name ?
                                <div className='invalid-text'>Account Name is required</div> : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name='Opening Bal.' column sm={2}/>
                        <Col sm={7}>
                            <TextField type='number' value={opening_balance} name='opening_balance'
                                       onChange={handleChange}/>
                        </Col>
                    </Form.Group>
                    <div className="text-center">
                        <button type="submit" className="btn btn-sm pharmacy-info-btn"
                                disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>
                    </div>
                </form>
            </Card>

        </div>
    )
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBank)
import React, {useRef} from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions';
import {printFunctionality} from "../../../Utils/printFunctionality";
import Card from '@material-ui/core/Card';
import {useFetchData} from "../CustomHooks/useFetchData";
import jsPDF from 'jspdf'
import autoTable from "jspdf-autotable";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {printStyles, ReusableReportHeader} from "./ReusableReportHeader";

const data = [{name: 'Operating Cash and Equipment', total: 30000, children: [{id: 1, name: 'Cash in Hand', value: 30000}]},
    {name: 'Cash Flow from operating Activities', total: 30000, children: [{id: 1, name: 'Accounts payable', value: 30000}]},
    {name: 'Cash Flow from investing activities', total: 0, children: []},
    {name: 'Cash Flow from Financing activities', total: 0, children: []},
]


const CashFlowStatement = ({actions, snackbars}) => {
    const {date,   closeSnackbar, period, handleChangePeriod,
        inputType, handleChangeDate, year, handleChangeYear
    } = useFetchData(actions,'cash_flow')
    const {from_date, to_date} = date;

    const downloadPDF = () => {
        const doc = new jsPDF()
        autoTable(doc, {html: '#cash_flow_statement'})
        doc.save('Cash Flow Statement.pdf')
    };
    const printRef = useRef();
    const {open, variant, message} = snackbars
    return (
        <div className="acc_reports journals">
            <PharmacySnackbar message={message} open={open} variant={variant} handleCloseBar={closeSnackbar}/>
            <ReusableReportHeader handleChangeDate={handleChangeDate} to_date={to_date} inputType={inputType}
                                  handleChangePeriod={handleChangePeriod} period={period} handleChangeYear={handleChangeYear} year={year}
                                  downloadPdf={downloadPDF} from_date={from_date} title='Cash Flow Statement'
                                  handlePrint={()=>printFunctionality(printRef.current,printStyles())} subTitle='Profit flow statement'/>
            <div ref={printRef} className='mui-tables'>
                <Card className='p-3 mt-2' elevation={0}>
                    <table className='table table-borderless profile-table balance-sheet-data balance-sheet-table table-sm'
                    id='cash_flow_statement'>
                        <thead>
                        <tr>
                            <td colSpan={2} style={{borderBottom: '1px solid #606060'}}
                                align="center">Cash Flow Statement<br/> December 31, 2020
                            </td>
                        </tr>
                        <tr>
                            <td style={{borderBottom: '1px solid #606060'}}><strong>Particulars</strong></td>
                            <td style={{borderBottom: '1px solid #606060'}}><strong>Total</strong></td>
                        </tr>
                        </thead>
                        {data.map((item, index) => (
                            <tbody key={index}>
                            <tr>
                                <td colSpan={2} style={{color: '#a3a3a3'}}>
                                    {item.name}</td>
                            </tr>
                            {item.children.map(it => (
                                <tr key={it.id}>
                                    <td style={{paddingLeft: '.5rem'}}>{it.name}</td>
                                    <td>{it.value}</td>
                                </tr>
                            ))}
                            <tr>
                                <td>
                                    <p style={{fontWeight: '600', margin: 0}}>{`Total ${item.name}`}</p>
                                </td>
                                <td style={{borderTop: '1px solid #606060'}}>
                                    <strong>{item.total}</strong>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                        <tfoot>
                        <tr>
                            <td>
                                <strong>TOTAL CLOSING CASH & CASH EQUIVALENT</strong>
                            </td>
                            <td>
                                <strong>60000</strong>
                            </td>
                        </tr>
                        </tfoot>

                    </table>
                </Card>
            </div>
        </div>
    )
        ;
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(CashFlowStatement)

import React from 'react';
import {splitText, titleCase} from "../../Utils/titleCaseFunction";

const MainCustomerDetails = ({customer, title, loading, miniTable = null, headData=[], tableData = null, data = []}) => {
    return (
        <div className="card mt-2 p-3">
            <h6 className="mpeke-heading">{title}</h6>
            <div className="row ">
                <div className="col-md-8">
                    <div className="customer-info">
                        <span>{customer.customer_name ? titleCase(customer.customer_name) : ''}</span><br/>
                        <span>{customer.customer_email ? customer.customer_email : ''}</span><br/>
                        <span>{customer.customer_mobile ? customer.customer_mobile : ''}</span>
                        <span>{customer.customer_address ? splitText(customer.customer_address) : ''}</span>
                    </div>
                </div>
                <div className="col-md-4">
                    {miniTable}
                </div>
            </div>
            <div className="customer-table table-responsive">
                <table className="table table-bordered table-sm mpeke-customer-table">
                    <thead>
                    {headData.map(item=>(
                        <th key={item}>{item}</th>
                    ))}
                    </thead>
                    {loading ? <div className="sales-loader"><img src="/images/loader.gif" alt="loading"/></div> :
                        data.length > 0 ? tableData: <div className="text-center p-3">No Records Found</div>}
                </table>
            </div>
        </div>
    );
}


export default MainCustomerDetails;
import React from 'react';
import Grid from '@material-ui/core/Grid';
import ReusableSalesTemplate from "./ReusableSalesTemplate";
import Typography from "@material-ui/core/Typography";
import {useAccountingStyles} from "../CustomHooks/useAccountingStyles";
import Card from '@material-ui/core/Card';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import {useInvoiceReportDetails} from "../CustomHooks/useInvoiceReportDetails";
import * as snackbarActions from '../../../Redux/Actions/SnackbarActions/SnackbarActions';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";


const InvoiceReportDetails = ({actions, snackbars, match}) => {
    const classes = useAccountingStyles();
    const  {loading, search,  total, handleSearch, filteredList, page,closeSnackbar,
        rowsPerPage, handleChangeRowsPerPage, handleChangePage,
        handleClickInvoice, invoiceDetails,allInvoices} = useInvoiceReportDetails(actions, match)
    const {open, variant, message} = snackbars;
    const items = [{id: 1, text: 'John Doe'}, {id: 2, text: 'Jane Doe'}]
    return (
        <ReusableSalesTemplate filteredList={filteredList} title='Invoices' handleSearch={handleSearch}
                               search={search} placeholder='Search invoices'
                               icon={<ReceiptOutlinedIcon classes={{root: classes.icon}}/>}
                               handleClick={handleChangePage} subTitle='Invoices' itemList={items}>
            <PharmacySnackbar handleCloseBar={closeSnackbar} variant={variant} message={message} open={open}/>
            <div className="top-bar">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Typography variant='body1'
                                    classes={{body1: classes.body1}}>10</Typography>
                    </Grid>
                    <Grid item xs={6} md={9}>
                        <div className="text-right">
                            {/*<button className='btn btn-sm pharmacy-info-btn'>Record Payment</button>*/}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="pt-3 px-3">
                <div className="text-right">
                    <div className="btn-group mb-2">
                        <button className='btn btn-sm sms-btn-secondary '>
                            <PrintOutlinedIcon classes={{root: classes.icon}}/></button>
                    </div>
                </div>
                <Card className='p-3'>
                        <table className="table table-sm table-borderless prescription-table">
                            <tr>
                                <td>Bill To</td>
                                <td align='right'>Invoice No: 10</td>
                            </tr>
                            <tr>
                                <td>John Doe</td>
                                <td align='right'>Invoice Date: 2021-10-20</td>
                            </tr>
                        </table>
                        <table className='table table-borderless  invoice-table table-sm mt-1'>
                            <thead>
                            <tr>
                                <td/>
                            </tr>
                            <tr>
                                <td>Item</td>
                                <td align='center'>Quantity</td>
                                <td align='center'>Rate</td>
                                <td align='center'>Amount</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>CBC</td>
                                <td align='center'>1</td>
                                <td align='center'>15000</td>
                                <td align='center'>15000</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={2}/>
                                <td align='center'><strong>Total</strong></td>
                                <td align='center'><strong>15000</strong></td>
                            </tr>
                            </tfoot>
                        </table>
                </Card>
            </div>
        </ReusableSalesTemplate>
    )
}
function mapStateToProps(state) {
    return {snackbars: state.snackbars}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceReportDetails);
import {useEffect, useState} from "react";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";

export const useMedicineType = (actions, match={}) =>{
    const {type_id:medicine_type_id} = match;
    const [type_name, setTypeName] = useState('');
    const [type_id, setTypeId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [types, setTypes] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState(false);
    const [medicineType, setType] = useState({});
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();

    const dep = isSubmitted === 'resolved';
    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Cproduct/typeindex`).then(response => {
            const data = response.data
            const dt = data ? data : {};
            const list = dt.type_list ? dt.type_list : []
            setTypes(list);
            setLoading(false)
        }).catch(error => {
            //errorMessages(error, null, actions)
            setLoading(false);
        })
    }, [dep])

    useEffect(() => {
        if (!medicine_type_id) {
            return;
        }
        axios.post(`${config.epharmUrl}/Cproduct/type_update_form`, null, {params: {type_id: medicine_type_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            setType(dt)
        }).catch(error => {
            //errorMessages(error, null, actions)
        })
    }, [medicine_type_id])

    useEffect(() => {
        const {type_name: name} = medicineType;
        setTypeName(name)
    }, [medicineType])

    const handleChange = event => {
        const {value} = event.target;
        setTypeName(value)
    };
    const handleSubmitType = event => {
        event.preventDefault();
        const formData = new FormData();
        if (medicine_type_id) {
            formData.append('type_id', medicine_type_id);
            formData.append('status', 1);
        }
        formData.append('type_name', type_name);
        setSubmitted(true)
        if (type_name) {
            setIsSubmitted('pending');
            const url = medicine_type_id ? 'type_update' : 'insert_type'
            axios.post(`${config.epharmUrl}/Cproduct/${url}`, formData).then(() => {
                actions.snackbarActions.successSnackbar('Medicine type added successfully')
                setIsSubmitted('resolved')
                setOpenDialog(false)
                setTypeName('')
            }).catch(error => {
                errorMessages(error, null, actions)
                setIsSubmitted('rejected')
            })
        }
    };

    const handleOpenDelete = (_id = '') => {
        setTypeId(_id)
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    }

    const deleteMedicineType = () => {
        axios.post(`${config.epharmUrl}/Cproduct/type_delete`, null, {params: {type_id}}).then(() => {
            actions.snackbarActions.successSnackbar('Medicine type deleted successfully');
            const arr = types.filter(item => item.type_id !== type_id)
            setTypes(arr)
            setOpenDelete(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    return {type_name, type_id, openDialog, openDelete, types, submitted, isSubmitted,
        loading, medicineType, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleChange,
        handleSubmitType, handleOpenDelete,
        handleCloseDelete, deleteMedicineType, handleOpenDialog, handleCloseDialog, handleCloseSnackbar}
}
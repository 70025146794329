import React from "react";
import EditAddPerson from "../PersonalLoan/EditAddPerson";
import {Link} from "react-router-dom";


const AddOfficePerson = () =>{
    return(
        <EditAddPerson officePerson officeLoan >
            <div className="styled-buttons">
                <Link to="/managepersonoffice"><button className="btn pharmacy-info-btn">Manage Person</button></Link>
            </div>
        </EditAddPerson>
    )
};
export default AddOfficePerson;
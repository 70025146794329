import * as types from "../../Actions/actionTypes";


const initialState = {
    purchaseList: [],
    purchase_id: '',
    productPurchase: {},
    allProducts: [],
    product: {},
    purchaseUpdate: {},
    formSubmit: {},
    purchase_count: 0
};
export default function purchaseReducer(state = initialState, action) {
    switch (action.type) {
        case types.RETRIEVE_PURCHASE_DETAILS:
            return {...state, productPurchase: action.productPurchase};
        case types.CHECK_PURCHASE_LIST:
            return {...state, purchaseList: action.purchaseList};
        case types.DELETE_PURCHASE:
            return {...state,
                purchaseList: state.purchaseList.filter(purchase => purchase.purchase_id !== action.purchase_id)
            };
        case types.PRODUCTS_BY_MANUFACTURER:
            return {...state, allProducts: action.allProducts};
        case types.SINGLE_PURCHASE_PRODUCT:
            return {
                ...state, product: action.product
            };
        case types.PURCHASE_UPDATE_FORM:
            return {...state, purchaseUpdate: action.purchaseUpdate};
        case types.INVOICE_HTML_FORM:
            return {...state, formSubmit: action.formSubmit};
        case types.PURCHASE_COUNT:
            return {...state, purchase_count: action.purchase_count};
        case types.EDITPURCH_SUCCESS:
            return {
                submitting: false
            };
        case types.EDITPURCH_ERROR:
            return {
                submitting:false
            };
        case types.EDITPURCH_REQUEST:
            return {
                submitting: true
            };
        default:
            return state;
    }
}
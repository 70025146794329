import * as types from "../../Actions/actionTypes";


const initialState = {
    manufacturers: {},
    manufacturer: {},
    manufacturerUpdate: {},
    manufacturer_id: '',
    manufacturerSales: [],
    ledgerReport: [],
    ledgerInfo: {},
    ledger: {},
    manufacturer_count: 0,
    all_manufacturers:[]
};

export default function manufacturerReducer(state = initialState, action) {
    switch (action.type) {
        case types.MANUFACTURER_REQUEST:
            return {
                loading:true
            };
        case types.MANAGE_MANUFACTURER:
            return {
                ...state, manufacturers: action.manufacturers
            };
        case types.RETRIEVE_MANUFACTURER_DETAILS:
            return {
                ...state, manufacturer: action.manufacturer
            };
        case types.MANUFACTURER_UPDATE_FORM:
            return {...state, manufacturerUpdate: action.manufacturerUpdate};
        case types.DELETE_MANUFACTURER:
            return {
                ...state,
                manufacturers: state.manufacturers.filter(manufacturer => manufacturer.manufacturer_id !== action.manufacturer_id)
            };
        case types.MANUFACTURER_SALES_DETAILS:
            return {
                ...state, manufacturerSales: action.manufacturerSales
            };
        case types.MANUFACTURER_LEDGER_REPORT:
            return {
                ...state, ledgerReport: action.ledgerReport,loading:false
            };
        case types.MANUFACTURER_LEDGER_INFO:
            return {
                ...state, ledgerInfo: action.ledgerInfo,loading:false
            };
        case types.MANUFACTURER_LEDGER:
            return {...state, ledger: action.ledger};
        case types.MANUFACTURER_COUNT:
            return {...state, manufacturer_count: action.manufacturer_count};
        case types.RETRIEVE_ALL_MANUFACTURERS:
            return {...state, all_manufacturers:action.all_manufacturers};
        case types.MANUF_SUCCESS:
            return {
                submitting:false
            };
        case types.MANUF_ERROR:
            return {
                submitting:false
            };
        case types.MANUF_REQUEST:
            return{
                submitting:true
            };
        case types.EDITMANUF_SUCCESS:
            return{
                submitting:false
            };
        case types.EDITMANUF_ERROR:
            return{
                submitting:false
            };
        case types.EDITMANUF_REQUEST:
            return{
                submitting:true
            };

        default:
            return state;

    }

}
import React, {useState} from "react";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import axios from "axios";
import config from "../../Helpers/config";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {history} from "../../Helpers/history";
import MainCompany from "./MainCompany";


const AddCompany = ({actions,snackbars}) =>{
    const [state,setState] = useState({company_name:'', email:'', address:'', mobile:'', website:''});
    const [submitted,setSubmitted] = useState(false);
    const [submitting,setSubmitting] = useState(false);

    const handleChange = (event) =>{
        const {name,value} = event.target;
        setState({
            ...state,[name]:value
        })
    };
    const submitCompany = (event) =>{
        event.preventDefault();
        const formData = new FormData();
        formData.append('company_name',state.company_name);
        formData.append('email',state.email);
        formData.append('address',state.address);
        formData.append('mobile',state.mobile);
        formData.append('website',state.website);
        if(state.company_name && state.email && state.address && state.mobile){
            setSubmitted(false);
            setSubmitting(true);
        axios.post(`${config.epharmUrl}/Company_setup/insert_company`,formData).then(()=>{
            setSubmitting(false);
            actions.snackbarActions.successSnackbar('Company successfully submitted');
            history.push('/managecompany')
        }).catch(error=>{
            errorMessages(error,null,actions);
            setSubmitting(false)
        })
        }else{
            setSubmitted(true)
        }
    };
    const handleCloseSnackbar = () =>{
        actions.snackbarActions.hideSnackBar();
    };
    const {variant,open,message} = snackbars;
    return(
        <MainCompany name="Add Company" address={state.address} company_name={state.company_name} email={state.email}
        handleChange={handleChange} handleCloseBar={handleCloseSnackbar} message={message} mobile={state.mobile}
        variant={variant} open={open} submitCompany={submitCompany} submitted={submitted} submitting={submitting}
        website={state.website}/>
    )
};
function mapStateToProps(state) {
    return{
        snackbars:state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions:{
            snackbarActions:bindActionCreators(snackbarActions,dispatch)
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps) (AddCompany);
import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import {titleCase} from "../../Utils/titleCaseFunction";

class ManufDetails extends Component {

    componentDidMount() {
        const {actions} = this.props;
        const {manufacturer_id} = this.props.match.params;
        actions.manufacturerActions.retrieveManufacturerDetails(manufacturer_id)
    }

    render() {
        const {manufacturer} = this.props.manufacturers;
        const purchase_information  = !manufacturer.purchase_info ? [] :manufacturer.purchase_info;
        return (
            <div>
                <DashboardHeader manufacturer manufacturerDetails/>
            <div className="mx-3">
                <div className="pos-buttons">
                    <Link to="/addmanufacturer" style={{textDecoration:"none", color:"inherit"}}>
                        <button type="button" className="btn mr-2 pharmacy-info-btn">
                            <FontAwesomeIcon icon={faPlus} /> Add Supplier</button></Link>
                    <Link to="/managemanufacturer"><button type="button" className="btn pharmacy-info-btn mr-2">
                        <FontAwesomeIcon icon={faList} /> Manage Supplier</button></Link>
                    <Link to="/manufacturerledger"><button type="button" className="btn pharmacy-primary-btn  mr-2">
                        <FontAwesomeIcon icon={faList} /> Manufacturer Supplier</button></Link>
                    <Link to="/manufacturersales"><button type="button" className="btn pharmacy-btn " >
                        <FontAwesomeIcon icon={faList} className="mr-1" />Supplier Sales Details</button></Link>
                </div>
                <div className="card mt-2 p-3">
                    <h6>Supplier Details</h6>
                    <hr/>
                    <div className="customer-info">
                        <p>Supplier Name:<span className="ml-1">{titleCase(manufacturer.manufacturer_name)}</span></p>
                        <p>Mobile: <span>{manufacturer.manufacturer_mobile}</span></p>
                        <p>Address: <span>{titleCase(manufacturer.manufacturer_address)}</span></p>
                    </div>
                </div>
                <div className="card mt-2 p-3">
                    <h6>Supplier Information</h6>
                    <hr/>
                    <div className="customer-table table-responsive">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Invoice No</th>
                                <th>Details</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            {purchase_information.map((info, index)=>(
                                <tr key={index}>
                                <td>{info.final_date}</td>
                                <td><Link to={{pathname:`/manufacturerinvoice/${info.purchase_id}`}} style={{color:"#09905c"}}>{info.chalan_no}</Link></td>
                                <td>{titleCase(info.purchase_details)}</td>
                                <td>{info.grand_total_amount}</td>
                                </tr>
                                ))}

                            <tr>
                                <td colSpan={3} className="text-right">Total:</td>
                                <td>{manufacturer.total_amount}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return{
        manufacturers:state.manufacturers
    }

}
function mapDispatchToProps(dispatch) {
    return{
        actions:{
            manufacturerActions:bindActionCreators(manufacturerActions, dispatch)
        }
    }

}

export default connect(mapStateToProps,mapDispatchToProps) (ManufDetails);
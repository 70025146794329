import React, {Component} from 'react';
import MainSupplierLedger from "./MainSupplierLedger";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as supplierActions from "../../Redux/Actions/SupplierActions/SupplierActions";

class SupplierLedgerReport extends Component {
    state = {
        supplier_value: '',
        from_date: '',
        to_date: '',
        openLedger: false
    };

    componentDidMount() {
        const {actions} = this.props;
        actions.supplierActions.retrieveLedgerReport();
        actions.supplierActions.retrieveAllSuppliers()
    }

    handleChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})

    };

    handleChangeSupplier = (value) =>{
        this.setState({supplier_value:value})
    };
    submitLedger = event =>{
        event.preventDefault();
        const {actions} = this.props;
        const {from_date,to_date, supplier_value} = this.state;
        actions.supplierActions.retrieveSupplierLedger(from_date, to_date, supplier_value.value);
        this.setState(prevState =>({
            openLedger: !prevState.openLedger
        }))
    };

    render() {
        const {ledgerReport,allSuppliers} = this.props.suppliers;
        const supplier = !ledgerReport ? {} : ledgerReport;
        const ledger = !supplier.ledger ? [] : supplier.ledger;
        const {from_date, to_date, supplier_value,openLedger} = this.state;
        const suppliers = !allSuppliers ? [] : allSuppliers;
        return (
            <div>
                <MainSupplierLedger
                    sledgerReport
                    ledgerData={ledger}
                    supplier_value={supplier_value}
                    from_date={from_date}
                    to_date={to_date}
                    supplierLedgerInfo={supplier}
                    handleChange={this.handleChange}
                    handleChangeSupplier={this.handleChangeSupplier}
                    submitLedger={this.submitLedger}
                    openLedger={openLedger}
                    supplierList={suppliers}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        suppliers: state.suppliers
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            supplierActions: bindActionCreators(supplierActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierLedgerReport);
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AppHeader from "./AppHeader";
import {appRoutes} from "../routes";
import {PrivateRoute} from "../PrivateRoute";
import {NavLink, Switch} from "react-router-dom";
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBalanceScale} from "@fortawesome/free-solid-svg-icons/faBalanceScale";
import {faBriefcaseMedical} from "@fortawesome/free-solid-svg-icons/faBriefcaseMedical";
import {faChartBar} from "@fortawesome/free-solid-svg-icons/faChartBar";
import {faHandshake} from "@fortawesome/free-solid-svg-icons/faHandshake";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import {faRetweet} from "@fortawesome/free-solid-svg-icons/faRetweet";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import address from "../Helpers/companyAddress.json"
import {StyledListItemIcon} from "../Utils/List/StyledList";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: '#1290a4'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarApp:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 1),
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
        width: "4px"
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: "rgba(0,0,0,.2)"
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    overflow:'initial'
  },
  logo: {
    display: "block",
    padding: 0,
    fontWeight: 300,
    transition: "width .3s ease-in-out"
},
img: {
    // height: "100px",
    maxWidth: "100px"
},
panelImg: {
    maxWidth: "80px",
    height: "80px",
    border: "2px solid #DCDCDC",
    borderRadius: "50%",
    marginTop: 5
},
info: {
    fontWeight: "500",
    margin: "5px 0",
    letterSpacing: "1px"
},
active: {
    color: "#1290a4"
},
nested: {
    paddingLeft: theme.spacing(4),
},
fontIcons: {
    fontSize: "14px"
},
text: {
    fontSize: "13.7px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"

},
toggleIcon: {
    fontSize: "14px"
},
listItem: {
    padding: 0
},

listItemIcon:{
    minWidth:'33px'
}
}));

function MainPage(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const user = JSON.parse(sessionStorage.getItem('user'));
  const acc_status = JSON.parse(localStorage.getItem('acc_status'));
  const singleUser = !user ? {} : user;
  const user_type = singleUser.user_type ?? '';
  const [invoice, setInvoice] = React.useState(user_type === 1 ? false:true);
  const [customer, setCustomer] = React.useState(false);
  const [isShowSettings, setShowSettings] = React.useState(false);
  const [medicine, setMedicine] = React.useState(false);
  const [unit, setUnit] = React.useState(false);
  const [manufacturer, setManufacturer] = React.useState(false);
  const [purchase, setPurchase] = useState(false);
  const [stock, setStock] = useState(false);
  const [stockReturn, setReturn] = useState(false);
  const [report, setReport] = useState(false);
  const [profit, setProfit] = useState(false);
  const [account, setAccount] = useState(false);
  const [accountReport, setAccountReport] = useState(false);
  const [bank, setBank] = useState(false);
  const [tax, setTax] = useState(false);
  const [hrm, setHrm] = useState(false);
  const [humanResource, setHumanResource] = useState(false);
  const [attendance, setAttendance] = useState(false);
  const [payroll, setPayroll] = useState(false);
  const [expense, setExpense] = useState(false);
  const [fixedAssets, setFixedAssets] = useState(false);
  const [personalLoan, setPersonalLoan] = useState(false);
  const [officeLoan, setOfficeLoan] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [service, setService] = useState(false);
  const [settings, setSettings] = useState(false);
  const [software, setSoftware] = useState(false);
  const [role, setRole] = useState(false);
  const [dataSync, setDataSync] = useState(false);
  const [sms, setSms] = useState(false);

  const toggleSettings = () => {
      setSettings(!settings)
      setService(false)
      setSupplier(false)
      setHrm(false)
      setAccount(false);
      setProfit(false);
      setStock(false);
      setPurchase(false);
      setManufacturer(false);
      setMedicine(false);
      setInvoice(false);
      setCustomer(false);
      setReturn(false);
      setReport(false)
      setBank(false)
      setTax(false)
      setAccount(false)
      
  };

  const toggleBank = () => {
      setBank(!bank)
      setSettings(false)
      setService(false)
      setSupplier(false)
      setHrm(false)
      setAccount(false);
      setProfit(false);
      setStock(false);
      setPurchase(false);
      setManufacturer(false);
      setMedicine(false);
      setInvoice(false);
      setCustomer(false);
      setReturn(false);
      setReport(false)
      setTax(false)
      setAccount(false)
      
  };

  const toggleAccount = () => {
      setAccount(!account);
      setProfit(false);
      setStock(false);
      setPurchase(false);
      setManufacturer(false);
      setMedicine(false);
      setInvoice(false);
      setCustomer(false);
      setReturn(false);
      setReport(false)
      setBank(false)
      setTax(false)
      setHrm(false)
      setService(false)
      setSupplier(false)
      setSettings(false)
      
  };

  const toggleReport = () => {
      setReport(!report);
      setStock(false);
      setPurchase(false);
      setManufacturer(false);
      setMedicine(false);
      setInvoice(false);
      setCustomer(false);
      setReturn(false);
      setBank(false)
      setTax(false)
      setAccount(false)
      setHrm(false)
      setService(false)
      setSupplier(false)
      setSettings(false)
      
  };

  const toggleStock = () => {
      setStock(!stock);
      setPurchase(false);
      setManufacturer(false);
      setMedicine(false);
      setInvoice(false);
      setCustomer(false);
      setReturn(false);
      setReport(false)
      setBank(false)
      setTax(false)
      setAccount(false)
      setHrm(false)
      setService(false)
      setSupplier(false)
      setSettings(false)
      
  };
  const toggleReturn = () => {
      setStock(false);
      setPurchase(false);
      setManufacturer(false);
      setMedicine(false);
      setInvoice(false);
      setCustomer(false);
      setReturn(!stockReturn);
      setReport(false)
      setBank(false)
      setTax(false)
      setAccount(false)
      setHrm(false)
      setService(false)
      setSupplier(false)
      setSettings(false)
      
  };

  const togglePurchase = () => {
      setPurchase(!purchase);
      setManufacturer(false);
      setMedicine(false);
      setInvoice(false);
      setCustomer(false);
      setReturn(false);
      setReport(false)
      setBank(false)
      setTax(false)
      setAccount(false)
      setHrm(false)
      setService(false)
      setSupplier(false)
      setSettings(false)
      
  };

  const toggleManufacturer = () => {
      setStock(false);
      setPurchase(false);
      setManufacturer(!manufacturer);
      setMedicine(false);
      setInvoice(false);
      setCustomer(false);
      setReturn(false)
      setReport(false)
      setBank(false)
      setTax(false)
      setAccount(false)
      setHrm(false)
      setService(false)
      setSupplier(false)
      setSettings(false)
      
  };

  const toggleMedicine = () => {
      setStock(false);
      setPurchase(false);
      setMedicine(!medicine);
      setInvoice(false);
      setCustomer(false);
      setManufacturer(false)
      setReturn(false);
      setReport(false)
      setBank(false)
      setTax(false)
      setAccount(false)
      setHrm(false)
      setService(false)
      setSupplier(false)
      setSettings(false)
      
  };


  const toggleShowSettings = () => {
      setShowSettings(!isShowSettings)
  };
  const toggleCustomer = () => {
      setCustomer(!customer);
      setReturn(false);
      setStock(false);
      setPurchase(false);
      setInvoice(false);
      setMedicine(false);
      setManufacturer(false);
      setReport(false)
      setBank(false)
      setTax(false)
      setAccount(false)
      setHrm(false)
      setService(false)
      setSupplier(false)
      setSettings(false)
      
  };



  const handleOpenInvoice = () => {
      setInvoice(!invoice);
      setStock(false);
      setPurchase(false);
      setManufacturer(false);
      setReturn(false);
      setReport(false)
      setBank(false)
      setTax(false)
      setAccount(false)
      setHrm(false)
      setService(false)
      setSupplier(false)
      setSettings(false)
      setCustomer(false);
      setMedicine(false);
      
  };


  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const permissions = !singleUser.permission ? {} : singleUser.permission;
  //invoice
  const new_invoice = !permissions.new_invoice ? {} : permissions.new_invoice;
  const manage_invoice = !permissions.manage_invoice ? {} : permissions.manage_invoice;
  const pos_invoice = !permissions.pos_invoice ? {} : permissions.pos_invoice;
  const gui_pos = !permissions.gui_pos ? {} : permissions.gui_pos;
  //customer
  const add_customer = !permissions.add_customer ? {} : permissions.add_customer;
  const manage_customer = !permissions.manage_customer ? {} : permissions.manage_customer;
  const credit_customer = !permissions.credit_customer ? {} : permissions.credit_customer;
  const paid_customer = !permissions.paid_customer ? {} : permissions.paid_customer;
  //medicine
  const add_category = !permissions.add_category ? {} : permissions.add_category;
  const medicine_type = !permissions.medicine_type ? {} : permissions.medicine_type;
  const add_medicine = !permissions.add_medicine ? {} : permissions.add_medicine;
  const import_medicine_csv = !permissions.import_medicine_csv ? {} : permissions.import_medicine_csv;
  const manage_medicine = !permissions.manage_medicine ? {} : permissions.manage_medicine;
  const add_unit = !permissions.add_unit ? {} : permissions.add_unit;
  const unit_list = !permissions.unit_list ? {} : permissions.unit_list;
  //manufacturer
  const add_manufacturer = !permissions.add_manufacturer ? {} : permissions.add_manufacturer;
  const manage_manufacturer = !permissions.manage_manufacturer ? {} : permissions.manage_manufacturer;
  const manufacturer_ledger = !permissions.manufacturer_ledger ? {} : permissions.manufacturer_ledger;
  const manufacturer_sales_details = !permissions.manufacturer_sales_details ? {} : permissions.manufacturer_sales_details;
  //purchase
  const add_purchase = !permissions.add_purchase ? {} : permissions.add_purchase;
  const manage_purchase = !permissions.manage_purchase ? {} : permissions.manage_purchase;
  const add_lpo = !permissions.add_lpo ? {} : permissions.add_lpo;
  const edit_lpo = !permissions.edit_lpo ? {} : permissions.edit_lpo;
  const approve_lpo = !permissions.approve_lpo ? {} : permissions.approve_lpo;
  const cancel_lpo = !permissions.cancel_lpo ? {} : permissions.cancel_lpo;
  //stock
  const stock_report = !permissions.stock_report ? {} : permissions.stock_report;
  const stock_report_batch_wise = !permissions.stock_report_batch_wise ? {} : permissions.stock_report_batch_wise;
  //report
  const todays_report = !permissions.todays_report ? {} : permissions.todays_report;
  const sales_report = !permissions.sales_report ? {} : permissions.sales_report;
  const purchase_report = !permissions.purchase_report ? {} : permissions.purchase_report;
  const sales_report_medicine_report = !permissions.sales_report_medicine_wise ? {} : permissions.sales_report_medicine_wise;
  const profit_loss = !permissions.profit_loss ? {} : permissions.profit_loss;
  //return
  const invoice_return = !permissions.return ? {} : permissions.return;
  const stock_return_list = !permissions.stock_return_list ? {} : permissions.stock_return_list;

  //bank
  const add_new_bank = !permissions.add_new_bank ? {} : permissions.add_new_bank;
  const manage_bank = !permissions.manage_bank ? {} : permissions.manage_bank;
  const bank_transaction = !permissions.bank_transaction ? {} : permissions.bank_transaction;

  //accounting
  const chart_of_accounts = !permissions.chart_of_accounts ? {} : permissions.chart_of_accounts;
  const transaction_types = !permissions.transaction_types ? {} : permissions.transaction_types;
  const journals = !permissions.journals ? {} : permissions.journals;
  const expenses = !permissions.expenses ? {} : permissions.expenses;
  const reports = !permissions.reports ? {} : permissions.reports;


  const add_user = !permissions.add_user ? {} : permissions.add_user;
  const manage_users = !permissions.manage_users ? {} : permissions.manage_users;
  const add_role = !permissions.add_role ? {} : permissions.add_role;
  const role_list = !permissions.role_list ? {} : permissions.role_list;
  const user_assign_role = !permissions.user_assign_role ? {} : permissions.user_assign_role;

  const AppDrawer = () =>  (
    <div>

        <List className={classes.listItem}>
        <div className={classes.toolbarApp}>
            <div className={classes.logo}>
            <span onContextMenu={toggleShowSettings}>
                <img className={classes.img} src={address.img_url}
                        style={{width: "70px", margin: '-20% 0 -20% -60%'}} alt="mpeke"/>
            </span>
            </div>
            <IconButton onClick={()=>{setMobileOpen(false)}}>
                {theme.direction === 'rtl' ? <ChevronRightIcon className={classes.toggleIcon}/> :
                    <ChevronLeftIcon className={classes.toggleIcon}/>}
            </IconButton>
        </div>
        <Divider/>
            <NavLink color="inherit" className="navlink text-center" to="/"
                        activeClassName={classes.active}>
                <ListItem button>
                    <StyledListItemIcon><FontAwesomeIcon className={classes.fontIcons} icon={faHome}/></StyledListItemIcon>
                    <ListItemText classes={{root: classes.text}}
                                    disableTypography={true}>Dashboard</ListItemText>
                </ListItem></NavLink>
            {((permissions.new_invoice || permissions.manage_invoice || permissions.pos_invoice || permissions.gui_pos) &&
                (new_invoice.create || new_invoice.read || new_invoice.update || new_invoice.delete ||
                    manage_invoice.create || manage_invoice.read || manage_invoice.update || manage_invoice.delete ||
                    pos_invoice.create || pos_invoice.read || pos_invoice.update || pos_invoice.delete ||
                    gui_pos.create || gui_pos.read || gui_pos.update || gui_pos.delete)) ?
                <ListItem button onClick={handleOpenInvoice}>
                    <StyledListItemIcon>
                        <FontAwesomeIcon className={classes.fontIcons} icon={faBalanceScale}/>
                    </StyledListItemIcon>
                    <ListItemText primary="Invoice" classes={{root: classes.text}} disableTypography={true}/>
                    {invoice ? <ExpandMore className={classes.toggleIcon}/> :
                        <ChevronLeftIcon className={classes.toggleIcon}/>}
                </ListItem> : null}
            <Collapse in={invoice} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.listItem}>
                    {(pos_invoice.create || pos_invoice.read || pos_invoice.update || pos_invoice.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/createinvoice/pos-invoice"><ListItem button className={classes.nested}>
                            <ListItemText primary="POS Invoice" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                    {(new_invoice.create || new_invoice.read || new_invoice.update || new_invoice.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/createinvoice/new-invoice">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="New Invoice" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}
                    {(manage_invoice.create || manage_invoice.read || manage_invoice.update || manage_invoice.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/manageinvoice"><ListItem button className={classes.nested}>
                            <ListItemText primary="Manage Invoice" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}

                </List>
            </Collapse>

            {((permissions.add_customer || permissions.manage_customer || permissions.paid_customer || permissions.credit_customer) &&
                (add_customer.create || add_customer.read || add_customer.update || add_customer.delete ||
                    manage_customer.create || manage_customer.read || manage_customer.update || manage_customer.delete ||
                    credit_customer.create || credit_customer.read || credit_customer.update || credit_customer.delete ||
                    paid_customer.create || paid_customer.read || paid_customer.update || paid_customer.delete)) ?
                <ListItem button onClick={toggleCustomer}>
                    <StyledListItemIcon>
                        <FontAwesomeIcon className={classes.fontIcons} icon={faHandshake}/>
                    </StyledListItemIcon>
                    <ListItemText primary="Customer" classes={{root: classes.text}} disableTypography={true}/>
                    {customer ? <ExpandMore className={classes.toggleIcon}/> :
                        <ChevronLeftIcon className={classes.toggleIcon}/>}
                </ListItem> : null}
            <Collapse in={customer} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.listItem}>
                    {(add_customer.create || add_customer.read || add_customer.update || add_customer.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/addcustomer">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Add Customer" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}
                    {(manage_customer.create || manage_customer.read || manage_customer.update || manage_customer.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/managecustomer/manage_customer"><ListItem button className={classes.nested}>
                            <ListItemText primary="Customers" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                </List>
            </Collapse>

            {((permissions.add_category || permissions.medicine_type || permissions.add_medicine ||
                    permissions.import_medicine_csv || permissions.manage_medicine || permissions.add_unit
                    || permissions.unit_list) &&
                (add_category.create || add_category.read !== 0 || add_category.update || add_category.delete ||
                    medicine_type.create || medicine_type.read || medicine_type.update || medicine_type.delete ||
                    add_unit.create || add_unit.read || add_unit.update || add_unit.delete ||
                    unit_list.create || unit_list.read || unit_list.update || unit_list.delete ||
                    add_medicine.create || add_medicine.read || add_medicine.update || add_medicine.delete ||
                    import_medicine_csv.create || import_medicine_csv.read || import_medicine_csv.update || import_medicine_csv.delete ||
                    manage_medicine.create || manage_medicine.read || manage_medicine.update || manage_medicine.delete)) ?
                <ListItem button onClick={toggleMedicine}>
                    <StyledListItemIcon>
                        <FontAwesomeIcon className={classes.fontIcons} icon={faBriefcaseMedical}/>
                    </StyledListItemIcon>
                    <ListItemText primary="Medicine" classes={{root: classes.text}} disableTypography={true}/>
                    {medicine ? <ExpandMore className={classes.toggleIcon}/> :
                        <ChevronLeftIcon className={classes.toggleIcon}/>}
                </ListItem> : null}
            <Collapse in={medicine} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.listItem}>
                    {(add_medicine.create || add_medicine.read || add_medicine.update || add_medicine.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/addmedicine"><ListItem button className={classes.nested}>
                            <ListItemText primary="Add Medicine" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                    {(manage_medicine.create || manage_medicine.read || manage_medicine.update || manage_medicine.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/managemedicine"><ListItem button className={classes.nested}>
                            <ListItemText primary="Manage Medicine" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                    {(medicine_type.create || medicine_type.read || medicine_type.update || medicine_type.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/medicinetype"><ListItem button className={classes.nested}>
                            <ListItemText primary="Medicine Type" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                    {(add_unit.create || add_unit.read || add_unit.update || add_unit.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/addunit">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Unit" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}
                    {(add_category.create || add_category.read !== 0 || add_category.update || add_category.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/managecategory">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Category" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}


                </List>
            </Collapse>

            {((permissions.add_purchase || permissions.manage_purchase || add_lpo || edit_lpo ||
                    approve_lpo || cancel_lpo) &&
                (add_purchase.create || add_purchase.read || add_purchase.update || add_purchase.delete ||
                    manage_purchase.create || manage_purchase.read || manage_purchase.update || manage_purchase.delete ||
                    add_lpo.create || add_lpo.read || add_lpo.update || add_lpo.delete ||
                    edit_lpo.create || edit_lpo.read || edit_lpo.update || edit_lpo.delete ||
                    approve_lpo.create || approve_lpo.read || approve_lpo.update || approve_lpo.delete ||
                    cancel_lpo.create || cancel_lpo.read || cancel_lpo.update || cancel_lpo.delete)) ?
                <ListItem button onClick={togglePurchase}>
                    <StyledListItemIcon>
                        <FontAwesomeIcon className={classes.fontIcons} icon={faShoppingCart}/>
                    </StyledListItemIcon>
                    <ListItemText primary="Purchase" classes={{root: classes.text}} disableTypography={true}/>
                    {purchase ? <ExpandMore className={classes.toggleIcon}/> :
                        <ChevronLeftIcon className={classes.toggleIcon}/>}
                </ListItem> : null}
            <Collapse in={purchase} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.listItem}>

                    {(manage_purchase.create || manage_purchase.read || manage_purchase.update || manage_purchase.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/managepurchase"><ListItem button className={classes.nested}>
                            <ListItemText primary="Purchases" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                    {(add_lpo.create || add_lpo.read || add_lpo.update || add_lpo.delete ||
                        edit_lpo.create || edit_lpo.read || edit_lpo.update || edit_lpo.delete ||
                        approve_lpo.create || approve_lpo.read || approve_lpo.update || approve_lpo.delete ||
                        cancel_lpo.create || cancel_lpo.read || cancel_lpo.update || cancel_lpo.delete) ?

                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/managepurchaseorder">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Purchase Orders" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink>
                        : null}

                </List>
            </Collapse>

            {((permissions.stock_report || permissions.stock_report_batch_wise) &&
                (stock_report.create || stock_report.read || stock_report.update || stock_report.delete ||
                    stock_report_batch_wise.create || stock_report_batch_wise.read || stock_report_batch_wise.update
                    || stock_report_batch_wise.delete)) ?
                <ListItem button onClick={toggleStock}>
                    <StyledListItemIcon>
                        <FontAwesomeIcon className={classes.fontIcons} icon={faChartBar}/>
                    </StyledListItemIcon>
                    <ListItemText primary="Stock" classes={{root: classes.text}} disableTypography={true}/>
                    {stock ? <ExpandMore className={classes.toggleIcon}/> :
                        <ChevronLeftIcon className={classes.toggleIcon}/>}
                </ListItem> : null}
            <Collapse in={stock} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.listItem}>
                    {(stock_report.create || stock_report.read || stock_report.update ||
                        stock_report.delete || stock_report_batch_wise.create || stock_report_batch_wise.read || stock_report_batch_wise.update
                        || stock_report_batch_wise.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/stockreport">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Stock Report" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}
                    {/*{(stock_report_batch_wise.create || stock_report_batch_wise.read || stock_report_batch_wise.update*/}
                    {/*    || stock_report_batch_wise.delete) ?*/}
                    {/*    <NavLink color="inherit" className="navlink" activeClassName={classes.active}*/}
                    {/*             to="/stockbatchwise"><ListItem button className={classes.nested}>*/}
                    {/*        <ListItemText primary="Stock Report (Batch Wise)" classes={{root: classes.text}}*/}
                    {/*                      disableTypography={true}/>*/}
                    {/*    </ListItem></NavLink> : null}*/}
                    {(stock_report.create || stock_report.read || stock_report.update || stock_report.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/stockadjustment">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Stock Adjustment" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}
                </List>
            </Collapse>

            {(permissions.return || permissions.stock_return_list || permissions.manufacturer_return_list ||
                permissions.wastage_return_list) ?
                <ListItem button onClick={toggleReturn}>
                    <StyledListItemIcon>
                        <FontAwesomeIcon className={classes.fontIcons} icon={faRetweet}/>
                    </StyledListItemIcon>
                    <ListItemText primary="Return" classes={{root: classes.text}} disableTypography={true}/>
                    {stockReturn ? <ExpandMore className={classes.toggleIcon}/> :
                        <ChevronLeftIcon className={classes.toggleIcon}/>}
                </ListItem> : null}
            <Collapse in={stockReturn} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.listItem}>
                    {(invoice_return.create || invoice_return.read || invoice_return.update || invoice_return.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/return">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Add Return" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}
                    {(stock_return_list.create || stock_return_list.read || stock_return_list.update
                        || stock_return_list.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/stockreturnlist"><ListItem button className={classes.nested}>
                            <ListItemText primary="Return List" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                </List>
            </Collapse>

            {((permissions.todays_report || permissions.sales_report || permissions.purchase_report ||
                    permissions.sales_report_medicine_wise || permissions.profit_loss) &&
                (todays_report.create || todays_report.read || todays_report.update || todays_report.delete ||
                    sales_report.create || sales_report.read || sales_report.update || sales_report.delete ||
                    purchase_report.create || purchase_report.read || purchase_report.update || purchase_report.delete ||
                    sales_report_medicine_report.create || sales_report_medicine_report.read ||
                    sales_report_medicine_report.update || sales_report_medicine_report.delete ||
                    profit_loss.create || profit_loss.read || profit_loss.update || profit_loss.delete)) ?
                <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                            to="/allreports"><ListItem button onClick={toggleReport}>
                    <StyledListItemIcon>
                        <FontAwesomeIcon className={classes.fontIcons} icon={faBook}/>
                    </StyledListItemIcon>
                    <ListItemText primary="Reports" classes={{root: classes.text}} disableTypography={true}/>
                </ListItem></NavLink> : null}

            {((permissions.add_manufacturer || permissions.manage_manufacturer || permissions.manufacturer_ledger ||
                    permissions.manufacturer_sales_details) &&
                (add_manufacturer.create || add_manufacturer.read || add_manufacturer.update || add_manufacturer.delete ||
                    manage_manufacturer.create || manage_manufacturer.read || manage_manufacturer.update || manage_manufacturer.delete ||
                    manufacturer_ledger.create || manufacturer_ledger.read || manufacturer_ledger.update || manufacturer_ledger.delete ||
                    manufacturer_sales_details.create || manufacturer_sales_details.read ||
                    manufacturer_sales_details.update || manufacturer_sales_details.delete)) ?
                <ListItem button onClick={toggleManufacturer}>
                    <StyledListItemIcon>
                        <FontAwesomeIcon className={classes.fontIcons} icon={faUser}/>
                    </StyledListItemIcon>
                    <ListItemText primary="Supplier" classes={{root: classes.text}} disableTypography={true}/>
                    {manufacturer ? <ExpandMore className={classes.toggleIcon}/> :
                        <ChevronLeftIcon className={classes.toggleIcon}/>}
                </ListItem> : null}
            <Collapse in={manufacturer} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.listItem}>
                    {/*{(add_manufacturer.create || add_manufacturer.read || add_manufacturer.update || add_manufacturer.delete) ?*/}
                    {/*    <NavLink color="inherit" className="navlink" activeClassName={classes.active}*/}
                    {/*             to="/addmanufacturer">*/}
                    {/*        <ListItem button className={classes.nested}>*/}
                    {/*            <ListItemText primary="Add Supplier" classes={{root: classes.text}}*/}
                    {/*                          disableTypography={true}/>*/}
                    {/*        </ListItem></NavLink> : null}*/}
                    {(manage_manufacturer.create || manage_manufacturer.read || manage_manufacturer.update || manage_manufacturer.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/managemanufacturer"><ListItem button className={classes.nested}>
                            <ListItemText primary="Manage Supplier" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                    {/*{(manufacturer_ledger.create || manufacturer_ledger.read || manufacturer_ledger.update || manufacturer_ledger.delete) ?*/}
                    {/*    <NavLink color="inherit" className="navlink" activeClassName={classes.active}*/}
                    {/*             to="/manufacturerledger"><ListItem button className={classes.nested}>*/}
                    {/*        <ListItemText primary="Supplier Ledger" classes={{root: classes.text}}*/}
                    {/*                      disableTypography={true}/>*/}
                    {/*    </ListItem></NavLink> : null}
                    {(manufacturer_sales_details.create || manufacturer_sales_details.read ||
                        manufacturer_sales_details.update || manufacturer_sales_details.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/manufacturersales"><ListItem button className={classes.nested}>
                            <ListItemText primary="Supplier Sales Details"
                                            classes={{root: classes.text}} disableTypography={true}
                                // className="text-wrap"
                            />
                        </ListItem></NavLink> : null*/}
                </List>
            </Collapse>
            {/*{(add_new_bank.create || add_new_bank.read || add_new_bank.update || add_new_bank.delete ||*/}
            {/*    manage_bank.create || manage_bank.read || manage_bank.update || manage_bank.delete ||*/}
            {/*    bank_transaction.create || bank_transaction.read || bank_transaction.update || bank_transaction.delete*/}
            {/*) ?*/}
            {/*    <ListItem button onClick={toggleBank}>*/}
            {/*        <StyledListItemIcon>*/}
            {/*            <AccountBalanceIcon className={classes.fontIcons}/>*/}
            {/*        </StyledListItemIcon>*/}
            {/*        <ListItemText primary="Bank" classes={{root: classes.text}} disableTypography={true}/>*/}
            {/*        {bank ? <ExpandMore className={classes.toggleIcon}/> :*/}
            {/*            <ChevronLeftIcon className={classes.toggleIcon}/>}*/}
            {/*    </ListItem>*/}
                {/*// : null}*/}
            {/*<Collapse in={bank} timeout="auto" unmountOnExit>*/}
            {/*    <List component="div" disablePadding>*/}
                    {/*{(add_new_bank.create || add_new_bank.read || add_new_bank.update ||*/}
                    {/*    add_new_bank.delete || manage_bank.create || manage_bank.read*/}
                    {/*    || manage_bank.update || manage_bank.delete) ?*/}
                    {/*    <NavLink color="inherit" className="navlink" activeClassName={classes.active}*/}
                    {/*             to="/banks">*/}
                    {/*        <ListItem button className={classes.nested}>*/}
                    {/*            <ListItemText primary="Banks" classes={{root: classes.text}}*/}
                    {/*                          disableTypography={true}/>*/}
                    {/*        </ListItem></NavLink>*/}
                    {/*: null}*/}

                    {/*{(bank_transaction.create || bank_transaction.read || bank_transaction.update || bank_transaction.delete) ?*/}
                    {/*    <NavLink color="inherit" className="navlink" activeClassName={classes.active}*/}
                    {/*             to="/banktransactions"><ListItem button className={classes.nested}>*/}
                    {/*        <ListItemText primary="Transactions" classes={{root: classes.text}}*/}
                    {/*                      disableTypography={true}/>*/}
                    {/*    </ListItem></NavLink>*/}
                    {/*: null}*/}
            {/*    </List>*/}
            {/*</Collapse>*/}
            {/* {(chart_of_accounts.create || chart_of_accounts.read || chart_of_accounts.update || chart_of_accounts.delete ||
                transaction_types.create || transaction_types.read || transaction_types.update || transaction_types.delete ||
                journals.create || journals.read || journals.update || journals.delete ||
                reports.create || reports.read || reports.update || reports.delete ||
                expenses.create || expenses.read || expenses.update || expenses.delete) ?
                <ListItem button onClick={toggleAccount}>
                    <StyledListItemIcon>
                        <FontAwesomeIcon className={classes.fontIcons} icon={faMoneyBill}/>
                    </StyledListItemIcon>
                    <ListItemText primary="Accounting" classes={{root: classes.text}} disableTypography={true}/>
                    {account ? <ExpandMore className={classes.toggleIcon}/> :
                        <ChevronLeftIcon className={classes.toggleIcon}/>}
                </ListItem> : null}
            <Collapse in={account} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {acc_status !== 1 ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/accounting/setup">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Setup" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}
                    {(chart_of_accounts.create || chart_of_accounts.read || chart_of_accounts.update || chart_of_accounts.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/coa">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Chart of Accounts" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}

                    {(transaction_types.create || transaction_types.read || transaction_types.update || transaction_types.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/transactiontypes"><ListItem button className={classes.nested}>
                            <ListItemText primary="Transaction Types" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}

                    {(journals.create || journals.read || journals.update || journals.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/journals">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Journals" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}
                    {(expenses.create || expenses.read || expenses.update || expenses.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/expenses">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Expenses" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}
                    {(reports.create || reports.read || reports.update || reports.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/accountingreports">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Reports" classes={{root: classes.text}}
                                                disableTypography={true}/>
                            </ListItem></NavLink> : null}
                </List>
            </Collapse> */}
            <ListItem button onClick={toggleSettings}>
                <StyledListItemIcon>
                    <FontAwesomeIcon className={classes.fontIcons} icon={faCog}/>
                </StyledListItemIcon>
                <ListItemText primary="Settings" classes={{root: classes.text}} disableTypography={true}/>
                {settings ? <ExpandMore className={classes.toggleIcon}/> :
                    <ChevronLeftIcon className={classes.toggleIcon}/>}
            </ListItem>
            <Collapse in={settings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {(manage_users.create || manage_users.read || manage_users.update
                        || manage_users.delete || add_user.create || add_user.read || add_user.update
                        || add_user.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/manageusers"><ListItem button className={classes.nested}>
                            <ListItemText primary="Manage Users" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                    {(role_list.create || role_list.read || role_list.update
                        || role_list.delete || add_role.create || add_role.read || add_role.update || add_role.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/rolelist"><ListItem button className={classes.nested}>
                            <ListItemText primary="Roles" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                    {(user_assign_role.create || user_assign_role.read || user_assign_role.update
                        || user_assign_role.delete) ?
                        <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                    to="/assignuserrole"><ListItem button className={classes.nested}>
                            <ListItemText primary="Assign User Role" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink> : null}
                        {/*<NavLink color="inherit" className="navlink" activeClassName={classes.active}*/}
                        {/*         to="/addcompany"><ListItem button className={classes.nested}>*/}
                        {/*    <ListItemText primary="Add Company" classes={{root: classes.text}}*/}
                        {/*                  disableTypography={true}/>*/}
                        {/*</ListItem></NavLink>*/}
                    <NavLink color="inherit" className="navlink" activeClassName={classes.active}
                                to="/support">
                        <ListItem button className={classes.nested}>
                            <StyledListItemIcon>
                                <FontAwesomeIcon className={classes.fontIcons} icon={faQuestionCircle}/>
                            </StyledListItemIcon>
                            <ListItemText primary="Support" classes={{root: classes.text}}
                                            disableTypography={true}/>
                        </ListItem></NavLink>
                </List>
            </Collapse>
        </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppHeader position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppHeader>
      <nav className={classes.drawer} aria-label="modules">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
           <AppDrawer/>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <AppDrawer/>
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
            {appRoutes.map((route, index) => {
                return (
                    <PrivateRoute key={index} exact={route.exact} path={route.path}
                                    component={route.component}/>
                )
            })}
        </Switch>
      </main>
    </div>
  );
}

export default MainPage;

import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import GuiPosInvoice from "./GUIPosInvoice";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import GUIPosInvoiceForm from "./GUIPosInvoiceForm";
import axios from "axios";
import config from "../../Helpers/config";
import * as customerActions from "../../Redux/Actions/CustomerActions/CustomerActions";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import {convertDate} from "../../Utils/titleCaseFunction";
import {errorMessages} from "../../Helpers/ErrorMessages";
// import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {history} from "../../Helpers/history";
import Card from "@material-ui/core/Card";


export class MainGuIComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            product_name: '',
            category_id: '',
            rows: [],
            total_discount: 0,
            grand_total_price: '',
            change: '',
            n_total: '',
            paid_amount: '',
            due_amount: '',
            invdcount: '',
            total_tax: 0,
            selectCustomer: 'Walking Customer',
            customer_id: '1',
            showCustomers: false,
            invoice_date: convertDate(),
            bank_id: '',
            paytype: 1,
            openInvoice: false,
            submitted: false,
            invoice_id: '',
            inva_details: ''
        }
    }

    componentDidMount() {
        const {actions} = this.props;
        actions.invoiceActions.retrieveGuiPosData();
    }

    handleRetrieveCustomers = (event) => {
        const {actions} = this.props;
        const val = event.target.value.trim();
        if (val !== '') {
            actions.invoiceActions.fetchCustomers(event.target.value);
        }
        this.setState({selectCustomer: event.target.value, showCustomers: true})

    };

    handleClickCustomer = event => {
        const {actions} = this.props;
        this.setState({selectCustomer: event.target.innerText, showCustomers: false, customer_id: event.target.value});
        actions.invoiceActions.getPreviousBalance(event.target.value);
    };

    handleSearch = (event) => {
        const {value} = event.target;
        this.setState({value})
    };
    handleChangeCategory = value => {
        const {product_name} = this.state;
        const {actions} = this.props;
        this.setState({category_id: value});
        actions.invoiceActions.getGuiItemList(product_name, value.value);

    };
    handleAddRows = (product_id) => {
        const {actions} = this.props;
        const {rows} = this.state;
        axios.get(config.epharmUrl + "/Cinvoice/gui_pos_invoice", {params: {product_id}}).then(response => {
            const data = response.data
            const dt = data ? data : [];
            this.setState({rows: [...rows, dt]});
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };

    handleDeleteRow = (index) => {
        const {rows} = this.state;
        const newRows = rows.slice(0, index).concat(rows.slice(index + 1));
        this.setState({
            rows: newRows,
        });
    };

    handleChangeBatch = (event, idx) => {
        const {actions} = this.props;
        let rows = [...this.state.rows];
        rows[idx]['batch_id'] = event.target.value;
        axios.get(config.epharmUrl + "/Cinvoice/retrieve_product_batch_id", {params: {batch_id: rows[idx]['batch_id']}}).then(response => {
            if(response.data){
                rows[idx]['available_quantity'] = response.data.total_product < 0 ? 0 : response.data.total_product;
                rows[idx]['expire_date'] = response.data.expire_date;
                this.setState({rows});
            }


        }).catch(error => {
            errorMessages(error, null, actions)
        });

        this.setState({rows});


    };

    handleChangeQuantity = (event, index) => {
        const {actions} = this.props;
        // const {productInfo} = this.props.invoices;
        let {value} = event.target;
        let {rows} = this.state;

        if (rows[index]) {
            rows[index]["product_quantity"] = value;
            if (rows[index]["product_quantity"] > rows[index]["available_quantity"]) {
                actions.snackbarActions.warningSnackbar(`You cannot sell more than ${rows[index]["available_quantity"]} Item(s)`);
                // rows[index]["product_quantity"] = 0;
                rows[index]["total_price"] = 0
            } else {
                this.calculateFinalTotal(index);
            }

        }
        this.setState({rows});

    };
    handleChangeDiscount = (event, index) => {
        const {value} = event.target;
        const {rows} = this.state;
        if (rows[index]) {
            rows[index].product_details['discount'] = value;
            this.calculateFinalTotal(index);
            this.calculateTotalDiscount(rows);
        } else {
            rows[index] = {
                // ['discount']: value
            }
        }
        this.setState({rows});
    };

    calculateTotal = (index) => {
        const {rows} = this.state;
        return +(!rows[index]['product_quantity'] ? 0 : rows[index]['product_quantity']) * +(rows[index].product_details['price']);

    };
    calculateDiscount = (index) => {
        const {rows} = this.state;
        let discount = !rows[index].product_details['discount'] ? 0 : +(rows[index].product_details['discount']) / 100;
        return this.calculateTotal(index) * discount;

    };

    calculateFinalTotal = (index) => {
        const {rows} = this.state;
        rows[index]['total_price'] = this.calculateTotal(index) - this.calculateDiscount(index);
        this.calculateGrandTotal(rows);
    };
    calculateGrandTotal = (arr) => {
        let {paid_amount} = this.state;
        const {previous} = this.props.invoices;
        const previous_amount = !previous ? {} : previous;
        const previous_balance = !previous_amount.balance ? 0 : +(previous_amount.balance);
        const sum = arr.reduce((a, row) => {
            return Number(a + !row.total_price ? 0 : row.total_price);
        }, 0);
        let net_total = +(sum + (previous_balance));
        const amount = +(net_total - paid_amount);
        const final_amount = amount > 0 ? amount : 0;
        const change = +(paid_amount - net_total);
        const final_change = change > 0 ? change : 0;
        this.setState({
            grand_total_price: sum,
            n_total: net_total,
            due_amount: final_amount,
            change: final_change
        });

    };
    calculateTotalDiscount = (arr) => {
        const discount = arr.reduce((a, row) => {
            let total_apparent = +(row.product_details.price) * +(row.product_quantity);
            let dis = !row.product_details.discount ? 0 : +(row.product_details.discount * total_apparent);
            let disc = dis / 100;
            return +(a + disc);
        }, 0);
        this.setState({total_discount: discount.toFixed(2, 2)});
    };
    calculateChange = () => {
        const {paid_amount, n_total} = this.state;
        const due_amount = +(n_total - paid_amount);
        const change = +(paid_amount - n_total);
        const final_due = due_amount > 0 ? due_amount : 0;
        const final_change = change > 0 ? change : 0;
        this.setState({due_amount: final_due, change: final_change});

    };

    handleChangeBalance = (event) => {
        const {value} = event.target;
        this.setState({paid_amount: value}, () => this.calculateChange());

    };
    handleCloseCustomerField = () => {
        this.setState({showCustomers: false})
    };

    handleOpenInvoiceDialog = (invoice_id) => {
        this.setState({openInvoice: true, invoice_id: invoice_id})
    };
    handleCloseInvoiceDialog = () => {
        this.setState({openInvoice: false})
    };

    handleSaveInvoice = event => {
        event.preventDefault();
        const {actions} = this.props;
        const {
            rows, selectCustomer, bank_id, inva_details, grand_total_price, n_total, paid_amount, due_amount, change, invoice_date,
            total_tax, invdcount, customer_id, total_discount, paytype
        } = this.state;
        const {previous} = this.props.invoices;
        const previousObj = !previous ? {} : previous;
        const previous_amount = !previousObj.balance ? 0 : +(previousObj.balance);
        const credit_limit = !previousObj.credit_limit ? 0 : previousObj.credit_limit;
        let formData = new FormData();
        if (due_amount <= credit_limit || credit_limit === 0) {
            formData.append('invoice_date', invoice_date);
            formData.append('customer_id', customer_id);
            formData.append('customer_name', selectCustomer);
            formData.append('paytype', paytype);
            formData.append('bank_id', bank_id);
            for (let row in rows) {
                if (rows.hasOwnProperty(row)) {
                    formData.append('product_name', rows[row].product_details.product_name);
                    formData.append('product_id', rows[row].product_details.product_id);
                    formData.append('batch_id', rows[row].batch_id);
                    formData.append('product_quantity', rows[row].product_quantity);
                    formData.append('discount', rows[row].product_details.discount);
                    formData.append('product_rate', rows[row].product_details.price);
                    formData.append('total_price', rows[row].total_price);
                    formData.append('tax', 0);
                }
            }
            formData.append('total_tax', total_tax);
            formData.append('inva_details', inva_details);
            formData.append('invdcount', invdcount);
            formData.append('total_discount', total_discount);
            formData.append('total_discount_amount', total_discount);
            formData.append('grand_total_price', grand_total_price);
            formData.append('previous', previous_amount);
            formData.append('n_total', n_total);
            formData.append('paid_amount', paid_amount);
            formData.append('due_amount', due_amount);
            formData.append('change', change);
            this.setState({submitted: true});
            axios.post(config.epharmUrl + "/Cinvoice/insert_invoice", formData).then(response => {
                if (response.data) {
                    actions.snackbarActions.successSnackbar("Invoice saved successfully!");
                    this.setState({submitted: false});
                    this.handleOpenInvoiceDialog(response.data.invoice_id)
                }
            }).catch(error => {
                errorMessages(error, null, actions)
                this.setState({submitted: false});
            })

        } else {
            actions.snackbarActions.infoSnackbar(`The customer has reached their credit limit of
             ${credit_limit.toLocaleString()}.\n Please clear the balance before purchasing more items`)
        }
    };

    handlePrint = () => {
        const {invoice_id} = this.state;
        history.push(`/posdetails/${invoice_id}`)
    };


    render() {
        const {guiList, guiPos, customers, previous} = this.props.invoices;
        const {
            value, category_id, rows, total_discount, grand_total_price, change, n_total, paid_amount,
            due_amount, invdcount, total_tax, selectCustomer, customer_id, showCustomers, invoice_date,
            bank_id, paytype, openInvoice, invoice_id, submitted
        } = this.state;
        const gui = !guiPos ? {} : guiPos;
        const categories = !gui.categorylist ? [] : gui.categorylist;
        const previousObj = !previous ? {} : previous;
        const previous_amount = !previousObj.balance ? 0 : previousObj.balance;
        return (
            <div>
                <DashboardHeader  component="GUI POS Invoice" heading="Invoice"
                                  subHeading="GUI invoice" link="/guipos" />
                
                    <div className="pos-buttons text-right mb-2" >
                        <Link to="/todaysInvoice">
                            <button type="button" className="btn mr-2 btn-sm pharmacy-info-btn">
                                <FontAwesomeIcon icon={faList}/> Today's Sales
                            </button>
                        </Link>
                    </div>
               
                <Card className=" p-2 mx-2">
                    <div className="row ">
                        <div className="col-md-5" style={{paddingLeft: "25px", paddingTop: "15px"}}>
                            <GuiPosInvoice
                                value={value}
                                category_id={category_id}
                                guiList={guiList}
                                categories={categories}
                                handleSearch={this.handleSearch}
                                handleChangeCategory={this.handleChangeCategory}
                                handleAddRows={this.handleAddRows}
                            />
                        </div>
                        <div className="col-md-7" style={{paddingLeft: "25px", paddingTop: "15px"}}>
                            <GUIPosInvoiceForm
                                rows={rows}
                                invoice_date={invoice_date}
                                bank_id={bank_id}
                                paytype={paytype}
                                total_discount={total_discount}
                                grand_total_price={grand_total_price}
                                change={change}
                                n_total={n_total}
                                paid_amount={paid_amount}
                                due_amount={due_amount}
                                invdcount={invdcount}
                                total_tax={total_tax}
                                handleDeleteRow={this.handleDeleteRow}
                                handleChangeBatch={this.handleChangeBatch}
                                handleChangeQuantity={this.handleChangeQuantity}
                                handleChangeDiscount={this.handleChangeDiscount}
                                handleChangeBalance={this.handleChangeBalance}
                                selectCustomer={selectCustomer}
                                customer_id={customer_id}
                                showCustomers={showCustomers}
                                handleRetrieveCustomers={this.handleRetrieveCustomers}
                                handleClickCustomer={this.handleClickCustomer}
                                customers={customers}
                                previous={previous_amount}
                                handleCloseCustomerField={this.handleCloseCustomerField}
                                openInvoice={openInvoice}
                                handleCloseInvoiceDialog={this.handleCloseInvoiceDialog}
                                invoice_id={invoice_id}
                                handleSubmitInvoice={this.handleSaveInvoice}
                                submitted={submitted}
                                handlePrint={this.handlePrint}
                            />
                        </div>
                    </div>

                </Card>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        invoices: state.invoices,
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            invoiceActions: bindActionCreators(invoiceActions, dispatch),
            customerActions: bindActionCreators(customerActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(MainGuIComponent);
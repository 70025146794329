import React from "react";
import EditAddPerson from "./EditAddPerson";
import CommonButtons from "./CommonButtons";


const AddPerson = () => {
    return (
        <EditAddPerson addPerson personalLoan>
            <CommonButtons addPerson/>
        </EditAddPerson>
    )
};
export default AddPerson;
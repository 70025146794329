import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../Helpers/config.json";
import {titleCase} from "../../../Utils/titleCaseFunction";
import {errorMessages} from "../../../Helpers/ErrorMessages";


export const useInvoiceReportDetails = (actions, match) => {
    const {invoice_no} = match.params;
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [total, setTotal] = useState(0);
    const [allInvoices, setAllInvoices] = useState([])
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [specificItem, setItem] = useState({invoice_no, customer_name: ''})


    useEffect(() => {
        axios.get(`${config.epharmUrl}/cbilling/retrieallInvoices`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.invoices ? [] : dt.invoices;
            const items = arr.map(item=>({
                id:item.invoice_no,
                text:item.customer_name
            }))
            setAllInvoices(items);
        }).catch(err => {
            errorMessages(err,null,actions)
        })
    }, []);


    useEffect(() => {
        const {invoice_no, customer_name} = specificItem;
        const formData = new FormData();
        formData.append('invoice_no', invoice_no);
        axios.post(`${config.epharmUrl}/cinvoice/posinvoicedetails`, formData).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : [];
            const total= arr.reduce((sum, item) => {
                return +sum + +item.amount;
            }, 0)
            setInvoiceDetails(dt)
            setTotal(total)
            setLoading(false)
        }).catch(err => {
            errorMessages(err, null,actions)
            setLoading(false)
        })

    }, [specificItem])


    const handleClickInvoice = (item) => {
        const {id, text} = item;
        setItem({invoice_no: id, customer_name: text})
    }

    const handleSearch = (e) => {
        const {value} = e.target;
        setSearch(value)
    }


    const filteredList = (arr) => {
        return arr.filter(data => {
            if (search === '')
                return data
            else if (titleCase(data.text).includes(titleCase(search))) {
                return data
            }
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar();
    }


    return {loading, search,  total, handleSearch, filteredList, page,closeSnackbar,
        rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleClickInvoice, invoiceDetails,allInvoices}
}
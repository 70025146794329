import React, {useEffect, useState} from 'react';
import MainCustomerDetails from "./MainCustomerDetails";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {ReusableRouteTabs} from "../../Utils/Tabs/ReusableTabs";
import {history} from "../../Helpers/history";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {dateStringConvert, titleCase} from "../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";


const CustomerLedger = ({actions, snackbars, match: {params}}) => {
    const [customerLedger, setCustomerLedger] = useState({});
    const [loading, setLoading] = useState(false)
    const {customer_id} = params;

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Ccustomer/customerledger/${customer_id}`).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            setCustomerLedger(dt)
            setLoading(false)
        }).catch((error) => {
            errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [customer_id, actions])

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };


    const customer = !customerLedger ? {} : customerLedger
    const invoice_information = !customer.ledger ? [] : customer.ledger;

    const items = [{label: 'Ledger', path: `/customer_ledger/${customer_id}`},
        {label: 'Purchases', path: `/customerpurchases/${customer_id}`}];

    const miniTable = (
        <table className="table-striped table table-bordered table-sm mpeke-customer-table">
            <tr>
                <td>Debit Amount</td>
                <td>{customer.total_debit ? customer.total_debit : 0}</td>
            </tr>
            <tr>
                <td>Credit Amount</td>
                <td>{customer.total_credit ? customer.total_credit : 0}</td>
            </tr>
            <tr>
                <td>Balance Amount</td>
                <td>{customer.total_balance ? customer.total_balance : 0}</td>
            </tr>
        </table>
    )
    const tableData = (
        <tbody>
        {invoice_information.map((invoice, index) => {
            const deb = invoice.d_c === 'd' ? invoice.amount : 0;
            const cred = invoice.d_c === 'c' ? invoice.amount : 0;
            return (
                <tr key={index}>
                    <td>{dateStringConvert(invoice.date)}</td>
                    <td>{invoice.invoice_no}</td>
                    <td>{invoice.invoice_no}</td>
                    <td>{titleCase(invoice.description)}</td>
                    <td>{invoice.d_c === 'd' ? invoice.amount : 0}</td>
                    <td>{invoice.d_c === 'c' ? invoice.amount : 0}</td>
                    <td>{deb - cred}</td>
                </tr>)
        })}
        <tr>
            <td colSpan={4} className="text-right">Total:</td>
            <td>{customer.total_debit ? customer.total_debit : 0}</td>
            <td>{customer.total_credit ? customer.total_credit : 0}</td>
            <td>{customer.total_balance ? customer.total_balance : 0}</td>
        </tr>
        </tbody>
    )
    const headData = ["Date", "Invoice No", "Receipt No", "Description", "Debit", "Credit", "Balance"]
    const {open, variant, message} = snackbars;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Customer Ledger' heading="Customer"
                                     subHeading='Customer Ledger' link={history.location.pathname}/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={items}/>
            <PharmacySnackbar open={open} variant={variant} message={message} handleCloseBar={handleCloseBar}/>
            <MainCustomerDetails customer={customer} data={invoice_information} miniTable={miniTable}
                                 headData={headData} tableData={tableData}
                                 loading={loading} title='Customer Ledger'/>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLedger);
import React,{Component} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from 'react-bootstrap/Card';
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";








class TaxSettings extends Component{
   render(){
       
       return(
           <div>
               <DashboardHeader tax taxSettings/>
               <Card className="card p-3">
                    <h6>Tax Settings</h6>
                    <hr/>
                    <Form autoComplete="off">
                    <Form.Group as={Row}>
                        <Form.Label column sm="2" className="text-left">Number of Tax <span className="text-danger asterics">*</span></Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" placeholder="2"/>
                        </Col>
                       

                       
                        
                        
                        </Form.Group>
                        
                    <Form.Group as={Row}>

                        <Form.Label column sm="1" className="text-left">Tax Name 1 <span className="text-danger asterics">*</span></Form.Label>
                        <Col sm="2">
                            <Form.Control type="text" placeholder="Tax Name"/>
                        </Col>
                        <Form.Label column sm="1" className="text-left">Default Value % </Form.Label>
                        <Col sm="2">
                            <Form.Control type="numbers" placeholder="1"/>
                        </Col>
                        
                        <Form.Label column sm="2" className="text-left">Registration No. </Form.Label>
                        <Col sm="2">
                            <Form.Control type="text" placeholder="234234"/>
                        </Col>

                        <Col sm="2">
                            <Form.Check type="checkbox" label="Is Show" style={{transform: "scale(1)"}}/>
                        </Col>
                        </Form.Group>



                        <Form.Group as={Row}>
                        
                        <Form.Label column sm="1" className="text-left">Tax Name 2 <span className="text-danger asterics">*</span></Form.Label>
                        <Col sm="2">
                            <Form.Control type="numbers" placeholder="VAT"/>
                        </Col>
                       <Form.Label column sm="1" className="text-left">Default Value % </Form.Label>
                        <Col sm="2">
                            <Form.Control type="numbers" placeholder="1"/>
                        </Col>
                        
                        <Form.Label column sm="2" className="text-left">Registration No. </Form.Label>
                        <Col sm="2">
                            <Form.Control type="text" placeholder="2432134"/>
                        </Col>

                        <Col sm="2">
                            <Form.Check type="checkbox" label="Is Show" style={{transform: "scale(1)"}}/>
                        </Col>
                        </Form.Group>
                        



                    
                    <div  className="text-center">
                        <button type="submit" className="btn pharmacy-info-btn px-4">Save
                        </button>
                    </div>

                </Form>
            </Card>
           </div>
       );
   }
}
export default TaxSettings;
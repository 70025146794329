import {useEffect, useRef, useState} from "react";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/exportPDF";
import {copyToClipboard} from "../../../Utils/copyToClipboard";
import {history} from "../../../Helpers/history";

export const useMedicineCategory = (actions, match={}) =>{
    const {category_id} = match;
    const [category_name, setCategoryName] = useState('');
    const [product_category_id, setCategoryId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [categories, setCategories] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState({});
    const [term, setTerm] = useState('')
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const componentRef = useRef();

    const dep = isSubmitted === 'resolved';
    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Ccategory/manage_category`).then(response => {
            const data = response.data
            const dt = data ? data : {};
            const list = dt.category_list ? dt.category_list : []
            setCategories(list);
            setLoading(false)
        }).catch(error => {
            //errorMessages(error, null, actions)
            setLoading(false);
        })
    }, [dep])

    useEffect(() => {
        if (!category_id) {
            return;
        }
        axios.post(`${config.epharmUrl}/Ccategory/category_update_form`, null, {params: {category_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            setCategory(dt)
        }).catch(error => {
            //errorMessages(error, null, actions)
        })
    }, [category_id])

    useEffect(() => {
        const {category_name: name} = category;
        setCategoryName(name)
    }, [category])

    const handleSearch = (value) =>{
        setTerm(value)
    }

    const handleChange = event => {
        const {value} = event.target;
        setCategoryName(value)
    };
    const handleSubmitCategory = event => {
        event.preventDefault();
        const formData = new FormData();
        if (category_id) {
            formData.append('category_id', category_id);
            formData.append('status', 1);
        }
        formData.append('category_name', category_name);
        setSubmitted(true)
        if (category_name) {
            setIsSubmitted('pending');
            const url = category_id ? 'category_update' : 'insert_category'
            axios.post(`${config.epharmUrl}/Ccategory/${url}`, formData).then(() => {
                actions.snackbarActions.successSnackbar(`Category ${category_id ? 'updated':'added'}  successfully`)
                setIsSubmitted('resolved');
                setOpenDialog(false);
                setCategoryName('');
                if (category_id){
                    history.push('/managecategory')
                }
            }).catch(error => {
                errorMessages(error, null, actions)
                setIsSubmitted('rejected')
            })
        }
    };

    const handleOpenDelete = (_id = '') => {
        setCategoryId(_id)
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    }

    const deleteCategory = () => {
        axios.post(`${config.epharmUrl}/Ccategory/category_delete`, null, {params: {category_id:product_category_id}}).then(() => {
            actions.snackbarActions.successSnackbar('Category deleted successfully');
            const arr = categories.filter(item => item.category_id !== product_category_id)
            setCategories(arr)
            setOpenDelete(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const downloadPDF = () => {
        exportPDF("Medicine Categories Report", [["SL", "Category Name"]],
            categories.map((med, index) => [index += 1, med.category_name]), "Medicine Categories.pdf");
    };
    const copyTable = () => {
        copyToClipboard(actions, 10, componentRef)
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    return {category_name, product_category_id, openDialog, openDelete, categories, submitted, isSubmitted,
        loading, category, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleChange,
        handleSubmitCategory, handleOpenDelete, handleCloseDelete, deleteCategory, handleOpenDialog,
        handleCloseDialog, handleCloseSnackbar,downloadPDF,copyTable,componentRef, term, handleSearch}
}
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";

const MainFixedPurchaseAsset = (props) => {
    const {assets, addNewRows,deleteRow, addAsset,editAsset} = props;
    return (
        <div>
        <DashboardHeader fixedAsset addPurchaseAsset={addAsset} editPurchaseAsset={editAsset}/>
        <div className="card styled-table-card p-3">
            <h6>{addAsset ? "Add Purchase" :"Edit Purchase"}</h6>
            <hr/>
            <Form autoComplete="off">
                <Form.Row>
                    <Col>
                        <Form.Group as={Row}>
                            <Form.Label column sm={3}>Purchase Date<span className="text-danger asterics">*</span></Form.Label>
                            <Col sm={8}>
                                <Form.Control type="date"/>
                            </Col>
                        </Form.Group>

                    </Col>
                    <Col>
                        <Form.Group as={Row}>
                            <Form.Label column sm={3}>Payment Type<span className="text-danger asterics">*</span></Form.Label>
                            <Col sm={8}>
                                <Select/>
                            </Col>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group as={Row}>
                            <Form.Label column sm={3}>Supplier<span className="text-danger asterics">*</span></Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"/>
                            </Col>
                        </Form.Group>

                    </Col>
                    <Col>
                        <Form.Group as={Row}>
                            <Form.Label column sm={3}>Address</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"/>
                            </Col>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group as={Row}>
                            <Form.Label column sm={3}>Previous</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"/>
                            </Col>
                        </Form.Group>

                    </Col>
                    <Col/>
                </Form.Row>
                <div className="table-responsive">
                    <table className="table  table-bordered">
                        <thead>
                        <tr>
                            <td>Item Code</td>
                            <td>Item Information</td>
                            <td>Item Quantity</td>
                            <td>Sale Price</td>
                            <td>Total</td>
                            <td>Action</td>
                        </tr>
                        </thead>

                        <tbody >
                        {assets.map((asset, index)=>(
                            <tr key={index}>
                                <td><Form.Control type="text" name="item_code" value={asset.item_code}/></td>
                                <td><Form.Control type="text" name="item_name" value={asset.item_name}/></td>
                                <td><Form.Control type="text" name="item_qty" value={asset.item_qty}/></td>
                                <td><Form.Control type="text" name="item_price" value={asset.item_price}/></td>
                                <td><Form.Control type="text" name="total_price" value={asset.total_price}/></td>
                                <td><button type="button" onClick={()=>deleteRow(index)} className="btn btn-sm pharmacy-btn-dismiss">Delete</button></td>
                            </tr> ))}
                        <tr>
                            <td colSpan={3}/>
                            <td>Grand Total</td>
                            <td align="right"><Form.Control type="text"/></td>
                        </tr>
                        <tr>
                            <td colSpan={5}>
                                <button type="button" onClick={addNewRows} className="btn btn-sm pharmacy-info-btn">Add New Item</button>
                            </td>
                        </tr>
                        </tbody>

                    </table>
                </div>
                <button className="btn pharmacy-primary-btn px-4">Submit</button>
            </Form>
        </div>
        </div>
    )
};
export default MainFixedPurchaseAsset;
import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as medicineActions from "../../Redux/Actions/MedicineActions/MedicineActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import {titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import Card from '@material-ui/core/Card';
import Row from 'react-bootstrap/Row';
import TextField from "../../Utils/FormInputs/TextField";
import Label from "../../Utils/FormInputs/Label";
import SelectInput, {CreatableCustomSelect, CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Textarea from "../../Utils/FormInputs/Textarea";
import {useAddMedicine} from "./CustomHooks/useAddMedicine";
import {AddProductForm} from "./AddProductForm";


const  AddMedicine  = ({actions, match,snackbars}) => {
    const {product_id} = match.params;
const {productProps, handleCloseSnackbar} = useAddMedicine(actions,product_id)
        const {open, variant, message} = snackbars;
        const title = product_id ? 'Edit Medicine' : 'Add Medicine';
        return (
            <div className='journals'>
                <ReusableDashboardHeader component={title} heading="Medicine"
                                         subHeading={title} link={history.location.pathname}/>
                <div className="general-ledger-header">
                    <div className="row">
                        <div className="col-md-3">
                            <h6 className='mt-2 mb-0'>{title}</h6>
                        </div>
                        <div className="col-md-9">
                            <div className="text-right">
                                <Link to="/managemedicine">
                                    <button type="button" className="btn  pharmacy-btn-outline">
                                        <FontAwesomeIcon icon={faList}/> Manage Medicine
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-medicine-card">
                    <PharmacySnackbar open={open} variant={variant} message={message}
                                      handleCloseBar={handleCloseSnackbar}/>
                    <Card className="p-3 general-card">
                        <AddProductForm {...productProps}/>
                    </Card>
                </div>
            </div>
        )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars,
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMedicine);
import React from "react";
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col";
import {titleCase} from "../../Utils/titleCaseFunction";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import {Card} from "@material-ui/core";



const MainCompany = ({company_name,email,mobile,address,website,handleChange,submitted,submitting,open,message,variant,
                         submitCompany,handleCloseBar}) =>{
    return(
        <div className='journals'>
            <ReusableDashboardHeader component='Add Company' heading="Settings"
                                     subHeading='Add Company' link={history.location.pathname}/>
            <div className='general-ledger-header'>
                <h6>Add Company</h6>
            </div>
            <Card className=" styled-component p-3">
                <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={handleCloseBar}/>
                <form onSubmit={submitCompany} autoComplete="off">
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Company Name<span
                                className="text-danger asterics ml-1">*</span></Form.Label>
                            <TextField type="text"  name="company_name"
                                          value={titleCase(company_name)} onChange={handleChange}/>
                            {(submitted && !company_name) && <div className="invalid-text">Please enter the company name</div>}
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Mobile<span
                                className="text-danger asterics ml-1">*</span></Form.Label>
                            <TextField type="text" name="mobile"
                                          value={mobile} onChange={handleChange}/>
                            {(submitted && !mobile) && <div className="invalid-text">Please enter the company mobile</div>}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email<span
                                className="text-danger asterics ml-1">*</span></Form.Label>
                            <TextField type="email"  name="email"
                                          value={email} onChange={handleChange}/>
                            {(submitted && !email) && <div className="invalid-text">Please enter the company email</div>}
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Address<span
                                className="text-danger asterics ml-1">*</span></Form.Label>
                            <Textarea as="textarea"  name="address"
                                          value={address} onChange={handleChange}/>
                            {(submitted && !address) && <div className="invalid-text">Please enter the company address</div>}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label>Website</Form.Label>
                            <TextField type="url" name="website" value={website}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridZip">
                        </Form.Group>
                    </Form.Row>
                    <button type="submit"  disabled={!!submitting }
                            className="btn pharmacy-btn offset-md-5">{submitting ? "Submitting...":"Submit"}</button>
                </form>
            </Card>
        </div>
    )
};
export default MainCompany;
import * as types from "../../Actions/actionTypes";

const initialState = {
    statistics: {}
};
export default function dashboardReducer(state = initialState, action){
    switch (action.type) {
        case types.GET_DASHBOARD_STATISTICS:
            return{
                ...state, statistics:action.statistics
            }
        default:
            return state;
    }
};
import React, { PureComponent } from 'react';
import {ComposedChart, Line, Area,  XAxis, YAxis, CartesianGrid, Tooltip,AreaChart,
    Legend,  ResponsiveContainer,
} from 'recharts';
import {CustomizedAxisTick} from "./ReusableLineChart";



export default class ReusableAreaChart extends PureComponent {

    render() {
        const { data,toPercent, dataKey, label,xlabel, children, height} = this.props;
        const h = height ? height : 350;
        return (
            <div data-testid="sms-composed-chart">
                <ResponsiveContainer width="100%" height={h}>
                    <AreaChart
                        data={data}
                        margin={{top: 20, right: 0, bottom: 20, left: 20}}
                    >
                        {/*<CartesianGrid stroke="#f5f5f5" vertical={false}/>*/}
                        <XAxis dataKey={dataKey} height={60} label={xlabel} tick={<CustomizedAxisTick/>} padding={{right: 10 }}/>
                        <YAxis label={label} allowDecimals={false} tick={<CustomizedAxisTick/>}/>
                        <Tooltip/>

                        {children}
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

import React,{Component} from "react";
import Form from "react-bootstrap/Form";
// import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";
import Card from 'react-bootstrap/Card';
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
// import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";






class AddIncomeTax extends Component{
    render(){
        
        return(
            <div>
                <DashboardHeader add incomeTax/>
                <Card className="card p-3">
                     <h6>Setup Tax</h6>
                     <hr/>
                     <Form autoComplete="off">
                     <table className="table">
                            <th>SL.</th>
                            <th>Start Amount</th>
                            <th>End Amount</th>
                            <th>Tax Rate</th>
                            <th>Delete?</th>
                            <th>Add More?</th>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td><Form.Control type = "text"/> </td>
                                    <td><Form.Control type = "text"/> </td>
                                    <td><Form.Control type = "text"/> </td>

                                    <td>
                                    <div  className="text-center">
                                        <button type="submit" className="btn btn-sm pharmacy-btn-dismiss">
                                        <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                    </div></td>
                                   <td>
                                   <div  className="text-center">
                                        <button type="submit" className="btn  pharmacy-info-btn btn-sm">
                                        <FontAwesomeIcon icon={faPlus}/>
                                        </button>
                                    </div></td>
                                    
                                    
                                    
                                </tr>
                            </tbody>

                        </table>

                     
                     
 
 
 
                         
                         
 
 
 
                     
                     <div  className="text-center">
                         <button type="submit" className="btn pharmacy-info-btn px-4  mr-1" >Reset
                         </button>

                         <button type="submit" className="btn pharmacy-info-btn px-4">Setup
                         </button>

                     </div>
                     
 
 
                 </Form>
             </Card>
            </div>
        );
    }
 }
 export default AddIncomeTax;
import React from 'react'
import {useAccountingStyles} from "../CustomHooks/useAccountingStyles";
import Grid from "@material-ui/core/Grid";
import {ledgerStyles} from "./GeneralLedger";
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../../Utils/FormInputs/Label";
import Dropdown from "react-bootstrap/Dropdown";
import TextField from "../../../Utils/FormInputs/TextField";
import {CSVLink} from "react-csv";

export const SortSelect = ({category,categories,handleChangeCategory, placeholder}) =>{
    const classes = useAccountingStyles();
    return(
        <div className={classes.customSelect}>
            <Form.Group as={Row} style={{marginBottom:'.4rem'}}>
                <Label name='Sort by:' sm={2} column style={{textAlign:'right'}}/>
                <Col sm={5}>
                    <CustomSelect value={category} options={categories} onChange={handleChangeCategory} placeholder={placeholder}/>
                </Col>
            </Form.Group>
        </div>
        )

}

const ReusableSalesHeader = ({children, periods, years, period,downLoadPdf,csvRef,csvTitle, csvData, headers,
                                 from_date, to_date, handleChangeDate,  year, inputTypes, handleChangePeriod,
                                  handleChangeYear, fetchCsvData} = {}) => {
    const classes = useAccountingStyles();
    return (
        <div className="acc_reports journals">
            <div className="general-ledger-header mb-3">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        <div style={ledgerStyles.filters}>
                            <Form.Group as={Row}>
                                <Col sm={3}>
                                    <CustomSelect onChange={handleChangePeriod} value={period} options={periods}/>
                                </Col>
                                {period.value !== 'today' ? <>
                                    {period.value === 'yearly' ?
                                        <Col sm={3}>
                                            <CustomSelect options={years} value={year}
                                                         onChange={handleChangeYear}/>
                                        </Col> :
                                        <Col sm={5}>
                                            <Form.Group as={Row}>
                                                {period.value === 'custom' ?
                                                    <Label name='From' column sm={2}
                                                           style={{textAlign: 'right'}}/> : null}
                                                <Col sm={10}>
                                                    <TextField type={inputTypes[period.value]}
                                                               onChange={handleChangeDate}
                                                               value={from_date} name='from_date'/>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    }
                                </> : null}
                                {period.value === 'custom' ? <Col sm={4}>
                                    <Form.Group as={Row}>
                                        <Label name='To' column sm={2}/>
                                        <Col sm={10}>
                                            <TextField type='date' value={to_date} onChange={handleChangeDate}
                                                       name='to_date'/>
                                        </Col>
                                    </Form.Group>
                                </Col> : null}
                            </Form.Group>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={4} classes={{root: classes.item}}>
                        <div className='export-dropdown'>
                            <Dropdown align='start'>
                                <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                    Export As
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={downLoadPdf} style={{fontSize:'.85em'}}>PDF</Dropdown.Item>
                                    <Dropdown.Item onClick={fetchCsvData}  style={{fontSize:'.85em'}}>CSV</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <CSVLink
                                data={csvData}
                                filename={`${csvTitle}.csv`}
                                className="hidden"
                                headers={headers}
                                ref={csvRef}
                                target="_blank"
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            {children}
        </div>
    )

}


export default ReusableSalesHeader

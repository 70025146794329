import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {removeTime} from "../../../Utils/titleCaseFunction";

export const useEditPurchaseData = (actions, purchase_id) => {
    const [purchase, setPurchase] = useState({});
    const [products, setProducts] = useState([])

    useEffect(() => {
        if (!purchase_id) {
            return
        }
        axios.get(config.epharmUrl + "/Cpurchase/purchase_update_form", {params: {purchase_id}}).then(response => {
            const purchaseinfo = response.data;
            const dt = purchaseinfo ? purchaseinfo : {}
            const all_purchase = dt.purchase_info ? dt.purchase_info : [];
            setPurchase({
                payment_type: dt?.paytype,
                invoice_no: dt?.chalan_no,
                purchase_date: dt?.purchase_date,
                details: dt?.purchase_details,
                manufacturer_name: {
                    value: !dt?.manufacturer_selected ? '' :
                        dt?.manufacturer_selected.manufacturer_id,
                    label: !dt?.manufacturer_selected ? '' :
                        dt?.manufacturer_selected.manufacturer_name
                },
                grand_total: dt?.grand_total
            })
            const data = all_purchase.map(item => ({
                ...item,
                product_name: item.product_name,
                product_id: item.product_id,
                batch_id: item.batch_id,
                expiry_date: item.expeire_date ? removeTime(item.expeire_date) : '',
                total_product: item.stock_qty,
                product_quantity: item.quantity,
                product_rate: item.manufacturer_price,
                total_price: item.total_amount,
                discount: item.discount,
                isValidBatch:null
            }))
            setProducts(data)
        }).catch(error => {
            errorMessages(error, null, actions)

        })
    }, [])
    return {purchase, products}
}

export const usePurchaseOrderData = (actions, local_purchase_order_id) => {
    const [orderData, setOrderData] = useState([]);
    const [manufacturer, setManufacturer] = useState({});

    useEffect(() => {
        if (!local_purchase_order_id) {
            return
        }
        axios.get(config.epharmUrl + "/Cpurchaseorder/purchaseoder_update_form", {params: {local_purchase_order_id}}).then(response => {
            const info = response.data;
            const dt = info ? info : {}
            const all_purchase =  dt.product_list ? dt.product_list :[];
            setManufacturer({value:dt?.manufacturer_id, label:dt?.manufacturer_name})
            const data = all_purchase.map(item => ({
                product_name: item.product_name,
                product_id: item.product_id,
                batch_id: '',
                expiry_date: '',
                total_product: 0,
                product_quantity: item.quantity,
                product_rate: item.rate,
                total_price: item.quantity * item.rate,
                discount: 0,
                disableExpiry:true,
                isValidBatch: null
            }))
            setOrderData(data)
        }).catch(error => {
            errorMessages(error, null, actions)

        })
    }, [])
    return {orderData, manufacturer}
}
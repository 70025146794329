/* eslint-disable no-unused-expressions */
import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {Scrollbars} from 'react-custom-scrollbars';
import FilterResults from 'react-filter-search';
import Select from "react-select";

class GuiPosInvoice extends Component {
    render() {
        const {guiList,categories,category_id,value,handleChangeCategory,handleSearch, handleAddRows} = this.props;
        return (
            <div id="gui-pos-invoice">
                <div className="card my-3 p-3" style={{backgroundColor: "#dee2e6"}}>
                    <div className="row">
                        <div className="col-md-5">
                            <Select
                                options={categories.map(category=>({
                                    label:category.category_name,
                                    value:category.category_id
                                }))}
                                value={category_id}
                                onChange={handleChangeCategory}
                            />

                        </div>
                        <div className="col-md-5">
                            <Form.Control type="text" placeholder="Search By Product" value={value}
                                          onChange={handleSearch}/>
                        </div>
                        {/*<div className="col-md-2">*/}
                        {/*    <Button> <FontAwesomeIcon icon={faSearch}/></Button>*/}
                        {/*</div>*/}

                    </div>
                </div>
                <Scrollbars style={{height: 500, backgroundColor: "#dee2e6"}}>
                    <div className="row ">
                        <FilterResults value={value} data={guiList} renderResults={results => (
                            results.map((item, index) => {
                                return (<div className="col-sm-4" id="1" key={index}>
                                        <div className="card  m-2" style={{width: "8rem"}} onClick={()=>handleAddRows(item.product_id)}>
                                            <div className="card-body">
                                                <img src="/images/product.png" className="card-img-top" alt="..."
                                                     style={{width: "3rem"}}/>
                                            </div>
                                            <div className="card-footer">{item.product_name}</div>
                                        </div>
                                    </div>
                                )
                            }))
                        }/>
                    </div>
                </Scrollbars>
            </div>
        )
    }

}


export default GuiPosInvoice



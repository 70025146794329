import {useEffect, useRef, useState} from "react";
import {convertDate, titleCase} from "../../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

function useAddStockAdjustment(actions, params={}) {
    const {product_id:prdt_id, product_name:prdt_name} = params
    const [products, setProducts] = useState([])
    const [product, setProduct] = useState({product_name:'', product_id:prdt_id, selected_product:prdt_name})
    const [showProduct, setShowProduct] = useState(false)
    const [purchaseDate, setPurchaseDate] = useState(convertDate);
    const [stockCsv, setStockCsv] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [allStock, setAllStock] = useState([])
    const [drugs, setDrugs] = useState([])
    const csvLink = useRef()
    const {product_id, selected_product} = product

    useEffect(()=>{
        if(!product_id){
            return
        }
        axios.post(`${config.epharmUrl}/Cinvoice/retrieve_product_data_inv`, null,
            {params: {product_id: product_id}}).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const arr = dt.batch ? dt.batch : []
            const batchData = arr.map(item=>({
                batch_id:item.batch_id,
                expiry_date:item.expiry_date,
                stock:item.stock,
                physical_stock:'',
                quantity:'',
                unit_cost:item.unit_cost,
                isBatch:true,

            }))
            const allBatches = arr.length > 0 ? batchData : [{
                batch_id:'',
                expiry_date:'',
                stock:'',
                physical_stock:'',
                quantity:'',
                unit_cost:'',
                isBatch:false,

            }]
            // const limit = arr[0] ? arr[0] : {}
            const expiry_limit = dt.expiry_limit ? dt.expiry_limit:0
            const obj = {product_name:selected_product, product_id:product_id,expiry_limit, open:true, batches:allBatches}
            setProducts([...products,obj])
        }).catch(error => {
            errorMessages(error, null, actions)
        });

    },[product_id])


    const handleOpen = (idx) =>{
        const itemsArr = products.map((item, index)=>{
            if(index === idx){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setProducts(itemsArr)
    }

    const handleRetrieveDrugs = (event) => {
        setProduct({...product,product_name:event.target.value})
        let tmp = event.target.value.trim();
        if (tmp !== '') {
            axios.get(`${config.epharmUrl}/Cinvoice/autocompleteproductsearch`, {params: {product_name: titleCase(tmp)}}).then(response => {
                const drugs = response.data;
                const dt = !drugs ? [] : drugs;
                setDrugs(dt)
            }).catch(error => {
                errorMessages(error, null, actions)
            })
            setShowProduct(true)
        }else {
            setShowProduct(false)
        }
    };

    const handleClickDrug = (event) => {
        setProduct({product_id: event.value, product_name:'', selected_product: event.innerText})
        setShowProduct(false)
    };

    const handleAddBatch = (idx) =>{
        const obj =  {  batch_id:'',
            expiry_date:'',
            stock:0,
            physical_stock:'',
            quantity:'',
            unit_cost:'',
            isBatch:false
        }
        const arr = products.map((item, index)=>{
            if(index === idx){
                return {...item, batches:[...item.batches,obj]}
            }
            return item
        })
        setProducts(arr)

    }

    const handleRemoveProduct = (index) =>{
        const arr = products.filter((_, idx) => index !== idx)
        setProducts(arr)
    }

    const handleRemoveBatch = (idx, batch_idx) =>{
        const arr = products.map((item, index)=>{
            if(index === idx){
                const arr = item.batches.filter((_, batch_index)=>batch_index !== batch_idx)
                return {...item, batches:arr}
            }
            return item
        })
        setProducts(arr)
    }

    const handleChangeItem = (event,idx, batch_idx) =>{
        const {name, value} = event.target
        const arr = products.map((item, index)=>{
            if(index === idx){
                const arr = item.batches.map((batch, batch_index)=>{
                    if (batch_index === batch_idx){
                        return {...batch, [name]:value}
                    }
                    return batch
                })
                return {...item, batches:arr}
            }
            return item
        })
        setProducts(arr)
    }


    const handleChangeDate = (event) =>{
        setPurchaseDate(event.target.value)
    }


    const handleOpenDialog = () => {
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleUploadCsv = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('upload_csv_file', stockCsv ? stockCsv : '');
        axios.post(`${config.epharmUrl}/Creport/upload_adjustment_CSV`, formData)
            .then(() => {
                actions.snackbarActions.successSnackbar('Stock csv uploaded Successfully');
                setTimeout(() => {
                    history.push('/stockreport')
                }, 2000);
            }).catch(error => {
            errorMessages(error, null, actions);
        })
    };

    const handleChangePurchaseCsv = event => {
        if (event.target.files[0].name.substr(event.target.files[0].name.length - 4) === ".csv") {
            setStockCsv(event.target.files[0])
        } else {
            event.target.value = null;
            actions.snackbarActions.errorSnackbar('Please upload only csv format')
        }

    };



    const handleSubmit = event => {
        event.preventDefault();
        const user = JSON.parse(sessionStorage.getItem('user'));
        const singleUser = !user ? {} : user;
        const user_id = !singleUser.user_id ? "" : singleUser.user_id;
        setSubmitted(true)
        const data = products.reduce((r, a) => r.concat(a.batches.map(b => ({product_id: a.product_id,...b}))), []);
        const old_stock = data.filter(item=>item.isBatch).filter(item=>item.physical_stock !== '').map(({physical_stock, batch_id, product_id})=>({batch_id,product_id, quantity: +physical_stock}))
        const new_stock = data.filter(item=>!item.isBatch).map(({physical_stock, isBatch, stock, unit_cost, ...item})=>({...item,product_id, quantity: +physical_stock, unit_cost:+unit_cost}))
        const arr = data.filter(item=>!item.isBatch).every(item => item.batch_id && item.expiry_date && item.unit_cost && item.physical_stock !== '');
        if (arr) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Creport/save_adjustment`,{user_id,stock_data:old_stock, new_stock})
                .then(() => {
                    actions.snackbarActions.successSnackbar('Stock adjusted Successfully');
                    history.push('/stockreport')
                    setIsSubmitted('resolved')
                }).catch(error => {
                errorMessages(error, null, actions);
                setIsSubmitted('rejected')
            })
        }
    };


    const fetchData = () => {
        axios.get(`${config.epharmUrl}/Creport/getStock`).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const stock_list = !dt.stock ? [] : dt.stock
            setAllStock(stock_list)
            csvLink.current.link.click()
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };



    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    return {products, product,drugs, showProduct,handleOpen, handleClickDrug, handleRetrieveDrugs,csvLink,
        purchaseDate,isResolved, isPending, isRejected, submitted, handleAddBatch, handleRemoveBatch,
        handleRemoveProduct, handleChangeItem, handleChangePurchaseCsv, handleChangeDate, handleOpenDialog,
        fetchData, openDialog,allStock, handleCloseBar, handleCloseDialog,handleSubmit,handleUploadCsv}
}
export {useAddStockAdjustment}
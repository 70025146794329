import React, {Component} from 'react';
import {Form} from "react-bootstrap";
// import {Link} from "react-router-dom";
import * as userActions from "../../Redux/Actions/UserActions/UserActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faEye} from "@fortawesome/free-solid-svg-icons";
import {history} from "../../Helpers/history";
import * as alertActions from "../../Redux/Actions/AlertActions";


class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            submitted:false
        };
        history.listen((location, action)=>{
            const {actions} = this.props;
            actions.alertActions.clearAlert();
        });
    }


    handleChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    };
    handleResetPassword = event => {
        event.preventDefault();
        const {actions} = this.props;
        const {email} = this.state;
        actions.userActions.resetPassword(email);
        this.setState({submitted:true});
    };

    render() {
        const {email,submitted} = this.state;
        const {type, message} = this.props.alert;
        const {submitting}=this.props.users;
        return (
            <div className="styled-reset">
                {message && <div className={` alert ${type}`}>{message}</div>}
                <div className="card styled-reset-card p-4">
                    <div className="py-4">
                        <div className="text-center styled-reset-content mb-3">
                            <h4>Reset Password</h4>
                        </div>
                        <Form onSubmit={this.handleResetPassword} autoComplete="off">
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Recovery Email" value={email} name="email"
                                              onChange={this.handleChange}/>
                                {(submitted && !email) && <div className="invalid-text">Please enter your email</div>}
                            </Form.Group>
                            <div className="text-center">
                                <button className="btn pharmacy-btn px-5 mt-3" disabled={!!submitting}
                                style={{width: "100%"}}>{submitting ? "Sending....":"Send email"}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        users:state.users
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            userActions: bindActionCreators(userActions, dispatch),
            alertActions:bindActionCreators(alertActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
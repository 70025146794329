import {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/exportPDF";
import {copyToClipboard} from "../../../Utils/copyToClipboard";

export const useManageMedicine = (actions) =>{
    const [openDialog, setOpenDialog] = useState(false);
    const [product_id, setProductId] = useState('');
    const [product_count, setProductCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const csvLink = useRef();
    const componentRef = useRef();

    useEffect(() => {
        const formData = new FormData();
        formData.append("draw", 1);
        formData.append("columns[0][data]", "sl");
        formData.append("columns[0][name]", "");
        formData.append("columns[0][searchable]", "true");
        formData.append("columns[0][orderable]", false);
        formData.append("columns[0][search][value]", "");
        formData.append("columns[0][search][regex]", false);
        formData.append("columns[1][data]", "product_name");
        formData.append("columns[1][name]", "");
        formData.append("columns[1][searchable]", true);
        formData.append("columns[1][orderable]", true);
        formData.append("columns[1][search][value]", "");
        formData.append('columns[1][search][regex]', false);
        formData.append("columns[2][data]", "generic_name");
        formData.append("columns[2][name]", "");
        formData.append("columns[2][searchable]", true);
        formData.append("columns[2][orderable]", false);
        formData.append("columns[2][search][value]", "");
        formData.append("columns[2][search][regex]", false);
        formData.append("columns[3][data]", "product_model");
        formData.append('columns[3][name]', "");
        formData.append("columns[3][searchable]", true);
        formData.append("columns[3][orderable]", false);
        formData.append("columns[3][search][value]", "");
        formData.append("columns[3][search][regex]", false);
        formData.append("columns[4][data]", "manufacturer_name");
        formData.append("columns[4][name]", "");
        formData.append("columns[4][searchable]", true);
        formData.append("columns[4][orderable]", false);
        formData.append("columns[4][search][value]", "");
        formData.append("columns[4][search][regex]", false);
        formData.append("columns[5][data]", "product_location");
        formData.append("columns[5][name]", "");
        formData.append("columns[5][searchable]", true);
        formData.append("columns[5][orderable]", false);
        formData.append("columns[5][search][value]", "");
        formData.append("columns[5][search][regex]", false);
        formData.append("columns[6][data]", "price");
        formData.append("columns[6][name]", "");
        formData.append("columns[6][searchable]", true);
        formData.append("columns[6][orderable]", false);
        formData.append("columns[6][search][value]", "");
        formData.append("columns[6][search][regex]", false);
        formData.append("columns[7][data]", "purchase_p");
        formData.append("columns[7][name]", "");
        formData.append("columns[7][searchable]", true);
        formData.append("columns[7][orderable]", false);
        formData.append("columns[7][search][value]", "");
        formData.append("columns[7][search][regex]", false);
        formData.append("columns[8][data]", "strength");
        formData.append("columns[8][name]", "");
        formData.append("columns[8][searchable]", true);
        formData.append("columns[8][orderable]", false);
        formData.append("columns[8][search][value]", "");
        formData.append("columns[8][search][regex]", false);
        formData.append("columns[9][data]", "image");
        formData.append("columns[9][name]", "");
        formData.append("columns[9][searchable]", true);
        formData.append("columns[9][orderable]", false);
        formData.append("columns[9][search][value]", "");
        formData.append("columns[9][search][regex]", false);
        formData.append('columns[10][data]', "button");
        formData.append("columns[10][name]", "");
        formData.append("columns[10][searchable]", true);
        formData.append("columns[10][orderable]", false);
        formData.append("columns[10][search][value]", "");
        formData.append("columns[10][search][regex]", false);
        formData.append("order[0][column]", 1);
        formData.append("order[0][dir]", "asc");
        formData.append("start", activePage);
        formData.append("length", 10);
        formData.append("search[value]", searchValue);
        formData.append("search[regex]", false);
        setLoading(true)
        axios.post(`${config.epharmUrl}/Cproduct/CheckProductList`, formData).then(response => {
            const res = response.data;
            const dt = res ? res : {};
            const data = dt.aaData ? dt.aaData : [];
            const count = dt.iTotalRecords ? dt.iTotalRecords : 0;
            setProducts(data);
            setProductCount(count);
            setLoading(false);
        }).catch(error => {
            errorMessages(error, null, actions);
            setLoading(false)
        })
    }, [activePage, searchValue])


    const handleChange = (event) => {
        setSearchValue(event.target.value)
    };

    const handleOpenDialog = (_id) => {
        setProductId(_id)
        setOpenDialog(true)
    };
    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleDelete = () => {
        axios.get(`${config.epharmUrl}/Cproduct/product_delete/${product_id}`).then(() => {
            const arr = products.filter(medicine => medicine.product_id !== product_id);
            setProducts(arr)
            actions.snackbarActions.successSnackbar('Product Successfully deleted');
            setOpenDialog(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setOpenDialog(false)
        });
    };

    const downloadPDF = () => {
        exportPDF("Medicine Report", [["SL", "Medicine Name", "Generic Name", "Medicine Type", "Unit",
                "Shelf", "Strength"]],
            products.map((med, index) => [index += 1, med.product_name, med.generic_name,
                med.product_model, med.unit, med.manufacturer_name, med.product_location, med.price,
                med.purchase_p, med.strength]), "Medicine.pdf");
    };

    const copyTable = () => {
        copyToClipboard(actions, 10, componentRef)
    };

    const fetchData = () => {
       csvLink.current.link.click()
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };
    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    return {openDialog, product_id, product_count, activePage, products, loading, searchValue,
         csvLink, handleChange, handleOpenDialog, handleCloseDialog, downloadPDF,
        copyTable, handleCloseBar, fetchData, handlePageChange,handleDelete}
}
import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../Helpers/config.json";
import {v4 as uuidv4} from 'uuid';
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useNewLedgerEntry = (actions) => {
    const initialState = [{
        id: uuidv4(),
        account_name: '',
        account_id: '',
        description: '',
        entry_type: '',
        debit: '',
        credit: ''
    },{
        id: uuidv4(),
        account_name: '',
        account_id: '',
        description: '',
        entry_type: '',
        debit: '',
        credit: ''
    }]
    const [state, setState] = useState({date: '', notes: ''});
    const [accounts, setAccounts] = useState([]);
    const [ledger, setLedger] = useState(initialState);
    const [total, setTotal] = useState({debit_total: 0, credit_total: 0})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');

    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    const handleChange = (event) => {
        const {value, name} = event.target;
        setState({...state, [name]: value})
    }

    const handleChangeAccount = (val, id) => {
        const arr = ledger.map(item => {
            if (item.id === id) {
                return {...item, account_id: val}
            }
            return item
        })
        setLedger(arr)
    }
    const calculateTotal = (arr) => {
        const debit_total = arr.reduce((sum, item) => {
            return +sum + +item.debit;
        }, 0)
        const credit_total = arr.reduce((sum, item) => {
            return +sum + +item.credit;
        }, 0)
        setTotal({debit_total, credit_total})
    }

    const handleBlurDebit = (id) => {
        const arr = ledger.map(item => {
            if (item.id === id) {
                if (item.credit !== '' && item.debit) {
                    return {...item, credit: '', entry_type: 'DEBIT'}
                } else {
                    return item;
                }
            }
            return item
        })
        setLedger(arr);
        calculateTotal(arr)
    }
    const handleBlurCredit = (id) => {
        const arr = ledger.map(item => {
            if (item.id === id) {
                if (item.debit !== '' && item.credit) {
                    return {...item, debit: '', entry_type: 'CREDIT'}
                } else {
                    return item;
                }
            }
            return item
        })
        setLedger(arr);
        calculateTotal(arr)
    }

    const handleChangeItems = (event, id) => {
        const {value, name} = event.target;
        const arr = ledger.map(item => {
            if (item.id === id) {
                return {...item, [name]: value, entry_type: name === 'debit' ? 'DEBIT' : name === 'credit' ? 'CREDIT':''}
            }
            return item
        })
        setLedger(arr)
    }

    const addItem = () => {
        const row = {
            id: uuidv4(),
            account_name: '',
            account_id: '',
            description: '',
            entry_type: '',
            debit: '',
            credit: ''
        }
        setLedger([...ledger, row])
    }

    const removeRow = (id) => {
        const arr = ledger.filter(item => item.id !== id)
        setLedger(arr)
    }

    const handleSubmit = (event) => {
        const {date} = state;
        event.preventDefault();
        const formData = new FormData();
        formData.append('date_added', date);
        setSubmitted(true);
        ledger.filter(item => item.account_id.value).forEach(item => {
            if (item.entry_type === 'CREDIT'){
                formData.append('credit_ledger',   item.account_id.value);
            }
            if (item.entry_type === 'DEBIT'){
                formData.append('debit_ledger',   item.account_id.value);
            }
            formData.append('entry_category', item.entry_type);
            formData.append('amount', item.debit ? item.debit : item.credit ? item.credit : 0);
            formData.append('description', item.description);
        })
        const arr = ledger.every(item => item.account_id)
        if (arr && date) {
            setIsSubmitted('pending');
            axios.post(`${config.epharmUrl}/accounting/journals/entry`, formData).then(() => {
                actions.snackbarActions.successSnackbar('Entry made successfully')
                history.push('/journals')
                setIsSubmitted('resolved')
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }
    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    return {
        state, accounts, ledger, handleChange, handleChangeAccount, handleChangeItems, removeRow,
        addItem, total, handleBlurCredit, handleBlurDebit, handleSubmit, isSubmitted, submitted, closeSnackbar
    }
}
import React , {Component} from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as supplierActions from "../../Redux/Actions/SupplierActions/SupplierActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";

class EditSupplier extends Component{
    constructor() {
        super();
        this.state={
            supplier_name:'',
            mobile:'',
            address:'',
            details:'',
            previous_balance:''
        };

        this.supplierName = React.createRef();
        this.supplierMobile = React.createRef();
        this.address = React.createRef();
        this.details = React.createRef();
        this.submitBtn = React.createRef();
    }

    handleChange = event =>{
        const {name, value} = event.target;
        this.setState({[name]:value})
    };
    componentDidMount() {
        const {supplier_id} = this.props.match.params;
        const {actions} = this.props;
        actions.supplierActions.supplierUpdateForm(supplier_id);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {supplier} = this.props.suppliers;
        const supply =!supplier? {}:supplier
        if(this.props.suppliers.supplier !== prevProps.suppliers.supplier){
            this.setState({
                name:!supply.supplier_name ? '':supply.supplier_name,
                mobile:!supply.mobile ? '':supply.supplier_mobile,
                address:!supply.address ? '':supply.supplier_address,
                details:!supply.details ? '':supply.supplier_details,
                previous_balance:!supply.previous_balance ? 0 : supply.previous_balance,

               /* manufacturer_name: !manufacturer.manufacturer_name ? '':manufacturer.manufacturer_name,
                manufacturer_mobile: !manufacturer.manufacturer_mobile ? '':manufacturer.manufacturer_mobile,
                manufacturer_address: !manufacturer.manufacturer_address ? '':manufacturer.manufacturer_address,
                manufacturer_balance: !manufacturer.previous_balance ? 0 : manufacturer.previous_balance,*/
            })
        }
    }
    handleSupplierUpdate = event => {
        event.preventDefault();
        const {actions} = this.props;
        const {supplier_id} = this.props.match.params;
        const supplier_form = document.getElementById('supplier_form');
        let formData = new FormData(supplier_form);
        formData.append('supplier_id',supplier_id);
        actions.supplierActions.updateSupplier(formData);

    };
    handleCloseBar = () =>{
        const {actions} = this.props;
        actions.snackbarActions.hideSnackBar();
    };
    handleKeyPress = (event, reference) =>{
        if(event.key === "Enter"){
            event.preventDefault();
            reference.current.focus()

        }
    };





    render() {
        const {supplier_name,mobile,address,details} = this.state;
        const {open, variant,message} = this.props.snackbars;
        const {submitting}=this.props.suppliers;
        return(
            <div>
                <DashboardHeader supplier editSupplier/>
            <div className="mx-3 mt-5">
                <div className="card mt-4">
                    <h6 className="mx-2 mt-2">Edit Supplier</h6>
                    <hr/>
                    <div className="m-2">
                        <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={this.handleCloseBar}/>
                        <Form id="supplier_form" onSubmit={this.handleSupplierUpdate} autoComplete="off">
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Supplier Name</Form.Label>
                                    <Form.Control type="text" value={supplier_name}
                                                  ref={this.supplierName} onKeyDown={e=>this.handleKeyPress(e,this.supplierMobile)}
                                                  name="supplier_name" onChange={this.handleChange}  />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Supplier Mobile</Form.Label>
                                    <Form.Control type="text" value={mobile}
                                                  ref={this.supplierMobile} onKeyDown={e=>this.handleKeyPress(e,this.address)}
                                                  name="mobile" onChange={this.handleChange}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>

                                <Form.Group as={Col} controlId="formGridAddress2">
                                    <Form.Label>Supplier Address</Form.Label>
                                    <Form.Control as="textarea" value={address}
                                                  ref={this.address} onKeyDown={e=>this.handleKeyPress(e,this.details)}
                                                  name="address" onChange={this.handleChange}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label>Supplier Details</Form.Label>
                                    <Form.Control as="textarea" value={details}
                                                  ref={this.details} onKeyDown={e=>this.handleKeyPress(e,this.submitBtn)}
                                                  name="details" onChange={this.handleChange}/>
                                </Form.Group>
                            </Form.Row>

                            {/*<Form.Row>*/}
                            {/*    <Form.Group as={Col} controlId="formGridCity">*/}
                            {/*        <Form.Label>Previous Balance</Form.Label>*/}
                            {/*        <Form.Control type="text" value={previous_balance} name="previous_balance" onChange={this.handleChange} />*/}
                            {/*    </Form.Group>*/}
                            {/*    <Form.Group as={Col} controlId="formGridZip">*/}
                            {/*    </Form.Group>*/}
                            {/*</Form.Row>*/}
                            <button type="submit" ref={this.submitBtn} disabled={!!submitting }
                            className="btn pharmacy-btn offset-md-5">{submitting ? "Saving...":"Save Supplier"}</button>
                        </Form>
                    </div>

                </div>
            </div>
        </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        suppliers:state.suppliers,
        snackbars:state.snackbars
    }

}
function mapDispatchToProps(dispatch) {
    return{
        actions:{
            supplierActions:bindActionCreators(supplierActions, dispatch),
            snackbarAcrions:bindActionCreators(snackbarActions,dispatch)
        }
    }

}
export default connect(mapStateToProps, mapDispatchToProps) (EditSupplier);
import * as types from "../../Actions/actionTypes"


const initialState = {
    invoiceReturn: {},
    returnList: [],
    returnInvoiceDetails: {},
    invoice_id: '',
    manufacturerReturnList: [],
    manufacturerInserted: {},
    purchase_id: '',
    wastageList: [],
    customerInvoice: {},
    searchManufacturer: {},
    manufacturerForm: {},
    returnManufacturer: {},
    loading: false,
    return_count: 0
};
export default function returnReducer(state = initialState, action) {
    switch (action.type) {
        case types.INVOICE_RETURN_FORM:
            return {...state, invoiceReturn: action.invoiceReturn};
        case types.RETURN_LIST:
            return {...state, returnList: action.returnList,loading:false};
        case types.RETURN_INVOICE_INSERTED_DATA:
            return {...state, returnInvoiceDetails: action.returnInvoiceDetails};
        case types.DELETE_RETURN_INVOICE:
            return {...state, returnList: state.returnList.filter(invoice => invoice.invoice_id !== action.invoice_id)};
        case types.MANUFACTURER_RETURN_LIST:
            return {...state, manufacturerReturnList: action.manufacturerReturnList,loading:false};
        case types.MANUFACTURER_INSERTED_DATA:
            return {...state, manufacturerInserted: action.manufacturerInserted};
        case types.DELETE_RETURN_PURCHASE:
            return {
                ...state,
                manufacturerReturnList: state.manufacturerReturnList.filter(purchase => purchase.purchase_id !== action.purchase_id)
            };
        case types.WASTAGE_RETURN_LIST:
            return {...state, wastageList: action.wastageList,loading:false};
        case types.CUSTOMER_RETURN_INVOICE:
            return {...state, customerInvoice: action.customerInvoice};
        case types.SEARCH_MANUFACTURER:
            return {...state, searchManufacturer: action.searchManufacturer};
        case types.MANUFACTURER_RETURN_FORM:
            return {...state, manufacturerForm: action.manufacturerForm};
        case types.RETURN_MANUFACTURER:
            return {...state, returnManufacturer: action.returnManufacturer};
        case types.RETURN_COUNT:
            return {...state, return_count: action.return_count};
        case types.RETURN_GIF:
            return {
                loading:true
            };
        case types.RETURN_SUCCESS:
            return{
                submitting:false
            };
        case types.RETURN_ERROR:
            return{
                submitting:false
            };
        case types.RETURN_REQUEST:
            return{
                submitting:true
            };
        case types.MANUFRETURN_SUCCESS:
            return{
                submitted:false
            };
        case types.MANUFRETURN_ERROR:
            return{
                submitted:false
            };
        case types.MANUFRETURN_REQUEST:
            return{
                submitted:true
            };
        default:
            return {...state};

    }

}
export const GET_DASHBOARD_STATISTICS = "GET_DASHBOARD_STATISTICS";
export const CREATE_POS_INVOICE = "CREATE_POS_INVOICE";
export const RETRIEVE_DRUGS = "RETRIEVE_DRUGS";
export const RETRIEVE_CUSTOMERS = "RETRIEVE_CUSTOMERS";
export const RETRIEVE_PRODUCT_INVENTORY = "RETRIEVE_PRODUCT_INVENTORY";
export const RETRIEVE_PRODUCT_BATCH_INFO = "RETRIEVE_PRODUCT_BATCH_INFO";
export const RETRIEVE_INVOICES = "RETRIEVE_INVOICES";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const SIDEBAR_OPEN = "SIDEBAR_OPEN";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const ADD_CUSTOMERS = "ADD_CUSTOMERS";
export const MANAGE_CUSTOMERS = "MANAGE_CUSTOMERS";
export const PAID_CUSTOMERS = "PAID_CUSTOMERS ";
export const CREDIT_CUSTOMERS = "CREDIT_CUSTOMERS";
export const SUCCESS_SNACKBAR = "SUCCESS_SNACKBAR";
export const ERROR_SNACKBAR = "ERROR_SNACKBAR";
export const WARNING_SNACKBAR = "WARNING_SNACKBAR ";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const GUI_ITEM_LIST = "GUI_ITEM_LIST";
export const RETRIEVE_GUI_INVOICE_ITEMS = "RETRIEVE_GUI_INVOICE_ITEMS";
export const RETRIEVE_GUI_POS = "RETRIEVE_GUI_POS";
export const INSTANT_CUSTOMER = "INSTANT_CUSTOMER";
export const GET_CUSTOMER_PREVIOUS_BALANCE = "GET_CUSTOMER_PREVIOUS_BALANCE";
export const INVOICE_UPDATE_FORM = "INVOICE_UPDATE_FORM";
export const INVOICE_INSERTED_DATA = " INVOICE_INSERTED_DATA";
export const POS_INVOICE_INSERTED_DATA = "POS_INVOICE_INSERTED_DATA";
export const MANAGE_CATEGORY = "MANAGE_CATEGORY";
export const MANAGE_MEDICINE = "MANAGE_MEDICINE";
export const CATEGORY_UPDATE_FORM = "CATEGORY_UPDATE_FORM";
// export  const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY ";
export const DELETE_PRODUCT = "DELETE_PRODUCT ";
export const PRODUCT_UPDATE_FORM = "PRODUCT_UPDATE_FORM";
export const RETRIEVE_UNIT_LIST = "RETRIEVE_UNIT_LIST";
export const UNIT_UPDATE_FORM = "UNIT_UPDATE_FORM";
export const DELETE_UNIT = "DELETE_UNIT";
export const RETRIEVE_MEDICINE_TYPES = "RETRIEVE_MEDICINE_TYPES";
export const DELETE_MEDICINE_TYPE = "DELETE_MEDICINE_TYPE";
export const MEDICINE_TYPE_UPDATE_FORM = "MEDICINE_TYPE_UPDATE_FORM";
export const MANAGE_MANUFACTURER = "MANAGE_MANUFACTURER ";
export const RETRIEVE_PRODUCT_DETAILS = "RETRIEVE_PRODUCT_DETAILS";
export const RETRIEVE_PURCHASE_DETAILS = "RETRIEVE_PURCHASE_DETAILS";
export const RETRIEVE_MANUFACTURER_DETAILS = "RETRIEVE_MANUFCTURER_DETAILS";
export const RETRIEVE_CUSTOMER_DETAILS = "RETRIEVE_CUSTOMER_DETAILS";
export const MEDICINE_PURCHASE_DATA = "MEDICINE_PURCHASE_DATA";
export const MEDICINE_SALES_DATA = "MEDICINE_SALES_DATA";
export const RETRIEVE_STOCK_REPORT = "RETRIEVE_STOCK_REPORT";
export const RETRIEVE_BATCH_STOCK = "RETRIEVE_BATCH_STOCK";
export const MANUFACTURER_UPDATE_FORM = "MANUFACTURER_UPDATE_FORM ";
export const DELETE_MANUFACTURER = "DELETE_MANUFACTURER";
export const MANUFACTURER_SALES_DETAILS = "MANUFACTURER_SALES_DETAILS";
export const MANUFACTURER_LEDGER_REPORT = "MANUFACTURER_LEDGER_REPORT";
export const MANUFACTURER_LEDGER_INFO = "MANUFACTURER_LEDGER_INFO";
export const MANUFACTURER_LEDGER = "MANUFACTURER_LEDGER";
export const CHECK_PURCHASE_LIST = "CHECK_PURCHASE_LIST";
export const DELETE_PURCHASE = "DELETE_PURCHASE";
export const PRODUCTS_BY_MANUFACTURER = "PRODUCTS_BY_MANUFACTURER";
export const SINGLE_PURCHASE_PRODUCT = "SINGLE_PURCHASE_PRODUCT";
export const INFO_SNACKBAR = "INFO_SNACKBAR";
export const PURCHASE_UPDATE_FORM = "PURCHASE_UPDATE_FORM";
export const INVOICE_HTML_FORM = "INVOICE_HTML_FORM";
export const MANAGE_SUPPLIER = "MANAGE_SUPPLIER";
export const SUPPLIER_UPDATE_FORM = "SUPPLIER_UPDATE_FORM";
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const SUPPLIER_LEDGER_REPORT = "SUPPLIER_LEDGER_REPORT";
export const SUPPLIER_LEDGER = "SUPPLIER_LEDGER";
export const SUPPLIER_LEDGER_INFo = "SUPPLIER_LEDGER_INFo";
export const RETRIEVE_TODAYS_SALES_REPORT = "RETRIEVE_TODAYS_SALES_REPORT";
export const RETRIEVE_TODAYS_PURCHASE_REPORT =
  "RETRIEVE_TODAYS_PURCHASE_REPORT";
export const RERIEVE_ALL_REPORT = "RETRIEVE_ALL_REPORT";
export const RETRIEVE_SALES_REPORT_DATEWISE = "RETRIEVE_SALES_REPORT_DATEWISE";
export const RETRIEVE_PURCHASE_REPORT_DATEWISE =
  "RETRIEVE_PURCHASE_REPORT_DATEWISE";
export const SALES_REPORT_DATA_WISE = "SALES_REPORT_DATA_WISE";
export const PROFIT_MANUFACTURER = "PROFIT_MANUFACTURER";
export const PROFIT_PRODUCT_WISE = "PROFIT_PRODUCT_WISE";
export const OUT_OF_STOCK_LIST = "OUT_OF_STOCK_LIST";
export const EXPIRY_LIST = "EXPIRY_LIST";
export const MODAL_DISPLAY = "MODAL_DISPLAY";
export const SUPPLIER_COUNT = "SUPPLIER_COUNT";
export const INVOICE_RETURN_FORM = "INVOICE_RETURN_FORM";
export const RETURN_LIST = "RETURN_LIST";
export const RETURN_INVOICE_INSERTED_DATA = "RETURN_INVOICE_INSERTED_DATA";
export const DELETE_RETURN_INVOICE = "DELETE_RETURN_INVOICE";
export const MANUFACTURER_RETURN_LIST = "MANUFACTURER_RETURN_LIST";
export const MANUFACTURER_INSERTED_DATA = "MANUFACTURER_INSERTED_DATA";
export const DELETE_RETURN_PURCHASE = "DELETE_RETURN_PURCHASE";
export const WASTAGE_RETURN_LIST = "WASTAGE_RETURN_LIST";
export const CUSTOMER_RETURN_INVOICE = "CUSTOMER_RETURN_INVOICE";
export const SEARCH_MANUFACTURER = "SEARCH_MANUFACTURER";
export const MANUFACTURER_RETURN_FORM = "MANUFACTURER_RETURN_FORM";
export const RETURN_MANUFACTURER = "RETURN_MANUFACTURER";
export const TOTAL_MEDICINE_RECORDS = "TOTAL_MEDICINE_RECORDS";
export const RETURN_COUNT = "RETURN_COUNT";
export const STOCK_COUNT = "STOCK_COUNT";
export const PURCHASE_COUNT = "PURCHASE_COUNT";
export const REPORT_COUNT = "REPORT_COUNT";
export const MANUFACTURER_COUNT = "MANUFACTURER_COUNT";
export const RETRIEVE_ALL_MANUFACTURERS = "RETRIEVE_ALL_MANUFACTURERS";
export const MANAGE_USERS = "MANAGE_USERS";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const DELETE_USER = "DELETE_USER";
export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_ERROR = "ALERT_ERROR";
export const ALERT_CLEAR = "ALERT_CLEAR";
export const ROLE_LIST = "ROLE_LIST";
export const DELETE_ROLE = "DELETE_ROLE";
export const EXPIRED_DRUGS = "EXPIRED_DRUGS";
export const OUT_OF_STOCK = "OUT_OF_STOCK";
export const EDIT_OF_ROLE ="EDIT_OF_ROLE";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const CUSTOMER_UPDATE_FORM = "CUSTOMER_UPDATE_FORM";
export const CUSTOMER_LEDGER = "CUSTOMER_LEDGER";
export const CUSTOMER_DELETE = "CUSTOMER_DELETE";
export const DELETE_CREDIT_CUSTOMER = "DELETE_CREDIT_CUSTOMER";
export const DELETE_PAID_CUSTOMER = "DELETE_PAID_CUSTOMER";
export const RETRIEVE_ALL_SUPPLIERS = "RETRIEVE_ALL_SUPPLIERS";
export const GET_ALL_INVOICES_REQUEST = "GET_ALL_INVOICES_REQUEST";
export const CUSTOMER_GIF="CUSTOMER_GIF";
export const REPORT_GIF="REPORT_GIF";
export const RETURN_GIF="RETURN_GIF";
export const CUSTOMER_REQUEST="CUSTOMER_REQUEST";
export const CUSTOMER_ERROR="CUSTOMER_ERROR";
export const CUSTOMER_SUCCESS="CUSTOMER_SUCCESS";
export const RETURN_REQUEST="RETURN_REQUEST";
export const RETURN_ERROR="RETURN_ERROR";
export const RETURN_SUCCESS="RETURN_SUCCESS";
export const MANUFRETURN_REQUEST="MANUFRETURN_REQUEST";
export const MANUFRETURN_ERROR="MANUFRETURN_ERROR";
export const MANUFRETURN_SUCCESS="MANUFRETURN_SUCCESS";
export const MED_REQUEST="MED_REQUEST";
export const MED_ERROR="MED_ERROR";
export const MED_SUCCESS="MED_SUCCESS";
export const MANUF_REQUEST="MANUF_REQUEST";
export const MANUF_ERROR="MANUF_ERROR";
export const MANUF_SUCCESS="MANUF_SUCCESS";
export const SUPPLY_REQUEST="SUPPLY_REQUEST";
export const SUPPLY_ERROR="SUPPLY_ERROR";
export const SUPPLY_SUCCESS="SUPPLY_SUCCESS";
export const EDITPURCH_REQUEST="EDITPURCH_REQUEST";
export const EDITPURCH_ERROR="EDITPURCH_ERROR";
export const EDITPURCH_SUCCESS="EDITPURCH_SUCCESS";
export const EDITINV_REQUEST="EDITINV_REQUEST";
export const EDITINV_ERROR="EDITINV_ERROR";
export const EDITINV_SUCCESS="EDITINV_SUCCESS";
export const EDITCUST_REQUEST="EDITCUST_REQUEST";
export const EDITCUST_ERROR="EDITCUST_ERROR";
export const EDITCUST_SUCCESS="EDITCUST_SUCCESS";
export const EDITMED_REQUEST="EDITMED_REQUEST";
export const EDITMED_ERROR="EDITMED_ERROR";
export const EDITMED_SUCCESS="EDITMED_SUCCESS";
export const EDITMANUF_REQUEST="EDITMANUF_REQUEST";
export const EDITMANUF_ERROR="EDITMANUF_ERROR";
export const EDITMANUF_SUCCESS="EDITMANUF_SUCCESS";
export const EDITSUPPLY_REQUEST="EDITSUPPLY_REQUEST";
export const EDITSUPPLY_ERROR="EDITSUPPLY_ERROR";
export const EDITSUPPLY_SUCCESS="EDITSUPPLY_SUCCESS";
export const FORGOTPASSWORD_REQUEST="FORGOTPASSWORD_REQUEST";
export const FORGOTPASSWORD_ERROR="FORGOTPASSWORD_ERROR";
export const FORGOTPASSWORD_SUCCESS="FORGOTPASSWORD_SUCCESS";
export const RESETPASSWORD_REQUEST="RESETPASSWORD_REQUEST";
export const RESETPASSWORD_ERROR="RESETPASSWORD_ERROR";
export const RESETPASSWORD_SUCCESS="RESETPASSWORD_SUCCESS";
export const ADDROLE_REQUEST="ADDROLE_REQUEST";
export const ADDROLE_ERROR="ADDROLE_ERROR";
export const ADDROLE_SUCCESS="ADDROLE_SUCCESS";
export const ADDUSER_REQUEST="ADDUSER_REQUEST";
export const ADDUSER_ERROR="ADDUSER_ERROR";
export const ADDUSER_SUCCESS="ADDUSER_SUCCESS";
export const ASSIGNROLE_REQUEST="ASSIGNROLE_REQUEST";
export const ASSIGNROLE_ERROR="ASSIGNROLE_ERROR";
export const ASSIGNROLE_SUCCESS="ASSIGNROLE_SUCCESS";
export const EDITROLE_REQUEST="EDITROLE_REQUEST";
export const EDITROLE_ERROR="EDITROLE_ERROR";
export const EDITROLE_SUCCESS="EDITROLE_SUCCESS";
export const EDITUSER_REQUEST="EDITUSER_REQUEST";
export const EDITUSER_ERROR="EDITUSER_ERROR";
export const EDITUSER_SUCCESS="EDITUSER_SUCCESS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const MEDICINE_REQUEST = "MEDICINE_REQUEST";
export const MANUFACTURER_REQUEST = "MANUFACTURER_REQUEST";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";

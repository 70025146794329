import React from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";

export const style = {
    textRight: {
        textAlign: 'right'
    },
    col: {
        maxWidth: 700,
        width: '90%',
        margin: 'auto'
    }

}

export const Dates = ({from_date,to_date,submitted, handleChangeDate, children,custom}) =>{
    return (
        <div className="row col-md-12">
            <div className="col-md-6">
                <Form.Group as={Row}>
                    <Label name="From" sm={4} column style={style.textRight} type/>
                    <Col sm={8}>
                        <TextField type="date" onChange={handleChangeDate} value={from_date}
                                   name='from_date'/>
                        {(submitted && !from_date) ?
                            <div className="help-block">From date is required</div> : null}
                    </Col>
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group as={Row}>
                    <Label name="To" sm={custom ? 3 : 4} column style={style.textRight} type/>
                    <Col sm={custom ? 7 : 8}>
                        <TextField type="date" onChange={handleChangeDate} value={to_date} name='to_date'/>
                        {(submitted && !to_date) ?
                            <div className="help-block">To date is required</div> : null}
                    </Col>
                    {children}
                </Form.Group>
            </div>
        </div>
    )

}
export const DateSearch = ({handleSubmit, account, handleChangeAccount, accounts,  transaction,children,
                               transactions, handleChangeTransaction,  isSubmitted, showLedger, submitted}) => {
    return (
        <form autoComplete='off' onSubmit={handleSubmit} style={style.col}>
            {showLedger ? <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Label name="Account" sm={3} column style={style.textRight} type/>
                            <Col sm={9}>
                                <CustomSelect value={account} onChange={handleChangeAccount} options={accounts}/>
                                {(submitted && !account) ?
                                    <div className="help-block">Account is required</div> : null}
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Label name="Transaction" sm={3} column style={style.textRight} type/>
                            <Col sm={9}>
                                <CustomSelect value={transaction} options={transactions}
                                              onChange={handleChangeTransaction}/>
                                {(submitted && !transaction) ?
                                    <div className="help-block">Transaction is required</div> : null}
                            </Col>
                        </Form.Group>
                    </div>
                </div> :
                <div className="row">
                    <div className="col-md-8">
                        <Form.Group as={Row}>
                            <Label name="Account" sm={3} column style={style.textRight} type/>
                            <Col sm={9}>
                                <CustomSelect value={account} onChange={handleChangeAccount}
                                              options={accounts}/>
                                {(submitted && !account) ?
                                    <div className="help-block">Account is required</div> : null}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label name="Transaction" sm={3} column style={style.textRight} type/>
                            <Col sm={9}>
                                <CustomSelect value={transaction} options={transactions}
                                              onChange={handleChangeTransaction}/>
                                {(submitted && !transaction) ?
                                    <div className="help-block">Transaction is required</div> : null}
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-md-4"/>
                    {children}
                </div>}

            <div className="text-center">
                <button className="btn btn-sm sms-info-btn mb-2 px-3"
                        disabled={(isSubmitted === 'pending' || isSubmitted === 'resolved')}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>
            </div>

        </form>
    )
}
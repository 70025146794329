import  {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/exportPDF";
import {copyToClipboard} from "../../../Utils/copyToClipboard";

export const useManageCustomer = (actions, component) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [customer_id, setCustomerId] = useState('');
    const [customer_count, setCustomerCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [openDeactivate, setOpenDeactivate] = useState(false);
    const [openDeposit, setOpenDeposit] = useState(false);
    const [status, setStatus] = useState('');
    const [deposit_id, setDepositId] = useState('');
    const [deposit_amount, setDepositAmount] = useState('');
    const [paymentType, setPaymentType] = useState('1');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted,setIsSubmitted] = useState('idle')
    const csvLink = useRef();

    const dep = isSubmitted === 'resolved';
    useEffect(() => {
        const url = {
            manage_customer: 'checkCustomerList',
            paid_customer: 'checkPaidCustomerList',
            credit_customer: 'checkCreditCustomerList'
        }
        const formData = new FormData();
        formData.append("draw", 1);
        formData.append("columns[0][data]", "sl");
        formData.append("columns[0][name]", "");
        formData.append("columns[0][searchable]", "true");
        formData.append("columns[0][orderable]", false);
        formData.append("columns[0][search][value]", "");
        formData.append("columns[0][search][regex]", false);
        formData.append("columns[1][data]", "customer_name");
        formData.append("columns[1][name]", "");
        formData.append("columns[1][searchable]", true);
        formData.append("columns[1][orderable]", true);
        formData.append("columns[1][search][value]", "");
        formData.append('columns[1][search][regex]', false);
        formData.append("columns[2][data]", "address");
        formData.append("columns[2][name]", "");
        formData.append("columns[2][searchable]", true);
        formData.append("columns[2][orderable]", false);
        formData.append("columns[2][search][value]", "");
        formData.append("columns[2][search][regex]", false);
        formData.append("columns[3][data]", "mobile");
        formData.append('columns[3][name]', "");
        formData.append("columns[3][searchable]", true);
        formData.append("columns[3][orderable]", false);
        formData.append("columns[3][search][value]", "");
        formData.append("columns[3][search][regex]", false);
        formData.append("columns[4][data]", "balance");
        formData.append("columns[4][name]", "");
        formData.append("columns[4][searchable]", true);
        formData.append("columns[4][orderable]", false);
        formData.append("columns[4][search][value]", "");
        formData.append("columns[4][search][regex]", false);
        formData.append('columns[5][data]', "button");
        formData.append("columns[5][name]", "");
        formData.append("columns[5][searchable]", true);
        formData.append("columns[5][orderable]", false);
        formData.append("columns[5][search][value]", "");
        formData.append("columns[5][search][regex]", false);
        formData.append("order[0][column]", 1);
        formData.append("order[0][dir]", "asc");
        formData.append("start", activePage);
        formData.append("length", 10);
        formData.append("search[value]", searchValue);
        formData.append("search[regex]", false);
        setLoading(true)
        axios.post(`${config.epharmUrl}/Ccustomer/${url[component]}`, formData).then(response => {
            const customers = response.data;
            const dt = !customers ? {} : customers;
            const data = dt.aaData ? dt.aaData : [];
            const customer_count = dt.iTotalRecords ? dt.iTotalRecords : 0;
            setCustomerCount(customer_count);
            setCustomers(data);
            setLoading(false);
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [activePage, component, searchValue, customer_id,status,dep])


    const handleChange = (event) => {
        setSearchValue(event.target.value)
    };


    const handleOpenDialog = (_id) => {
        setCustomerId(_id)
        setOpenDialog(true)
    };
    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const downloadPDF = () => {
        const allCustomers = !customers ? [] : customers;
        exportPDF("All Customers", [["SL", "Customer Name", "Address", "Mobile", "Balance"]],
            allCustomers.map((customer, index) => [index += 1, customer.customer_name, customer.address, customer.mobile,
                customer.balance]), "Customers.pdf")
    };
    const copyTable = (componentRef) => {
        copyToClipboard(actions, 10, componentRef)
    };
    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const fetchData = () => {
        csvLink.current.link.click()
    };

    const handleOpenDeactivate = (_id, st) => {
        setCustomerId(_id)
        setStatus(st)
        setOpenDeactivate(true)
    };
    const handleCloseDeactivate = () => {
        setOpenDeactivate(false)
    };

    const deactivateCustomer = () => {
        const stat = status === 1 ? 0 : 1
        axios.post(`${config.epharmUrl}/Ccustomer/update_customer_status`, null,
            {params: {customer_id, status:stat}})
            .then(() => {
                actions.snackbarActions.successSnackbar('Customer status update successful');
                setOpenDeactivate(false)
                setCustomerId(customer_id);
                setStatus(stat)
            }).catch(error => {
            errorMessages(error, null, actions)
        })
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const handleOpenDeposit = (customer_deposit_id) => {
        setDepositId(customer_deposit_id);
        setOpenDeposit(true);
    };
    const handleCloseDeposit = () => {
        setOpenDeposit(false)
    };
    const handleChangeDeposit = (event) => {
        setDepositAmount(event.target.value)
    };

    const handleSubmitDeposit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('customer_id', deposit_id);
        formData.append('deposit', deposit_amount);
        formData.append('paytype', paymentType);
        setSubmitted(true)
        if (deposit_amount){
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Ccustomer/customer_deposit`, formData).then(() => {
                actions.snackbarActions.successSnackbar('Amount Deposited Successfully');
                setOpenDeposit(false)
                setIsSubmitted('resolved')
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }
    };

    return {openDialog, customer_id, customer_count, activePage, customers, loading, searchValue, openDeactivate,
        openDeposit, status, deposit_id, deposit_amount, paymentType, submitted, isSubmitted, csvLink,
        handleChange, handleOpenDialog, handleCloseDialog, downloadPDF, copyTable, handleCloseBar,
        fetchData, handleOpenDeactivate, handleCloseDeactivate, deactivateCustomer, handlePageChange,
        handleOpenDeposit, handleCloseDeposit, handleChangeDeposit, handleSubmitDeposit}
}
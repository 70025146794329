import React, {useEffect, useState} from "react";
import MainInvoiceDetails from "./MainInvoiceDetails"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {convertGmtTime, titleCase} from "../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";


const headData = ["Sl", "Medicine Name", "Quantity", "Discount(%)", "Unit Price", "Amount"]
const InvoiceDetails = ({actions,snackbars, match: {params}}) => {
    const {invoice_id} = params;
    const [invoice, setInvoice] = useState({});
    const [medicines, setMedicines] = useState([]);
    const [loading, setLoading] = useState('idle')

    useEffect(() => {
        setLoading('pending')
        axios.get(`${config.epharmUrl}/Cinvoice/invoice_inserted_data`, {params: {invoice_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            const meds = data.invoice_all_data ?? [];
            setMedicines(meds)
            setInvoice(dt)
            setLoading('resolved')
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('rejected')
        })
    }, [actions, invoice_id])

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    }

    const {user_first_name, user_last_name, customer_name: name, customer_address: user_address, customer_mobile: mobile,
        previous, subTotal_ammount, total_amount, paid_amount, due_amount, invoice_no, final_date
    } = invoice;
    const user_name = titleCase(`${!user_first_name ? "" : user_first_name} ${!user_last_name ? "" : user_last_name}`);
    const invArr = [{name:'Previous', value:previous},{name:'Grand Total', value:total_amount},
        {name:'Paid Amount', value:paid_amount},{name:'Due Amount', value:due_amount}]
    const invoiceTotal = (
        <table className="table table-borderless table-sm">
            {invArr.map((item,index)=>(
                <tr key={index}>
                    <td align="right"/>
                    <td align="right"/>
                    <td align="right"/>
                    <td align="right"/>
                    <td align="right">{item.name}</td>
                    <td align="center">{item.value}</td>
                </tr>
            ))}
        </table>
    )
    const invoiceAddress = (
        <address className="mt-2">
            <span>Invoice No. : {invoice_no}</span><br/>
            <span>Billing Date:{final_date ? convertGmtTime(final_date) : ''}</span><br/>
        </address>
    )

    const {variant, message, open} = snackbars;

    const arr = medicines.map(item => ({
        amount: item.total_price, id: item.product_id, ...item
    }))
    const isLoading = loading === 'pending';
    const isSuccess = loading === 'resolved';
    const isError = loading === 'rejected';
    return (
        <>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseSnackbar}/>
            <MainInvoiceDetails user_name={user_name} headData={headData} address={invoiceAddress}
                                user={{name, user_address, mobile}}
                                title='Invoice' total_amount={subTotal_ammount} heading='Invoice' medicines={arr}
                                totalSummary={invoiceTotal} isLoading={isLoading} isSuccess={isSuccess}
                                isError={isError}/>
        </>

    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
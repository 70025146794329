import React, { useEffect,  useState} from "react";
import {Link} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import Workbook from "react-excel-workbook";
import {exportPDF} from "../../Utils/exportPDF";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions"
import axios from "axios";
import config from "../../Helpers/config";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {titleCase, removeTime} from "../../Utils/titleCaseFunction";
import {CSVLink} from "react-csv";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Tabs/ReusableTabs";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";


const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'medicineName', numeric: false, disablePadding: false, label: 'Medicine Name'},
    {id: 'strength', numeric: false, disablePadding: false, label: 'Strength'},
    {id: 'bactchId', numeric: false, disablePadding: false, label: 'Batch ID'},
    {id: 'expiryDate', numeric: false, disablePadding: false, label: 'Expiry Date'},
    {id: 'inQuantity', numeric: false, disablePadding: false, label: 'In Quantity'},
    {id: 'soldQuantity', numeric: false, disablePadding: false, label: 'Sold Quantity'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
];


const StockBatchWise = ({actions, snackbars}) => {
    const [stockBatch, setStockBatch] = useState([]);
    const [stockCount, setStockCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const formData = new FormData();
        formData.append("draw", 1);
        formData.append("columns[0][data]", "sl");
        formData.append("columns[0][name]", "");
        formData.append("columns[0][searchable]", "true");
        formData.append("columns[0][orderable]", false);
        formData.append("columns[0][search][value]", "");
        formData.append("columns[0][search][regex]", false);
        formData.append("columns[1][data]", "product_name");
        formData.append("columns[1][name]", "");
        formData.append("columns[1][searchable]", true);
        formData.append("columns[1][orderable]", true);
        formData.append("columns[1][search][value]", "");
        formData.append('columns[1][search][regex]', false);
        formData.append("columns[2][data]", "strength");
        formData.append("columns[2][name]", "");
        formData.append("columns[2][searchable]", true);
        formData.append("columns[2][orderable]", false);
        formData.append("columns[2][search][value]", "");
        formData.append("columns[2][search][regex]", false);
        formData.append("columns[3][data]", "batch_id");
        formData.append('columns[3][name]', "");
        formData.append("columns[3][searchable]", true);
        formData.append("columns[3][orderable]", false);
        formData.append("columns[3][search][value]", "");
        formData.append("columns[3][search][regex]", false);
        formData.append("columns[4][data]", "expeire_date");
        formData.append("columns[4][name]", "");
        formData.append("columns[4][searchable]", true);
        formData.append("columns[4][orderable]", false);
        formData.append("columns[4][search][value]", "");
        formData.append("columns[4][search][regex]", false);
        formData.append("columns[5][data]", "inqty");
        formData.append("columns[5][name]", "");
        formData.append("columns[5][searchable]", true);
        formData.append("columns[5][orderable]", false);
        formData.append("columns[5][search][value]", "");
        formData.append("columns[5][search][regex]", false);
        formData.append("columns[6][data]", "outqty");
        formData.append("columns[6][name]", "");
        formData.append("columns[6][searchable]", true);
        formData.append("columns[6][orderable]", false);
        formData.append("columns[6][search][value]", "");
        formData.append("columns[6][search][regex]", false);
        formData.append("columns[7][data]", "stock");
        formData.append("columns[7][name]", "");
        formData.append("columns[7][searchable]", true);
        formData.append("columns[7][orderable]", false);
        formData.append("columns[7][search][value]", "");
        formData.append("columns[7][search][regex]", false);
        formData.append("order[0][column]", 1);
        formData.append("order[0][dir]", "asc");
        formData.append("start", activePage);
        formData.append("length", 10);
        formData.append("search[value]", searchValue);
        formData.append("search[regex]", false);
        setLoading(true)
        axios.post(`${config.epharmUrl}/Creport/checkbatchstock`, formData).then(res => {
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.aaData ?? [];
            const stock_count = dt.iTotalRecords ?? 0;
            setStockBatch(arr);
            setStockCount(stock_count)
            setLoading(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [actions, activePage, searchValue])

    const handleChange = (event) => {
        setSearchValue(event.target.value);
    };

    const downloadPDF = () => {
        const batchStock = !stockBatch ? [] : stockBatch;
        exportPDF("Stock Report(Batch Wise)", [["SL", "Medicine Name", "Strength",
                "Batch ID", "Expiry Date", "InQuantity", "Sold Quantity",
                "Stock"]],
            batchStock.map((stock, index) => [index += 1, stock.product_name, stock.strength,
                stock.batch_id, stock.expeire_date, stock.inqty, stock.outqty,
                stock.Stock]), "Stock Report.pdf");
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar()
    };
    

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const batchStock = !stockBatch ? [] : stockBatch;
    const {open, message, variant} = snackbars;
    const components = [{label: 'Stock Report', path: '/stockreport'},
        {label: 'Stock Report(Batch Wise)', path: '/stockbatchwise'}]
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Stock Report(Batch Wise)' heading="Stock"
                                     subHeading='Stock Report(Batch Wise)' link={history.location.pathname}/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <div className="mt-3">
                <MuiPaper>
                    <EnhancedTableToolbar title=''>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                                           <CSVLink
                                               data={batchStock}
                                               filename="stock.csv"
                                               target="_blank"
                                           >
                            <button className="btn btn-sm pharmacy-btn mr-1">CSV</button>
                        </CSVLink>

                        <Workbook filename="Stock Report.xlsx" element={
                            <span><button className="btn btn-sm pharmacy-btn mr-1">Excel</button></span>
                        }>
                            <Workbook.Sheet data={batchStock} name="Stock">
                                <Workbook.Column label="Medicine Name" value="product_name"/>
                                <Workbook.Column label="Strength" value="strength"/>
                                <Workbook.Column label="Batch ID" value="batch_id"/>
                                <Workbook.Column label="Expiry Date" value="expeire_date"/>
                                <Workbook.Column label="InQuantity" value="inqty"/>
                                <Workbook.Column label="Sold Quantity" value="outqty"/>
                                <Workbook.Column label="Stock" value="stock"/>
                            </Workbook.Sheet>
                        </Workbook>
                        <span><button className="btn btn-sm pharmacy-btn mr-1"
                                      onClick={downloadPDF}>Pdf</button></span>
                            </span>
                        <Form.Control type="text" value={searchValue ? titleCase(searchValue) : ''}
                                      onChange={handleChange}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    <Table
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="8" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader"
                                                                       style={{width: '5%'}}/></TableCell>
                        </TableRow></TableBody> : batchStock.length > 0 ?
                            <TableBody>
                                {batchStock.slice(0, 10)
                                    .map((d, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'>
                                                <TableCell component="th" scope="row" padding="default"
                                                           className='mui-table-cell'>
                                                    <span>{d.sl}</span>
                                                </TableCell>
                                                <TableCell>
                                                                <span><Link
                                                                    to={{pathname: `/medicinedetails/${d.product_id}`}}
                                                                    style={{color: "#09905c"}}>{titleCase(d.product_name)}</Link></span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.strength}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.batch_id}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.expeire_date ? removeTime(d.expeire_date) : ''}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.inqty}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.outqty}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.stock}</span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            : <TableBody><TableRow>
                                <TableCell colSpan="8" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                    <CustomPagination data={batchStock} records={10} activePage={activePage} total_count={stockCount}
                                      handleChangeNextPage={handlePageChange}/>
                </MuiPaper>
            </div>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(StockBatchWise);
import React from 'react';
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import ReusableTabs, {TabPanel} from "../../Utils/Tabs/ReusableTabs";
import AdvancedSalesReport from "./AdvancedSalesReport";
import AdvancedSalesMedicineWiseReport from "./AdvancedSalesReportMedicineWIse";
import AdvancedPurchaseReport from "./AdvancedPurchaseReport";
import ProductTrends from "./ProductTrends";


const PurchaseAndSalesReports = () => {
    const [value, setValue] = React.useState(0);

    const tabHeading = ['Sales Report', 'Sales Report(Medicine Wise)', 'Purchase Report',
        'Product Trends']

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="journals">
            <ReusableDashboardHeader component="Reports" heading="Accounting"
                                     subHeading="Reports" link="/allreports"/>
            <ReusableTabs value={value} handleChange={handleChange} tabHeading={tabHeading}>
                <TabPanel value={value} index={0}>
                    <AdvancedSalesReport/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AdvancedSalesMedicineWiseReport/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <AdvancedPurchaseReport/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <ProductTrends/>
                </TabPanel>
            </ReusableTabs>
        </div>
    )
}
export default PurchaseAndSalesReports;
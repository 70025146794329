import React from 'react';
import {Link, NavLink, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import {faPrescription} from "@fortawesome/free-solid-svg-icons/faPrescription";

const ReusableDashboardHeader = ({dashboard, heading, subHeading, component, link}) => {
    return (
        <div>
            <div className="content-header">
                <div className="row">
                    <div className="col-md-6">
                        <Link to="/" style={{textDecoration: "none", color: "inherit"}}>
                            <div className="header-icon">
                                <FontAwesomeIcon icon={faPrescription}/>
                            </div>
                        </Link>
                        <h5 style={{marginBottom: 0, marginTop: 8, fontSize: 15}}>{component}</h5>
                        <small>{subHeading}</small>
                    </div>
                    <div className="col-md-6 mt-2">
                        <div className="header-title">
                            <ol className="breadcrumb">
                                <Link to="/" style={{textDecoration: "none", color: "inherit"}}
                                      className="breadcrumb-item">
                                    <li>
                                        <FontAwesomeIcon icon={faHome}/><span className="ml-1">Home</span>
                                    </li>
                                </Link>
                                <li className="breadcrumb-item active">
                                    <span>{heading}</span>
                                </li>
                                {dashboard ? null :
                                    <li className="breadcrumb-item">
                                        <NavLink color="inherit"
                                                 className="dashboard-link"
                                                 activeClassName="active"
                                                 to={link}>{component}</NavLink>
                                    </li>}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default withRouter(ReusableDashboardHeader);

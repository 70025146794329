import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle,DialogContent} from "./pharmacyDialog";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme=>({
    root:{
        padding:'12px'
    },
    paper:{
        overflow:'initial'
    }
}))

const DetailsDialog = ({openDialog, handleClose, title, children, width='sm'}) =>{
    const classes = useStyles()
    return(
        <Dialog open={openDialog} maxWidth={width} fullWidth classes={{paper:classes.paper}}>
            <DialogTitle onClose={handleClose} classes={{root:classes.root}}>
                <div className="text-center">
                    {title}
                </div>
            </DialogTitle>
            <DialogContent className={classes.paper}>
                {children}
            </DialogContent>
        </Dialog>
    )

};
export default DetailsDialog;
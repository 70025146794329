import React, {Component} from 'react';
import ManufacturerLedger from "./ManufacturerLedger";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions"
import {convertDate} from "../../Utils/titleCaseFunction";

class ManufacturerLedgerInfo extends Component {
    state={
        manufacturer_id:'',
        from_date:convertDate(),
        to_date:convertDate(),
        openLedger:false
    };

    handleChange = event =>{
        const {name, value} = event.target;
        this.setState({[name]:value})
    };
    handleChangeManufacturer = value =>{
        this.setState({manufacturer_id:value})
    };

    handleSearch = (event) => {
        event.preventDefault();
        const {actions} = this.props;
        const {from_date, to_date} = this.state;
         const {manufacturer_id} = this.props.match.params;
        actions.manufacturerActions.manufacturerLedger(from_date, to_date, manufacturer_id);
        this.setState(prevState =>({
            openLedger: !prevState.openLedger
        }))
    };

    componentDidMount() {
        const {actions} = this.props;
        const {manufacturer_id} = this.props.match.params;
        actions.manufacturerActions.manufacturerLedgerInfo(manufacturer_id);
    }
    render() {
        const {ledgerInfo,loading} = this.props.manufacturers;
        const {from_date, to_date, manufacturer_id,openLedger} = this.state;
        const ledger = !ledgerInfo ? {} : ledgerInfo;
        const ledgerData = !ledger.ledger ? [] : ledger.ledger;
        return (
            <div>
            <ManufacturerLedger
                ledgerInfo
                ledgerInformation={ledger}
                ledgerData={ledgerData}
                from_date={from_date}
                to_date={to_date}
                handleChange={this.handleChange}
                handleChangeManufacturer={this.handleChangeManufacturer}
                manufacturer_id={manufacturer_id}
                handleSearch={this.handleSearch}
                openLedger={openLedger}
                loading={loading}
            />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        manufacturers:state.manufacturers
    }

}
function mapDispatchToProps(dispatch) {
    return{
        actions:{
            manufacturerActions:bindActionCreators(manufacturerActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerLedgerInfo);
import React, {useRef, useState} from "react";
import {Table} from "react-bootstrap";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Paper from "@material-ui/core/Paper";
import {EnhancedPharmacyListHead, EnhancedToolbar, getSorting, stableSort, useStyles} from "../../../Utils/Tables/MuiTables";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import DrugsAvailableTablePagination from "../../../Utils/Tables/MuiTablePagination";
import {withStyles} from "@material-ui/core/styles";
import CheckInDialog from "./CheckInDialog";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";

const data = [
    {
        sl: 1,
        name: "John Doe",
        date: "12-03-2020",
        check_in:"",
        check_out:"",
        stay:"",

    },

];

const AddAttendance = (props)=>{
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(0);
    const [dense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('benefits');
    const [singleCheckIn, setSingleCheckIn] = useState(false);
    const [bulkCheck, setBulkCheck] = useState(false);
    const [checkOut, setCheckOut] = useState(false);
    const ref = useRef();
    const {classes} = props;

    const handleOpenCheckout = () =>{
        setCheckOut(true)
    };
    const handleCloseCheckOut = () =>{
        setCheckOut(false)
    };

    const handleOpenBulkCheck = () =>{
        setBulkCheck(true)
    };

    const handleCloseBulkCheck = () =>{
        setBulkCheck(false)
    };

    const handleOpenSingleCheckIn = () =>{
        setSingleCheckIn(true)
    };
    const handleCloseSingleCheckin = () =>{
        setSingleCheckIn(false)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handler = (value) => {
        setTerm(value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0)
    };
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        return(
            <div>
            <DashboardHeader attendance addAttendance/>
            <div className="card styled-table-card p-3">
                <div align="right" >
                    <button type="button" onClick={handleOpenSingleCheckIn} className="btn pharmacy-primary-btn mr-2"> <FontAwesomeIcon icon={faClock} /> Single CheckIn</button>
                    <button type="button" onClick={handleOpenBulkCheck} className="btn pharmacy-primary-btn mr-2"> <FontAwesomeIcon icon={faClock} /> Bulk CheckIn</button>
                    <Link to="/manageattendance"><button type="button" className="btn pharmacy-primary-btn mr-2">
                        <FontAwesomeIcon icon={faClock} /> Manage Attendance</button></Link>
                </div>
                <hr/>
                <CheckInDialog singleCheck openDialog ={singleCheckIn} handleClose={handleCloseSingleCheckin}/>
                <CheckInDialog bulkCheck openDialog ={bulkCheck} handleClose={handleCloseBulkCheck}/>
                <CheckInDialog checkOut openDialog ={checkOut} handleClose={handleCloseCheckOut}/>
                <Paper className={`${classes.paper} table-responsive`}>
                    <EnhancedToolbar handler={handler} targetRef={ref} data={data}
                                     addAttendance/>
                    <div className={classes.tableWrapper} ref={ref}>
                        <Table
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedPharmacyListHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                                addAttendance
                            />
                            <FilterResults
                                value={term}
                                data={data}
                                renderResults={results => (
                                    <TableBody>
                                        {stableSort(results, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((d, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                        className='mui-table-row'>
                                                        <TableCell component="th" scope="row" padding="default"
                                                                   className='mui-table-cell'>
                                                            <span>{d.sl}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.name}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.date}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.check_in}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.check_out}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.stay}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                        <span>
                                                            <button onClick={handleOpenCheckout} className="btn pharmacy-info-btn btn-sm  ">
                                                                <FontAwesomeIcon icon={faClock} className="mr-1"/>Check Out
                                                            </button>
                                                        </span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 49 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                            />
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        component="div"
                        page={page}
                        SelectProps={{
                            inputProps: {'aria-label': 'rows per page'},
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={DrugsAvailableTablePagination}
                    />
                </Paper>
            </div>
            </div>
        );
};
export default (withStyles(useStyles)) (AddAttendance);
import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const AddFixedAssets = () => {
    return (
        <div>
        <DashboardHeader fixedAsset addAsset/>
        <div className="card styled-card p-3">
            <h6>Add Fixed Assets</h6>
            <hr/>
            <Form autoComplete="off">
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Item Code <span className="text-danger asterics">*</span></Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Item Name <span className="text-danger asterics">*</span></Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Price <span className="text-danger asterics">*</span></Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Assets Quantity</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text"/>
                    </Col>
                </Form.Group>
                <div className="text-center">
                    <button className="btn pharmacy-btn px-4">Save</button>
                </div>
            </Form>
        </div>
        </div>
    )
};
export default AddFixedAssets;
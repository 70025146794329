import React, {useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import {history} from "../../Helpers/history"
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Card from "@material-ui/core/Card";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";


const Return = ({actions, snackbars, manufacturers}) => {
    const [invoice_no, setInvoiceNo] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [return_value, setReturnValue] = useState('customer');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');

    useEffect(() => {
        actions.manufacturerActions.getAllManufacturers();
    }, [actions.manufacturerActions])


    const handleChangeInvoice = (event) => {
        setInvoiceNo(event.target.value)
    };

    const handleChangeReturnValue = (event) => {
        setReturnValue(event.target.value)
    }

    const handleSearchInvoice = (event) => {
        event.preventDefault();
        setSubmitted(true)
        if (invoice_no) {
            setIsSubmitted('pending')
            const formData = new FormData();
            formData.append('invoice_no', invoice_no)
            axios.post(`${config.epharmUrl}/Creturn/invoice_return_form`, formData).then(() => {
                setIsSubmitted('resolved');
                history.push(`/stockreturninvoice/${invoice_no}`);
            }).catch(error => {
                setIsSubmitted('rejected');
                errorMessages(error, null, actions)
            })
        }
    };
    const handleChangeManufacturer = (value) => {
        setManufacturer(value)
    };

    const handleSearchManufacturer = () => {
        setSubmitted(true)
        if (manufacturer) {
            setIsSubmitted('resolved')
            history.push(`/manufacturerreturn/${manufacturer.value}`);
        }
    };


    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar()
    };

    const {message, variant, open} = snackbars;
    const {all_manufacturers} = manufacturers;
    const manufs = !all_manufacturers ? [] : all_manufacturers;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Add Return' heading="Return"
                                     subHeading='Add Return' link={history.location.pathname}/>
            <div className="general-ledger-header">
                <h6 className="mt-2">{return_value === 'customer' ? 'Customer' : 'Supplier'} Return</h6>
            </div>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>
            <div className="styled-return-card">
                <Card className="p-3 general-card">
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Check inline value="customer" onChange={handleChangeReturnValue}
                                        name="return_value" label="Customer" type="radio"
                                        checked={return_value === 'customer'}/>
                            <Form.Check inline value="supplier" onChange={handleChangeReturnValue}
                                        name="return_value" label="Supplier" type="radio"
                                        checked={return_value === 'supplier'}/>
                        </Col>

                    </Form.Group>
                    <form autoComplete="off"
                          onSubmit={return_value === 'customer' ? handleSearchInvoice : handleSearchManufacturer}
                    >
                        <Form.Group as={Row}>
                            <Label column sm={2} name={return_value === 'customer' ? 'Invoice No:' : 'Supplier'}/>
                            <Col sm={6}>
                                {return_value === 'customer' ?
                                    <><TextField type="text" name="invoice_no" value={invoice_no}
                                                 onChange={handleChangeInvoice}/>
                                        {submitted && !invoice_no ?
                                            <div className='invalid-text'>Invoice no. is required</div> : null}
                                    </> :
                                    <>
                                        <CustomSelect
                                            placeholder="Select Supplier"
                                            options={manufs.map(manufacturer => ({
                                                value: manufacturer.manufacturer_id,
                                                label: manufacturer.manufacturer_name
                                            }))}
                                            value={manufacturer}
                                            onChange={handleChangeManufacturer}
                                        />
                                        {submitted && !manufacturer ?
                                            <div className='invalid-text'>Manufacturer is required</div> : null}
                                    </>
                                }
                            </Col>
                            <Col sm={3}>
                                <button type="submit" className="btn pharmacy-info-btn btn-sm"
                                        disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? "Searching..." : "Search"}</button>
                            </Col>
                        </Form.Group>
                    </form>
                </Card>
            </div>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars,
        manufacturers: state.manufacturers
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            manufacturerActions: bindActionCreators(manufacturerActions, dispatch)
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Return);
import React, {Component} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import AsyncSelect from "react-select/async";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";


// const customers = [
//     {customer_id: 1, customer_name: "John Tembo"},
//     {customer_id: 2, customer_name: "Jude Othieno"},
//     {customer_id: 2, customer_name: "Allison Grace"},
// ];

// const drugList = [
//     {value: "Acidex 20mg", label: "Acidex 20mg"},
//     {value: "Paracetamol", label: "Paracetamol"}
//
// ];


class ServiceInvoice extends Component {
    state = {
        showNewCustomer: false,
        selectCustomer: null,
        rows: [{
            serviceName: ''
        }],
        serviceName: null,
        manualCode: '',
        invoiceDate: '',
        payeeName: '',
        employeeName: '',
        address: '',
        paymentMethod: ''
    };
    handleChangeNameFields = event => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    handleChangeFormFields = event => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };
    handleChangePaymentMethod = (event, idx) => {
        this.setState({paymentMethod: event.target.value});
    };

    handleChange = (event, index) => {
        let rows = [...this.state.rows];
        let {name, value} = event.target;
        let tmp = rows[index] ? rows[index] : {};
        tmp[name] = value;
        rows[index] = tmp;
        this.setState({rows});
    };
    handleChangeBatch = (event, idx) => {
        let rows = [...this.state.rows];
        let {value, name, options} = event.target;
        // const selectedIndex = event.target.options.selectedIndex;
        if (rows[idx]) {
            rows[idx][name] = value

        } else {
            rows[idx] = {
                options
            };
        }
        this.setState({rows});

    };

    handleFetchData = (product_name, callback) =>{
        const {actions} = this.props;
        const {drugs} = this.props.invoices;
        if(!product_name){
            return callback([])
        }
        setTimeout(()=>{
            actions.invoiceActions.getDrugs(product_name);
            return(callback(drugs));
        },500)
    };
    handleFetchCustomers = (customer_id, callback) =>{
        const {actions} = this.props;
        const {customers} = this.props.invoices;
        if(!customer_id){
            return callback([])
        }
        setTimeout(()=>{
            actions.invoiceActions.fetchCustomers(customer_id);
            return(callback(customers));
        },500)
    };
    handleChangeServiceName = (serviceName, idx )  => {
        // const inputValue = itemInfo.replace(/\w/g, '');
        const {actions} = this.props;
        let rows = [...this.state.rows];
        if (rows[idx]) {
            rows[idx] = serviceName
        } else {
            rows[idx] = {
                serviceName
            };
        }
        this.setState({rows});
        actions.invoiceActions.getProductInfo(rows[idx].value)

    };
    handleAddRow = () => {
        const item = {
            serviceName: {},
            // batch: 0,
            // avQty: "",
            // expiry: "",
            // unit: "",
            qty: "",
            price: "",
            discount: "",
            total: "",

        };
        this.setState({
            rows: [...this.state.rows, item]
        });
    };
    handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.state.rows];
        rows.splice(idx, 1);
        this.setState({rows})
    };

    handleShowNewCustomer = () => {

        const {showNewCustomer} = this.state;
        this.setState({showNewCustomer: !showNewCustomer})
    };

    handleSelectCustomer = selectCustomer => {
        this.setState({selectCustomer});
    };

    handleSaveInvoice = event => {
        event.preventDefault();
        const {rows} = this.state;
        // const {productInfo} = this.props.invoices;
        let formData = new FormData();
        let serviceName, qty, price, discount, total;
        for(let row in rows){
            serviceName = rows[row].label;
            qty = rows[row].qty;
            price = rows[row].price;
            discount = rows[row].discount;
            total = rows[row].total;
        }
        formData.append('serviceName', serviceName);
        formData.append('qty',qty);
        formData.append('price',price);
        formData.append('discount',discount);
        formData.append('total',total);



    };
    render() {
        const {
            showNewCustomer, selectCustomer, rows,
            invoiceDate, payeeName, address,  employeeName
        } = this.state;
        return(
            <div>
            <DashboardHeader service serviceInvoice/>
            <div className="card my-3 p-3">
                <div className="pos-buttons">
                    {/* <Link to="/manageinvoice"><button type="button" className="btn pharmacy-info-btn mr-2">
                        <FontAwesomeIcon icon={faList} /> Manage Invoice</button></Link>
                    <Link to="/createposinvoice"><button type="button" className="btn pharmacy-primary-btn mr-2">
                        <FontAwesomeIcon icon={faList} /> POS Invoice</button></Link>
                    <button type="button" className="btn pharmacy-info-btn">
                         Upload CSV Invoice</button> */}
                </div>
                <div className="card mt-4">
                    <h6 className="mx-2 mt-2">New Invoice</h6>
                    <hr/>
                    <div className="m-2">
                        <Form onSubmit={this.handleSaveInvoice} autoComplete="off">
                            
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label>Employee Name</Form.Label>
                                        <Form.Control as="select" name="employeeName" value={employeeName}
                                                    onChange={this.handleChangeNameFields}>
                                                        <option value="Name 1">Name 1</option>
                                                        <option value="Name 2">Name 2</option>
                                                        <option value="Name 3">Name 3</option>
                                                        <option value="Name 4">Name 4</option>
                                        </Form.Control>
                                </Form.Group>

                               {/* <Form.Group as={Col} controlId="formGridAddress2">
                                    <Form.Label>Payment Method</Form.Label>
                                    <Form.Control as="select" value={paymentMethod}
                                                  onChange={this.handleChangePaymentMethod}>
                                        <option value="Choose Payment">Choose Payment</option>
                                        <option value="Cash Payment">Cash Payment</option>
                                        <option value="Insurance">Insurance</option>
                                        <option value="Mobile Money Payment">Mobile Money Payment</option>
                                    </Form.Control>
                                </Form.Group> */}

                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control type="date" name="invoiceDate" value={invoiceDate}
                                                  onChange={this.handleChangeFormFields}/>
                                </Form.Group>
                            </Form.Row> 

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>{!showNewCustomer ? "Customer Name/Mobile" : "Customer Name"}</Form.Label>
                                    {!showNewCustomer ?
                                        <AsyncSelect
                                            cacheOptions
                                            loadOptions={this.handleFetchCustomers}
                                            onChange={(this.handleSelectCustomer)}
                                            value={selectCustomer}
                                        />: <Form.Control type="text" name="payeeName" value={payeeName}
                                                          onChange={this.handleChangeFormFields}/>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label></Form.Label><br/><p/>
                                    <button type="button" className="btn pharmacy-btn btn-sm"
                                            onClick={this.handleShowNewCustomer}>{!showNewCustomer ? "New Customer" : "Old Customer"}</button>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridZip">
                                </Form.Group>
                            </Form.Row>
                            {showNewCustomer &&
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridPhone">
                                    <Form.Label>Customer Mobile</Form.Label>
                                    <Form.Control type="number" name="address" value={address}
                                                  onChange={this.handleChangeFormFields}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>Customer Address</Form.Label>
                                    <Form.Control type="text" name="address" value={address}
                                                  onChange={this.handleChangeFormFields}/>
                                </Form.Group>
                            </Form.Row>
                            }
                            <div className="table-responsive">
                            <table className="table table-bordered table-sm">
                                <thead>
                                <tr>
                                    <td width="15%">Service Name</td>
                                    <td>Quantity</td>
                                    <td>Charge</td>
                                    {/* <td>Expiry</td>
                                    <td>Unit</td>
                                    <td>Qty</td>
                                    <td>Price</td> */}
                                    <td>Discount%</td>
                                    <td>Total</td>
                                    <td>Action</td>
                                </tr>
                                </thead>
                                <tbody>
                                {rows.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={this.handleFetchData}
                                                onChange={(e) => this.handleChangeServiceName(e, idx)}
                                                value={rows[idx].serviceName}
                                            />
                                        </td>
                                        {/* <td width="10%">
                                            <Form.Control as="select" name="batch"
                                                          value={rows[idx].batch}
                                                          onChange={(e) => this.handleChangeBatch(e, idx)}>
                                                <option>Choose</option>
                                                {drugList.map(drug=>{
                                                    return(
                                                        <option key={drug.drug_id} data-key={drug.drug_id} value={drug.value}>{drug.label}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="avQty"
                                                value={rows[idx].avQty}
                                                onChange={(e) => this.handleChange(e, idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="expiry"
                                                value={rows[idx].expiry}
                                                onChange={(e) => this.handleChange(e, idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="unit"
                                                value={rows[idx].unit}
                                                onChange={(e) => this.handleChange(e, idx)}
                                                className="form-control"
                                            />
                                        </td> */}
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="qty"
                                                value={rows[idx].qty}
                                                onChange={(e) => this.handleChange(e, idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="price"
                                                value={rows[idx].price}
                                                onChange={(e) => this.handleChange(e, idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="discount"
                                                value={rows[idx].discount}
                                                onChange={(e) => this.handleChange(e, idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                name="total"
                                                value={rows[idx].total}
                                                onChange={(e) => this.handleChange(e, idx)}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-outline-danger btn-sm"
                                                onClick={this.handleRemoveSpecificRow(idx)}
                                            >
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={3} rowSpan={2}>
                                        <Form.Group>
                                            <Form.Label>Invoice Details</Form.Label>
                                            <Form.Control as="textarea"/>
                                        </Form.Group>
                                    </td>
                                    <td className="text-right">Invoice Discount</td>
                                    <td>
                                        <Form.Group>
                                            <Form.Control type="text"/>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <button type="button" onClick={this.handleAddRow}
                                                className="btn  pharmacy-info-btn ">
                                            <FontAwesomeIcon icon={faPlus}/></button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-right"> Total Discount</td>
                                    <td>
                                        <Form.Group>
                                            <Form.Control type="text"/>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="text-right"> Total Tax</td>
                                    <td>
                                        <Form.Group>
                                            <Form.Control type="text"/>
                                        </Form.Group>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="text-right">Grand Total</td>
                                    <td>
                                        <Form.Group>
                                            <Form.Control type="text"/>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="text-right"> Previous</td>
                                    <td>
                                        <Form.Group>
                                            <Form.Control type="text"/>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="text-right"> Net Total</td>
                                    <td>
                                        <Form.Group>
                                            <Form.Control type="text"/>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="text-right"> Paid Amount</td>
                                    <td>
                                        <Form.Group>
                                            <Form.Control type="text"/>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="text-right"> Due</td>
                                    <td>
                                        <Form.Group>
                                            <Form.Control type="text"/>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="text-right"> Change</td>
                                    <td>
                                        <Form.Group>
                                            <Form.Control type="text"/>
                                        </Form.Group>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <button type="submit" className="btn pharmacy-btn ">Submit Invoice</button>
                        </Form>
                    </div>


                </div>
            </div>
            </div>
        )

    }

}
function mapStateToProps(state) {
    return {
        invoices: state.invoices
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            invoiceActions: bindActionCreators(invoiceActions, dispatch)
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps) (ServiceInvoice);
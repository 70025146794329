import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    listItemIcon: {
        minWidth: '33px'
    }

}));

export const StyledListItemIcon = (props) => {
    const classes = useStyles();
    return (
        <ListItemIcon classes={{root: classes.listItemIcon}} {...props}/>
    )

}
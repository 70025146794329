import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Paper from "@material-ui/core/Paper";
import {EnhancedToolbar,EnhancedPharmacyListHead, stableSort, getSorting, useStyles} from "../../Utils/Tables/MuiTables";
import DrugsAvailableTablePagination from "../../Utils/Tables/MuiTablePagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FilterResults from 'react-filter-search';
import TablePagination from '@material-ui/core/TablePagination';
import {withStyles} from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    },
    subMenuLink: {
        display: "block",
        color: "#757575",
        textDecoration: "none"
        // padding: theme.spacing.unit * 6,
    },
    itemStyle: {
        backgroundColor: "#f9f9f9"
    },
    itemOdd: {
        backgroundColor: "#fff"
    }
});

const data = [
    {
        id: 1,
        servicename: "Pressure management",
        charge: "130,000",
        description: "  ",
        tax: "%",
        vat: "%",
        Action: " "
    },
    {
        id: 2,
        servicename: "Blood test",
        charge: "200,000",
        description: " ",
        tax: "%",
        vat: "%",
        Action: " "
    },

];

class ManageService extends Component{
    constructor(props){
        super(props);
        this.state={
            term: "",
            order: 'asc',
            setOrder: 'asc',
            orderBy: 'person',
            selected: [],
            setSelected: [],
            page: 0,
            setPage: 0,
            dense: false,
            setDense: false,
            rowsPerPage: 5,
        };
        this.handler = this.handler.bind(this);
        this.ref = React.createRef();
    }
    handler(value) {
        this.setState({term: value})
    }
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0});
    };
    render() {
        const {order, dense, orderBy, selected, rowsPerPage, page, term} = this.state;
        const {classes} = this.props;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        return(
            <div>
            <DashboardHeader service manageService/>
            <div className="card my-3 p-3">
                <div className="pos-buttons">
                    <Link to="/addservice" style={{textDecoration:"none", color:"inherit"}}>
                        <button type="button" className="btn mr-2 pharmacy-info-btn">
                            <FontAwesomeIcon icon={faPlus} /> Add Service</button>
                    </Link>
                </div>

                <div className="card mt-4 p-2">
                    <h6 className="ml-3 mt-2">Manage Service</h6>
                    <hr/>
                    <Paper className={`${classes.paper} table-responsive`}>
                        <EnhancedToolbar numSelected={selected.length} handler={this.handler} targetRef={this.ref} data={data} service/>
                        <div className={classes.tableWrapper} ref={this.ref}>
                            <Table
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                // className={classes.table}
                                id="section-to-print"
                                >
                                <EnhancedPharmacyListHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                    service
                                />
                                <FilterResults
                                    value={term}
                                    data={data}
                                    renderResults={results => (
                                        <TableBody>
                                            {stableSort(results, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((d, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={d.id}
                                                            className='mui-table-row'>
                                                            <TableCell component="th" scope="row" padding="default"
                                                                       className='mui-table-cell'>
                                                                <span>{d.id}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.servicename}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.charge}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.description}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.tax}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.vat}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>
                                                                    <Tooltip title="Update" placement="left-start">
                                                                    <button className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                                                    </button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete" placement="left-start">
                                                                    <button className="btn pharmacy-btn-dismiss btn-sm  ">
                                                                        <FontAwesomeIcon icon={faTrash}/>
                                                                    </button>
                                                                    </Tooltip>
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 49 * emptyRows}}>
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    )}
                                />
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[ 5, 10, 25]}
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={DrugsAvailableTablePagination}
                        />
                    </Paper>
                </div>
            </div>
            </div>
        )
    }

}
export default (withStyles(useStyles, styles) )(ManageService);
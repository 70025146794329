import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowRestore} from "@fortawesome/free-solid-svg-icons/faWindowRestore";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {dateStringConvert, titleCase} from "../../Utils/titleCaseFunction";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Tabs/ReusableTabs";
import {CustomPagination, EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";


const CommonReturnList = ({data, search_value, state, handleChange, activePage, handlePageChange, loading, headData,
                              handleChangeSearch, title, total_count, handleOpenDialog,handleSubmitSearch, children
                          }) => {
    const components = [{label: 'Customer Returns', path: '/stockreturnlist'},
        {label: 'Supplier Returns', path: '/manufreturnlist/supplier'}, {
            label: 'Wastage Returns',
            path: '/wastagereturnlist/wastages'
        },
        {label: 'Damages', path: '/damages/damages'}];
    const {from_date, to_date} = state;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component={title} heading="Return"
                                     subHeading={title} link={history.location.pathname}/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            {children}
            <div className="general-ledger-header">
                <form onSubmit={handleSubmitSearch} style={{marginBottom: '-1%'}}>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group as={Row}>
                                <Label column sm={2} name='Start:'/>
                                <Col sm={6}>
                                    <TextField type="date" name="from_date"
                                               value={from_date} onChange={handleChange}/>
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group as={Row}>
                                <Label column sm={2} name='End:'/>
                                <Col sm={6}>
                                    <TextField type="date" name="to_date" value={to_date}
                                               onChange={handleChange}/>
                                </Col>
                                <Col sm={2}>
                                    <button type="submit" className="btn pharmacy-info-btn btn-sm">Search</button>
                                </Col>
                            </Form.Group>
                        </div>
                    </div>
                </form>
            </div>

            <MuiPaper>
                <EnhancedTableToolbar title=''>
                    <Form.Control type="text" value={search_value ? titleCase(search_value) : ''}
                                  onChange={handleChangeSearch}
                                  className='form__control'
                                  placeholder="Search" style={{float: 'right'}}/>
                </EnhancedTableToolbar>

                <Table
                    aria-labelledby="tableTitle"
                    size='medium'
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead headCells={headData}/>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="9" align="center"><img src="/images/listPreloader.gif"
                                                                   alt="loader"
                                                                   style={{width: '5%'}}/></TableCell>
                    </TableRow></TableBody> : data.length > 0 ?
                        <TableBody>
                            {data.slice(0, 10)
                                .map((d, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={d.id}
                                            className='mui-table-row'>
                                            <TableCell>
                                                <span>{d.id}</span>
                                            </TableCell>
                                            <TableCell><span>{d.name}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{dateStringConvert(d.date)}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{d.quantity}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{d.rate}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{d.discount}</span>
                                            </TableCell>

                                            <TableCell>
                                                <span>{d.total_amount}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>
                                                    <Tooltip title="Invoice" placement="left-start">
                                                    <Link to={{
                                                        pathname: `/${d.url}/${d.return_id}`
                                                    }}>
                                                    <button className="btn pharmacy-btn btn-sm mr-1 ">
                                                        <FontAwesomeIcon icon={faWindowRestore}/>
                                                    </button></Link>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="left-start">
                                                    <button
                                                        onClick={() => handleOpenDialog(d.id)}
                                                        className="btn pharmacy-btn-dismiss btn-sm">
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </button>
                                                    </Tooltip>
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>

                        : <TableBody><TableRow>
                            <TableCell colSpan="9" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </Table>
                <CustomPagination data={data} records={10} activePage={activePage} total_count={total_count}
                                  handleChangeNextPage={handlePageChange}/>
            </MuiPaper>
        </div>
    )

}


export default CommonReturnList;
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const EmployeeWiseReport = () => {
    return (
        <div>
        <DashboardHeader attendance employeeWise/>
            <div className="card styled-report-card p-3">
                <hr/>
                <Form autoComplete="off">
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Employee Name</Form.Label>
                        <Col sm={8}>
                            <Select/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Start Date</Form.Label>
                        <Col sm={8}>
                            <Form.Control type="date"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>End Date</Form.Label>
                        <Col sm={8}>
                            <Form.Control type="date"/>
                        </Col>
                    </Form.Group>
                    <div className="text-center">
                        <button className="btn pharmacy-info-btn px-4">Search</button>
                    </div>
                </Form>
            </div>

            <div className="card styled-report-card p-3">
                <h6>Employee Attendance Report</h6>
                <hr/>
                <div className="text-center">
                    <h5>Attendance Report</h5>
                </div>
                <div className="row">
                    <div className="col-md-4"/>
                    <div className="col-md-8">
                        <h6>Name: </h6>
                        <h6>ID NO: </h6>
                        <h6>Designation: </h6>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <td>SL</td>
                            <td>Date</td>
                            <td>Check In</td>
                            <td>Check Out</td>
                            <td>Work hours</td>
                        </tr>
                        </thead>
                        <tbody/>
                    </table>
                </div>
            </div>
        </div>
    )
};
export default EmployeeWiseReport;
import React from "react";
import MainPersonLoanDetails from "../PersonalLoan/MainPersonLoanDetails";
import {Link} from "react-router-dom";



const PersonalLoanLedger = () =>{
    return (
        <MainPersonLoanDetails personLedger officeLoan>
            <div className="styled-buttons">
                <Link to="/addpersonoffice"><button className="btn pharmacy-info-btn mr-2">Add Person</button></Link>
                <Link to="/managepersonoffice"><button className="btn pharmacy-primary-btn">Manage Person</button></Link>
            </div>
        </MainPersonLoanDetails>
    )
};
export default PersonalLoanLedger
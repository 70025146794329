import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as supplierActions from "../../Redux/Actions/SupplierActions/SupplierActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import CsvDialog from "../../Utils/CsvDialog";


class AddSupplier extends Component {
    constructor() {
        super();
        this.state = {
            supplier_name: '',
            mobile: '',
            address: '',
            details: '',
            previous_balance: '',
            supplierCsv:'',
            openAlert:false,
            openDialog:false,
            submitted: false
        };
        this.supplierName = React.createRef();
        this.supplierMobile = React.createRef();
        this.address = React.createRef();
        this.details = React.createRef();
        this.balance = React.createRef();
        this.submitBtn = React.createRef();
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value, openAlert:false})
    };
    handleSubmit = (event) => {
        const {actions} = this.props;
        const {supplier_name} = this.state;
        event.preventDefault();
        if(supplier_name){
            this.setState({submitted:false});
            let supplier_form = document.getElementById('supplier_form');
            let formData = new FormData(supplier_form);
            actions.supplierActions.addSupplier(formData)

        }else {
            this.setState({submitted:true})
        }

    };
    handleCloseBar = () => {
        const {actions} = this.props;
        actions.snackbarActions.hideSnackBar()
    };

    handleOpenDialog = () =>{
        this.setState({openDialog:true})
    };
    handleCloseDialog = () =>{
        this.setState({openDialog:false})
    };

    handleUploadCsv = (event) =>{
        const {actions} = this.props;
        const {supplierCsv} = this.state;
        let formData = new FormData();
        event.preventDefault();
        formData.append('upload_csv_file',supplierCsv);
        actions.supplierActions.uploadSupplierCsv(formData)
    };

    handleChangeUpload = event =>{
        const {actions} = this.props;
        if(event.target.files[0].name.substr(event.target.files[0].name.length-4) === ".csv"){
            actions.snackbarActions.errorSnackbar('Please upload only csv file format!');
            event.target.value = null;
        }else{
            this.setState({supplierCsv:event.target.files[0]});
        }
    };
    handleKeyPress = (event, reference) =>{
        if(event.key === "Enter"){
            event.preventDefault();
            reference.current.focus()

        }
    };

    render() {
        const {supplier_name, mobile, address, details, previous_balance,supplierCsv, openAlert,openDialog,submitted} = this.state;
        const {open, message, variant} = this.props.snackbars;
        const {submitting}=this.props.suppliers;
        return (
            <div>
            <DashboardHeader supplier addSupplier />
            <div className="card my-3 p-3">
                <div className="pos-buttons">
                    <Link to="/managesupplier">
                        <button type="button" className="btn mr-2 pharmacy-info-btn">
                            <FontAwesomeIcon icon={faList} className="mr-1"/>Manage Supplier
                        </button>
                    </Link>
                    <Link to="/supplierledger">
                        <button type="button" className="btn pharmacy-primary-btn mr-2">
                            <FontAwesomeIcon icon={faList}/> Supplier Ledger
                        </button>
                    </Link>
                    <CsvDialog openDialog={openDialog} handleCloseDialog={this.handleCloseDialog}
                               handleUploadCsv={this.handleUploadCsv} handleChangeUpload={this.handleChangeUpload}
                               message="Supplier">
                        <a href="/csv/supplier_csv_sample.csv" download>
                            <button  className="btn pharmacy-primary-btn">Download Sample File</button></a>
                    </CsvDialog>
                    {/*<input type="file" ref={input => this.input =input} style={{display:"none"}} onChange={this.handleUploadCsv}/>*/}
                    <button className="btn pharmacy-info-btn mr-1" onClick={this.handleOpenDialog}>Upload CSV</button>
                    <span>{supplierCsv.name}</span>
                </div>
                {openAlert && <div className="alert alert-danger text-center mt-3">Please fill in the required field</div>}
                <div className="card mt-3">
                    <h6 className="mx-2 mt-2">Add Supplier</h6>
                    <hr/>
                    <div className="m-2">
                        <PharmacySnackbar open={open} message={message} variant={variant}
                                          handleCloseBar={this.handleCloseBar}/>
                        <Form id="supplier_form" onSubmit={this.handleSubmit} autoComplete="off">
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Supplier Name<span className="text-danger asterics ml-1">*</span></Form.Label>
                                    <Form.Control type="text" value={supplier_name} name="supplier_name"
                                                  ref={this.supplierName} onKeyDown={e=>this.handleKeyPress(e,this.supplierMobile)}
                                                  onChange={this.handleChange}/>
                                        {(submitted && !supplier_name) && <div className="invalid-text">Please fill in the supplier name</div>}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Supplier Mobile</Form.Label>
                                    <Form.Control type="text" value={mobile} name="mobile"
                                                  ref={this.supplierMobile} onKeyDown={e=>this.handleKeyPress(e,this.address)}
                                                  onChange={this.handleChange}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>

                                <Form.Group as={Col} controlId="formGridAddress2">
                                    <Form.Label>Supplier Address</Form.Label>
                                    <Form.Control as="textarea" value={address} name="address"
                                                  ref={this.address} onKeyDown={e=>this.handleKeyPress(e,this.details)}
                                                  onChange={this.handleChange}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label>Supplier Details</Form.Label>
                                    <Form.Control as="textarea" value={details} name="details"
                                                  ref={this.details} onKeyDown={e=>this.handleKeyPress(e,this.balance)}
                                                  onChange={this.handleChange}/>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>Previous Balance</Form.Label>
                                    <Form.Control type="text" value={previous_balance} name="previous_balance"
                                                  ref={this.balance} onKeyDown={e=>this.handleKeyPress(e,this.submitBtn)}
                                                  onChange={this.handleChange}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridZip">
                                </Form.Group>
                            </Form.Row>
                            <button type="submit" ref={this.submitBtn}disabled={!!submitting }
                             className="btn pharmacy-btn offset-md-5">{submitting ? "Saving...":"Save Supplier"}</button>
                        </Form>
                    </div>

                </div>
            </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars,
        suppliers:state.suppliers
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            supplierActions: bindActionCreators(supplierActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AddSupplier);
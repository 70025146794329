import React from 'react'
import {alpha, makeStyles, withStyles} from '@material-ui/core/styles';
import {TreeView, TreeItem} from "@material-ui/lab";
import Card from '@material-ui/core/Card';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions'
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Grid from "@material-ui/core/Grid";
import { ReusableNewCOA} from "./NewCOAccount";
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { v4 as uuidv4 } from 'uuid';
import {useNewCOAccount} from "./CustomHooks/useNewCOAccount";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 400,
    },
    icon: {
        fill: '#605e5e'
    }
});


const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    label: {
        fontSize: 14
    }
}))((props) => <TreeItem {...props} />);

export const ReusableCOA = ({coa}) =>{
    const classes = useStyles();
    const renderTree = (nodes) =>(
        <StyledTreeItem key={uuidv4()} nodeId={nodes.id === 'root' ? nodes.id : uuidv4()} label={nodes.name ? nodes.name : nodes.head_name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </StyledTreeItem>
    );
    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<FolderOpenIcon/>}
            defaultExpanded={['root']}
            defaultExpandIcon={<FolderIcon classes={{root: classes.icon}}/>}
            defaultEndIcon={<FolderOpenIcon/>}

        >
            {renderTree(coa)}
        </TreeView>
    )
}

const ChartOfAccounts = ({actions, snackbars}) => {
    const {accountType, state, parentAccount, handleChangeAccountType, defaultAccounts, subAccounts,
        handleChangeState, handleChangeParentAccount, submitted, isSubmitted, handleSubmit,
        closeSnackbar, entryType, handleChangeEntryType, openDialog,handleOpenDialog,handleCloseDialog,coa
    } = useNewCOAccount(actions);

    const {account_name, opening_balance, isSubAccount, balance_date} = state;
    const entryTypes = [{value: 'DEBIT', label: 'DEBIT'}, {value: 'CREDIT', label: 'CREDIT'}]
    const coaProps = {accountType, defaultAccounts, handleChangeAccountType, submitted, handleChangeState,
        account_name, subAccounts, parentAccount, handleChangeParentAccount,
        isSubAccount, opening_balance, balance_date, entryType, handleChangeEntryType, entryTypes}
    const {open, variant, message} = snackbars;
    return (
        <div>
            <ReusableDashboardHeader component="Chart of Accounts" heading="Accounting"
                                     subHeading="Chart of accounts" link="/coa"/>
            <div className="general-ledger-header">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        {/*<Typography variant='body1'>Chart of Accounts Tree</Typography>*/}
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <div className="text-right">
                            <button onClick={handleOpenDialog}  className="btn btn-sm pharmacy-info-btn">New Account</button>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className='mui-tables'>
                <PharmacySnackbar handleCloseBar={closeSnackbar} variant={variant} open={open} message={message}/>
                <ReusableDialog openDialog={openDialog} message='Create Account' handleClose={handleCloseDialog}
                                handleSubmit={handleSubmit} isSubmitted={isSubmitted} width='sm'>
                    <ReusableNewCOA {...coaProps}/>
                </ReusableDialog>
                <Card className="p-3 mt-2" elevation={0}>
                    <ReusableCOA coa={coa}/>
                </Card>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccounts);

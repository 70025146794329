import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as purchaseActions from "../../Redux/Actions/PurchaseActions/PurchaseActions";
import MainInvoiceDetails from "../Invoice/MainInvoiceDetails";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";


class InvoiceHtml extends Component {

    componentDidMount() {
        const {purchaseActions} = this.props;
        const {purchase_id} = this.props.match.params;
        // const purchase_id = this.props.match.params.purchase_id
        purchaseActions.invoiceHtmlForm(purchase_id)
    }

    render() {
        const {formSubmit} = this.props.purchases;
        const invoice_data = !formSubmit.details ? [] : formSubmit.details;
        const manufacturer_info = !formSubmit.manufacturer_info ? [] : formSubmit.manufacturer_info;
        const purchase = !formSubmit.purchase ? [] : formSubmit.purchase;
        const singlepurchase =  !purchase[0] ? {} :purchase[0];
        const singleManufacturer = !manufacturer_info[0] ? {} :manufacturer_info[0];
        return (
            <div>
                <DashboardHeader purchase purcaseDetails purchaseInvoice/>
                <MainInvoiceDetails purchaseInvoice
                                    invoiceIData={formSubmit} invoice_data={invoice_data}
                                    manufacturer_info={singleManufacturer}
                                    purchase={singlepurchase}
                />
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        purchases: state.purchases
    }
}

function mapDispatchToProps(dispatch) {
    return {
        purchaseActions: bindActionCreators(purchaseActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceHtml);
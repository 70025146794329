import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import {makeStyles} from '@material-ui/core/styles';
import ReusableList from "./ReusableList";
import {JournalSelect, NoTransaction, ReusableJournalTable} from "./ReusableJournalTable";
import {useJournal} from "./CustomHooks/useJournal";
import Typography from "@material-ui/core/Typography";
import PharmacyBackdrop from "../../Utils/PharmacyBackDrop";
import TextField from "../../Utils/FormInputs/TextField";
import {titleCase} from "../../Utils/titleCaseFunction";
import {Link} from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";

const useStyles = makeStyles(() => ({
    item: {
        borderRight: '1px solid #c7c6c7',
        borderLeft: '1px solid #c7c6c7',
        height: '100vh',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    },
    rounded: {
        borderRadius: 0
    },
    body1: {
        fontSize: '0.98em'
    },
    body2: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.85em'
    },


}));
const Journals = () => {
    const classes = useStyles()
    const {allJournals, handleClickJournal, journal, journalDetails, totals, loading,
        search, handleSearch, filteredList, page,rowsPerPage,handleChangePage,
        handleChangeRowsPerPage, sortJournal, handleChangeJournal,accounts} = useJournal()
    const {head_name, head_code} = journalDetails;
    const {total_credit, total_debit} = totals;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Journals" heading="Accounting"
                                     subHeading="Journals" link="/journals"/>
            <Card elevation={0} classes={{root: classes.rounded}}>
                <Grid container spacing={0}>
                    <Grid item xs={6} md={4} classes={{item: classes.item}}>
                        <div className="top-bar all-journals">
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <JournalSelect journal={sortJournal} handleChange={handleChangeJournal}>
                                        <option value="all">All Journals</option>
                                        <optgroup label="Assets">
                                            <option value="current_assets">Current Assets</option>
                                            <option value="non_current_assets">Non-Current Assets</option>
                                        </optgroup>
                                        <optgroup label="Liabilities">
                                            <option value="current_liabilities">Current Liabilities</option>
                                            <option value="non_current_liabilities">Non-Current Liabilities</option>
                                        </optgroup>
                                        <option value="equity">Equity</option>
                                        <option value="income">Income</option>
                                        <option value="expense">Expense</option>
                                    </JournalSelect>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField type='text' value={titleCase(search)} name='search'
                                               onChange={handleSearch}
                                               placeholder='Search journal'/>
                                </Grid>
                            </Grid>

                        </div>
                        {filteredList(allJournals).map(item => (
                            <ReusableList key={item.id} primaryText={item.head_name}
                                          secondaryText={item.head_code} onClick={() => handleClickJournal(item)}/>
                        ))}

                    </Grid>
                    <Grid item xs={6} md={8}>
                        {loading ? <div className='text-center mt-5'><img src="/images/listPreloader.gif"
                                                                          style={{width: '10%'}}
                                                                          alt="loader"/></div> :
                            <>
                                <div className="top-bar">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant='body1'
                                                        classes={{body1: classes.body1}}>{head_name}</Typography>
                                            <Typography variant='body2'
                                                        classes={{body2: classes.body2}}>{head_code}</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <div className="text-right">
                                                <Link to='/newledgerentry'>
                                                    <button className="btn btn-sm pharmacy-info-btn">New Entry</button>
                                                </Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="sub-journal-bar">

                                    <div className="row">
                                        <div className="col-md-8"/>
                                        <div className="col-md-4">
                                            <table className="table table-bordered profile-table table-sm debit-table">
                                                <tr>
                                                    <td align='right'>TOTAL DEBIT</td>
                                                    <td>{total_debit}</td>
                                                </tr>
                                                <tr>
                                                    <td align='right'>TOTAL CREDIT</td>
                                                    <td>{total_credit}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-3 px-3">
                                    {journal.length > 0 ?
                                        <>
                                            <ReusableJournalTable data={journal.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} totalDebit={total_debit}
                                        totalCredit={total_credit}/>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25,30,50,100]}
                                                component="div"
                                                labelRowsPerPage='Transactions per page'
                                                count={journal.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </> :
                                        <NoTransaction text='transactions'/>}

                                </div>
                            </>}

                    </Grid>
                </Grid>
            </Card>

        </div>
    )
}
export default Journals
import React from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectInput from "../FormInputs/SelectInput";
import Label from "../FormInputs/Label";
import TextField from "../FormInputs/TextField";
import {CustomSelect} from "../FormInputs/SelectInput";
import Grid from "@material-ui/core/Grid";


export const ReusablePeriodFilter = ({period, handleChangePeriod, year, handleChangeYear, handleChangeDate,
                                         from_date, to_date, inputType,children,handleFilter}) => {
    const years = [{value: '2020', text: '2020'},{value: '2021', text: '2021'}, {value: '2022', text: '2022'}, {value: '2023', text: '2023'},
        {value: '2024', text: '2024'}, {value: '2025', text: '2025'}, {value: '2026', text: '2026'},
        {value: '2027', text: '2028'}, {value: '2029', text: '2029'}, {value: '2030', text: '2030'}]
    const periods = [{value: 'date', label: 'Date'}, {value: 'weekly', label: 'Weekly'},
        {value: 'monthly', label: 'Monthly'}, {value: 'yearly', label: 'Yearly'}, {value: 'custom', label: 'Custom'}]
    return (
        <div className="general-ledger-header mb-3">
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={6} md={8}>
                        {/*<Form.Group as={Row} style={{marginBottom: '-1%'}}>*/}
                        {/*    <Col sm={3}>*/}
                        {/*        /!*<span style={ledgerStyles.span}>Period:</span>*!/*/}
                        {/*        /!*<span>*!/*/}
                        {/*        <CustomSelect value={period} onChange={handleChangePeriod} options={periods}/>*/}
                        {/*        /!*</span>*!/*/}
                        {/*    </Col>*/}
                        {/*    {period.value !== 'today' ? <>*/}
                        {/*        {period.value === 'yearly' ?*/}
                        {/*            <Col sm={3}>*/}
                        {/*                <SelectInput options={years} value={year}*/}
                        {/*                             onChange={handleChangeYear}/>*/}
                        {/*            </Col> :*/}
                        {/*            <Col sm={4}>*/}
                        {/*                <Form.Group as={Row}>*/}
                        {/*                    {period.value === 'custom' ?*/}
                        {/*                        <Label name='From' column sm={2} style={{textAlign: 'right'}}/> : null}*/}
                        {/*                    <Col sm={10}>*/}
                        {/*                        <TextField type={inputType[period.value]} onChange={handleChangeDate}*/}
                        {/*                                   value={from_date} name='from_date'/>*/}
                        {/*                    </Col>*/}
                        {/*                </Form.Group>*/}
                        {/*            </Col>*/}
                        {/*        }*/}
                        {/*    </> : null}*/}
                        {/*    {period.value === 'custom' ? <Col sm={4}>*/}
                        {/*        <Form.Group as={Row}>*/}
                        {/*            <Label name='To' column sm={2}/>*/}
                        {/*            <Col sm={10}>*/}
                        {/*                <TextField type='date' value={to_date} onChange={handleChangeDate}*/}
                        {/*                           name='to_date'/>*/}
                        {/*            </Col>*/}
                        {/*        </Form.Group>*/}
                        {/*    </Col> : null}*/}
                        {/*    {(period.value && period.value !== 'today') ? <Col sm={1}>*/}
                        {/*        <button type='btn' className='btn btn-sm pharmacy-btn-outline' onClick={handleFilter}>Filter</button>*/}
                        {/*    </Col> : null}*/}
                        {/*</Form.Group>*/}
                    </Grid>

                    <Grid item xs={6} md={4}>
                        {children}
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
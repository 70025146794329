import {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {errorMessages} from "../../../Helpers/ErrorMessages";

export const usePurchaseOrderDetails = (match, actions) =>{
    const {local_purchase_order_id, lpo_status} = match.params;
    const pdfRef = useRef();
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const [sub_total, setSubTotal] = useState(0);
    const [id, setId] = useState({lpo_id:local_purchase_order_id, lpo_status:+lpo_status});
    const [loading, setLoading] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [reason, setReason] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [status, setStatus] = useState(false);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const  user_id = !singleUser.user_id ? '' :singleUser.user_id;

    const calculateSubTotal = (arr) => {
        const sum = arr.reduce((a, item) => {
            return a + item.total
        }, 0)
        setSubTotal(sum)
    }
    useEffect(() => {
        setLoading(true)
        const {lpo_id} = id;
        axios.get(`${config.epharmUrl}/Cpurchaseorder/retrieve_purchase_order`,
            {params: {local_purchase_order_id:lpo_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            const products = dt.product_list ? dt.product_list : [];
            setPurchaseOrder(dt);
            calculateSubTotal(products);
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [id]);

    const handleClick = (item) =>{
        const {local_purchase_order_id:lpo_id, status:st} = item
        setId({lpo_id, lpo_status: st })
    }

    const handleOpenConfirmation = () =>{
        setConfirmation(true)
    }

    const handleConfirm = () =>{
        const formData = new FormData();
        const {lpo_id} = id;
        formData.append('local_purchase_order_id',lpo_id)
        formData.append('user_id',user_id)
        axios.post(`${config.epharmUrl}/Cpurchaseorder/approve_order`,formData).then(()=>{
            actions.snackbarActions.successSnackbar('Purchase order approved ')
            setConfirmation(false)
            setStatus(!status)
        }).catch(err=>{
            errorMessages(err, null, actions)
        })
    }

    const handleCloseConfirmation = () =>{
        setConfirmation(false)
    }

    const handleOpenCancel = () =>{
        setCancel(true)
    }

    const handleCancel = (event) =>{
        event.preventDefault()
        const {lpo_id} = id;
        const formData = new FormData();
        formData.append('local_purchase_order_id',lpo_id);
        formData.append('user_id',user_id)
        formData.append('reason',reason)
        setSubmitted(true);
        if (reason){
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Cpurchaseorder/cancel_order`,formData).then(()=>{
                actions.snackbarActions.successSnackbar('Purchase order canceled ')
                setCancel(false)
                setIsSubmitted('resolved')
                setReason('')
                setSubmitted(false)
                setStatus(!status)
            }).catch(err=>{
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })

        }

    }

    const handleCloseCancel = () =>{
        setCancel(false)
    }

    const handleChangeReason = (event) =>{
        setReason(event.target.value)
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }

    const downloadPdf = () => {
        const input = pdfRef.current;
        html2canvas(input)
            .then((canvas) => {
                let imgData = canvas.toDataURL('image/png');
                let imgWidth = 210;
                let pageHeight = 297;
                let imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;

                let doc = new jsPDF('p', 'mm');
                let position = 0;

                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                doc.save("Purchase Order.pdf");
            })
        ;
    };
    const order = !purchaseOrder ? {} : purchaseOrder;
    const productList = !order.product_list ? [] : order.product_list;
    const orderDetails = !order.purchaseorder ? [] : order.purchaseorder;
    const details = !orderDetails[0] ? {} : orderDetails[0];
    const shipping_address = !details.ship_to_address ? "" : details.ship_to_address;
    const arr = shipping_address.split('\n');
    const shipping_terms = !details.shipping_termes ? "" : details.shipping_termes;
    const instructions = !details.comments ? "" : details.comments;
    const prepared_by = !details.prepared_by ? "" : details.prepared_by;
    const approved_by = `${!details.first_name ? "" : details.first_name} ${!details.last_name ? "" : details.last_name}`;

    return {purchaseOrder, sub_total, downloadPdf, productList, details,cancel,confirmation,isSubmitted,submitted,
        arr,loading, shipping_terms, instructions, pdfRef, id,handleClick, handleConfirm,handleCloseConfirmation,
    handleOpenConfirmation,handleCancel, handleChangeReason, handleOpenCancel, handleCloseCancel,reason,status,
    closeSnackbar, setId, approved_by,prepared_by}
}
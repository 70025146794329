import React, {Component} from "react";
import AddEditDesignation from "./AddEditDesignation";


class EditDesignation extends Component {
    render() {
        return (
            <AddEditDesignation editDesignation/>
        );
    }
}

export default EditDesignation;
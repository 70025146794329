import * as types from "../actionTypes";
import axios from "axios";
import config from "../../../Helpers/config";
import {successSnackbar} from "../SnackbarActions/SnackbarActions";
import {history} from "../../../Helpers/history";
import {errorMessages} from "../../../Helpers/ErrorMessages";

const configuration = {
    headers: {
        'content-Type': 'multipart/form-data',
        user_id: 1
    }
};

function manageSupplier(suppliers) {
    return {
        type: types.MANAGE_SUPPLIER,
        suppliers
    }
}

function getAllSuppliers(allSuppliers) {
    return{
        type:types.RETRIEVE_ALL_SUPPLIERS,
        allSuppliers
    }
}

function supplierForm(supplier) {
    return {
        type: types.SUPPLIER_UPDATE_FORM,
        supplier
    }

}

function supplierDelete(supplier_id) {
    return {
        type: types.DELETE_SUPPLIER,
        supplier_id
    }

}

function supplierLedgerReport(ledgerReport) {
    return {
        type: types.SUPPLIER_LEDGER_REPORT,
        ledgerReport
    }

}

// function supplierLedger(supplierLedger) {
//     return {
//         type: types.SUPPLIER_LEDGER,
//         supplierLedger
//     }
//
// }

function ledgerInfo(supplierLedgerInfo) {
    return {
        type: types.SUPPLIER_LEDGER_INFo,
        supplierLedgerInfo
    }

}

function supplierCount(supplier_count) {
    return {type: types.SUPPLIER_COUNT, supplier_count}

}
function addSupplyRequest(){
    return {
        type:types.SUPPLY_REQUEST,
    }
}
function addSupplyError(){
    return {
        type:types.SUPPLY_ERROR,
    }
}
function addSupplySuccess(){
    return {
        type:types.SUPPLY_SUCCESS,
    }
}
function editSupplyRequest(){
    return{
        type:types.EDITSUPPLY_REQUEST,
    }
}
function editSupplyError(){
    return{
        type:types.EDITSUPPLY_ERROR,
    }
}
function editSupplySuccess(){
    return{
        type:types.EDITSUPPLY_SUCCESS,
    }
}

export function retrieveAllSuppliers() {
    return function (dispatch) {
        return axios.get(`${config.epharmUrl}/Csupplier/retrieve_all_suppliers`).then(res=>{
            const suppliers = res.data.total_manufacturers;
            dispatch(getAllSuppliers(suppliers))
        }).catch((error)=>{
            errorMessages(error, dispatch, null)
        })
    }

}

export function supplierLedgerInfo(supplier_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Csupplier/supplier_ledger_info", {params: {supplier_id}}).then(response => {
            const data = response.data;
            dispatch(ledgerInfo(data));

        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }

}

export function retrieveSupplierLedger(from_date,to_date,supplier_id) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Csupplier/supplier_ledger",null, {params:{from_date, to_date, supplier_id}}).then(response => {
            const data = response.data;
            if(history.location.pathname === "/supplierledger"){
                dispatch(supplierLedgerReport(data));
            }
            dispatch(ledgerInfo(data))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }

}

export function retrieveLedgerReport() {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Csupplier/supplier_ledger_report").then(response => {
            const data = response.data;
            dispatch(supplierLedgerReport(data))
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }

}

export function uploadSupplierCsv(formData) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Csupplier/uploadCsv_supplier", formData, configuration).then(response => {
            // const data = response.data;
            dispatch(successSnackbar('Upload Successful!'))
        }).catch(error => {
            errorMessages(error, dispatch, null)

        })
    }

}

export function deleteSupplier(supplier_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Csupplier/delete_supplier", {params: {supplier_id}}).then(res => {
            // const data = res.data;
            dispatch(supplierDelete(supplier_id));
            dispatch(successSnackbar('Supplier Deleted Successfully!'));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })

    }

}

export function updateSupplier(formData) {
    return function (dispatch) {
        dispatch(editSupplyRequest());
        return axios.post(config.epharmUrl + "/Csupplier/supplier_update", formData, configuration).then(() => {
            dispatch(editSupplySuccess());
            dispatch(successSnackbar('Supplier Updated Successfully'));
            setTimeout(() => {
                history.push('/managesupplier')
            }, 1000)
        }).catch(error => {
            dispatch(editSupplyError());
            errorMessages(error, dispatch, null)

        })

    }

}

export function supplierUpdateForm(supplier_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Csupplier/supplier_update_form", {params: {supplier_id}}).then(response => {
            const data = response.data;
            dispatch(supplierForm(data));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }

}

export function retrieveSuppliers(page) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + `/Csupplier/manage_supplier/${page}`).then(response => {
            const data = response.data.suppliers_list;
            const count = response.data.total_count;
            dispatch(manageSupplier(data));
            dispatch(supplierCount(count));
        }).catch(error => {
            errorMessages(error, dispatch, null)
        })
    }

}

export function addSupplier(formData) {
    return function (dispatch) {
        dispatch(addSupplyRequest());
        return axios.post(config.epharmUrl + "/Csupplier/insert_supplier", formData, configuration).then(() => {
            dispatch(addSupplySuccess());
            dispatch(successSnackbar('Supplier Added Successfully'));
            setTimeout(() => {
                history.push('/managesupplier')
            }, 2000)
        }).catch(error => {
            dispatch(addSupplyError());
            errorMessages(error, dispatch, null)

        })

    }

}
import React from 'react'
import Card from '@material-ui/core/Card';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions'
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {useTransactionTypes} from "./CustomHooks/useTransactionTypes";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import {style} from "./DateSearch";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";

export const TransactionsTable = ({transactionTypes, text}) => {
    return (
        <table className='table table-bordered profile-table table-sm'>
            <thead>
            <tr>
                <td colSpan={4}
                    align="center">{text}
                </td>
            </tr>
            <tr>
                <td>Transaction Name</td>
                <td>Transaction Type</td>
                <td>Debit Ledger</td>
                <td>Credit Ledger</td>
            </tr>
            </thead>
            <tbody>
            {transactionTypes.map((item, index) => (
                <tr key={index}>
                    <td>{item.type_name}</td>
                    <td>{item.type}</td>
                    <td>{item.debit_ledger}</td>
                    <td>{item.credit_ledger}</td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}


const TransactionTypes = ({actions, snackbars}) => {
    const {
        transactionTypes, closeSnackbar, transactionName, transactionType, debit, credit, journals,
        open, handleChangeName, handleChangeType, handleChangeCredit, handleChangeDebit, handleOpen,
        handleClose, handleSubmit, submitted, isSubmitted, categories, category, handleChangeCategory
    } = useTransactionTypes(actions)
    const {variant, message, open: openBar} = snackbars;
    const types = [{value: 'SALES', label: 'SALES'}, {value: 'PURCHASES', label: 'PURCHASES'}, {
        value: 'EXPENSES',
        label: 'EXPENSES'
    }]
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Transaction Types" heading="Accounting"
                                     subHeading="Transaction types" link="/transactiontypes"/>
            <div className='general-ledger-header'>
                <div className="text-right">
                    <button onClick={handleOpen} type="button" className="btn btn-sm pharmacy-info-btn mb-1">New
                        Transaction Type
                    </button>
                </div>
            </div>
            <div className='mui-tables'>
                <Card className="p-3 mt-2" elevation={0}>
                    <PharmacySnackbar open={openBar} handleCloseBar={closeSnackbar} variant={variant}
                                      message={message}/>
                    <ReusableDialog message='New Transaction Type' openDialog={open} handleClose={handleClose}
                                    isSubmitted={isSubmitted} handleSubmit={handleSubmit} width='sm'>
                        <Form.Group as={Row}>
                            <Label name="Transaction Name" sm={4} column type style={style.textRight}/>
                            <Col sm={7}>
                                <TextField onChange={handleChangeName} name='transactionName' type="text"
                                           value={transactionName}/>
                                {(submitted && !transactionName) &&
                                <div className="help-block">Transaction name is required</div>}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Label name="Transaction Category" sm={4} column type style={style.textRight}/>
                            <Col sm={7}>
                                <CustomSelect value={category} options={categories} onChange={handleChangeCategory}/>
                                {(submitted && !category) &&
                                <div className="help-block">Transaction category is required</div>}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Label name="Transaction Type" sm={4} column type style={style.textRight}/>
                            <Col sm={7}>
                                <CustomSelect value={transactionType} options={types} onChange={handleChangeType}/>
                                {(submitted && !transactionType) &&
                                <div className="help-block">Transaction type is required</div>}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Label name="Debit" sm={4} column type style={style.textRight}/>
                            <Col sm={7}>
                                <CustomSelect value={debit} options={journals} onChange={handleChangeDebit}/>
                                {(submitted && !debit) && <div className="help-block">Debit is required</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label name="Credit" sm={4} column type style={style.textRight}/>
                            <Col sm={7}>
                                <CustomSelect onChange={handleChangeCredit} value={credit} options={journals}/>
                                {(submitted && !credit) && <div className="help-block">Credit is required</div>}
                            </Col>
                        </Form.Group>
                    </ReusableDialog>
                    <TransactionsTable transactionTypes={transactionTypes} text='Transaction Types'/>
                </Card>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionTypes)

import React , {Component} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";


// const ref = React.createRef();
class AddService extends Component{
    render() {
        return(
            <div>
            <DashboardHeader service addService/>
            <div className="card my-3 p-3">
                <div className="pos-buttons">
                    <Link to="/manageservice"><button type="button" className="btn mr-2 pharmacy-info-btn">
                        <FontAwesomeIcon icon={faList} />  Manage Service</button></Link>
                </div>

                <div className="card mt-4">
                    <h6 className="mx-2 mt-2">Add Service</h6>
                    <hr/>
                    <div className="m-2">
                        <Form autoComplete="off">
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Service Name</Form.Label>
                                    <Form.Control type="text" placeholder="Service Name" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Charge</Form.Label>
                                    <Form.Control type="number" placeholder="Charge (UGX)" />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control type="textarea" rows="5" placeholder="Description"/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridAddress2">
                                    <Form.Label>Branch</Form.Label>
                                    <Form.Control type="text" placeholder="Branch" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridZip">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Control type="number" placeholder="%"/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>VAT</Form.Label>
                                    <Form.Control type="number" placeholder="%"/>
                                </Form.Group>
                            </Form.Row>
                                    <button className="btn pharmacy-btn offset-md-5" type="submit"> Save </button>
                        </Form>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}
export default AddService;
import React, {useState} from 'react'
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {dateStringConvert} from "../../Utils/titleCaseFunction";


export const useMuiPagination = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage}
}

export const ReusableCreditTables = ({ title, headData, loading, data}) => {
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    return (
        <div className='progress-report-chart'>
            <MuiPaper>
                <EnhancedTableToolbar title={title}>
                    {/*<Link to={route}>View all</Link>*/}
                </EnhancedTableToolbar>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size='small'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="4" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader" style={{width:'10%'}}/></TableCell>
                        </TableRow></TableBody> : data.length > 0 ?
                            <TableBody>
                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={item.id}
                                                className='mui-table-row'>
                                                {/*<TableCell>{item.date}</TableCell>*/}
                                                {/*<TableCell>{item.invoice_no}</TableCell>*/}
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.amount}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            : <TableBody><TableRow>
                                <TableCell colSpan="4" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                    <MuiPagination data={data} rowsPerPage={rowsPerPage} page={page}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleChangePage={handleChangePage} rows={[5, 10, 25]} colSpan={4}/>
                </TableContainer>
          
            </MuiPaper>
        </div>
    )
}

export const ReusableDashboardPaymentTables = ({title, headData, loading, data,
                   page, rowsPerPage, handleChangeRowsPerPage, handleChangePage}) => {

    return (
        <div className='progress-report-chart'>
            <MuiPaper>
                <EnhancedTableToolbar title={title}/>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size='small'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="4" align="center"><img src="/images/smsloader.gif"
                                                                       alt="loader"/></TableCell>
                        </TableRow></TableBody> : data.length > 0 ?
                            <TableBody>
                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={item.id}
                                                className='mui-table-row'>
                                                <TableCell>{item.date ? dateStringConvert(item.date):item.date}</TableCell>
                                                <TableCell>{item.invoice_no}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.amount}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            : <TableBody><TableRow>
                                <TableCell colSpan="4" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                    <MuiPagination data={data} rowsPerPage={rowsPerPage} page={page}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage} rows={[5, 10, 25]} colSpan={4}/>
                </TableContainer>
   
            </MuiPaper>
        </div>
    )
}
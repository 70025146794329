import axios from "axios";
import config from "../../../Helpers/config.json";
import {useEffect, useState} from "react";

export const useReturnList = (actions, component) =>{
    const [state, setState] = useState({from_date: '', to_date: ''});
    const [data, setData] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [search_value, setSearchValue] = useState('');
    const [activePage, setActivePage] = useState(1)
    const [submitted, setSubmitted] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState('idle');


    const dep = isSubmitted === 'resolved';
    useEffect(()=>{
        const url = {
            customer:'return_list',
            supplier:'manufacturer_return_list',
            damages:'damage_return_list',
            wastages:'wastage_return_list'
        }
        const {from_date,to_date} = state;
        const formData = new FormData();
        formData.append('from_date', from_date);
        formData.append('to_date', to_date);
        setLoading(true)
        axios.post(`${config.epharmUrl}/Creturn/${url[component]}/${activePage}`, formData).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const list = !dt.return_list ? [] : dt.return_list
            let count = 0;
            if (dt.total_return){
                count = dt.total_return ?? 0;
            }else{
                count = dt.total_count ?? 0;
            }
            setData(list);
            setTotalCount(count)
            setLoading(false)
        }).catch(() => {
            // errorMessages(error, null, actions)
            setLoading(false)
        })
    },[search_value,activePage, dep])


    const handleChangeSearchValue = (event) =>{
        setSearchValue(event.target.value)
    }
    const handleChange = event => {
        const {value, name} = event.target;
        setState({...state,[name]: value});
    };
    const handleSubmitSearch = (event) => {
        event.preventDefault();
        const {from_date, to_date} = state;
        setSubmitted(true)
        if (from_date && to_date) {
           setIsSubmitted('resolved')
        }
    };

    const handlePageChange = (pageNumber) =>{
        setActivePage(pageNumber)
    };

    const handleCloseSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }

    return {state, data, total_count, loading, search_value, activePage, submitted, isSubmitted,setData,
        handleChangeSearchValue, handleChange, handleSubmitSearch, handlePageChange, handleCloseSnackbar}
}
import React from "react";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const EditBenefit = () =>{
    const benefits = [
        {label:"Add", value:1},
        {label:"Deduct", value:2}
    ];
    return(
        <div>
        <DashboardHeader payroll editBenefit/>
        <div className="card p-3  styled-card">
            <h6>Edit Benefit</h6>
            <hr/>
            <Form autoComplete="off">
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Salary Benefits<span className="text-danger asterics">*</span></Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Salary Benefits<span className="text-danger asterics">*</span></Form.Label>
                    <Col sm={8}>
                        <Select
                            options={benefits.map(benefit=>({
                                label:benefit.label,
                                value:benefit.value
                            }))}
                        />
                    </Col>
                </Form.Group>
                <div className="text-center">
                <button className="btn pharmacy-btn px-4 mt-3 ">Update</button>
                </div>
            </Form>
        </div>
        </div>
    )
};
export default EditBenefit;
import React from 'react';
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import ReusableTabs, {TabPanel} from "../../Utils/Tabs/ReusableTabs";
import GeneralLedger from "./Reports/GeneralLedger";
import BalanceSheet from "./Reports/BalanceSheet";
import ProfitLossStatement from "./Reports/ProfitLossStatement";
import CashFlowStatement from "./Reports/CashFlowStatement";
import TrialBalance from "./Reports/TrialBalance";
import AccountReceivables from "./Reports/AccountReceivables";
import AccountsPayables from "./Reports/AccountsPayables";


const AccountingReports = () => {
    const [value, setValue] = React.useState(0);

    const tabHeading = ['General Ledger', 'Balance Sheet', 'Income Statement',
        'Trial Balance']

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="journals">
            <ReusableDashboardHeader component="Reports" heading="Accounting"
                                     subHeading="Reports" link="/accountingreports"/>
            <ReusableTabs value={value} handleChange={handleChange} tabHeading={tabHeading}>
                <TabPanel value={value} index={0}>
                    <GeneralLedger/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <BalanceSheet/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ProfitLossStatement/>
                </TabPanel>
                {/*<TabPanel value={value} index={3}>*/}
                {/*    <CashFlowStatement/>*/}
                {/*</TabPanel>*/}
                <TabPanel value={value} index={3}>
                    <TrialBalance/>
                </TabPanel>
                {/*<TabPanel value={value} index={4}>*/}
                {/*    <AccountReceivables/>*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={value} index={5}>*/}
                {/*    <AccountsPayables/>*/}
                {/*</TabPanel>*/}
            </ReusableTabs>
        </div>
    )
}
export default AccountingReports;
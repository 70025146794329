import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import CsvDialog from "../../Utils/CsvDialog";
import {titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Row from 'react-bootstrap/Row'
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import Card from '@material-ui/core/Card';
import {useAddCustomer} from "./CustomHooks/useAddCustomer";
import {history} from "../../Helpers/history";

const AddCustomer = ({actions, snackbars, match: {params}}) => {
    const {customer_id} = params;
    const {open, variant, message} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const add_customer = !permissions.add_customer ? {} : permissions.add_customer;
    const {state, openDialog, submitted, isSubmitted, handleFileUpload, handleOpenDialog,
        handleCloseDialog, fileUploader, handleChange, handleSubmit, handleCloseBar
    } = useAddCustomer(actions, params);
    const {customerName, customerEmail, mobile, address, previousBalance, credit_limit} = state;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component={`${customer_id ? 'Edit' : 'Add'} Customer`} heading="Customer"
                                     subHeading={`${customer_id ? 'Edit' : 'Add'} Customer`} link={history.location.pathname}/>
            <div className="general-ledger-header">
                <div className="row">
                    <div className="col-md-6">
                        <h6 className="mx-2 mt-2">{`${customer_id ? 'Edit' : 'Add'} Customer`}</h6>
                    </div>
                    {!customer_id ? <div className="col-md-6 text-right">
                        <CsvDialog message="Customer" openDialog={openDialog}
                                   handleCloseDialog={handleCloseDialog}
                                   handleChangeUpload={handleFileUpload} handleUploadCsv={fileUploader}>
                            <a href="/csv/customer_csv_sample.csv" download>
                                <button className="btn pharmacy-primary-btn">Download Sample File</button>
                            </a>
                        </CsvDialog>
                        <button onClick={handleOpenDialog} type="button"
                                className="btn pharmacy-info-btn mr-2">
                            Upload CSV
                        </button>
                    </div>: null}
                </div>
            </div>
            <div className=" styled-component">
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={handleCloseBar}/>
                <Card className='p-3'>
                    <form onSubmit={handleSubmit} autoComplete="off" className='mt-2'>
                        <Form.Group as={Row} controlId="formGridEmail">
                            <Form.Label column sm={2}>Customer Name<span
                                className="text-danger asterics ml-1">*</span></Form.Label>
                            <Col sm={7}>
                                <TextField type="text" placeholder="Customer name" name="customerName"
                                           value={customerName} onChange={handleChange}/>
                                {(submitted && !customerName) &&
                                <div className="invalid-text">Please enter the customer name</div>}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formGridPassword">
                            <Form.Label sm={2} column>Customer Email</Form.Label>
                            <Col sm={7}>
                                <TextField type="email" placeholder="Email Address" name="customerEmail"
                                           value={customerEmail} onChange={handleChange}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridAddress1">
                            <Form.Label sm={2} column>Customer Mobile</Form.Label>
                            <Col sm={7}>
                                <TextField type="text" name="mobile" value={mobile}
                                           onChange={handleChange}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formGridAddress2">
                            <Form.Label sm={2} column>Customer Address</Form.Label>
                            <Col sm={7}>
                                <Textarea as="textarea" name="address" value={address}
                                          onChange={handleChange}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridCity">
                            <Form.Label sm={2} column>Credit Limit</Form.Label>
                            <Col sm={7}>
                                <TextField type="number" name="credit_limit" value={credit_limit}
                                           onChange={handleChange} min="0"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridCity">
                            <Form.Label sm={2} column>Previous Balance</Form.Label>
                            <Col sm={7}>
                                <TextField type="number" name="previousBalance" value={previousBalance}
                                           onChange={handleChange} min="0"/>
                            </Col>
                        </Form.Group>
                        <button type="submit" disabled={isSubmitted === 'pending'}
                                className={`btn pharmacy-btn offset-md-5 ${!add_customer.create && 'disabled'}`}>{isSubmitted === 'pending' ? "Submitting..." : "Submit"}</button>
                    </form>
                </Card>
            </div>
        </div>
    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
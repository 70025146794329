import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import SelectInput, {CreatableCustomSelect, CustomSelect} from "../../Utils/FormInputs/SelectInput";
import {titleCase} from "../../Utils/titleCaseFunction";
import Textarea from "../../Utils/FormInputs/Textarea";
import React from "react";

function AddProductForm({state, unit, type_name, category_id, submitted, isSubmitted,
                            isLoading, options, price, formRef, handleChangeFields, handleChangeCategory, handleChangeExpiryLimit,
                            handleChangeType, handleChangeUnit,  handleInputChange, handleChange,
                            handleSaveMedicine,categoryList, unitList, typeList}) {
    const {product_name, strength, generic_name, box_size, expiry_limit, maximum_stock,
        product_location, description,  actual_price, reorder_level,price_type} = state;
    const allList = !unitList ? [] : unitList;
    const allCategory = !categoryList ? [] : categoryList;
    const allType = !typeList ? [] : typeList;
    return(
        <form onSubmit={handleSaveMedicine} autoComplete="off" ref={formRef}>
            <div className="row">
                <div className="col-md-6">
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label   type name='Medicine Name'/>
                        </Col>
                        <Col sm={7}>
                            <TextField type="text" placeholder="Medicine name" name="product_name"
                                       value={product_name}
                                       onChange={handleChangeFields}/>
                            {(submitted && !product_name) &&
                                <div className="invalid-text">Medicine name is required</div>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label   type name='Generic Name'/>
                        </Col>

                        <Col sm={7}>
                            <TextField type="text" placeholder="Generic name" name="generic_name"
                                       value={generic_name}
                                       onChange={handleChangeFields}/>
                            {(submitted && !generic_name) &&
                                <div className="invalid-text">Generic name is required</div>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label   name='Strength'/>
                        </Col>

                        <Col sm={7}>
                            <TextField type="text" placeholder="Strength" name="strength"
                                       value={strength} onChange={handleChangeFields}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label   name='Category' type/>
                        </Col>

                        <Col sm={7}>
                            <CustomSelect
                                placeholder="select category"
                                options={allCategory.map(category => ({
                                    value: category.category_id,
                                    label: titleCase(category.category_name)
                                }))}
                                value={category_id}
                                onChange={handleChangeCategory}
                            />
                            {(submitted && !category_id) &&
                                <div className="invalid-text">Category is required</div>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label name='Details'  />
                        </Col>
                        <Col sm={7}>
                            <Textarea name="description" value={description}
                                      onChange={handleChangeFields}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label  name='Price Type' type/>
                        </Col>

                        <Col sm={7}>
                            <SelectInput value={price_type} onChange={handleChangeFields}
                                         options={[{value: '', text: 'Price Type'},
                                             {value: '1', text: 'Mark-up price'},
                                             {value: '0', text: 'Actual price'}]} name='price_type'/>
                            {(submitted && !price_type) &&
                                <div className="invalid-text">Price type is required</div>}
                        </Col>
                    </Form.Group>
                    {price_type === "0" ?
                        <Form.Group as={Row}>
                            <Col sm={3}>
                                <Label   name='Actual Price' type/>
                            </Col>
                            <Col sm={7}>
                                <TextField type="number" placeholder="Enter actual price"
                                           onChange={handleChangeFields} value={actual_price} name='actual_price'/>
                                {(submitted && !actual_price) &&
                                    <div className="invalid-text">Actual price is required</div>}
                            </Col>
                        </Form.Group>
                        : price_type === "1" ?
                            <Form.Group as={Row}>
                                <Col sm={3}>
                                    <Label   name='Mark-up price(%)' type/>
                                </Col>

                                <Col sm={7}>
                                    <CreatableCustomSelect
                                        isClearable
                                        isLoading={isLoading}
                                        onChange={handleChange}
                                        onCreateOption={handleInputChange}
                                        options={options}
                                        value={price}
                                    />
                                    {(submitted && !price) &&
                                        <div className="invalid-text">Mark-up price is required</div>}
                                </Col>
                            </Form.Group>
                            :
                            <div/>
                    }
                </div>
                <div className="col-md-6">
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label   name='Unit'/>
                        </Col>

                        <Col sm={7}>
                            <CustomSelect
                                placeholder="select unit"
                                value={unit}
                                onChange={handleChangeUnit}
                                options={allList.map(unit => ({
                                    value: unit.id,
                                    label: titleCase(unit.unit_name)
                                }))}

                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label   name='Medicine Type' type/>
                        </Col>

                        <Col sm={7}>
                            <CustomSelect
                                placeholder="select medicine type"
                                options={allType.map(type => ({
                                    value: type.type_id,
                                    label: titleCase(type.type_name)
                                }))}
                                value={type_name}
                                onChange={handleChangeType}
                            />
                            {(submitted && !type_name) &&
                                <div className="invalid-text">Medicine type is required</div>}
                        </Col>

                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label   name='Box Size'/>
                        </Col>

                        <Col sm={7}>
                            <TextField type="text" placeholder="Box size" name="box_size"
                                       value={titleCase(box_size)} onChange={handleChangeFields}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label   name='Medicine Shelf'/>
                        </Col>
                        <Col sm={7}>
                            <TextField type="text" placeholder="Medicine shelf" name="product_location"
                                       value={titleCase(product_location)}
                                       onChange={handleChangeFields}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label name='Reorder Level'/>
                        </Col>

                        <Col sm={7}>
                            <TextField type="number" placeholder="Reorder level" name="reorder_level"
                                       value={reorder_level} min="0"
                                       onChange={handleChangeFields} defaultValue={0}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label  name='Expiry Limit'/>
                        </Col>

                        <Col sm={5}>
                            <TextField type="number" name="expiry_limit"
                                       value={expiry_limit} min="0"
                                       onChange={handleChangeFields} />
                        </Col>
                        <Col sm={2}>
                            <Label column name='In Days'/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={3}>
                            <Label name='Maximum Stock'/>
                        </Col>
                        <Col sm={7}>
                            <TextField type="number" name="maximum_stock"
                                       value={maximum_stock} min="0"
                                       onChange={handleChangeFields} defaultValue={0}/>
                        </Col>
                    </Form.Group>
                </div>
            </div>
            <div className='text-right'>
                <button type="submit" disabled={isSubmitted === 'pending'}
                        className="btn pharmacy-btn px-3">{isSubmitted === 'pending' ? "Submitting..." : "Submit"}</button>
            </div>

        </form>
    )

}
export {AddProductForm}
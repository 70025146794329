import * as types from "../../Actions/actionTypes"

const initialState = {
    categoryList: [],
    medicineList: [],
    category: {},
    category_id: '',
    product_id: '',
    productUpdateDetails: {},
    unitList: [],
    unit: {},
    id: '',
    typeList: [],
    type_id: '',
    medicineType: {},
    productDetails: {},
    medicineTotal: 0
};

export default function medicineReducer(state = initialState, action) {
    switch (action.type) {
        case types.MEDICINE_REQUEST:
            return {loading:true};
        case types.MANAGE_CATEGORY:
            return {...state, categoryList: action.categoryList};
        case types.MANAGE_MEDICINE:
            return {...state, medicineList: action.medicineList};
        case types.CATEGORY_UPDATE_FORM:
            return {...state, category: action.category};
        case types.DELETE_CATEGORY:
            return {
                ...state,
                categoryList: state.categoryList.filter(category => category.category_id !== action.category_id)
            };
        case types.DELETE_PRODUCT:
            return {
                ...state,
                medicineList: state.medicineList.filter(medicine => medicine.product_id !== action.product_id)
            };
        case types.PRODUCT_UPDATE_FORM:
            return {...state, productUpdateDetails: action.productUpdateDetails};
        case types.RETRIEVE_UNIT_LIST:
            return {...state, unitList: action.unitList};
        case types.UNIT_UPDATE_FORM:
            return {...state, unit: action.unit};
        case types.DELETE_UNIT:
            return {...state, unitList: state.unitList.filter(unit => unit.id !== action.id)};
        case types.RETRIEVE_MEDICINE_TYPES:
            return {...state, typeList: action.typeList};
        case types.DELETE_MEDICINE_TYPE:
            return {...state, typeList: state.typeList.filter(type => type.type_id !== action.type_id)};
        case types.MEDICINE_TYPE_UPDATE_FORM:
            return {...state, medicineType: action.medicineType};
        case types.RETRIEVE_PRODUCT_DETAILS:
            return {...state, productDetails: action.productDetails,loading: false};
        case types.MED_SUCCESS:
            return {
                submitting:false
            };
        case types.MED_ERROR:
            return{
                submitting:false
            };
        case types.MED_REQUEST:
            return{
                submitting:true
            };
        case types.EDITMED_SUCCESS:
            return {
                submitting:false
            };
        case types.EDITMED_ERROR:
            return {
                submitting:false
            };
        case types.EDITMED_REQUEST:
            return{
                submitting:true
            };
        default:
            return state;
    }

}
import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../Helpers/config.json";
import {titleCase} from "../../../Utils/titleCaseFunction";


export const useJournal = () => {
    const [allJournals, setAllJournals] = useState([]);
    const [journal, setJournal] = useState([]);
    const [journalDetails, setJournalDetails] = useState({head_name: '', head_code: ''});
    const [totals, setTotals]  = useState({total_debit:0, total_credit:0});
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [sortJournal, setSortJournal] = useState('');
    const [accounts, setAccounts] = useState([])
    const [specificItem, setItem] = useState({id:'', head_name:'', head_code:''})

    useEffect(() => {
        const formData = new FormData();
        if (sortJournal){
            formData.append('id', sortJournal);
        }
        axios.get(`${config.epharmUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const item = arr[0] ? arr[0] : {};
            setAllJournals(arr)
            setItem(item)
        }).catch(err => {
            // errorMessages(err, null,actions)
        })

    }, [sortJournal])

    useEffect(()=>{
        const {id, head_name, head_code} = specificItem;
        if (id){
            const formData = new FormData();
            formData.append('ledger', id);
            if (!sortJournal){
                setLoading(true);
            }
            axios.post(`${config.epharmUrl}/accounting/specific_journal`, formData).then(res => {
                const data = res.data;
                const dt = data ? data : {};
                const arr = dt.data ? dt.data : [];
                const list = arr.map(item=>({
                    id:item.id,
                    date:item.date_added,
                    details:item.entry_detail,
                    credit:item.entry_category === 'CREDIT' ? item.entry_amount:'',
                    debit:item.entry_category === 'DEBIT' ? item.entry_amount:''
                }))
                const total_debit = list.reduce((sum,item)=>{
                    return  +sum + +item.debit;
                },0)
                const total_credit = list.reduce((sum,item)=>{
                    return  +sum + +item.credit;
                },0)
                setJournal(list)
                setTotals({total_debit:total_debit.toLocaleString(), total_credit:total_credit.toLocaleString()})
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
            setJournalDetails({head_name, head_code})
        }
    },[specificItem])

    useEffect(() => {
        axios.get(`${config.epharmUrl}/accounting/chart/accounts`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            setAccounts(arr)
        }).catch(err => {
            // errorMessages(err, null,actions)
        })
    }, [])

    const handleClickJournal = (item) => {
        const {id, head_name, head_code} = item;
        setItem(item)
        setJournalDetails({head_name, head_code})
    }

    const handleSearch = (e) =>{
        const {value} = e.target;
        setSearch(value)
    }

    const handleChangeJournal = (event) =>{
        setSortJournal(event.target.value)
    }

    const filteredList = (arr) =>{
        return arr.filter((data) => {
            if (search === '')
                return data
            else if (titleCase(data.head_name).includes(titleCase(search))) {
                return data
            }
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return {allJournals, handleClickJournal, journalDetails, journal, totals,loading, search,
        handleSearch, filteredList, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage,
    sortJournal,handleChangeJournal, accounts}
}
import React from "react";
import Col from "react-bootstrap/Col";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import {useMedicineType} from "./CustomHooks/useMedicineType";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import Card from "@material-ui/core/Card";
import Row from "react-bootstrap/Row";
import Form from 'react-bootstrap/Form';


const EditMedicineType = ({actions, snackbars, match: {params}}) => {
    const {open, message, variant} = snackbars;
    const {
        type_name, submitted, isSubmitted, handleChange,
        handleSubmitType, handleCloseSnackbar
    } = useMedicineType(actions, params)
    return (
        <div className='journals'>
            <ReusableDashboardHeader component=' Edit Medicine Types' heading="Medicine"
                                     subHeading=' Edit Medicine Types' link={history.location.pathname}/>
            <div className="general-ledger-header">
                <h6 className="mt-2 mb-0">Edit Medicine Type</h6>
            </div>
            <div className="mini-styled-component">
                <Card className='p-3' style={{width:'500px'}}>
                    <PharmacySnackbar open={open} message={message} variant={variant}
                                      handleCloseBar={handleCloseSnackbar}/>
                    <form onSubmit={handleSubmitType} autoComplete="off" className="general-card">
                        <Form.Group as={Row}>
                            <Label column sm="3" type name='Type Name'/>
                            <Col sm="7">
                                <TextField type="text" value={type_name} name="type_name"
                                           onChange={handleChange}/>
                                {submitted && !type_name ? <div>Type name is required</div> : null}
                            </Col>
                        </Form.Group>
                        <div className="text-center">
                        <button type="submit" className="btn btn-sm pharmacy-info-btn mt-2"
                                disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(EditMedicineType);
export const printFunctionality = (printDiv, styles='') =>{
    let prtContent = printDiv;
    let WinPrint = window.open();
    let htmlToPrint = styles;
    htmlToPrint+=prtContent.innerHTML
    WinPrint.document.write(htmlToPrint);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
}
import {useEffect, useRef, useState} from 'react';
import {useDates} from "./useDates";
import axios from 'axios';
import config from '../../../Helpers/config.json'
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useMuiPagination} from "../../../Containers/Dasboard/DashboardTables";
import {convDate} from "../../../Utils/titleCaseFunction";
import {exportPDF} from "../../../Utils/exportPDF";

export const useExpenses = (actions) => {
    const CURRENT_YEAR = new Date().getFullYear();
    const [openDialog, setOpenDialog] = useState(false);
    const [period, setPeriod] = useState({value: 'date', label: 'Date'});
    const [date, handleChangeDate, setDate] = useDates();
    const [year, setYear] = useState(CURRENT_YEAR);
    const [expenses, setExpenses] = useState([]);
    const {from_date, to_date} = date;
    const [state, setState] = useState({receipt_no: '', amount: ''});
    const [expenseType, setExpenseType] = useState('');
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState(false);
    const [new_expense, setNewExpense] = useState('');
    const [openExpense, setOpenExpense] = useState(false);
    const [submitExpense, setSubmitExpense] = useState(false);
    const [isSubmitExpense, setIsSubmitExpense] = useState('idle');
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const csvRef = useRef();

    const headers = [{key: 'date', label: 'Date'}, {key: 'receipt_no', label: 'Receipt No.'},
        {key: 'expense_type_name', label: 'Expense'},{key: 'amount', label: 'Amount'}]


    const inputType = {
        'date': 'date',
        'weekly': 'week',
        'monthly': 'month',
        'custom': 'date'
    }
    const typeDep = isSubmitExpense === 'resolved'
    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`${config.epharmUrl}/accounting/expense_types`)
                let _data = res.data
                let dt = _data.data ?? [];
                let exp_types = dt.map(i => ({label: i.expense_type_name, value: i.id}))
                setExpenseTypes(exp_types)
            } catch (e) {
                errorMessages(e, null, actions)
            }
        })();
    }, [typeDep])

    const dep = isSubmitted === 'resolved'
    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const res = await axios.get(`${config.epharmUrl}/accounting/expenses`)
                let _data = res.data
                let dt = _data.data ?? [];
                const arr = dt.map(({date,receipt_no, expense_type_name, amount,expense_id})=>({
                    date:convDate(date),
                    receipt_no,expense_type_name,amount,expense_id
                }))
                setExpenses(arr)
                setLoading(false)
            } catch (e) {
                errorMessages(e, null, actions)
                setLoading(false)
            }
        })();
    }, [dep])


    const handleChangeExpenseType = (value) => {
        setExpenseType(value)
    }

    const handleChangeNewExpense = (e) =>{
        setNewExpense(e.target.value)
    }

    const handleOpenExpense = () =>{
        setOpenExpense(true)
    }

    const handleCloseExpense = () =>{
        setOpenExpense(false)
    }

    const submitNewExpense = (event) =>{
        event.preventDefault();
        const formData = new FormData()
        formData.append('expense_type_name', new_expense)
        setSubmitExpense(true);
        if (new_expense) {
            setIsSubmitExpense('pending')
            axios.post(`${config.epharmUrl}/accounting/expenses_types/add`, formData).then(() => {
                actions.snackbarActions.successSnackbar('Expense added successfully')
                setOpenExpense(false)
                setIsSubmitExpense('resolved')
                setNewExpense('');
                setSubmitExpense(false);
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitExpense('rejected')
            })
        }

    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setState({...state, [name]: value})
    }

    const handleSubmitExpense = (e) => {
        e.preventDefault()
        const {receipt_no, amount} = state
        const formData = new FormData()
        formData.append('related_expense_type', expenseType.value)
        formData.append('receipt_no', receipt_no)
        formData.append('amount', amount)
        setSubmitted(true);
        if (expenseType && receipt_no && amount) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/accounting/expenses/add`, formData).then(() => {
                actions.snackbarActions.successSnackbar('Expense recorded successfully')
                setOpenDialog(false)
                setIsSubmitted('resolved')
                setExpenseType('');
                setState({receipt_no: '',amount: ''});
                setSubmitted(false);
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }
    }


    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const handleChangePeriod = (value) => {
        setPeriod(value)
        setDate({from_date: '', to_date: ''})
    }

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }
    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar()
    }

    const downloadPDF = () => {
        exportPDF("Expense Report", [["Date", "Receipt No.", "Expense", "Amount",]],
            expenses.map(exp => [exp.date,exp.receipt_no, exp.expense_type_name,exp.amount]), "Expense Report.pdf",
            )
    };


    return {openDialog, handleCloseDialog, handleOpenDialog, period, handleChangePeriod, inputType, date,
        handleChangeDate, year, handleChangeYear, expenses, handleSubmitExpense, handleChangeExpenseType,
        expenseType, expenseTypes,state,handleChange,closeSnackbar, submitted,isSubmitted,
        page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, loading, csvRef,headers,
        downloadPDF, openExpense,submitNewExpense,new_expense,handleChangeNewExpense,handleOpenExpense,
    handleCloseExpense, submitExpense, isSubmitExpense}
}
import {useSessionStorageState} from "./useSessionStorage";


export const useAccountingSetUp = () =>{
    const [activeStep, setActiveStep] = useSessionStorageState('activeStep',0)
    const steps = ['Select accounting period', 'Setup chart of accounts', 'Add default transaction types'];


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const finishSetUp = () =>{
        console.log('finished')
    }

    return {activeStep, steps,handleNext, handleBack, setActiveStep, finishSetUp}
}
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as medicineActions from "../../Redux/Actions/MedicineActions/MedicineActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {Link} from "react-router-dom";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import TextField from "../../Utils/FormInputs/TextField";
import Label from "../../Utils/FormInputs/Label";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import DeleteDialog from "../../Utils/DeleteDialog";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useMedicineType} from "./CustomHooks/useMedicineType";


const headData = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Type Name'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];


const MedicineType = ({actions, snackbars}) => {
    const  {type_name,  openDialog, openDelete, types, submitted, isSubmitted,
        loading,  page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleChange,
        handleSubmitType, handleOpenDelete,
        handleCloseDelete, deleteMedicineType, handleOpenDialog, handleCloseDialog, handleCloseSnackbar} = useMedicineType(actions)

    const {open, message, variant} = snackbars;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Medicine Types' heading="Medicine"
                                     subHeading='Medicine Types' link='/medicinetype'/>
            <div className="general-ledger-header">
                <div className="row">
                    <div className="col-md-6">
                        <h6 className='mt-2 mb-0'>Medicine Types</h6>
                    </div>
                    <div className="col-md-6">
                        <div className="text-right">
                            <button type='button' onClick={handleOpenDialog}
                                    className='btn btn-sm pharmacy-info-btn'>Add
                                Medicine Type
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseSnackbar}/>
            <DeleteDialog handleClose={handleCloseDelete} openDialog={openDelete} message='medicine type'
                          handleDelete={deleteMedicineType}/>
            <ReusableDialog message='New Medicine Type' openDialog={openDialog}
                            handleSubmit={handleSubmitType} isSubmitted={isSubmitted}
                            handleClose={handleCloseDialog}
                            width='sm'>
                <Form.Group as={Row}>
                    <Label column sm="3" type name='Type Name'/>
                    <Col sm="7">
                        <TextField type="text" value={type_name} name="type_name"
                                   onChange={handleChange}/>
                        {submitted && !type_name ? <div>Type name is required</div> : null}
                    </Col>
                </Form.Group>
            </ReusableDialog>
            <div className="mui-tables">
                <MuiPaper>
                    <EnhancedTableToolbar title={''}/>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='small'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="2" align="center"><img src="/images/listPreloader.gif"
                                                                           alt="loader"
                                                                           style={{width: '5%'}}/></TableCell>
                            </TableRow></TableBody> : types.length > 0 ?
                                <TableBody>
                                    {types.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((type) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={type.type_id}
                                                >
                                                    <TableCell>{type.type_name}</TableCell>
                                                    <TableCell>
                                                        <Link to={{pathname: `/editmedicinetype/${type.type_id}`}}>
                                                        <button className="btn btn-sm pharmacy-info-btn mr-1">
                                                            <FontAwesomeIcon icon={faPencilAlt}/></button></Link>

                                                        <button className="btn btn-sm pharmacy-btn-dismiss"
                                                                onClick={() => handleOpenDelete(type.type_id)}>
                                                            <FontAwesomeIcon icon={faTrash}/></button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                                : <TableBody><TableRow>
                                    <TableCell colSpan="2" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
                    </TableContainer>
                    <MuiPagination data={types} rowsPerPage={rowsPerPage} page={page}
                                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                                   handleChangePage={handleChangePage} rows={[10, 20, 30, 50 ,100]} colSpan={2}/>
                </MuiPaper>
            </div>
        </div>

    )


}

function mapStateToProps(state) {
    return {
        medicines: state.medicines,
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            medicineActions: bindActionCreators(medicineActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(MedicineType);
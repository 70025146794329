import * as types from "../actionTypes";
import config from "../../../Helpers/config";
import {successSnackbar} from "../SnackbarActions/SnackbarActions";
import axios from "axios";
import {history} from "../../../Helpers/history";
import {errorMessages} from "../../../Helpers/ErrorMessages";


const configuration = {
    headers:{
        'content-Type':'multipart/form-data'
    }
};

function retrieveManufacturers(manufacturers) {
    return{
        type:types.MANAGE_MANUFACTURER,
        manufacturers
    }

}
function manufacturerDetails(manufacturer) {
    return{
        type:types.RETRIEVE_MANUFACTURER_DETAILS,
        manufacturer
    }

}
function manufacturerUpdateForm(manufacturerUpdate) {
    return{
        type:types.MANUFACTURER_UPDATE_FORM,
        manufacturerUpdate
    }

}
function manufacuturerDelete(manufacturer_id) {
    return {
        type:types.DELETE_MANUFACTURER,
        manufacturer_id
    }

}
function manufacturerSales(manufacturerSales) {
    return {
        type:types.MANUFACTURER_SALES_DETAILS,
        manufacturerSales
    }

}
function manufacturerLedgerReport(ledgerReport) {
    return {
        type:types.MANUFACTURER_LEDGER_REPORT,
        ledgerReport
    }

}
function manufLedgerInfo(ledgerInfo) {
    return {
        type:types.MANUFACTURER_LEDGER_INFO,
        ledgerInfo
    }

}
// function manufLedger(ledger) {
//     return {
//         type:types.MANUFACTURER_LEDGER,
//         ledger
//     }
//
// }
function manufacturerCount(manufacturer_count) {
    return {
        type:types.MANUFACTURER_COUNT,
        manufacturer_count
    }

}
function retrieveAllManufacturers(all_manufacturers) {
    return {
        type:types.RETRIEVE_ALL_MANUFACTURERS,
        all_manufacturers
    }
    
}
function manufacturerRequest() {
    return {
        type:types.MANUFACTURER_REQUEST
    }

}
function addManufRequest(){
    return {
        type:types.MANUF_REQUEST,
    }
}
function addManufError(){
    return {
        type:types.MANUF_ERROR,
    }
}
function addManufSuccess(){
    return{
        type:types.MANUF_SUCCESS,
    }
}
function editManufRequest(){
    return{
        type:types.EDITMANUF_REQUEST,
    }
}
function editManufError(){
    return {
        type:types.EDITMANUF_ERROR,
    }
}
function editManufSuccess(){
    return {
        type:types.EDITMANUF_SUCCESS,
    }
}
export function getAllManufacturers() {
    return async function (dispatch) {
        try{
            let response = await axios.get(`${config.epharmUrl}/Cmanufacturer/retrieve_all_manufacturers`);
            const res = response.data;
            const dt = res ? res : {};
            const manufacturers = dt.total_manufacturers ? dt.total_manufacturers : [];
            manufacturers.reverse();
            dispatch(retrieveAllManufacturers(manufacturers))
        }catch(e){
        }
    }
}
export function manufacturerLedger(from_date, to_date, manufacturer_id) {
    return function (dispatch) {
        dispatch(manufacturerRequest());
        return axios.post(config.epharmUrl + "/Cmanufacturer/manufacturer_ledger",
            null, {params:{from_date, to_date, manufacturer_id}}).then(response=>{
                const res = response.data;
            const data = res ? res :{};
            if(history.location.pathname === "/manufacturerledger"){
                dispatch(manufacturerLedgerReport(data))
            }
            dispatch(manufLedgerInfo(data));
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }

}
export function manufacturerLedgerInfo(manufacturer_id) {
    return function (dispatch) {
        dispatch(manufacturerRequest());
        return axios.post(config.epharmUrl + `/Cmanufacturer/manufacturer_ledger_info/${manufacturer_id}`).then(response=>{
            const res = response.data;
            const data = res ? res : {}
            dispatch(manufLedgerInfo(data))
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }

}
export function getManufacturerLedgerReport() {
    return function (dispatch) {
        dispatch(manufacturerRequest());
        return axios.get(config.epharmUrl + "/Cmanufacturer/manufacturer_ledger_report").then(response=>{
            const res =  response.data;
            const data = res ? res : {};
            dispatch(manufacturerLedgerReport(data));
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })
    }

}
export function uploadCsv(formData) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Cmanufacturer/uploadCsv_Manufacturer", formData, configuration).then(()=>{
            dispatch(successSnackbar('Upload Successful'));
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }

}
export function manufacturerSalesDetails(page) {
    console.log("Do we ever come here?")
    return function (dispatch) {
        return axios.post(config.epharmUrl + `/Cmanufacturer/manufacturer_sales_details_all/${page}`).then(response=>{
            const data = response.data;
            const manufacturer_count = response.data.total_count;
            dispatch(manufacturerSales(data));
            dispatch(manufacturerCount(manufacturer_count));
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }

}
export function deleteManufacturer(manufacturer_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cmanufacturer/delete_manufacturer", {params:{manufacturer_id}}).then(res=>{
            dispatch(manufacuturerDelete(manufacturer_id));
            dispatch(successSnackbar('Supplier Deleted Successfully'))
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }

}
export function updateManufacturer(formData) {
    return function (dispatch) {
        dispatch(editManufRequest());
        return axios.post(config.epharmUrl + "/Cmanufacturer/manufacturer_update", formData,configuration).then(res=>{
            dispatch(editManufSuccess());
            dispatch(successSnackbar('Supplier update successful'));
            setTimeout(()=>{
                history.push('/managemanufacturer')
            },2000)
        }).catch(error=>{
            dispatch(editManufError());
           errorMessages(error, dispatch, null)

        })

    }


}
export function manufacturerUpdateDetails(manufacturer_id) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cmanufacturer/manufacturer_update_form", {params:{manufacturer_id}}).then(response=>{
            const data = response.data;
            const dt = !data ? {} : data;
            dispatch(manufacturerUpdateForm(dt))
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }
}
export function retrieveManufacturerDetails(manufacturer_id){
    return function (dispatch) {
        return axios.get(config.epharmUrl + "/Cmanufacturer/manufacturer_details",{params:{manufacturer_id}}).then(response=>{
            const data = response.data;
            const dt = data ? data : {};
            dispatch(manufacturerDetails(dt))
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })
    }
}

export function manageManufacturers(page) {
    return function (dispatch) {
        return axios.get(config.epharmUrl + `/Cmanufacturer/manage_manufacturer/${page}`).then(response=>{
            const data = response.data;
            const dt = data ? data :{};
            dispatch(retrieveManufacturers(dt));
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }

}


export function addNewManufacturer(formData) {
    return function (dispatch) {
        return axios.post(config.epharmUrl + "/Cmanufacturer/new_manufacturer", formData, configuration).then(()=>{
            dispatch(successSnackbar('Manufacturer Saved Successfully'));
            dispatch(manageManufacturers(1))
        }).catch(error=>{
            errorMessages(error, dispatch, null)
        })

    }

}
export function addManufacturer(manufacturer_name,address,mobile,details) {
    return function (dispatch) {
        dispatch(addManufRequest());
        return axios.post(config.epharmUrl + "/Cmanufacturer/insert_manufacturer", null,
            {params:{manufacturer_name,address,mobile,details}}).then(()=>{
            dispatch(addManufSuccess());
            dispatch(successSnackbar('Manufacturer Saved Successfully'));
            if (history.location.pathname === "/addmanufacturer") {
                setTimeout(()=>{
                    history.push("/managemanufacturer")
                },2000)
            }

        }).catch(error=>{
            dispatch(addManufError());
            errorMessages(error, dispatch, null)
        })

    }

}
import React, {Component} from "react";
import AddEditDesignation from "./AddEditDesignation";


class AddDesignation extends Component {
    render() {
        return (
            <AddEditDesignation addDesignation/>
        );
    }
}

export default AddDesignation;
export const copyToClipboard = (actions,rowsPerPage,componentRef) => {
    const elTable = componentRef;

    let range, sel;

// Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {

        range = document.createRange();
        sel = window.getSelection();
        // unselect any element in the page
        sel.removeAllRanges();

        try {
            range.selectNodeContents(elTable);
            sel.addRange(range);
        } catch (e) {
            range.selectNode(elTable);
            sel.addRange(range);
        }

        document.execCommand('copy');
    }
    sel.removeAllRanges();
    actions.snackbarActions.infoSnackbar(` Copied ${rowsPerPage} to clipboard! Paste it in a file`)
};
import React from "react";
import MainAttendanceReport from "./MainAttendanceReport";
import {Link} from "react-router-dom";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const AttendanceReport = (props) =>  {
        return(
            <div>
            <DashboardHeader attendance attendanceReport/>
            <div className="card styled-table-card p-3">
                <h6>Attendance Report</h6>
                <hr/>
                <div align="right">
                    <Link to="/datewiseattendance"><button type="button" className="btn pharmacy-primary-btn mr-2"> Date Wise Report</button></Link>
                    <Link to="/employeewiseattendance"><button type="button" className="btn pharmacy-primary-btn mr-2"> Employee Wise Report</button></Link>
                    <Link to="/timewisereport"><button type="button" className="btn pharmacy-primary-btn mr-2"> In Time Report</button></Link>
                </div>
                <MainAttendanceReport />
            </div>
            </div>
        );
};
export default AttendanceReport;
import React from "react";
import MainAttendanceReport from "./MainAttendanceReport";
import MainDateWiseReport from "./MainDateWiseReport";



const DateWiseAttendanceReport = () =>{
    return (
        <div>
            <MainDateWiseReport dateWise type="date"/>
            <div className="card styled-report-card p-3">
                <h6>Date Wise Report</h6>
                <hr/>
                <MainAttendanceReport/>
            </div>
        </div>
    )
};
export default DateWiseAttendanceReport;
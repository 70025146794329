export const base = 'white'
export const text = '#1A1818'
export const gray = '#f1f2f7'
export const gray00 = '#c2c3c5'
export const gray001 = '#f4f6f8'
export const danger = '#ef5350'
export const black = '#000'
export const pending = '#D9512C'
export const progress = '#ea8b054a'


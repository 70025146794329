import React from 'react';
import Card from '@material-ui/core/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import ClearIcon from '@material-ui/icons/Clear';
import {makeStyles} from "@material-ui/core/styles";
import {useNewLedgerEntry} from "./CustomHooks/useNewLedgerEntry";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: 0,
    },
    inputBase: {
        fontSize: '0.9em',
        padding: '2px'
    },

    col: {
        maxWidth: 600,
    },
    td: {
        width: '20%'
    },
    btn: {
        width: '4%'
    },
    icon: {
        fontSize: '.8rem'
    },
    totalSection:{
        borderRadius:'10px',
        backgroundColor:'#fbfafa'
    }
}));
const style = {
    textRight: {
        textAlign: 'right'
    },
}
const NewLedgerEntry = ({actions, snackbars}) => {
    const classes = useStyles();
    const {state, accounts, ledger, handleChange, handleChangeAccount, handleSubmit, isSubmitted,
        submitted, handleChangeItems, removeRow, addItem, total, handleBlurDebit, handleBlurCredit,
        closeSnackbar} = useNewLedgerEntry(actions);
    const {credit_total, debit_total} = total;
    const {date, notes} = state
    const {variant, message, open:openBar} = snackbars;
    return (
        <div>
            <ReusableDashboardHeader component="New Journal" heading="Accounting"
                                     subHeading="New Journal" link="/newledgerentry"/>
            <div className="new-entry-card">
                <PharmacySnackbar open={openBar} handleCloseBar={closeSnackbar} variant={variant}
                                  message={message}/>
                <Card className='p-3 mt-2 general-card' elevation={0}>
                    <form onSubmit={handleSubmit} autoComplete='off'>
                        <div className={classes.col}>
                            <Form.Group as={Row}>
                                <Label name='Date' sm={2} column style={style.textRight} type/>
                                <Col sm={6}>
                                    <TextField type="date" onChange={handleChange} value={date} name="date"/>
                                    {(submitted && !date) && <div className='help-block'>Date is required</div>}
                                </Col>
                            </Form.Group>
                            {/*<Form.Group as={Row}>*/}
                            {/*    <Label name='Description' sm={2} column style={style.textRight} type/>*/}
                            {/*    <Col sm={6}>*/}
                            {/*        <Textarea value={notes} onChange={handleChange} name='notes'/>*/}
                            {/*        {(submitted && !notes) && <div className='help-block'>Details are required</div>}*/}
                            {/*    </Col>*/}
                            {/*</Form.Group>*/}
                        </div>
                        <table className="table table-bordered table-sm">
                            <tr>
                                <td className={classes.td}>Account<span className="help-block">*</span></td>
                                <td className={classes.td}>Memo</td>
                                <td className={classes.td}>Debit</td>
                                <td className={classes.td}>Credit</td>
                                <td className={classes.btn}/>
                            </tr>
                            <tbody>
                            {ledger.map(item => (
                                <tr key={item.id}>
                                    <td>
                                        <CustomSelect value={item.account_id} options={accounts}
                                                      onChange={value => handleChangeAccount(value, item.id)}/>
                                        {(submitted && !item.account_id) &&
                                        <div className='help-block'>Select account</div>}
                                    </td>
                                    <td><Textarea value={item.description} onChange={e => handleChangeItems(e, item.id)}
                                                  name='description'/></td>
                                    <td><TextField type="number" onChange={e => handleChangeItems(e, item.id)}
                                                   name='debit' value={item.debit} min="0"
                                                   onBlur={() => handleBlurDebit(item.id)}/></td>
                                    <td><TextField type="number" onChange={e => handleChangeItems(e, item.id)}
                                                   name="credit" value={item.credit} min="0"
                                                   onBlur={() => handleBlurCredit(item.id)}/></td>
                                    <td>
                                        <button type='button' onClick={() => removeRow(item.id)}
                                                className="btn btn-sm pharmacy-btn-dismiss"><ClearIcon
                                            classes={{root: classes.icon}}/></button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <div className="row col-md-12">
                            <div className="col-md-4">
                                <button type="button" onClick={addItem} className="btn btn-sm pharmacy-grey-btn">Add
                                    another Line
                                </button>
                            </div>
                            <div className="col-md-8">
                                <div className={classes.totalSection}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <p >Total</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className='text-center'>{debit_total}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className='text-center'>{credit_total}</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <button type="submit" disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'}
                                    className="btn btn-sm pharmacy-info-btn mt-2">{isSubmitted === 'pending' ? 'Submitting...':'Submit'}
                            </button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}
export default connect(mapStateToProps, mapDispatchToProps)(NewLedgerEntry);
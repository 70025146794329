import * as types from "../../Actions/actionTypes";


const initialState = {
    stockList: {},
    stockBatch: {},
    outOfStock: {},
    expiryList:{},
};

export default function stockReducer(state = initialState, action) {
    switch (action.type) {
        case types.RETRIEVE_STOCK_REPORT:
            return {...state, stockList: action.stockList};
        case types.RETRIEVE_BATCH_STOCK:
            return {...state, stockBatch: action.stockBatch};
        case types.OUT_OF_STOCK_LIST:
            return {...state, outOfStock: action.outOfStock};
        case types.EXPIRY_LIST:
            return {...state,expiryList: action.expiryList};
        default:
            return state;

    }

}
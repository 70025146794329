/* eslint-disable react/no-multi-comp */
import React, {PureComponent} from 'react';
import {LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';




export class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y,  payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`} >
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
            </g>
        );
    }
}

export default class ReusableLineChart extends PureComponent {

    render() {
        const {label, data,xlabel, toPercent,height, children, dataKey} = this.props;
        return (
            <div data-testid="sms-line-chart">
                <ResponsiveContainer width="100%" height={height}>
                    <LineChart
                        data={data}
                        margin={{
                            top: 20, right: 0, left: 0, bottom: 10,
                        }}
                    >
                        {/*<CartesianGrid vertical={false}/>*/}
                        <XAxis dataKey={dataKey} height={60} tick={<CustomizedAxisTick/>}
                               label={xlabel} padding={{right: 10 }}/>
                        <YAxis  label={label}/>
                        <Tooltip/>
                        {children}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

import React, {useEffect,  useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowRestore} from "@fortawesome/free-solid-svg-icons/faWindowRestore";
import {faFax} from '@fortawesome/free-solid-svg-icons/faFax';
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {titleCase, convertGmtTime, convertDate} from "../../Utils/titleCaseFunction";
import {exportPDF} from "../../Utils/exportPDF";
import Workbook from 'react-excel-workbook';
import DeleteDialog from "../../Utils/DeleteDialog";
import config from "../../Helpers/config";
import axios from "axios";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import {CSVLink} from "react-csv";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Grid from "@material-ui/core/Grid";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";

const ManageInvoice = ({actions, snackbars}) => {
    const [state, setState] = useState({start_date: convertDate(), end_date:convertDate()});
    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [invoice_id, setInvoiceId] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState(false);
    const {start_date, end_date} = state;

    const calculateTotalAmount = (arr) => {
        const sum = arr.reduce((a, invoice) => {
            return +(a + invoice.total_amount);
        }, 0);
        setTotalAmount(sum)
    };

    const dep = isSubmitted === 'resolved';
    const dep2  = start_date && end_date;
    useEffect(() => {
        setLoading(true)
        const formData = new FormData();
        formData.append("draw", 1);
        formData.append("columns[0][data]", "sl");
        formData.append("columns[0][name]", "");
        formData.append("columns[0][searchable]", "true");
        formData.append("columns[0][orderable]", false);
        formData.append("columns[0][search][value]", "");
        formData.append("columns[0][search][regex]", false);
        formData.append("columns[1][data]", "invoice");
        formData.append("columns[1][name]", "");
        formData.append("columns[1][searchable]", true);
        formData.append("columns[1][orderable]", true);
        formData.append("columns[1][search][value]", "");
        formData.append('columns[1][search][regex]', false);
        formData.append("columns[2][data]", "invoice_id");
        formData.append("columns[2][name]", "");
        formData.append("columns[2][searchable]", true);
        formData.append("columns[2][orderable]", true);
        formData.append("columns[2][search][value]", term);
        formData.append('columns[2][search][regex]', true);
        formData.append("columns[3][data]", "customer_name");
        formData.append("columns[3][name]", "");
        formData.append("columns[3][searchable]", true);
        formData.append("columns[3][orderable]", true);
        formData.append("columns[3][search][value]", "");
        formData.append("columns[3][search][regex]", false);
        formData.append("columns[4][data]", "date");
        formData.append('columns[4][name]', "");
        formData.append("columns[4][searchable]", true);
        formData.append("columns[4][orderable]", true);
        formData.append("columns[4][search][value]", "");
        formData.append("columns[4][search][regex]", false);
        formData.append("columns[5][data]", "total_amount");
        formData.append("columns[5][name]", "");
        formData.append("columns[5][searchable]", true);
        formData.append("columns[5][orderable]", true);
        formData.append("columns[5][search][value]", "");
        formData.append("columns[5][search][regex]", false);
        formData.append('columns[6][data]', "button");
        formData.append("columns[6][name]", "");
        formData.append("columns[6][searchable]", true);
        formData.append("columns[6][orderable]", false);
        formData.append("columns[6][search][value]", titleCase(term));
        formData.append("columns[6][search][regex]", false);
        formData.append("order[0][column]", 4);
        formData.append("order[0][dir]", "desc");
        formData.append("start", activePage);
        formData.append("length", 10);
        formData.append("search[value]", term);
        formData.append("search[regex]", false);
        formData.append("fromdate", start_date);
        formData.append("todate", end_date);
        axios.post(`${config.epharmUrl}/Cinvoice/CheckInvoiceList`, formData).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const invoicesArr = dt.aaData ? dt.aaData : [];
            const count = dt.iTotalRecords ? dt.iTotalRecords : [];
            setInvoices(invoicesArr);
            setTotalCount(count)
            calculateTotalAmount(invoicesArr)
            setLoading(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading(false)
        });
    }, [activePage, term, dep,dep2])

    const handler = (event) => {
        setTerm(event.target.value)
    };

    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setSubmitted(true)
        if (start_date && end_date) {
            setIsSubmitted('resolved')
            setState({start_date ,end_date})
        }
    };

    const changeDate = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };
    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const handleOpenDialog = (_id) => {
        setInvoiceId(_id);
        setOpenDialog(true)
    };

    const handleDelete = () => {
        axios.get(`${config.epharmUrl}/Cinvoice/invoice_delete`, {params: {invoice_id}}).then(() => {
            actions.snackbarActions.successSnackbar('Invoice Deleted Successfully');
            const arr = invoices.filter(invoice => invoice.invoice_id !== invoice_id);
            setInvoices(arr)
            setOpenDialog(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })

    };
    const handleCloseDialog = () => {
        setOpenDialog(false)
        this.setState({openDialog: false})
    };

    const downloadPDF = () => {
        const allInvoices = !invoices ? [] : invoices;
        exportPDF("Invoice Report", [["SL", "Invoice NO", "Customer Name", "Date", "Total Amount"]],
            allInvoices.map((invoice, index) => [index += 1, invoice.invoice, invoice.customer_name,
                invoice.date, invoice.total_amount]), "Invoice.pdf");
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };


    const allInvoices = !invoices ? [] : invoices;
    const {variant, message, open} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const manage_invoice = !permissions.manage_invoice ? {} : permissions.manage_invoice;
    const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
        {id: 'invoice', numeric: false, disablePadding: false, label: 'Invoice No'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Customer Name'},
        {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
        {id: 'total', numeric: false, disablePadding: false, label: 'Total Amount'},
        (manage_invoice.create || manage_invoice.update || manage_invoice.delete)
            ? {id: 'action', numeric: false, disablePadding: false, label: 'Action'} :
            {id: 'nonaction', numeric: false, disablePadding: false, label: ''}];
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Invoices" heading="Invoice"
                                     subHeading="Invoices" link="/manageinvoice"/>
            <div className="general-ledger-header mb-3">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        <form onSubmit={handleSubmitSearch}>
                            <Form.Group as={Row} style={{marginBottom: '-1%'}}>
                                <Col sm={4}>
                                    <Form.Group as={Row}>

                                        <Label name='From' column sm={2} style={{textAlign: 'right'}}/>
                                        <Col sm={10}>
                                            <TextField type='date' value={start_date} onChange={changeDate}
                                                       name='start_date'/>
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col sm={4}>
                                    <Form.Group as={Row}>
                                        <Label name='To' column sm={2}/>
                                        <Col sm={10}>
                                            <TextField type='date' name="end_date" value={end_date}
                                                       onChange={changeDate}/>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col sm={1}>
                                    <button type='submit' className='btn btn-sm pharmacy-btn-outline'>Filter</button>
                                </Col>
                            </Form.Group>
                        </form>
                    </Grid>

                    <Grid item xs={6} md={4}>
                        <div className="text-right">
                            <Link to='/createinvoice/pos-invoice'>
                                <button className='btn btn-sm pharmacy-info-btn py-1'> New Invoice</button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="mui-table">
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={handleCloseSnackbar}/>
                <DeleteDialog openDialog={openDialog} handleDelete={handleDelete}
                              handleClose={handleCloseDialog}
                              message="Invoice"/>
                <MuiPaper>
                    <EnhancedTableToolbar title='Invoices'>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                                     <CSVLink
                                         data={allInvoices}
                                         filename="Invoices.csv"
                                         target="_blank"
                                     >
                                    <button className="btn btn-sm pharmacy-btn mr-1">CSV</button>
                                </CSVLink>
                                <Workbook filename="invoice.xlsx" element={
                                    <span><button className="btn pharmacy-btn mr-1 btn-sm">Excel</button></span>
                                }>
                                    <Workbook.Sheet data={allInvoices} name="Invoices">
                                        <Workbook.Column label="Invoice NO" value="invoice"/>
                                        <Workbook.Column label="Customer Name" value="customer_name"/>
                                        <Workbook.Column label="Date" value="final_date"/>
                                        <Workbook.Column label="Total Amount" value="total_amount"/>
                                    </Workbook.Sheet>
                                </Workbook>

                                <span><button className="btn pharmacy-btn mr-1 btn-sm "
                                              onClick={downloadPDF}>Pdf</button></span>
                            </span>
                        <Form.Control type="text" value={term ? titleCase(term) : ''}
                                      onChange={handler}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    {/* <TableContainer> */}
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="6" align="center"><img src="/images/listPreloader.gif"
                                                                           alt="loader"
                                                                           style={{width: '5%'}}/></TableCell>
                            </TableRow></TableBody> : allInvoices.length > 0 ?
                                <TableBody>
                                    {allInvoices.slice(0, 10)
                                        .map((info, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell component="th" scope="row" padding="default"
                                                               className='mui-table-cell'>
                                                        <span>{info.sl}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{info.invoice}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{titleCase(info.customer_name)}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{info.date ? convertGmtTime(info.date) : ''}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{info.total_amount}</span>
                                                    </TableCell>
                                                    {(manage_invoice.create || manage_invoice.update ||
                                                        manage_invoice.delete) ?
                                                        <TableCell id="mainbuttons">
                                                            <Tooltip title="Invoice" placement="left-start">
                                                                <Link
                                                                    to={{pathname: `/invoicedetails/${info.invoice_id}`}}>
                                                                    <button className="btn btn-sm pharmacy-btn mr-1">
                                                                        <FontAwesomeIcon
                                                                            icon={faWindowRestore}
                                                                            className="btn-icon"/>
                                                                    </button>
                                                                </Link>
                                                            </Tooltip>

                                                            <Tooltip title="POS Invoice"
                                                                     placement="left-start">
                                                                <Link
                                                                    to={{pathname: `/posdetails/${info.invoice_id}`}}>
                                                                    <button
                                                                        className="btn btn-sm pharmacy-amber-btn mr-1">
                                                                        <FontAwesomeIcon icon={faFax}/>
                                                                    </button>
                                                                </Link>
                                                            </Tooltip>

                                                            {(manage_invoice.update) ?
                                                                <Tooltip title="Update"
                                                                         placement="left-start">
                                                                    <Link
                                                                        to={{pathname: `/updateinvoice/update-invoice/${info.invoice_id}`}}>
                                                                        <button
                                                                            className="btn btn-sm pharmacy-info-btn mr-1">
                                                                            <FontAwesomeIcon
                                                                                icon={faPencilAlt}/>
                                                                        </button>
                                                                    </Link>
                                                                </Tooltip> : null}

                                                            {(manage_invoice.delete) ?
                                                                <button
                                                                    onClick={() => handleOpenDialog(info.invoice_id)}
                                                                    className="btn btn-sm pharmacy-btn-dismiss">
                                                                    <FontAwesomeIcon icon={faTrash}/>
                                                                </button> : null}
                                                        </TableCell> : <TableCell/>}
                                                </TableRow>
                                            );
                                        })}
                                    <TableRow>
                                        <TableCell colSpan={4} className="text-right">
                                            Total Amount
                                        </TableCell>
                                        <TableCell>{totalAmount}</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableBody>

                                : <TableBody><TableRow>
                                    <TableCell colSpan="6" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
              
                        <CustomPagination data={allInvoices} records={10} activePage={activePage} total_count={total_count}
                                      handleChangeNextPage={handlePageChange}/>
                    {/* </TableContainer> */}
                </MuiPaper>
            </div>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvoice);

import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from 'react-bootstrap/Card';
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";



class AddEditDesignation extends Component {

    render() {
        const {addDesignation,editDesignation} = this.props;
        return (
            <div>
            <DashboardHeader hrm addDesignation={addDesignation} editDesignation={editDesignation}/>
            <Card className="styled-card p-3">
                <h6>{addDesignation ? "Add Designation" : "Edit Designation"}</h6>
                <hr/>
                <Form autoComplete="off">
                    <Form.Group as={Row}>
                        <Form.Label column sm="2" className="text-left ">Designation<span
                            className="text-danger"> *</span></Form.Label>
                        <Col sm="8">
                            <Form.Control as="input"/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="2" className="text-left ">Details</Form.Label>
                        <Col sm="8">
                            <Form.Control as="textarea"/>
                        </Col>
                    </Form.Group>

                    <div  className="text-center">
                        <button type="button submit" className="btn pharmacy-btn px-4">Save</button>
                    </div>

                </Form>
            </Card>
            </div>
        );
    }
}

export default AddEditDesignation;
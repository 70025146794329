import * as types from "./actionTypes"


export const successAlert = message =>{
    return {
        type:types.ALERT_SUCCESS,
        message
    }
};
export const errorAlert = message =>{
    return {
        type:types.ALERT_ERROR,
        message
    }
};
export const clearAlert = () =>{
    return {
        type:types.ALERT_CLEAR,
    }
};
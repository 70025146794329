import React from "react";
import Form from "react-bootstrap/Form";


const Textarea = ({name,value,onChange, ...props}) =>{
    return(
        <Form.Control as="textarea" name={name} value={value} onChange={onChange}  className='form__control' {...props}/>
    )
};
export default Textarea;

import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Paper from "@material-ui/core/Paper";
import {EnhancedPharmacyListHead, EnhancedToolbar, getSorting, stableSort, useStyles} from "../../Utils/Tables/MuiTables";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FilterResults from 'react-filter-search';
import {withStyles} from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as supplierActions from "../../Redux/Actions/SupplierActions/SupplierActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {compose} from "recompose";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import CustomPagination from "../../Utils/Tables/CustomPagination";
// import {titleCase} from "../../Utils/titleCaseFunction";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import DeleteDialog from "../../Utils/DeleteDialog";
import axios from "axios";
import config from "../../Helpers/config"
import {errorMessages} from "../../Helpers/ErrorMessages";
import Pagination from "react-js-pagination";


const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    },
    subMenuLink: {
        display: "block",
        color: "#757575",
        textDecoration: "none"
        // padding: theme.spacing.unit * 6,
    },
    itemStyle: {
        backgroundColor: "#f9f9f9"
    },
    itemOdd: {
        backgroundColor: "#fff"
    }
});


class ManageSupplier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            term: "",
            order: 'asc',
            setOrder: 'asc',
            orderBy: 'supplier_name',
            selected: [],
            setSelected: [],
            page: 0,
            setPage: 0,
            dense: false,
            setDense: false,
            rowsPerPage: 10,
            openDialog: false,
            supplier_id: '',
            page_count: 0,
            per_page: 10,
            suppliers: [],
            supplier_count:0,
            pages: [],
            pg:1,
            start:0,
            end:5,
            count:0,
            newArray:[],
            records:10,
            disabledPrevious:false,
            disabledNext:false,
            loading: false
        };
        this.handler = this.handler.bind(this);
        this.ref=React.createRef();
    }

    retrieveSuppliers = () =>{
        const {actions} = this.props;
        const {pg} = this.state;
        axios.get(config.epharmUrl + `/Csupplier/manage_supplier/${pg}`).then(response => {
            const data = response.data.suppliers_list;
            const count = response.data.total_count;
            this.setState({suppliers: data, supplier_count: count})
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };


    componentDidMount() {
        this.retrieveSuppliers();
    }

    handleOpenDialog = supplier_id => {
        this.setState({openDialog: true, supplier_id: supplier_id});
    };
    handleCloseDialog = () => {
        this.setState({openDialog: false})
    };
    handleDeleteSupplier = () => {
        const {supplier_id,pg,suppliers} = this.state;
        const {actions} = this.props;
        axios.get(config.epharmUrl + "/Csupplier/delete_supplier", {params: {supplier_id}}).then(res => {
            this.setState({suppliers:suppliers.filter(supplier=> supplier.supplier_id !== supplier_id)
            },()=>this.retrieveSuppliers());
            actions.snackbarActions.successSnackbar('Supplier Deleted Successfully!');
        }).catch(error => {
            errorMessages(error, null, actions)
        });
        setTimeout(() => {
            this.setState({openDialog: false})
        }, 2000);
    };
    handleCloseBar = () => {
        const {actions} = this.props;
        actions.snackbarActions.hideSnackBar();
    };

    handler(value) {
        this.setState({term: value})
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0});
    };
    handlePageChange=(pageNumber)=>{
        this.setState({pg:pageNumber},()=>this.retrieveSuppliers())
    };


    render() {
        const {order, dense, orderBy, selected, rowsPerPage, page,pg,count, term, openDialog, pages,records,
            start, end,disabledPrevious, disabledNext,suppliers, supplier_count,loading} = this.state;
        const {classes} = this.props;
        const supplierList = !suppliers ? [] : suppliers;
        // const emptyRows = rowsPerPage - Math.min(rowsPerPage, supplierList.length - page * rowsPerPage);
        const {open, message, variant} = this.props.snackbars;
        console.log(supplierList.length);
        let counter = 0;
        return (
            <div>
                <DashboardHeader supplier manageSupplier/>
            <div className="card my-3 p-3">
                <div className="pos-buttons">
                    <Link to="/addsupplier" style={{textDecoration: "none", color: "inherit"}}>
                        <button type="button" className="btn mr-2 pharmacy-info-btn">
                            <FontAwesomeIcon icon={faPlus}/> Add Supplier
                        </button>
                    </Link>
                    <Link to="/supplierledger">
                        <button type="button" className="btn pharmacy-primary-btn mr-2">
                            <FontAwesomeIcon icon={faList}/> Supplier Ledger
                        </button>
                    </Link>
                </div>
                <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={this.handleCloseBar}/>
                <DeleteDialog handleDelete={this.handleDeleteSupplier} handleClose={this.handleCloseDialog}
                openDialog={openDialog} message="Supplier"/>

                <div className="card mt-4 p-2">
                    <h6 className="ml-3 mt-2">Manage Supplier</h6>
                    <hr/>
                    <Paper className={`${classes.paper} table-responsive`}>
                        <EnhancedToolbar numSelected={selected.length} handler={this.handler} supplier/>
                        <div className={classes.tableWrapper}>
                            <Table
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                // className={classes.table}
                            >
                                <EnhancedPharmacyListHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={supplierList.length}
                                    supplier
                                />
                                <FilterResults
                                    value={term}
                                    data={supplierList}
                                    renderResults={results => (
                                        loading ? <div><img className="loading-gif" src="/images/tenor 1.gif"/></div>: 
                                        supplierList.length > 0 ?
                                        <TableBody>
                                            {stableSort(results, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((d, index) => {
                                                    const count = counter +=1;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={index}
                                                            className='mui-table-row'>
                                                            <TableCell component="th" scope="row" padding="default"
                                                                       className='mui-table-cell'>
                                                                <span>{count}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link to={{pathname: `/sledgerinfo/${d.supplier_id}`}}
                                                                      style={{color: "#09905c"}}><span>{d.supplier_name}</span></Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.address}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.mobile}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>{d.details}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>
                                                                    <Tooltip title="Update" placement="left-start">
                                                                   <Link
                                                                       to={{pathname: `/editsupplier/${d.supplier_id}`}}> <button
                                                                       className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                                                    </button></Link>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete" placement="left-start">
                                                                    <button className="btn pharmacy-btn-dismiss btn-sm"
                                                                            onClick={() => this.handleOpenDialog(d.supplier_id)}>
                                                                        <FontAwesomeIcon icon={faTrash}/>
                                                                    </button>
                                                                    </Tooltip>
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody> : <div className="text-center p-3"> No Records Found </div>
                                    )}
                                />
                            </Table>
                        </div>
                    </Paper>
                    <div className="row">
                        <div className="col-md-6"/>
                        <div className="col-md-6">
                            <div className="text-right">
                                {supplierList.length > 0 && <Pagination
                                    prevPageText='Prev'
                                    nextPageText='Next'
                                    firstPageText='First'
                                    lastPageText='Last'
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={pg}
                                    itemsCountPerPage={records}
                                    totalItemsCount={supplier_count}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        suppliers: state.suppliers,
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            supplierActions: bindActionCreators(supplierActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(useStyles, styles))(ManageSupplier);
import React from 'react';
import CommonButtons from "./CommonButtons";
import MainManagePerson from "./MainManagePerson";

const ManagePerson = (props) => {

    return (
        <MainManagePerson managePerson personalLoan>
            <CommonButtons managePerson/>
        </MainManagePerson>
    );
};

export default ManagePerson;
import React from "react";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const MainLoanPayment = (props) => {
    const {children, addLoan, addPayment,personalLoan} = props;
    return (
        <div>
        <DashboardHeader personalLoan={personalLoan} addLoan={addLoan} addPayment={addPayment}/>
        <div className="styled-component">
            {children}
            <div className="card styled-person-card p-3">
                <h6>{addLoan ? "Add Loan" : "Add Payment"}</h6>
                <hr/>
                <Form autoComplete="off">
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Name<span className="text-danger asterics">*</span></Form.Label>
                        <Col sm={8}>
                            <Select/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Phone<span className="text-danger asterics">*</span></Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Amount<span className="text-danger asterics">*</span></Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Date</Form.Label>
                        <Col sm={8}>
                            <Form.Control type="date"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Details</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="textarea"/>
                        </Col>
                    </Form.Group>
                    <div className="text-center">
                        <button className="btn pharmacy-btn px-4">Save</button>
                    </div>
                </Form>
            </div>
        </div>
        </div>
    )
};
export default MainLoanPayment
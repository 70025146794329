import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions,DialogTitle,DialogContent} from "./Dialog/pharmacyDialog";


const DeleteDialog = ({openDialog, handleClose,handleDelete,message}) =>{
    return(
        <Dialog open={openDialog}>
            <DialogTitle onClose={handleClose}>
                <div className="text-center">
                    Delete {message}
                </div>
            </DialogTitle>
            <DialogContent>
                <h6>Are you sure you want to delete this {message}?</h6>
            </DialogContent>
            <DialogActions>
                <button className="btn pharmacy-btn-dismiss btn-sm"
                        onClick={handleDelete}>Delete
                </button>
                <button className="btn pharmacy-grey-btn btn-sm" onClick={handleClose}>Cancel
                </button>
            </DialogActions>
        </Dialog>
    )

};
export default DeleteDialog;
import React, {useEffect,  useState, useRef} from "react";
import {Link} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import Workbook from "react-excel-workbook";
import {exportPDF} from "../../Utils/exportPDF";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions"
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import axios from "axios";
import config from "../../Helpers/config";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {titleCase, removeTime} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import {CSVLink} from "react-csv";
import Form from "react-bootstrap/Form";

const headData = [
    {id: 'medicine_name', numeric: false, disablePadding: false, label: 'Medicine Name'},
    {id: 'generic_name', numeric: false, disablePadding: false, label: 'Generic Name'},
    {id: 'strength', numeric: false, disablePadding: false, label: 'Strength'},
    {id: 'batch_id', numeric: false, disablePadding: false, label: 'Batch ID'},
    {id: 'expiry_date', numeric: false, disablePadding: false, label: 'Expiry Date'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];
const ExpiryListReport = ({actions, snackbars}) => {
    const [expiryList, setExpiryList] = useState([]);
    const [stockCount, setStockCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [csvData, setCsvData] = useState([])
    const csvRef = useRef()

    useEffect(() => {
        const formData = new FormData();
        formData.append("draw", 1);
        formData.append("columns[0][data]", "sl");
        formData.append("columns[0][name]", "");
        formData.append("columns[0][searchable]", "true");
        formData.append("columns[0][orderable]", false);
        formData.append("columns[0][search][value]", "");
        formData.append("columns[0][search][regex]", false);
        formData.append("columns[1][data]", "product_id");
        formData.append("columns[1][name]", "");
        formData.append("columns[1][searchable]", true);
        formData.append("columns[1][orderable]", true);
        formData.append("columns[1][search][value]", "");
        formData.append('columns[1][search][regex]', false);
        formData.append("columns[2][data]", "batch_id");
        formData.append("columns[2][name]", "");
        formData.append("columns[2][searchable]", true);
        formData.append("columns[2][orderable]", false);
        formData.append("columns[2][search][value]", "");
        formData.append("columns[2][search][regex]", false);
        formData.append("columns[3][data]", "expeire_date");
        formData.append('columns[3][name]', "");
        formData.append("columns[3][searchable]", true);
        formData.append("columns[3][orderable]", false);
        formData.append("columns[3][search][value]", "");
        formData.append("columns[3][search][regex]", false);
        formData.append("columns[4][data]", "stock");
        formData.append("columns[4][name]", "");
        formData.append("columns[4][searchable]", true);
        formData.append("columns[4][orderable]", false);
        formData.append("columns[4][search][value]", "");
        formData.append("columns[4][search][regex]", false);
        formData.append("order[0][column]", 2);
        formData.append("order[0][dir]", "asc");
        formData.append("start", activePage);
        formData.append("length", 10);
        formData.append("search[value]", searchValue);
        formData.append("search[regex]", false);
        setLoading(true)
        axios.post(`${config.epharmUrl}/Creport/checkExpireList`, formData).then(res => {
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.aaData ?? [];
            const stock_count = dt.iTotalRecords ?? 0;
            setExpiryList(arr);
            setStockCount(stock_count)
            setLoading(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [actions, activePage, searchValue])

    const handleChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar()
    };


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };



    const downloadPDF = () => {
        axios.get(`${config.epharmUrl}/Creport/retrieve_all_out_of_expired_drugs`).then(response => {
            const data = response.data
            const arr = data ?? []
            exportPDF("Expiry List", [["SL", "Medicine Name","Generic Name","Strength",
                "Batch ID", "Expiry Date", "Stock"]],
                arr.map((stock, index) => [index += 1, stock.product_name,stock.generic_name,
                    stock.strength,stock.batch_id, stock.expeire_date,
                     stock.stock]), "Expired Items.pdf");
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };

    const fetchCsvData = () => {
        axios.get(`${config.epharmUrl}/Creport/retrieve_all_out_of_expired_drugs`).then(response => {
            const data = response.data;
            const arr = data ?? []
            setCsvData(arr)
            if(arr){
                csvRef.current.link.click()
            }
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };


    const headers = [{key:'sl', label:'Sl'},{key:'product_name', label:'Medicine Name'},
    {key:'generic_name', label:'Generic Name'},{key:'strength', label:'Strength'},
    {key:'batch_id', label:'Batch#'},{key:'expeire_date', label:'Expiry Date'},
    {key:'stock', label:'Stock'}]

    const {open, message, variant} = snackbars;
    return (
        <div>
            <ReusableDashboardHeader component='Expired Items' heading="Stock"
                                     subHeading='Expired items' link={history.location.pathname}/>
            <PharmacySnackbar open={open} message={message} variant={variant} handleCloseBar={handleCloseBar}/>
            <MuiPaper>
                <EnhancedTableToolbar title=''>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                            <span>
                                <CSVLink
                                    data={csvData}
                                    filename="Expired Items.csv"
                                    target="_blank"
                                    className="hidden"
                                    ref={csvRef}
                                    headers={headers}
                                />
                                <button className="btn btn-sm pharmacy-btn mr-1" onClick={fetchCsvData}>Excel</button>
                            </span>
{/* 
                           <Workbook filename="Expiry List.xlsx" element={
                               <span><button className="btn btn-sm pharmacy-btn mr-1">Excel</button></span>
                           }>
                                    <Workbook.Sheet data={expiryList} name="Expiry List">
                                        <Workbook.Column label="Medicine Name" value="product_name"/>
                                        <Workbook.Column label="Batch ID" value="batch_id"/>
                                        <Workbook.Column label="Expiry Date" value="expiry_date"/>
                                        <Workbook.Column label="Stock" value="stok_quantity"/>
                                    </Workbook.Sheet>
                                </Workbook> */}
                                <span><button className="btn btn-sm pharmacy-btn mr-1"
                                              onClick={downloadPDF}>Pdf</button></span></span>
                    <Form.Control type="text" value={searchValue ? titleCase(searchValue) : ''}
                                  onChange={handleChange}
                                  className='form__control'
                                  placeholder="Search" style={{float: 'right'}}/>
                </EnhancedTableToolbar>
                <Table
                    aria-labelledby="tableTitle"
                    size='medium'
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead headCells={headData}/>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan={headData.length} align="center"><img src="/images/listPreloader.gif"
                                                                   alt="loader"
                                                                   style={{width: '5%'}}/></TableCell>
                    </TableRow></TableBody> : expiryList.length > 0 ?
                        <TableBody>
                            {expiryList.slice(0, 10)
                                .map((d, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>
                                                <Link
                                                    to={{pathname: `/medicinedetails/${d.product_id}`}}
                                                    style={{color: "#09905c"}}>{titleCase(d.product_name)}</Link>
                                            </TableCell>
                                            <TableCell>
                                                {d.generic_name}
                                            </TableCell>
                                            <TableCell>
                                                {d.strength}
                                            </TableCell>
                                            <TableCell>
                                                {d.batch_id}
                                            </TableCell>
                                            <TableCell>
                                                {d.expeire_date ? removeTime(d.expeire_date) : ''}
                                            </TableCell>
                                            <TableCell>
                                                {d.stock}
                                            </TableCell>
                                            <TableCell>
                                                {d.total_cost}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        : <TableBody><TableRow>
                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </Table>
                <CustomPagination data={expiryList} records={10} activePage={activePage} total_count={stockCount}
                                  handleChangeNextPage={handlePageChange}/>
            </MuiPaper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ExpiryListReport);
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


const ExpenseStatement = () => {
    const [showStatement, setStatement] = React.useState(false);

    const handleGetStatement = (event) =>{
        event.preventDefault();
        setStatement(true);
    };
    return (
        <div>
        <DashboardHeader expense expenseStatement/>
            <div className="card  styled-expense p-3 ">
                <hr/>
                <Form onSubmit={handleGetStatement} autoComplete="off">
                    <Form.Row>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={4}>Expense Item </Form.Label>
                                <Col sm={7}>
                                    <Select/>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>Start Date</Form.Label>
                                <Col sm={7}>
                                    <Form.Control type="date"/>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>End Date</Form.Label>
                                <Col sm={7}>
                                    <Form.Control type="date"/>

                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <button type="submit" className="btn pharmacy-btn">Search</button>
                        </Col>
                    </Form.Row>

                </Form>
            </div>
            {showStatement &&<div className="card styled-expense p-3">
                <h6>Expense Statement</h6>
                <hr/>
                <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <td>Date</td>
                            <td>Expense Item Name</td>
                            <td>Amount</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>11-03-2020</td>
                            <td>Communication</td>
                            <td>400000</td>
                        </tr>
                        <tr>
                            <td colSpan={2} align="right">Total</td>
                            <td>400000</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>}
        </div>
    )
};
export default ExpenseStatement;
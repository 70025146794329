import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import styled from "@emotion/styled/macro";
import * as colors from '../../styles/colors'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



const ListContainer = styled.div({
    border:`0.78px solid ${colors.gray00}`,
    marginBottom:'10px'
})
const ItemIcon = styled(ListItemIcon)({
    minWidth:'5px'
})

const StyledList = styled(List)({
    paddingTop:0,
    paddingBottom:0,

})

const ItemButton = styled(ListItem)({
    '&::-webkit-tap-highlight-color':{
        background: colors.base
    },
    ':hover':{
        background:colors.gray001
    },
    paddingLeft:'6px',
    paddingRight:'6px',
    background:colors.gray001
})

const ItemText = styled(ListItemText)({
    '& .MuiTypography-root':{
        fontSize:'14px',
        fontWeight:700
    }

})

const StyledCell = styled(TableCell)({
    padding:'6px 13px',
    fontSize:'14px'
})

const HeadCell = styled(StyledCell)({
    borderTop:`0.78px solid ${colors.gray00}`,
    borderBottom:`0.78px solid ${colors.gray00}`,
    background:colors.gray001,
    fontWeight:700,
    // textAlign:'center',
    width:'fit-content'
})
const DataCell = styled(StyledCell)({
    borderBottom:`0.78px solid ${colors.gray00}`,
    // textAlign:'center'
})




function ModuleTable({children, headData=[]}) {
    return (
        <TableContainer>
            <Table aria-label="module table">
                <TableHead>
                    <TableRow>
                        {headData.map((item, index)=>(
                            <HeadCell align={item.align} key={index}>{item.item} </HeadCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


function CollapsibleList({handleOpen, children, open, name, button=null}) {
    return (
        <ListContainer>
            <StyledList
                sx={{ width: '100%'}}
                component="nav"
                aria-labelledby="collapsible-list"
            >
                <ItemButton  button>
                    <ItemIcon onClick={handleOpen}>
                        {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </ItemIcon>
                    <ItemText  primary={name}  />

                    {button}
                </ItemButton>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    {children}

                </Collapse>
            </StyledList>
        </ListContainer>
    );
}
export {CollapsibleList, DataCell, ModuleTable, ItemText}

import {applyMiddleware, compose, createStore} from "redux";
import {createLogger} from "redux-logger/src";
import thunk from "redux-thunk";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import rootReducer from "./Reducers"

const logger = createLogger({
    duration:true,
    timestamp:true
});
const reduxImmutable = reduxImmutableStateInvariant();
export  default function configureStore(initialState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;
    const middleWares = process.env.NODE_ENV === "development" ? composeEnhancers(applyMiddleware(thunk, logger,reduxImmutable)):
        composeEnhancers(applyMiddleware(thunk, reduxImmutable));
    return createStore(
        rootReducer,
        initialState,
        middleWares
    );
}




import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import {faFacebook} from "@fortawesome/free-brands-svg-icons/faFacebook";
import {faTwitter} from "@fortawesome/free-brands-svg-icons/faTwitter";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons/faWhatsapp";


const SupportPage = () => {
    return (
        <div>
            <DashboardHeader support/>
            <div className="user-support">
                <div className="styled-component p-3">
                    <div className="text-center mb-3">
                        <div className="mb-4">
                            <h5>Get In Touch</h5>
                        </div>
                        <span>Want to get in touch? We'd love to hear from you.Here's how you can reach us...</span>
                    </div>
                    <div className="text-center">
                        <div className="mb-3">
                            <div className="support-icon">
                                <FontAwesomeIcon icon={faPhone}/>
                            </div>
                            <div className="team-text mt-2">
                                <strong><h6>Talk to the Team</h6></strong>
                            </div>
                        </div>
                        <div className="my-2">
                            <p>+256702144852 / +256786211400</p>
                        </div>
                        <h4>OR</h4>

                        <div className="my-4">
                            <a href="https://twitter.com/MpekeRx" className="fa fa-twitter"
                               target="__blank"><FontAwesomeIcon icon={faTwitter}/></a>
                            <a href="https://www.facebook.com/MpekeRx-113569226853219" className="fa fa-facebook"
                               target="__blank"><FontAwesomeIcon icon={faFacebook}/></a>
                            <a href="https://bit.ly/mpekerx" className="fa fa-whatsapp"
                               target="__blank"><FontAwesomeIcon icon={faWhatsapp}/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default SupportPage;
import React, {useEffect, useState} from 'react';
import {useDates} from "./useDates";
import axios from 'axios';
import config from '../../../Helpers/config.json';
import {errorMessages} from "../../../Helpers/ErrorMessages";


export const useReusableDataFetch = (actions, dataUrl) =>{
    const CURRENT_YEAR = new Date().getFullYear();
    const periods = [{value:'today',label:'Today'},{value:'weekly',label:'Weekly'},{value:'monthly',label:'Monthly'},
        {value:'yearly',label:'Yearly'},{value:'custom',label:'Custom'}];
    const years = [{value:2021, label:2021},{value:2022, label:2022},{value:2023, label:2023},{value:2024, label:2024},
        {value:2025, label:2025},{value:2026, label:2026},{value:2027, label:2027},{value:2028, label:2028},
        {value:2029, label:2029},{value:2030, label:2030}];
    const [period, setPeriod] = useState({value:'today', label:'Today'});
    const [date, handleChangeDate, setDate] = useDates();
    const [category, setCategory] = useState('');
    const [data, setData] = useState([]);
    const [year, setYEar] = useState({value:CURRENT_YEAR, label:CURRENT_YEAR});
    const [loading, setLoading] = useState(false);
    const [total_count,setTotalCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');

    const {from_date, to_date} = date;
    const inputTypes = {
        weekly:'week',
        monthly:'month',
        custom:'date'
    };

    useEffect(()=>{
        setLoading(true)
        axios.get(`${config.epharmUrl}/accounting/reports/${dataUrl}`).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.data ?? [];
            setData(arr)
            setLoading(false)
        }).catch(err=>{
            // errorMessages(err, null, actions);
            setLoading(false)
        });
    },[from_date, to_date, category, activePage, search]);



    const handleChangePeriod = (value) =>{
        setPeriod(value);
    };

    const  handleChangeCategory = (value) =>{
        setCategory(value);
    };

    const handleChangeYear = (value) => {
        setYEar(value);
    };

    const handleChangeActivePage = (page) =>{
        setActivePage(page)
    }

    const handleChangeSearch = (event) =>{
        setSearch(event.target.value)
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackBar();
    };

    const reusableProps = {periods, years, period, from_date, to_date, handleChangeDate,
        year, inputTypes, handleChangePeriod,  handleChangeYear}

    return {setDate,  data,loading, total_count, activePage, search,category, handleChangeCategory,
        handleChangeActivePage, handleChangeSearch, closeSnackbar, reusableProps}
}
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Paper from "@material-ui/core/Paper";
import {EnhancedToolbar, EnhancedPharmacyListHead, stableSort, getSorting, useStyles} from "../../Utils/Tables/MuiTables";
import DrugsAvailableTablePagination from "../../Utils/Tables/MuiTablePagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FilterResults from 'react-filter-search';
import TablePagination from '@material-ui/core/TablePagination';
import {withStyles} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import {compose} from "recompose";
import Select from "react-select";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import {titleCase} from "../../Utils/titleCaseFunction";


const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    },
    subMenuLink: {
        display: "block",
        color: "#757575",
        textDecoration: "none"
        // padding: theme.spacing.unit * 6,
    },
    itemStyle: {
        backgroundColor: "#f9f9f9"
    },
    itemOdd: {
        backgroundColor: "#fff"
    }
});

const data = [
    {
        date: " ",
        details: " ",
        invoiceNo: " ",
        deposit: " ",
        debit: " ",
        credit: " ",
        balance: " ",
    },

];

class ManufacturerLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            term: "",
            order: 'asc',
            setOrder: 'asc',
            orderBy: 'person',
            selected: [],
            setSelected: [],
            page: 0,
            setPage: 0,
            dense: false,
            setDense: false,
            rowsPerPage: 5,
            openTable: true,


        };
        this.handler = this.handler.bind(this);
    }

    componentDidMount() {
        const {actions} = this.props;
        actions.manufacturerActions.manageManufacturers(1);
    }

    handler(value) {
        console.log(value);
        this.setState({term: value})
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0});
    };


    render() {
        const {order, dense, orderBy, selected, rowsPerPage, page, term} = this.state;
        const {classes, ledgerInfo, ledgerReport,  ledgerInformation,ledgerData,openLedger,
            from_date, to_date, manufacturer_id, handleChange, handleChangeManufacturer,handleSearch,loading
        } = this.props;
        const {manufacturers} = this.props.manufacturers;
        const allManufacturers = !manufacturers ? {} :manufacturers;
        const manufacturer = !ledgerInformation.manufacturer ? {} : ledgerInformation.manufacturer;
        const address = !manufacturer.address ? "" : manufacturer.address;
        const manufacturer_name = !manufacturer.manufacturer_name ? "" : manufacturer.manufacturer_name;
        const manufs = !allManufacturers.manufacturers_list ? [] : allManufacturers.manufacturers_list;
        return (
            <div>
                <DashboardHeader manufacturer ledgerInformation={ledgerInfo} ledgerReport={ledgerReport}/>
                <div className="card my-3 p-3">
                    <div className="pos-buttons">
                        <Link to="/addmanufacturer" style={{textDecoration: "none", color: "inherit"}}>
                            <button type="button" className="btn mr-2 pharmacy-info-btn">
                                <FontAwesomeIcon icon={faPlus}/> Add Supplier
                            </button>
                        </Link>
                        <Link to="/managemanufacturer">
                            <button type="button" className="btn pharmacy-primary-btn mr-2">
                                <FontAwesomeIcon icon={faList}/> Manage Supplier
                            </button>
                        </Link>
                        <Link to="/manufacturersales">
                            <button type="button" className="btn pharmacy-btn mr-2">
                                <FontAwesomeIcon icon={faList} className="mr-1"/>Supplier Sales Details
                            </button>
                        </Link>
                    </div>
                    <div className="card mt-4">
                        <div className="m-3">
                            <Form onSubmit={handleSearch}>
                                {ledgerReport && <Form.Group as={Row}>
                                    <Form.Label column sm={2}>Select Supplier</Form.Label>
                                    <Col sm={6}>
                                        <Select
                                            placeholder='Select Supplier'
                                            options={manufs.map(manufacturer => ({
                                                value: manufacturer.manufacturer_id,
                                                label: titleCase(manufacturer.manufacturer_name)
                                            }))}
                                            value={manufacturer_id}
                                            onChange={handleChangeManufacturer}
                                        />
                                    </Col>
                                </Form.Group>}
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>From</Form.Label>
                                    <Col sm={6}>
                                        <Form.Control type="date" value={from_date} name="from_date"
                                                      onChange={handleChange}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>To</Form.Label>
                                    <Col sm={6}>
                                        <Form.Control type="date" value={to_date} name="to_date"
                                                      onChange={handleChange}/>
                                    </Col>
                                </Form.Group>
                                <button type="submit" className="btn pharmacy-btn px-4 offset-md-5">Search</button>
                            </Form>
                        </div>
                    </div>
                    <div className="card mt-4 p-2">
                        <h6 className="ml-3 mt-2">Supplier Ledger</h6>
                        <hr/>
                        {openLedger &&
                        <div className="text-center">
                            <h6>{titleCase(manufacturer_name)}</h6>
                            <h6>{titleCase(address)}</h6>
                            <h6> Print Date: {from_date} To {to_date}</h6>
                        </div>}
                        <Paper className={`${classes.paper} table-responsive`}>
                            <EnhancedToolbar numSelected={selected.length} handler={this.handler} data={data} ledger/>
                            <div className={classes.tableWrapper}>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                    // className={classes.table}
                                >
                                    <EnhancedPharmacyListHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={ledgerData.length}
                                        ledger
                                    />
                                    <FilterResults
                                        value={term}
                                        data={ledgerData}
                                        renderResults={results => (
                                            loading ? <div className="mpeke-loader"><img src="/images/loader.gif" alt="loading"/></div>:
                                                ledgerData.length > 0 ?
                                            <TableBody>
                                                {stableSort(results, getSorting(order, orderBy))
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((d, index) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={index}
                                                                className='mui-table-row'>
                                                                <TableCell component="th" scope="row" padding="default"
                                                                           className='mui-table-cell'>
                                                                    <span>{d.date}</span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{titleCase(d.description)}</span>
                                                                </TableCell>
                                                                <TableCell>
                                                                <span><Link
                                                                    to={{pathname: `/manufacturerinvoice/${d.id}`}}
                                                                    style={{color: "#09905c"}}>{d.chalan_no}</Link></span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{d.deposit_no}</span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{d.debit}</span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{d.credit}</span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{d.balance}</span>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>:<div className="text-center p-3">No Records Found</div>
                                        )}
                                    />
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                count={ledgerData.length}
                                rowsPerPage={rowsPerPage}
                                component="div"
                                page={page}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={DrugsAvailableTablePagination}
                            />
                        </Paper>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        manufacturers: state.manufacturers
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            manufacturerActions: bindActionCreators(manufacturerActions, dispatch)
        }
    }

}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(useStyles, styles))(ManufacturerLedger);
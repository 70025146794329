import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Workbook from "react-excel-workbook";
import {splitText, titleCase} from "../../Utils/titleCaseFunction";
import {CSVLink} from "react-csv";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import {useManageManufacturer} from "./CustomHooks/useManageManufacturer";
import Textarea from "../../Utils/FormInputs/Textarea";
import DeleteDialog from "../../Utils/DeleteDialog";
import FilterResults from 'react-filter-search';


const ManageManufacturer = ({actions, snackbars}) => {
    const {
        term, activePage, manufacturers, total_count,
        openDelete, openDialog, loading, state,
        submitted, isSubmitted, form, handleOpenDialog, handleCloseDialog,
        handleOpenDelete, handleCloseDelete,
        handleDeleteManufacturer, handler, handlePageChange,
        handleChange, handleSubmit, handleCloseBar, downloadPDF
    } = useManageManufacturer(actions)
    const {manufacturer_name, address, mobile, details, previous_balance} = state;
    const {open, message, variant} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const manage_manufacturer = !permissions.manage_manufacturer ? {} : permissions.manage_manufacturer;
    const headData = [
        {id: 'name', numeric: false, disablePadding: false, label: 'Supplier Name'},
        {id: 'address', numeric: false, disablePadding: false, label: 'Address'},
        {id: 'mobile', numeric: false, disablePadding: false, label: 'Mobile'},
        {id: 'details', numeric: false, disablePadding: false, label: 'Details'},
        (manage_manufacturer.create || manage_manufacturer.update || manage_manufacturer.delete)
            ? {id: 'action', numeric: false, disablePadding: false, label: 'Action'} :
            {id: 'nonaction', numeric: false, disablePadding: false, label: ''}
    ];
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Suppliers" heading="Supplier"
                                     subHeading="Suppliers" link="/managemanufacturer"/>
            <div className='general-ledger-header'>
                <div className='text-right'>
                    <button onClick={handleOpenDialog} className='btn btn-sm pharmacy-info-btn'>New Supplier
                    </button>
                </div>
            </div>
            <ReusableDialog handleSubmit={handleSubmit} openDialog={openDialog} formRef={form} width='sm'
                            handleClose={handleCloseDialog} message='New Supplier' isSubmitted={isSubmitted}>
                <Form.Group as={Row}>
                    <Label name='Supplier Name' type sm={3} column/>
                    <Col sm={7}>
                        <TextField type="text" value={titleCase(manufacturer_name)}
                                   name="manufacturer_name" onChange={handleChange}/>
                        {(submitted && !manufacturer_name) ?
                            <div className="invalid-text">Supplier name is required</div> : null}
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Label name='Mobile' sm={3} column/>
                    <Col sm={7}>
                        <TextField type="text" value={mobile}
                                   name="mobile" onChange={handleChange}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Label name='Address' sm={3} column/>
                    <Col sm={7}>
                        <Textarea value={address}
                                  name="address" onChange={handleChange}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Label name='Details' sm={3} column/>
                    <Col sm={7}>
                        <Textarea value={details}
                                  name="details" onChange={handleChange}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Label name='Previous Bal.' sm={3} column/>
                    <Col sm={7}>
                        <TextField type="number" value={previous_balance}
                                   name="previous_balance" onChange={handleChange}/>
                    </Col>
                </Form.Group>
            </ReusableDialog>
            <PharmacySnackbar handleCloseBar={handleCloseBar} variant={variant} open={open} message={message}/>
            <DeleteDialog handleClose={handleCloseDelete} handleDelete={handleDeleteManufacturer}
                          openDialog={openDelete} message='supplier'/>
            <MuiPaper>
                <EnhancedTableToolbar title='Suppliers'>
                      <span className="btn-group" style={{flex: '1 1 100%'}}>
                    <CSVLink
                        data={manufacturers}
                        filename="Suppliers.csv"
                        target="_blank"
                    >
                        <button className="btn btn-sm pharmacy-btn mr-1">CSV</button>
                    </CSVLink>
                    <Workbook filename="Suppliers.xlsx" element={
                        <span><button className="btn btn-sm pharmacy-btn mr-1">Excel</button></span>
                    }>
                        <Workbook.Sheet data={manufacturers} name="Suppliers">
                            <Workbook.Column label="Supplier Name" value="manufacturer_name"/>
                            <Workbook.Column label="Address" value="address"/>
                            <Workbook.Column label="Mobile" value="mobile"/>
                            <Workbook.Column label="Details" value="details"/>
                        </Workbook.Sheet>
                    </Workbook>
                    <span><button className="btn pharmacy-btn mr-1 btn-sm " onClick={downloadPDF}>
                        Pdf</button></span></span>
                    <TextField type="text" onChange={event => handler(event.target.value)}
                               placeholder="Search" style={{float: 'right'}}/>
                </EnhancedTableToolbar>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="5" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader"
                                                                       style={{width: '5%'}}/></TableCell>
                        </TableRow></TableBody> : manufacturers.length > 0 ?
                            <FilterResults
                                value={term}
                                data={manufacturers}
                                renderResults={results => (<TableBody>
                                    {results.slice(0, 10)
                                        .map((d) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={d.manufacturer_id}
                                                    className='mui-table-row'>
                                                    <TableCell>
                                                        {/*<Link to={{pathname: `/manufacturerledgerinfo/${d.manufacturer_id}`}}*/}
                                                        {/*    style={{color: "#09905c"}}>*/}
                                                            {titleCase(d.manufacturer_name)}
                                                        {/*</Link>*/}
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{splitText(d.address ?? '')}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.mobile}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{splitText(d.details ?? '')}</span>
                                                    </TableCell>
                                                    <TableCell id="mainbuttons">
                                                        <Link 
                                                            to={{pathname: `/manufacturersales/${d.manufacturer_id}`}}>
                                                            <button style={{marginBottom: '4px'}}
                                                                className="btn pharmacy-btn btn-sm mr-1 ">
                                                                View Purchases
                                                            </button>
                                                        </Link>
                                                        {(manage_manufacturer.update || manage_manufacturer.delete) ?
                                                            <span>
                                                            {(manage_manufacturer.update) ?
                                                                <Tooltip title="Update"
                                                                         placement="left-start">
                                                                    <Link 
                                                                        to={{pathname: `/editmanufacturer/${d.manufacturer_id}`}}>
                                                                        <button style={{marginBottom: '4px'}}
                                                                            className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                            <FontAwesomeIcon
                                                                                icon={faPencilAlt}/>
                                                                        </button>
                                                                    </Link>
                                                                </Tooltip> : null}
                                                                {(manage_manufacturer.delete) ?
                                                                    <Tooltip title="Delete"
                                                                             placement="left-start">
                                                                        <button style={{marginBottom: '4px'}}
                                                                            className="btn pharmacy-btn-dismiss btn-sm"
                                                                            onClick={() => handleOpenDelete(d.manufacturer_id)}>
                                                                            <FontAwesomeIcon
                                                                                icon={faTrash}/></button>
                                                                    </Tooltip> : null}
                                                            </span> : null}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                </TableBody>)}
                            />
                            : <TableBody><TableRow>
                                <TableCell colSpan="5" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                </TableContainer>
                <CustomPagination data={manufacturers} records={10} activePage={activePage}
                                  total_count={total_count}
                                  handleChangeNextPage={handlePageChange}/>
            </MuiPaper>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageManufacturer);
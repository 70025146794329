import React from 'react';
import Grid from "@material-ui/core/Grid";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {JournalSelect} from "../ReusableJournalTable";
import SelectInput from "../../../Utils/FormInputs/SelectInput";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PrintIcon from "@material-ui/icons/Print";
import {ledgerStyles} from "./GeneralLedger";
import {useAccountingStyles} from "../CustomHooks/useAccountingStyles";

export const printStyles = () =>{
    return '' +
        '<style>' +
        'table {' +
        'width: 100%;'+
        'border-collapse:collapse;' +
        '}' +
        'table th, table td {' +
        'padding:.5rem;' +
        'font-size:16px;' +
        '}' +
        'table.general-ledger-table tbody tr:nth-child(even) td {' +
        'border-bottom: 1px solid #e4e4e4;' +
        '}' +
        'table.general-ledger-table thead td {' +
        'border-bottom:1px solid #606060;' +
        'color:#232323;' +
        '}' +
        '</style>';
}

export const ReusableReportHeader = ({period,handleChangePeriod,year, handleChangeYear, handleChangeDate,
                              from_date, to_date,inputType, title,subTitle, downloadPdf,handlePrint}) =>{
    const years = [{value: '2021', text: '2021'}, {value: '2022', text: '2022'}, {value: '2023', text: '2023'},
        {value: '2024', text: '2024'}, {value: '2025', text: '2025'}, {value: '2026', text: '2026'},
        {value: '2027', text: '2028'}, {value: '2029', text: '2029'}, {value: '2030', text: '2030'}]
    const classes = useAccountingStyles();
    return (
            <div className="general-ledger-header mb-3">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        {/*<div style={ledgerStyles.filters}>*/}
                        {/*    <Form.Group as={Row}>*/}
                        {/*        <Col sm={3}>*/}
                        {/*            <span style={ledgerStyles.span}>Period:</span>*/}
                        {/*            <span>*/}
                        {/*            <JournalSelect journal={period} handleChange={handleChangePeriod}>*/}
                        {/*                <option value="today">Today</option>*/}
                        {/*                <option value="weekly">Weekly</option>*/}
                        {/*                <option value="monthly">Monthly</option>*/}
                        {/*                <option value="yearly">Yearly</option>*/}
                        {/*                 <option value="custom">Custom</option>*/}
                        {/*            </JournalSelect>*/}
                        {/*        </span>*/}
                        {/*        </Col>*/}
                        {/*        {period !== 'today' ? <>*/}
                        {/*            {period === 'yearly' ?*/}
                        {/*                <Col sm={3}>*/}
                        {/*                    <SelectInput options={years} value={year}*/}
                        {/*                                 onChange={handleChangeYear}/>*/}
                        {/*                </Col> :*/}
                        {/*                <Col sm={5}>*/}
                        {/*                    <Form.Group as={Row}>*/}
                        {/*                        {period === 'custom' ?*/}
                        {/*                            <Label name='From' column sm={2} style={{textAlign: 'right'}}/> : null}*/}
                        {/*                        <Col sm={8}>*/}
                        {/*                            <TextField type={inputType[period]} onChange={handleChangeDate}*/}
                        {/*                                       value={from_date} name='from_date'/>*/}
                        {/*                        </Col>*/}
                        {/*                    </Form.Group>*/}
                        {/*                </Col>*/}
                        {/*            }*/}
                        {/*        </> : null}*/}
                        {/*        {period === 'custom' ? <Col sm={4}>*/}
                        {/*            <Form.Group as={Row}>*/}
                        {/*                <Label name='To' column sm={2}/>*/}
                        {/*                <Col sm={10}>*/}
                        {/*                    <TextField type='date' value={to_date} onChange={handleChangeDate}*/}
                        {/*                               name='to_date'/>*/}
                        {/*                </Col>*/}
                        {/*            </Form.Group>*/}
                        {/*        </Col> : null}*/}
                        {/*    </Form.Group>*/}
                        {/*</div>*/}
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <div className="text-right">
                            <div className="btn-group mt-2">
                                <button onClick={downloadPdf} className='btn btn-sm sms-btn-secondary'>
                                    <PictureAsPdfIcon classes={{root: classes.icon}}/></button>
                                <button onClick={handlePrint }
                                        className='btn btn-sm sms-btn-secondary'>
                                    <PrintIcon classes={{root: classes.icon}}/></button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
    )
}
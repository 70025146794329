import * as types from "./actionTypes";


export function handleNext(activeStep) {
    return {
        type: types.NEXT_STEP, activeStep
    }
}

export function handlePrevious(activeStep) {
    return {
        type: types.PREVIOUS_STEP, activeStep
    }
}


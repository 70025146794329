import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, DialogTitle} from "../../../Utils/Dialog/pharmacyDialog";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from "react-select";


const CheckInDialog = (props) => {
    const {openDialog, handleClose, singleCheck, checkOut} = props;
    return (
        <Dialog open={openDialog} fullWidth={true}>
            <DialogTitle onClose={handleClose}>
                <div className="text-center">
                    {checkOut ? "Check Out" : "Add Attendance"}
                </div>
            </DialogTitle>
            <DialogContent>
                {checkOut ?
                    <div className="text-center">
                        <h6>Are you sure you want to checkout this employee ?</h6>
                        <div className="mt-5">
                        <button onClick={handleClose} className="btn pharmacy-btn-dismiss mr-2">Cancel</button>
                        <button className="btn pharmacy-info-btn">Check Out</button>
                        </div>
                    </div>
                    : <div className="card styled-person-card p-3">
                        <h6>{singleCheck ? "Single Check In" : "Bulk Check In"}</h6>
                        <hr/>
                        {!singleCheck && <div className="text-right mb-3">
                            <button className="btn pharmacy-primary-btn">Download Sample File</button>
                        </div>}
                        <Form autoComplete="off">
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>{singleCheck ? "Employee Name" : "Upload CSV file"}<span
                                    className="text-danger asterics">*</span></Form.Label>
                                <Col sm={8}>
                                    {singleCheck ? <Select/> :
                                        <span className="card p-1"><Form.Control type="file"/></span>}
                                </Col>
                            </Form.Group>
                            <div className="text-center">
                        <span>
                            <button onClick={handleClose} type="button"
                                    className="btn pharmacy-btn-dismiss mr-2">Cancel</button>
                            <button type="submit" className="btn pharmacy-info-btn">Check In</button>
                        </span>
                            </div>
                        </Form>
                    </div>}

            </DialogContent>
        </Dialog>
    )
};
export default CheckInDialog;
import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import {useNewCOAccount} from "./CustomHooks/useNewCOAccount";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {ReusableCOA} from "./ChartOfAccounts";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions';
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import PrintDialog from "../../Utils/Dialog/PrintDialog";
import ReusableStepper from "../../Utils/Snackbars/ReusableSteppers";
import {useAccountingSetUp} from "./CustomHooks/useAccountingSetUp";
import {history} from "../../Helpers/history";
import * as steppersActions from '../../Redux/Actions/steppersActions';
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";

export const ReusableNewCOA = ({accountType, defaultAccounts, handleChangeAccountType, submitted, handleChangeState,
                                   account_name, subAccounts, parentAccount, handleChangeParentAccount,
                                   isSubAccount, opening_balance, balance_date, entryType, handleChangeEntryType, entryTypes}) =>{
    return(
        <div>
            <Form.Group as={Row}>
                <Label name='Account type' type sm="4" column style={{textAlign: 'right'}}/>
                <Col sm={8}>
                    <CustomSelect value={accountType} options={defaultAccounts}
                                  onChange={handleChangeAccountType}/>
                    {(submitted && !accountType) &&
                    <div className='help-block'>Account type is required</div>}
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Label name='Account name' type sm="4" column style={{textAlign: 'right'}}/>
                <Col sm={8}>
                    <TextField onChange={handleChangeState} value={account_name} name='account_name'
                               type='text'/>
                    {(submitted && !account_name) &&
                    <div className='help-block'>Account name is required</div>}
                </Col>
            </Form.Group>
            {(accountType && subAccounts.length > 0) ? <Form.Group as={Row}>
                <Col sm={4}/>
                <Col sm={8}>
                    <Form.Check type='checkbox' label='Make a sub-account' name='isSubAccount'
                                value={isSubAccount}
                                onChange={handleChangeState}/>
                </Col>
            </Form.Group> : null}

            {isSubAccount ?
                <>
                    <Form.Group as={Row}>
                        <Label name='Parent account' type sm="4" column style={{textAlign: 'right'}}/>
                        <Col sm={8}>
                            <CustomSelect value={parentAccount} onChange={handleChangeParentAccount}
                                          options={subAccounts}/>
                            {(submitted && isSubAccount && !parentAccount) &&
                            <div className='help-block'>Parent account is required</div>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name='Opening balance'  sm="4" column style={{textAlign: 'right'}}/>
                        <Col sm={8}>
                            <TextField onChange={handleChangeState} value={opening_balance}
                                       name='opening_balance' type='number'/>
                        </Col>
                    </Form.Group>
                    {/*<Form.Group as={Row}>*/}
                    {/*    <Col sm={2}/>*/}
                    {/*    <Col sm={5}>*/}
                    {/*        <Label name='Opening balance'/>*/}
                    {/*        <TextField onChange={handleChangeState} value={opening_balance}*/}
                    {/*                   name='opening_balance' type='number'/>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm={5}>*/}
                    {/*        <Label name='as of'/>*/}
                    {/*        <TextField onChange={handleChangeState} value={balance_date}*/}
                    {/*                   name='balance_date' type='date'/>*/}
                    {/*    </Col>*/}
                    {/*</Form.Group>*/}
                    <Form.Group as={Row}>
                        <Label name='Entry type' type sm="4" column style={{textAlign: 'right'}}/>
                        <Col sm={8}>
                            <CustomSelect value={entryType} onChange={handleChangeEntryType}
                                          options={entryTypes}/>
                            {(submitted && isSubAccount && !entryType) &&
                            <div className='help-block'>Entry type is required</div>}
                        </Col>
                    </Form.Group>
                </> : null}
        </div>
    )
}


const NewCOAccount = ({actions, snackbars,steppers}) => {
    const {steps} = useAccountingSetUp()
    const [confirmation,setConfirmation] = useState(false);
    const handleOpen = () => {
        setConfirmation(true)
    };

    const handleNext = () =>{
        actions.steppersActions.handleNext(1)
        setConfirmation(false);
        history.push('setuptypes')

    }
    const handleBack = () =>{
        actions.steppersActions.handlePrevious(1)
        history.push('/accounting/setup')
    }

    const handleClose = () =>{
        setConfirmation(false)
    }
    const {accountType, state, parentAccount, handleChangeAccountType, defaultAccounts, subAccounts,
        handleChangeState, handleChangeParentAccount, submitted, isSubmitted, handleSubmit, coa,
        closeSnackbar, entryType, handleChangeEntryType
    } = useNewCOAccount(actions);

    const {account_name, opening_balance, isSubAccount, balance_date} = state;
    const entryTypes = [{value: 'DEBIT', label: 'DEBIT'}, {value: 'CREDIT', label: 'CREDIT'}]
    const {open, variant, message} = snackbars
    const {activeStep} = steppers;
    const coaProps = {accountType, defaultAccounts, handleChangeAccountType, submitted, handleChangeState,
        account_name, subAccounts, parentAccount, handleChangeParentAccount,
        isSubAccount, opening_balance, balance_date, entryType, handleChangeEntryType, entryTypes}
    return (
        <div>

            <div className='journals'>
                <ReusableDashboardHeader component="Setup COA" heading="Accounting"
                                         subHeading="Setup COA" link="/accounting/setup/coa"/>

                <PharmacySnackbar message={message} open={open} variant={variant} handleCloseBar={closeSnackbar}/>
                <PrintDialog message='Confirm chart of accounts' text='Please confirm that you have fully setup your
            chart of accounts before proceeding to the next step'
                             handleClick={handleNext} handleClose={handleClose} openDialog={confirmation}
                             handleCancel={handleClose}
                             next='Next step' preview='Preview'/>
                <div className="general-ledger-header p-0">
                    <Grid container spacing={0}>
                        <Grid item xs={6} md={8}>
                            <ReusableStepper activeStep={activeStep} steps={steps}/>
                        </Grid>
                        <Grid item xs={6} md={4}>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                    <div className="p-3">
                        <form onSubmit={handleSubmit} autoComplete="off" className="general-card">
                            <ReusableNewCOA {...coaProps}/>
                            <div className="text-center">
                                <button type="submit" className="btn btn-sm pharmacy-info-btn"
                                        disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit & Add New'}</button>
                            </div>
                        </form>
                    </div>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Card className="p-3" elevation={0}>
                        <h6 className="" style={{fontSize: 18}}>Preview</h6>
                        <ReusableCOA coa={coa}/>
                    </Card>
                </Grid>
            </Grid>
            <div className="row">
                <div className="col-md-7"/>
                <div className="col-md-5">
                    <div className="text-center">
                        <div className="btn-group">
                            <button type='button' onClick={handleBack}
                                    className='btn btn-sm  mt-5'>Back
                            </button>
                            <button type='button' onClick={handleOpen}
                                    className='btn btn-sm pharmacy-info-btn mt-5'>Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

function mapStateToProps(state) {
    const {snackbars, steppers} = state;
    return {snackbars, steppers}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            steppersActions: bindActionCreators(steppersActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCOAccount)
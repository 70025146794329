import React from "react";

const PrintTableBalanceSheet = ({printRef, data}) =>{
    return (
        <div ref={printRef} style={{display:'none'}}>
            <table
                className='table table-borderless profile-table balance-sheet-data balance-sheet-table table-sm'
                id='balance_table'  >
                <thead>
                <tr>
                    <td style={{borderBottom: '1px solid #606060'}}><strong>Account</strong></td>
                    <td style={{borderBottom: '1px solid #606060'}}><strong>Total</strong></td>
                </tr>
                </thead>
                {data.map((item, index) => (
                    <tbody key={index}>
                    <tr>
                        <td colSpan={2} style={{backgroundColor: '#f7f7f7', padding: '8px 6px'}}>
                            <strong>{item.category}</strong></td>
                    </tr>
                    {item.parent_accounts.map(it => (
                        <>
                            {it.sub_accounts.length > 0 ? <tr key={it.id}>
                                <td style={{paddingLeft: '.95rem'}}>{it.account_name}</td>
                                <td>{''}</td>
                            </tr>:null}
                            {it.sub_accounts.map(({name, resulting_amount}, index)=>(
                                <tr key={index}>
                                    <td style={{paddingLeft: '1.3rem'}}>{name}</td>
                                    <td>{resulting_amount}</td>
                                </tr>
                            ))}
                        </>
                    ))}
                    </tbody>
                ))}
                {/*<tfoot>*/}
                {/*<tr>*/}
                {/*    <td>*/}
                {/*        <strong>Total LIABILITIES & EQUITY</strong>*/}
                {/*    </td>*/}
                {/*    <td>*/}
                {/*        <strong>500000</strong>*/}
                {/*    </td>*/}
                {/*</tr>*/}
                {/*</tfoot>*/}
            </table>
        </div>
    )
}

const PrintablePLStatement = ({printRef, data}) =>{
    return (
        <div ref={printRef} style={{display:'none'}}>
            <table className='table table-borderless profile-table balance-sheet-data balance-sheet-table table-sm'
                   id='pl_statement'>
                <thead>
                <tr>
                    <td colSpan={2} style={{borderBottom: '1px solid #606060'}}
                        align="center">Profit & Loss Statement
                    </td>
                </tr>
                <tr>
                    <td style={{borderBottom: '1px solid #606060'}}><strong>Account</strong></td>
                    <td style={{borderBottom: '1px solid #606060'}}><strong>Total</strong></td>
                </tr>
                </thead>
                {data.map((item, index) => (
                    <tbody key={index}>
                    <tr>
                        <td colSpan={2} style={{color: '#a3a3a3'}}>
                            {item.category}</td>
                    </tr>
                    {item.parent_accounts.map(it => (
                        <>
                        {it.sub_accounts.length > 0 ? <tr key={it.id}>
                            <td style={{paddingLeft: '.95rem'}}>{it.account_name}</td>
                            <td>{''}</td>
                        </tr>:null}
                            {it.sub_accounts.map(({name, resulting_amount}, index)=>(
                                <tr key={index}>
                                    <td style={{paddingLeft: '1.3rem'}}>{name}</td>
                                    <td>{resulting_amount}</td>
                                </tr>
                            ))}
                        </>
                    ))}
                    </tbody>
                ))}
                {/*<tfoot>*/}
                {/*<tr>*/}
                {/*    <td>*/}
                {/*        <strong>NET PROFIT/LOSS</strong>*/}
                {/*    </td>*/}
                {/*    <td>*/}
                {/*        <strong>500000</strong>*/}
                {/*    </td>*/}
                {/*</tr>*/}
                {/*</tfoot>*/}

            </table>
        </div>
    )
}

const PrintableTrialBalance = ({printRef, data}) =>{
    return (
        <div ref={printRef} style={{display:'none'}}>
            <table
                className='table table-borderless profile-table balance-sheet-data balance-sheet-table table-sm'
                id='trial_balance'  >
                <thead>
                <tr>
                    <td colSpan={3} style={{borderBottom: '1px solid #606060'}}
                        align="center">Trial Balance
                    </td>
                </tr>
                <tr>
                    <td style={{borderBottom: '1px solid #606060'}}><strong>Account</strong></td>
                    <td style={{borderBottom: '1px solid #606060'}}><strong>Debit</strong></td>
                    <td style={{borderBottom: '1px solid #606060'}}><strong>Credit</strong></td>
                </tr>
                </thead>
                {data.map((item, index) => (
                    <tbody key={index}>
                    {item.parent_accounts.map((it, index) => (
                        <>
                        {it.sub_accounts.length > 0 ? <tr key={index}>
                                <td>{it.account_name}</td>
                                <td>{''}</td>
                            </tr>:null}
                            {it.sub_accounts.map(({name, debit_amount, credit_amount}, index)=>(
                                <tr key={index}>
                                    <td style={{paddingLeft: '1.3rem'}}>{name}</td>
                                    <td>{debit_amount}</td>
                                    <td>{credit_amount}</td>
                                </tr>
                            ))}
                        </>
                    ))}
                    </tbody>
                ))}
                {/*<tfoot>*/}
                {/*<tr>*/}
                {/*    <td>*/}
                {/*        <strong>Total LIABILITIES & EQUITY</strong>*/}
                {/*    </td>*/}
                {/*    <td>*/}
                {/*        <strong>500000</strong>*/}
                {/*    </td>*/}
                {/*</tr>*/}
                {/*</tfoot>*/}
            </table>
        </div>
    )
}

export {PrintTableBalanceSheet, PrintablePLStatement,PrintableTrialBalance}

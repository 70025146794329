import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle, DialogContent} from "../../../Utils/Dialog/pharmacyDialog";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";


const PaymentDialog = (props) => {
    const {openDialog, handleCloseDialog} = props;
    return (
        <Dialog open={openDialog} fullWidth={true} maxWidth={'md'}>
            <DialogTitle onClose={handleCloseDialog}>
                <div className="text-center header-title">
                    Payment
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="card shadow-sm  p-3">
                    <h6>Payment Form</h6>
                    <hr/>
                    <Form autoCompleteOff>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Employee Name</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Total Salary</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Total Working Hours</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Total Working Days</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Salary Month</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text"/>
                            </Col>
                        </Form.Group>
                    </Form>
                    <div className="text-center">
                    <span>
                        <button onClick={handleCloseDialog} className="btn pharmacy-btn-dismiss mr-2">Cancel</button>
                        <button className="btn pharmacy-info-btn">Confirm</button>
                    </span>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )

};
export default PaymentDialog;
import React, { useEffect,  useState} from "react";
import Table from "react-bootstrap/Table";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import {Link} from 'react-router-dom';
import * as userActions from "../../Redux/Actions/UserActions/UserActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import DeleteDialog from "../../Utils/DeleteDialog";
import {titleCase} from "../../Utils/titleCaseFunction";
import {useMuiPagination} from "../../Containers/Dasboard/DashboardTables";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterResults from "react-filter-search";

const RoleList = ({actions, snackbars}) => {
    const [id, setRoleId] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [term, setTerm] = useState('');
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Permission/role_list`).then(response => {
            const data = response.data
            const dt = data ?? {};
            const list = dt.user_list ?? [];
            setRoles(list);
            setLoading(false)
        }).catch(() => {
            //errorMessages(error, null, actions)
            setLoading(false);
        })
    }, [])


    const handleSearch = (value) => {
        setTerm(value)
    }


    const handleOpenDelete = (_id = '') => {
        setRoleId(_id)
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    }

    const deleteRole = () => {
        axios.post(`${config.epharmUrl}/Permission/role_delete`, null, {params: {id}}).then(() => {
            actions.snackbarActions.successSnackbar('role deleted successfully');
            const arr = roles.filter(item => item.id !== id)
            setRoles(arr)
            setOpenDelete(false)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };


    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar()
    };
    const {open, message, variant} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const role_list = !permissions.role_list ? {} : permissions.role_list;
    const add_role = !permissions.add_role ? {} : permissions.add_role;
    const headData = [
        {id: 'role', numeric: false, disablePadding: false, label: 'Role'},
        (role_list.create || role_list.update || role_list.delete)
            ? {id: 'action', numeric: false, disablePadding: false, label: 'Action'} :
            {id: 'nonaction', numeric: false, disablePadding: false, label: ''},
    ];
    return (
        <div className='journals'>
            <DashboardHeader settings roleList/>
            <div className='general-ledger-header'>
                <div className="row">
                    <div className="col-lg-6">
                        <h6 style={{marginTop: '0.5rem'}}>Roles</h6>
                    </div>
                    <div className="col-lg-6">
                        <div className="text-right">
                            {(add_role.create || add_role.read || add_role.update || add_role.delete) ?
                                <Link to="/addrole" style={{textDecoration: "none", color: "inherit"}}>
                                    <button type="button" className="btn mr-2 pharmacy-info-btn btn-sm">Add Role
                                    </button>
                                </Link> : null}
                        </div>
                    </div>
                </div>
            </div>
            <DeleteDialog handleClose={handleCloseDelete} handleDelete={deleteRole}
                          openDialog={openDelete} message="role"/>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseSnackbar}/>
            <MuiPaper>
                <EnhancedTableToolbar title={''}>
                    <Form.Control type="text"
                                  onChange={event => handleSearch(event.target.value)}
                                  className='form__control'
                                  placeholder="Search" style={{float: 'right'}}/>
                </EnhancedTableToolbar>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="2" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader"
                                                                       style={{width: '5%'}}/></TableCell>
                        </TableRow></TableBody> : roles.length > 0 ?
                            <FilterResults
                                value={term}
                                data={roles}
                                renderResults={results => (
                                    <TableBody>
                                        {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((role) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={role.id}
                                                        className='mui-table-row'>
                                                        <TableCell>
                                                        {titleCase(role.type)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {role_list.update && <Link to={{
                                                                pathname: `/editrole/${role.id}/${role.type}`}}>
                                                                <button className="btn pharmacy-info-btn btn-sm mr-1">
                                                                    <FontAwesomeIcon icon={faPencilAlt}/>
                                                                </button>
                                                            </Link>}
                                                                {role_list.delete &&
                                                                <button onClick={() => handleOpenDelete(role.id)}
                                                                        className="btn pharmacy-btn-dismiss btn-sm mr-1 ">
                                                                    <FontAwesomeIcon icon={faTrash}/>
                                                                </button>}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                )}
                            />
                            : <TableBody><TableRow>
                                <TableCell colSpan="2" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                </TableContainer>
                <MuiPagination data={roles} rowsPerPage={rowsPerPage} page={page}
                               handleChangeRowsPerPage={handleChangeRowsPerPage}
                               handleChangePage={handleChangePage} rows={[10, 20, 30, 50, 100]} colSpan={3}/>
            </MuiPaper>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        users: state.users,
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            userActions: bindActionCreators(userActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
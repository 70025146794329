import React from 'react';
import Paper from "@material-ui/core/Paper";
import {EnhancedPharmacyListHead, EnhancedToolbar, getSorting, stableSort, useStyles} from "../../../Utils/Tables/MuiTables";
import Table from "@material-ui/core/Table";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import TablePagination from "@material-ui/core/TablePagination";
import DrugsAvailableTablePagination from "../../../Utils/Tables/MuiTablePagination";
import {withStyles} from "@material-ui/core/styles";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";

const data = [
    {
        sl: 1,
        month: "March 2020",
        generator: "Admin",
    },
    {
        sl: 2,
        month: "February",
        generator: "Admin",

    },

];
const ManageSalaryGenerate = (props) => {
    const [term, setTerm] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('month');
    const ref = React.useRef();
    const {classes} = props;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handler = (value) => {
        setTerm(value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0)
    };
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
        <div>
        <DashboardHeader payroll manageGenerate/>
        <div className="card styled-table-card p-3">
            <h6 className="heading-text">Manage Salary Generate</h6>
            <hr/>
            <Paper className={`${classes.paper} table-responsive`}>
                <EnhancedToolbar handler={handler} targetRef={ref} data={data}
                                 salaryGenerate/>

                <div className={classes.tableWrapper} ref={ref}>
                    <Table
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedPharmacyListHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                            salaryGenerate
                        />
                        <FilterResults
                            value={term}
                            data={data}
                            renderResults={results => (
                                <TableBody>
                                    {stableSort(results, getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((d, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell component="th" scope="row" padding="default"
                                                               className='mui-table-cell'>
                                                        <span>{d.sl}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.month}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.generator}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>
                                                            <button className="btn pharmacy-btn-dismiss btn-sm">
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </button>
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 49 * emptyRows}}>
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}
                        />
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    component="div"
                    page={page}
                    SelectProps={{
                        inputProps: {'aria-label': 'rows per page'},
                        native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={DrugsAvailableTablePagination}
                />
            </Paper>
        </div>
        </div>
    );
};

export default (withStyles(useStyles))(ManageSalaryGenerate);